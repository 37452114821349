import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";
import { Box, Typography } from "@mui/material";

const complaintData = [
  { month: "Apr-23", filed: 0, resolved: 0, pending: 0 },
  { month: "May-23", filed: 0, resolved: 0, pending: 0 },
  { month: "Jun-23", filed: 0, resolved: 0, pending: 0 },
  { month: "Jul-23", filed: 0, resolved: 0, pending: 0 },
  { month: "Aug-23", filed: 0, resolved: 0, pending: 0 },
  { month: "Sep-23", filed: 0, resolved: 0, pending: 0 },
  { month: "Oct-23", filed: 0, resolved: 0, pending: 0 },
  { month: "Nov-23", filed: 0, resolved: 0, pending: 0 },
  { month: "Dec-23", filed: 0, resolved: 0, pending: 0 },
  { month: "Jan-24", filed: 0, resolved: 0, pending: 0 },
  { month: "Feb-24", filed: 0, resolved: 0, pending: 0 },
  { month: "Mar-24", filed: 0, resolved: 0, pending: 0 },
];

const ComplaintResolutionStatusChart = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      padding="20px"
    >
      <Box
        display="flex"
        justifyContent="flex-start"
        width="100%"
        marginBottom="20px"
      >
        <Typography variant="h6">Complaint Resolution Status</Typography>
      </Box>
      <ResponsiveContainer width={1600} height={400}>
        <BarChart data={complaintData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month">
            <Label
              value="Time Period (Months)"
              offset={0}
              position="insideBottom"
            />
          </XAxis>
          <YAxis>
            <Label
              value="Number of Complaints"
              angle={-90}
              position="insideLeft"
            />
          </YAxis>
          <Tooltip />
          <Legend />
          <Bar
            dataKey="filed"
            stackId="a"
            fill="#d6e1f2" // Light blue color for filed complaints
            barSize={60}
          />
          <Bar
            dataKey="resolved"
            stackId="a"
            fill="#90e0ef" // Light blue for resolved complaints
            barSize={60}
          />
          <Bar
            dataKey="pending"
            stackId="a"
            fill="#ffbc42" // Light orange for pending complaints
            barSize={60}
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default ComplaintResolutionStatusChart;
