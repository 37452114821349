import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';
import supplyData from '../supply.json';

const fetchData = async () => {
     // Update path if needed
    const rawData = supplyData

    // Group data by category
    const categoryCounts = rawData.reduce((acc, item) => {
        acc[item.category] = (acc[item.category] || 0) + 1;
        return acc;
    }, {});

    // Convert to chart-friendly format
    const chartData = Object.keys(categoryCounts).map(category => ({
        category,
        count: categoryCounts[category]
    }));

    return chartData;
};

const SupplierCategoryChart = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        fetchData().then(setData);
    }, []);

    useEffect(() => {console.log(data)}, [data])

    return (
        <>
         <h5 className="text-center mb-4">No of Supplier's with MSI Assesments by Category</h5>
          
          <ResponsiveContainer width="100%" height={400}>
            <BarChart layout="vertical" data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis dataKey="category" type="category" width={200} />
                <Tooltip />
                <Bar dataKey="count" fill="#003f5c" barSize={30} />
            </BarChart>
        </ResponsiveContainer>
        </>
      
    );
};

export default SupplierCategoryChart;
