import React, { useEffect, useState } from "react";
import APIServices from "../../../../service/APIService";
import { API } from "../../../../constants/api_url";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { Dialog } from "primereact/dialog";
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
import { Editor } from 'primereact/editor';
import { Panel } from 'primereact/panel';
import { Card } from 'primereact/card';
import { saveAs } from "file-saver";
import { DateTime } from "luxon";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Swal from "sweetalert2";
import 'primeflex/primeflex.css';

import { TabMenu } from "primereact/tabmenu";
import { Tab, Row, Col, Nav, Table, Form, Modal } from 'react-bootstrap';
import SupplierPanel from "../SupplierScreen/SupplierPanel";
import { SupplierMSIDashboard } from "./SupplierMSIDashboard";

const SupplierHomeTVS = () => {
    const [assignments, setAssignments] = useState([])
    const [actionlist, setActionList] = useState([])
    const [activeAss, setActiveAss] = useState(null)
    const [asssrflist, setAssSrfList] = useState([])
    const [activeindex, setActiveIndex] = useState(0)
    const [activeform, setActiveForm] = useState({})
    const [selectedGmsOne, setSelectedGmsOne] = useState('GMS1'); // Default to GMS1
    const [currentFormData, setCurrentFormData] = useState({

        status: null,
        targetDate: null
    });

    // State for GMS Two nested tabs
    const [selectedGmsTwo, setSelectedGmsTwo] = useState('2.1'); // Default to 2.1

    // Read-only flag
    const [readOnly, setReadOnly] = useState(false); // Change to true if necessary
    const [supplierform, setSupplierForm] = useState(
        {
            response: [
                {
                    "type": "section",
                    "label": "This is section 1",
                    "name": "SRF274_1",
                    "required": true,
                    "expanded": false
                },
                {
                    "type": "text",
                    "label": "Total Scope 2 emissions (tCO2e)",
                    "name": "SRF274_2",
                    "required": true,
                    "expanded": false,
                    "placeholder": "Total Scope 2 emissions (tCO2e) of the organisation"
                },
                {
                    "type": "textarea",
                    "label": "Surface water withdrawal (KL)",
                    "name": "SRF274_3",
                    "required": true,
                    "expanded": false
                },
                {
                    "type": "section",
                    "label": "This is section 2",
                    "name": "SRF274_1",
                    "required": true,
                    "expanded": false
                },
                {
                    "type": "textarea",
                    "label": "Total Ground Water Withdrawal (KL)",
                    "name": "SRF274_9",
                    "required": true,
                    "expanded": false
                },
                {
                    "type": "text",
                    "label": "Total Third Party Water Withdrawal (KL)",
                    "name": "SRF274_10",
                    "required": true,
                    "expanded": false,
                    "placeholder": "Total Third Party Water Withdrawal (KL)"
                },
                {
                    "type": "text",
                    "label": "Water withdrawal from other sources (KL)",
                    "name": "SRF274_11",
                    "required": true,
                    "expanded": false
                },
                {
                    "type": "textarea",
                    "label": "Plastic Waste Generated (MT)",
                    "name": "SRF274_5",
                    "required": true,
                    "expanded": false
                },
                {
                    "type": "text",
                    "label": "Hazardous Waste generated (MT)",
                    "name": "SRF274_13",
                    "required": true,
                    "expanded": true
                },
                {
                    "type": "text",
                    "label": "Total No. of Male employees",
                    "name": "SRF274_6",
                    "required": true,
                    "expanded": false
                },
                {
                    "type": "text",
                    "label": "Total No. of Female Employees",
                    "name": "SRF274_7",
                    "required": true,
                    "expanded": false,
                    "placeholder": "Total no. of Female Employees"
                },
                {
                    "type": "text",
                    "label": "Total Products manufactured",
                    "name": "SRF274_8",
                    "required": true,
                    "expanded": false
                },
                {
                    "type": "text",
                    "label": "Total revenue for current financial year",
                    "name": "SRF274_12",
                    "required": true,
                    "expanded": false
                }
            ]
        })
    const [show, setShow] = useState(false)
    const userList = useSelector(state => state.userlist.userList)
    const supplierList = useSelector(state => state.userlist.supplierList)

    const admin_data = useSelector((state) => state.user.admindetail);
    const login_data = useSelector((state) => state.user.userdetail);
    const [assobj, setAssObj] = useState({ supplierId: null, auditor_ids: [], assessmentStartDate: null, assessmentEndDate: null, auditStartDate: null, auditEndDate: null, srfId: null })
    const [asssupplierobj, setAssSupplierObj] = useState({ supplierId: null })


    const [supplierlist, setSupplierList] = useState([])
    const [auditorlist, setAuditorList] = useState([])
    const [adddialog, setAddDialog] = useState(false)
    const [responseDialog, setResponseDialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [supplierdialog, setSupplierDialog] = useState(false)
    const [isDialogVisible, setDialogVisible] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const supplier = [
        {
            id: '240826-0058',
            supplierName: "Supplier A",
            questionary: "Questionary A",
            assessmentStartDate: "2024-10-01",
            assessmentEndDate: "2024-12-01",
            status: "Pending",
            score: 85
        },
        {
            id: '240826-0058',
            supplierName: "Supplier B",
            questionary: "Questionary B",
            assessmentStartDate: "2024-09-15",
            assessmentEndDate: "2024-11-15",
            status: "Completed",
            score: 90
        },
        {
            id: '240826-0058',
            supplierName: "Supplier C",
            questionary: "Questionary C",
            assessmentStartDate: "2024-08-20",
            assessmentEndDate: "2024-10-20",
            status: "In Progress",
            score: 75
        },
    ];
    const categoryOptions = [
        { id: 1, label: "Environment" },
        { id: 2, label: "Social" },
        { id: 3, label: "Governance" },
    ];

    const priorityOptions = [
        { id: 3, label: "High" },
        { id: 2, label: "Medium" },
        { id: 1, label: "Low" },
    ]
    const statusOptions = [
        { id: 1, label: "Not Started" },
        { id: 2, label: "In Progress" },
        { id: 3, label: "On Hold" },
        { id: 4, label: "Completed" },

    ]
    const [selectedAudit, setSelectedAudit] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [actionModal, setActionModal] = useState(false)
    const [actionmodal, showActionModal] = useState(false)

    const viewAudit = (rowData) => {
        setSelectedAudit(rowData)
        setActionModal(true)
    }

    const forceUpdate = useForceUpdate()


    useEffect(() => {
        let uriString = {

            "where": {
                "supplierId": login_data.id
            },
            "include": [
                {
                    "relation": "supplierAssignmentSubmission"
                },
                {
                    "relation": "supplierActions"
                },
                {
                    "relation": "srf"
                }
            ]

        };

        let asssection = {


            "include": [
                {
                    "relation": "assessmentSubSection1s", "scope": { "include": [{ "relation": "assessmentSubSection2s", "scope": { "include": ["srf"] } }] }
                }
            ]

        };
        const promise0 = APIServices.get(API.SupplierAssessmentAss_Up(admin_data.id))
        const promise1 = APIServices.get(API.SupplierAssessmentAss_Up(admin_data.id) + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`)
        const promise2 = APIServices.get(
            API.AssignDCFClient_UP(admin_data.id)
        );
        const promise3 = APIServices.get(API.SRF)
        const promise4 = APIServices.get(API.GetRole_Up(admin_data.id))

        Promise.all([promise0, promise1, promise2, promise3, promise4]).then((values) => {
            let srf_list = values[3].data
            setAssignments(values[1].data)
            setActionList(values[1].data.filter(i => i.supplierActions).flatMap(i => i.supplierActions))
            if (values[2].data.length && values[2].data[0].cf_ids && values[2].data[0].cf_ids.length) {
                let assSrfList = values[2].data[0].cf_ids.filter(i => srf_list.map(x => x.id).includes(i)).map(i => srf_list.find(x => x.id === i))
                setAssSrfList(assSrfList)
                console.log(assSrfList)
                setSupplierList(supplierList.filter(i => i.role === 'clientsupplier'))
                setAuditorList(userList.filter(i => values[4].data.some(x => x.user_id === i.id && x.roles && x.roles.includes(17))))

            }
         
        })
    }, [])
    useEffect(() => {
        console.log(supplierlist, auditorlist)
    }, [supplierlist, auditorlist])

    const addfooter = () => {
        return (
            <Button label="Save" onClick={saveAssignment} />
        )
    }
    const responseFooter = () => {
        return (
            <>
                <Button label="Save As Draft" onClick={draftSupplierResponse} />
                <Button label="Save" onClick={saveSupplierResponse} />
            </>

        )
    }
    const draftSupplierResponse = () => {
        let newObj = {}
        let dt = DateTime.utc()



        newObj['response'] = supplierform.response
        if (supplierform.id) {
            newObj['type'] = 0
            newObj['modified_by'] = login_data.id
            newObj['modified_on'] = DateTime.utc()
            APIServices.patch(API.SupplierAssessmentSubmission_Edit(supplierform.id), newObj).then((res) => {



                Swal.fire({
                    title: "Your Responses Have Been Recorded & Saved as Draft",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        window.close()
                    }
                })
            }).catch((e) => {
                Swal.fire({
                    title: "Something went wrong, try after some time. Contact admin if issue still persist",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        window.close()
                    }
                })

            })
        } else {
            newObj['srfId'] = activeform.srfId
            newObj['userProfileId'] = admin_data.id
            newObj['created_by'] = login_data.id
            newObj['created_on'] = DateTime.utc()
            newObj['supplierId'] = login_data.id
            newObj['type'] = 0
            APIServices.post(API.SupplierAssessmentSubmission(activeform.id), newObj).then((res) => {



                Swal.fire({
                    title: "Your Responses Have Been Recorded & Saved as Draft",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        window.close()
                    }
                })
            }).catch((e) => {
                Swal.fire({
                    title: "Something went wrong, try after some time. Contact admin if issue still persist",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        window.close()
                    }
                })

            })
        }





        forceUpdate()
    }

    const saveSupplierResponse = () => {

        let newObj = {}
        let dt = DateTime.utc()




        newObj['response'] = supplierform.response
        if (supplierform.id) {
            newObj['type'] = 1
            newObj['modified_by'] = login_data.id
            newObj['modified_on'] = DateTime.utc()
            APIServices.patch(API.SupplierAssessmentSubmission_Edit(supplierform.id), newObj).then((res) => {



                Swal.fire({
                    title: "Thanks, Your Responses Have Been Recorded & Sent To Respective Auditor",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        window.close()
                    }
                })
            }).catch((e) => {
                Swal.fire({
                    title: "Something went wrong, try after some time. Contact admin if issue still persist",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        window.close()
                    }
                })

            })
        } else {
            newObj['srfId'] = activeform.srfId
            newObj['created_by'] = login_data.id
            newObj['created_on'] = DateTime.utc()
            newObj['supplierId'] = login_data.id
            newObj['userProfileId'] = admin_data.id
            newObj['type'] = 1
            APIServices.post(API.SupplierAssessmentSubmission(activeform.id), newObj).then((res) => {



                Swal.fire({
                    title: "Thanks, Your Responses Have Been Recorded & Sent To Respective Auditor",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        window.close()
                    }
                })
            }).catch((e) => {
                Swal.fire({
                    title: "Something went wrong, try after some time. Contact admin if issue still persist",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        window.close()
                    }
                })

            })
        }





        forceUpdate()




    }

    const saveAssignmentSupplier = () => {
        setSubmitted(true)
        if (asssupplierobj.supplierId) {
            let newObj = {
                supplierId: asssupplierobj.supplierId
            }
            APIServices.post(API.SupplierList_supass(activeAss.id), { ...newObj, srfId: activeAss.srfId, mailSent: 1, created_on: DateTime.utc(), created_by: login_data.id }).then((res) => {
                let loc = JSON.parse(JSON.stringify(assignments))
                let index = loc.findIndex(i => i.id === activeAss.id)
                if (index !== -1) {
                    if (loc.assessmentSupplierLists) {
                        loc[index].assessmentSupplierLists.push(res.data)
                    } else {
                        loc[index].assessmentSupplierLists = [res.data]
                    }
                }

                setAssignments(loc)

            })
        }

    }
    const saveAssignment = () => {
        setSubmitted(true)
        if (assobj.srfId && assobj.supplierId && assobj.assessmentStartDate && assobj.auditor_ids.length) {
            let newObj = {
                assessmentStartDate: assobj.assessmentStartDate,
                assessmentEndDate: assobj.assessmentEndDate,
                auditStartDate: assobj.auditStartDate,
                auditEndDate: assobj.auditEndDate,
                auditor_ids: assobj.auditor_ids,
                srfId: assobj.srfId,
                supplierId: assobj.supplierId
            }
            console.log(assobj.id)
            if (assobj.id) {
                APIServices.patch(API.SupplierAssessmentAss_Edit(assobj.id), { ...newObj, modified_on: DateTime.utc(), modified_by: login_data.id }).then((res) => {
                    let loc = JSON.parse(JSON.stringify(assignments))
                    let index = loc.findIndex(i => i.id === assobj.id)
                    if (index !== -1) {
                        loc[index] = { ...assobj, modified_on: DateTime.utc(), modified_by: login_data.id }
                        setAssignments(loc)
                    }

                })
            } else {
                APIServices.post(API.SupplierAssessmentAss_Up(admin_data.id), { ...newObj, created_on: DateTime.utc(), created_by: login_data.id }).then((res) => {
                    let loc = JSON.parse(JSON.stringify(assignments))
                    loc.push(res.data)
                    setAssignments(loc)
                })

            }

        }
    }

    const updateAssObj = (obj, val) => {
        let loc = assobj
        loc[obj] = val
        setAssObj(loc)
        forceUpdate()

    }
    const supplierNameTemplate = (rowData) => {

        let txt = [{ name: 'Supplier 1', id: 1 }, { name: 'Supplier 2', id: 2 }, { name: 'Supplier 3', id: 3 }].find(i => rowData.supplierId === i.id)
        console.log(txt)
        return (<>{!txt ? '' : txt.name} </>)

    }
    const statusTemplate = (rowData) => {
        console.log(rowData)
        return rowData.supplierAssignmentSubmission ? rowData.supplierAssignmentSubmission.type === 1 ? 'Submitted' : "Drafted" : 'Not Submitted'

    }
    // const idTemplate = (rowData) => {
    //     let res ={}
    //     if(rowData.supplierAssignmentSubmission){
    //         res =rowData.supplierAssignmentSubmission

    //     }else{
    //         res= {response:rowData.srf.data1}
    //     }

    //     return <div className='clr-navy fw-6 fs-14 text-underline' onClick={() => { setShow(res.id ? res.type === 1 ? true : false  : false); setSupplierForm(res); setResponseDialog(true); setActiveForm(rowData) }}> {rowData.id} </div>
    // }
    const idTemplate = (rowData) => {
        return (
            // <a
            //     href="#"
            //     onClick={() => openDialog(rowData)} // Trigger dialog open
            //     style={{ textDecoration: 'none', color: '#007bff' }}
            // >
            //     {rowData.id}
            // </a>

            <div className='maskid' onClick={() => viewAudit(rowData)}>
                {rowData.id}
            </div>
        );
    };



    const scoreTemplate = (rowData) => {
        return rowData.supplierAssignmentSubmission ? rowData.supplierAssignmentSubmission.type === 1 ? '0' : "-" : 'NA'
    }
    const getDate = (date, format) => {

        if (!date) {
            return 'Not Set'
        } if (typeof date === 'string') {
            return DateTime.fromISO(date, { zone: 'utc' }).toLocal().toFormat(format ? format : 'dd-MM-yyyy')
        } else if (DateTime.isDateTime(date)) {
            return date.toFormat(format ? format : 'dd-MM-yyyy')
        } else {
            return DateTime.fromJSDate(date).toLocal().toFormat(format ? format : 'dd-MM-yyyy')
        }

    };
    const dateTemplate = (date) => {
        console.log(getDate(date))
        return getDate(date)

    }
    const getSupplier = (id) => {

        let user_name = 'Not Found'
        if (id === admin_data.id) {
            return 'Enterprise Admin'
        }
        let index = supplierList.findIndex(i => i.id === id)
        if (index !== -1) {
            user_name = supplierList[index].information.supplierName
        }
        return user_name

    }
    const resendMail = (rowData) => {
        let assessment = assignments.find(i => i.id === activeAss.id)
        let txt = supplierList.find(i => rowData.supplierId === i.id)
        if (assessment) {
            let body = `<p>Hi ${txt.information.empname}</p>  <p>You have received mail in order to fill your Questionary Response, <a href="${window.location.origin}/supplier/assessment/${rowData.id}">click here </a> to open Supplier Assessment Questionary Form</p><hr/><p style='font-style:italic'>This email is an automated notification. Please do not reply to this message</p>`
            APIServices.post(API.SubmissionMail, { email: [txt.email], subject: 'Supplier Assessment Form - ' + assessment.title, body: body }).then(res => {
                Swal.fire({
                    position: "center",
                    icon: "warning",
                    title: "Mail Sent Successfully",
                    showConfirmButton: false,
                    timer: 1500,
                });
            })

        }


    }

    const onCheckBoxSelected = (item, cbind) => {
        console.log(item)
        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = !items.selected
            }
        })
        forceUpdate()
    }
    const onRadioButtonSelected = (item, cbind) => {
        console.log(item)
        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = true
            } else {
                items.selected = false
            }
        })
        forceUpdate()
    }
    const onDateSelected = (item, val) => {

        item.value = val;
        forceUpdate()
    }
    const onNumberChange = (item, val, nan) => {
        console.log(item, val)

        item.value = val;


        forceUpdate()
    }
    const onCommentChange = (item, val, nan) => {

        item.comment = val;
        forceUpdate()
    }
    const onChangeDropwdown = (item, val) => {
        item.value = val;
        console.log(val)
        item.values.forEach((i) => {
            if (i.value === val) {
                i.selected = true
            } else {
                i.selected = false
            }
        })
        forceUpdate()
    }
    const renderItems = (item, index) => {
        const getLabel = (item) => item.label.replace(/(<([^>]+)>)/gi, "").replace(/\n/g, " ").replace(/&nbsp;/g, " ").replace('&amp;', '&');

        return (
            <div key={index} className="form-row grid m-0 p-fluid" style={{ padding: 10, border: item.error === 1 ? '1px solid red' : 'none' }}>
                <Tooltip target={`.tooltip-${index}`} position="top" />

                {/* Query Column */}
                <label className="col-4 fs-16 fw-5 text-justify">
                    {getLabel(item)}
                    {item.required && <span className="mandatory mr-2">*</span>}
                    {item.description && <i className={`material-icons fs-14 tooltip-${index}`} data-pr-tooltip={item.description}>help</i>}
                </label>

                {/* Response Column */}
                <div className="col-4">
                    {item.type === 'checkbox-group' && item.values.map((cb, cbind) => (
                        <div className="flex align-items-center" key={cbind}>
                            <Checkbox disabled={show} inputId={`cb-${index}-${cbind}`} name={cb.label} value={cb.value} onChange={(e) => onCheckBoxSelected(item, cbind)} checked={cb.selected} />
                            <label htmlFor={`cb-${index}-${cbind}`} className="ml-2">{cb.label}</label>
                        </div>
                    ))}

                    {item.type === 'date' && (
                        <Calendar disabled={show} value={item.value ? new Date(item.value) : null} onChange={(e) => onDateSelected(item, e.value)} />
                    )}

                    {item.type === 'number' && (
                        <InputNumber disabled={show} value={item.value} onChange={(e) => onNumberChange(item, e.value)} />
                    )}

                    {item.type === 'radio-group' && item.values.map((rb, rbindex) => (
                        <div className="flex align-items-center" key={rbindex}>
                            <RadioButton disabled={show} inputId={`rb-${index}-${rbindex}`} name={rb.label} value={rb.value} onChange={(e) => onRadioButtonSelected(item, rbindex)} checked={rb.selected === true} />
                            <label htmlFor={`rb-${index}-${rbindex}`} className="ml-2">{rb.label}</label>
                        </div>
                    ))}

                    {item.type === 'select' && (
                        <Dropdown disabled={show} value={item.value} options={item.values} optionLabel="label" onChange={(e) => onChangeDropwdown(item, e.value)} />
                    )}

                    {item.type === 'textarea' && (
                        <Editor disabled={show} value={item.value} onTextChange={(e) => onNumberChange(item, e.htmlValue)} />
                    )}

                    {item.type === 'text' && (
                        <InputText disabled={show} value={item.value} onChange={(e) => onNumberChange(item, e.target.value)} />
                    )}
                </div>

                {/* Comments Column */}
                <div className="col-4">
                    <InputText disabled={show} value={item.comments} onChange={(e) => onCommentChange(item, e.target.value)} placeholder="Add comments" />
                </div>
            </div>
        );
    };

    const questionaryTemplate = (rowData) => {

        let txt = asssrflist.find(i => rowData.srfId === i.id)
        console.log(txt)
        return (<>{!txt ? '' : txt.title} </>)

    }

    const openDialog = (rowData) => {
        setSelectedRow(rowData); // Store the clicked row's data
        setDialogVisible(true);  // Show the dialog
    };

    // Function to close the dialog
    const hideDialog = () => {
        setDialogVisible(false);
        setSelectedRow(null); // Clear the selected row
    };
    const updateAssessment = (val) => {
        setSelectedAudit(val);
        let loc = JSON.parse(JSON.stringify(assignments))
        let index = loc.findIndex(i => i.id === val.id)
        if (index) {
            loc[index].supplierAssignmentSubmission = val.supplierAssignmentSubmission;
            setAssignments(loc)
            forceUpdate()
        }
    }
    const footer = () => {
        return (
            <div className='flex col-12 justify-content-end'>
                <Button label='Save & Exit' onClick={() => { handleSubmit() }} />
            </div>
        )
    }
    const handleSubmit = async (e) => {
        setSubmitted(true)
        let loc = JSON.parse(JSON.stringify(actionlist))
        let obj = { status: currentFormData.status, targetDate: currentFormData.targetDate }
        if (obj.status && obj.targetDate) {
            if (currentFormData.id) {
                let newObj = { modified_by: login_data.id, modified_on: DateTime.utc() }
                APIServices.patch(API.SupplierAction_Edit(currentFormData.id), { ...newObj, ...obj }).then((res) => {
                    let index = loc.findIndex(i => i.id === currentFormData.id)
                    if (index !== -1) {
                        loc[index] = { ...currentFormData, ...newObj, ...obj }
                        setActionList(loc)
                    }
                    setSubmitted(false)
                    showActionModal(false)
                })
            }
        }

    };
    const categoryTemplate = (option) => {
        return categoryOptions.find(c => c.id === option.category)?.label || 'Not Found';
    };
    const statusTemplate_ = (option) => {
        return statusOptions.find(c => c.id === option.status)?.label || 'Not Found';
    };
    const priorityTemplate = (option) => {
        return priorityOptions.find(c => c.id === option.priority)?.label || 'Not Found';
    };
    const sNoTemplate = (rowData, { rowIndex }) => <span className="cur-pointer clr-navy text-underline" onClick={() => { editAction(rowData) }}>{rowData.title}</span>;

    const editAction = (rowData) => {
        let loc = JSON.parse(JSON.stringify(rowData))
        if (loc.dueDate) {
            loc.targetDate = DateTime.fromISO(loc.targetDate, { zone: 'utc' }).toLocal().toJSDate()
        }
        setCurrentFormData(loc)
        showActionModal(true)

    }

    return (
        <div className="col-12">

            <div className="col-12">
                <TabMenu model={[{ label: 'Assessment', icon: 'pi pi-book' }, { label: 'My Action', icon: 'pi pi-th-large' }
                ]} onTabChange={(e) => { setActiveIndex(e.index) }} activeIndex={activeindex} />



                {activeindex === 0 && <div className="col-12">

                    <DataTable value={assignments}>
                        <Column header="ID" body={idTemplate} />
                        <Column header="Supplier Name" body={(rowData) => getSupplier(rowData.supplierId)} />
                        <Column header="Self Assessment Start Date" body={(rowData) => dateTemplate(rowData.assessmentStartDate)} />
                        <Column header="Self Assessment End Date" body={(rowData) => dateTemplate(rowData.assessmentEndDate)} />
                        <Column header="Status" body={statusTemplate} />

                    </DataTable>

                </div>}
                {activeindex === 1 &&
                    <div className="col-12">
                        <DataTable value={actionlist} paginator rows={5} className="datatable-responsive styled-table">

                            <Column field="title" header="Action" body={sNoTemplate} style={{ width: "25%" }} />
                            <Column field="category" header="Category" body={categoryTemplate} style={{ width: "15%" }} />
                            <Column field="priority" header="Priority" body={priorityTemplate} style={{ width: "15%" }} />
                            <Column field="status" header="Status" body={statusTemplate_} style={{ width: "15%" }} />
                            <Column
                                field="targetDate"
                                header="Expected Completion Date"
                                body={rowData => getDate(rowData.targetDate, 'dd-MM-yyyy')}
                                style={{ width: "15%" }}
                            />
                            <Column
                                field="dueDate"
                                header="Target Date"
                                body={rowData => getDate(rowData.dueDate, 'dd-MM-yyyy')}
                                style={{ width: "15%" }}
                            />


                        </DataTable>
                    </div>

                }

                {selectedAudit && <Dialog
                    visible={actionModal}
                    style={{ width: '75%' }}
                    onHide={() => setActionModal(false)}


                >



                    <SupplierPanel users={supplierList} updateData={(e) => { updateAssessment(e) }} readOnly={true} auditId={selectedAudit} />



                </Dialog>
                }
                {<Dialog visible={actionmodal} style={{ width: '75%' }} footer={footer} header={"Update Action"} onHide={() => { showActionModal(false) }}>
                    <div className="p-fluid grid m-0 p-3">
                        {currentFormData.description && <div className="p-field col-12 font-italic ">
                            {currentFormData.description}
                        </div>}
                        <div className="p-field col-6 ">
                            <label htmlFor="status">Select Status<span className="mandatory"> *</span>  </label>
                            <Dropdown id="status" className='mt-2' value={currentFormData.status} optionLabel="label" optionValue='id' options={statusOptions} onChange={(e) => { setCurrentFormData((prev) => ({ ...prev, status: e.value })) }} placeholder="Select Priority" />

                            {submitted && !currentFormData.status && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >

                                    Select Status
                                </small>
                            )}
                        </div>
                        <div className="p-field col-6 ">
                            <label htmlFor="status">Expected Completion Date<span className="mandatory"> *</span>  </label>
                            <Calendar dateFormat="dd/m/yy" minDate={new Date()} id="status" className='mt-2' value={currentFormData.targetDate} onChange={(e) => { setCurrentFormData((prev) => ({ ...prev, targetDate: e.value })) }} placeholder="Select Target Date" />

                            {submitted && !currentFormData.targetDate && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >

                                    Set Target Date
                                </small>
                            )}
                        </div>
                    </div>

                </Dialog>}

            </div>

        </div>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(SupplierHomeTVS, comparisonFn);