import { ContextMenu } from "primereact/contextmenu";
import React, { useEffect, useState, useRef } from "react";
import EdiText from "react-editext";
import useForceUpdate from "use-force-update";
import APIServices from "../../service/APIService";
import { API } from "../../constants/api_url";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import Swal from "sweetalert2"; 
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import TextEditor from "../../components/TextEditor/TextEditor";



const DropdownConfig = () => { 


    const [list, setList] = useState([{ title: 'Initiative Category', active: true, id: 1 }])
    const [activeIndex, setActiveIndex] = useState(1)
    const [tier, setTier] = useState({ tier1: [], tier2: [] })
    const [title, setTitle] = useState({ tier1: '', tier2: '' })
    const [text, setText] = useState('')
    const [selected, setSelected] = useState({ tier1: '', tier2: '' })
    const tier4ref = useRef([]);
    const tier3ref = useRef([]);
    const tier2ref = useRef([]);
    const tier1ref = useRef([]);
    const forceUpdate = useForceUpdate()
    const selector = useSelector((state) => state.user.userdetail);
    useEffect(() => {
        APIServices.get(API.DropDownCategory).then((res) => {
            setTier({ tier1: res.data, tier2: [] })
        })
    }, [])

    const addNewTier1 = () => {
        let index = tier.tier1.findIndex((i) => { return i.title.trim().toLowerCase() === title.tier1.trim().toLowerCase() })

        if (index === -1 && title.tier1.trim().length !== 0) {
            APIServices.post(API.DropDownCategory,
                {
                    title: title.tier1.trim(), created_on: DateTime.utc(), created_by: selector.id
                },
            )
                .then((res) => {
                    console.log(res);
                    if (res.status === 200) {
                        let tier_ = tier;
                        tier_.tier1.push(res.data);
                        setTier(tier_);
                        setTitle({
                            tier1: "",
                            tier2: "",
                            tier3: "",

                            tier4: ''
                        });
                    }
                })
                .catch((e) => {
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: `Something went wrong, try again later `,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                });
        }
    }
    const addNewTier2 = () => {
        let index = tier.tier2.findIndex((i) => { return i.title.trim().toLowerCase() === title.tier2.trim().toLowerCase() })

        if (index === -1 && title.tier2.trim().length !== 0) {
            APIServices.post(API.DDCategoryOne_DropDownCategory(selected.tier1),
                {
                    title: title.tier2.trim(), created_on: DateTime.utc(), created_by: selector.id
                },
            )
                .then((res) => {
                    console.log(res);
                    if (res.status === 200) {
                        let tier_ = tier;
                        tier_.tier2.push(res.data);
                        setTier(tier_);
                        setTitle({
                            tier1: "",
                            tier2: "",
                            tier3: "",

                            tier4: ''
                        });
                    }
                })
                .catch((e) => {
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: `Something went wrong, try again later `,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                });
        }
    }
    const getTier2Data = (id) => {
        let sel = selected;
        sel.tier1 = id;
        sel.tier2 = "";

        setSelected(sel);

        APIServices.get(API.DDCategoryOne_DropDownCategory(id),
        ).then((res) => {
            setTier((prev) => ({ ...prev, tier2: res.data }));
        });
    }
    const setEditModeOffTier1 = () => {
        tier.tier1.map((i, j) => { i.edit = false })

        forceUpdate()
    }
    const setEditModeTier1 = (cyindex) => {
        tier.tier1.map((i, j) => { if (j === cyindex) { i.edit = true } else { i.edit = false } })

        forceUpdate()
    }
    const setEditModeTier2 = (cyindex) => {
        tier.tier2.map((i, j) => { if (j === cyindex) { i.edit = true } else { i.edit = false } })

        forceUpdate()
    }
    const deleteTier2 = (item) => {
        Swal.fire({
            title: `Are you sure want to delete ${item.title} ?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                let index = tier.tier2.findIndex((i) => { return i.id === item.id })
                APIServices.delete(API.DDCategoryOne_Edit + '/' + item.id
                ).then((res) => {
                    let sel = selected;

                    sel.tier2 = ''

                    setSelected(sel);

                    let loc = tier
                    loc.tier2.splice(index, 1);

                    setTier(loc);
                    forceUpdate();

                });
            }
        })

    }
    const deleteTier1 = (item) => {
        Swal.fire({
            title: `Are you sure want to delete ${item.title} ?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                let index = tier.tier1.findIndex((i) => { return i.id === item.id })
                APIServices.delete(API.DropDownCategory + '/' + item.id).then((res) => {
                    let sel = selected;

                    sel.tier1 = ''
                    sel.tier2 = ''

                    setSelected(sel);

                    let loc = tier

                    loc.tier1.splice(index, 1);

                    setTier(loc);
                    forceUpdate();

                });
            }
        })

    }
    const setEditModeOffTier2 = () => {
        tier.tier2.map((i, j) => { i.edit = false })

        forceUpdate()
    }
    const updateTier1 = (e, id) => {
        console.log(e)
        let index = tier.tier1.findIndex((i) => {
            return i.id === id;
        });
        if (index !== -1) {
            APIServices.patch(API.DropDownCategory + '/' + tier.tier1[index].id,
                {
                    title: e.trim(), modified_by: selector.id, modified_on: DateTime.utc()
                },
            ).then((res) => {
                let loc = tier
                loc.tier1[index].title = e.trim();
                setTier(loc);
                forceUpdate()
            });
        }
    }
    const updateTier2 = (e, id) => {
        console.log(e)
        let index = tier.tier2.findIndex((i) => {
            return i.id === id;
        });
        if (index !== -1) {
            APIServices.patch(API.DDCategoryOne_Edit + '/' + tier.tier2[index].id,
                {
                    title: e.trim(), modified_by: selector.id, modified_on: DateTime.utc()
                },
            ).then((res) => {
                let loc = tier
                loc.tier2[index].title = e.trim();
                setTier(loc);
                forceUpdate()
            });
        }
    }
    const showTier1CM = (e, ind) => {

        tier1ref.current.forEach((i, j) => {
            if (j === ind) {
                tier1ref.current[ind].show(e)
            } else {
                if (tier1ref.current[j] !== null) {
                    tier1ref.current[j].hide(e)
                }
            }
        })
        tier2ref.current.forEach((i, j) => {
            console.log(tier2ref.current[j])
            if (tier2ref.current[j] !== null) {
                tier2ref.current[j].hide(e)

            }
        })
        tier3ref.current.forEach((i, j) => {

            if (tier3ref.current[j] !== null) {
                tier3ref.current[j].hide(e)
            }

        })
        tier4ref.current.forEach((i, j) => {

            if (tier4ref.current[j] !== null) {
                tier4ref.current[j].hide(e)

            }
        })

    }
    const showTier2CM = (e, ind) => {

        tier2ref.current.forEach((i, j) => {
            if (j === ind) {
                tier2ref.current[ind].show(e)
            } else {
                if (tier2ref.current[j] !== null) {
                    tier2ref.current[j].hide(e)
                }
            }
        })
        tier1ref.current.forEach((i, j) => {

            if (tier1ref.current[j] !== null) {
                tier1ref.current[j].hide(e)

            }
        })
        tier3ref.current.forEach((i, j) => {

            if (tier3ref.current[j] !== null) {
                tier3ref.current[j].hide(e)

            }
        })
        tier4ref.current.forEach((i, j) => {

            if (tier4ref.current[j] !== null) {
                tier4ref.current[j].hide(e)

            }
        })
    }
    const updateTierValue = (obj, e) => {
        let mod = title;
        mod[obj] = e.target.value;
        setTitle(mod);
        forceUpdate();
    }
    const updateList = (index) => {
        let loc = list
        setActiveIndex(loc[index].id)
        loc.forEach((item, ind) => {
            if (ind === index) {
                item.active = true
            } else {
                item.active = false
            }

        })
        setList(loc)
        forceUpdate()
    }

    return (
        <div className="font-lato bg-white " style={{ height: "calc(100vh - 80px)" }}>
            <div className="card">        <h3> DropDown Configuration </h3>

                <div>
                    <div className="col-12">
                        <div className="col-4">
                            <div className="grid">
                                <div className="col-10">
                                    <InputText
                                        type={"text"}
                                        style={{ width: '100%' }}
                                        value={title.tier1}
                                        onChange={(e) => {
                                            updateTierValue("tier1", e);
                                        }}
                                        placeholder="Enter Category here..."
                                    />
                                </div>
                                <div className="col-2" style={{ marginLeft: -10 }}>
                                    <Button
                                        icon="pi pi-plus"

                                        style={{ cursor: title.tier1.trim().length === 0 ? 'not-allowed' : 'pointer', background: title.tier1.trim().length === 0 ? 'gray' : '#22C55E', border: title.tier1.trim().length === 0 ? '1px solid gray' : '1px solid #22C55E' }}
                                        className="p-button-success mr-2"
                                        onClick={() => {
                                            addNewTier1();
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="grid">

                            <div className="col-4">

                                <div
                                    className="col-12"
                                    style={{

                                        height: "calc(100vh - 200px)",
                                        overflow: "auto"
                                    }}
                                >
                                    {tier.tier1.length === 0 ? (
                                        <text
                                            style={{
                                                padding: 5,
                                            }}
                                        >

                                            {"Add Category"}
                                        </text>
                                    ) : (
                                        tier.tier1.map((item, cyindex) => {
                                            return (

                                                <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', borderBottom: '1px solid #315975',padding: '5px 0px' }}>

                                                    <div className="col-12 active"
                                                        onClick={() => {
                                                            getTier2Data(item.id);
                                                        }}
                                                        style={{


                                                         padding: '5px 0px',margin:'5px 0px'
                                                        }}
                                                    >
                                                        <TextEditor value={item.title} onSave={(e) => { updateTier1(e, item.id); }} onCancel={(e) => { console.log(e) }} saveIcon={<i className="pi pi-check" />}
                                                            cancelIcon={<i className="pi pi-times" />}
                                                            optionIcon={<i className="pi pi-ellipsis-v" />} />

                                                        {/* <EditText
                                                                            onSave={(e) => {
                                                                                if (e.value.trim().length !== 0) {
                                                                                    updateTier1(e);
                                                                                }
                                                                            }}
                                                                            name="textbox3"
                                                                            // value={item.country}
                                                                            defaultValue={item.title}
                                                                            editButtonProps={{
                                                                                style: {
                                                                                    marginLeft: "5px",
                                                                                    width: 16,
                                                                                },
                                                                            }}
                                                                            showEditButton
                                                                        /> */}
                                                    </div>

                                                </div>

                                            );
                                        })
                                    )}
                                </div>
                            </div>
                            {selected.tier1 !== "" && (
                                <div className="col-8" style={{ borderLeft: '1px solid #315975' }}>
                                    <div >
                                        <div className="col-6 fw-6 fs-16 clr-navy text-three-dot">
                                            Add {tier.tier1.find(x => x.id === selected.tier1).title}
                                        </div>
                                        <div className="col-12 grid m-0 p-0">
                                        <div className="col-8  ">
                                            <InputText
                                                type={"text"}
                                                style={{ width: '100%' }}
                                                value={title.tier2}
                                                onChange={(e) => {
                                                    updateTierValue("tier2", e);
                                                }}
                                                placeholder={"Add "}
                                            />
                                        </div>
                                        <div className="col-2" style={{ marginLeft: -10 }}>
                                            <Button
                                                icon="pi pi-plus"
                                                className="p-button-success mr-2"
                                                style={{ cursor: title.tier2.trim().length === 0 ? 'not-allowed' : 'pointer', background: title.tier2.trim().length === 0 ? 'gray' : '#22C55E', border: title.tier2.trim().length === 0 ? '1px solid gray' : '1px solid #22C55E' }}
                                                onClick={() => {
                                                    addNewTier2();
                                                }}
                                            />
                                        </div>
                                        </div>
                                    </div>
                                    <div
                                        className="col-12"
                                        style={{

                                            height: "calc(100vh - 200px)",
                                            overflow: "auto"
                                        }}
                                    >
                                        {tier.tier2.length === 0 ? (
                                            <text
                                                style={{
                                                    padding: 5,
                                                }}
                                            >

                                                {"Enter title here...  "}
                                            </text>
                                        ) : (
                                            tier.tier2.map((item, cyindex) => {
                                                return (
                                                    <>
                                                        <div className="col-8" style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', borderBottom: '1px solid #315975',padding: '5px 0px' }}>

                                                            <div className={"col-12 frame" + item.id}

                                                                style={{
                                                                    padding: '5px 0px',margin:'5px 0px'
                                                                
                                                                }}
                                                            >
                                                                <TextEditor value={item.title} onSave={(e) => { updateTier2(e, item.id); }} onCancel={(e) => { console.log(e) }} saveIcon={<i className="pi pi-check" />}
                                                                    cancelIcon={<i className="pi pi-times" />}
                                                                    optionIcon={<i className="pi pi-ellipsis-v" />} overlayOptions={[{
                                                                        label: 'Delete',
                                                                        command: () => { deleteTier2(item) }
                                                                    }]} />


                                                            </div>

                                                        </div>
                                                    </>
                                                );
                                            })
                                        )}
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>



            </div>
        </div>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(DropdownConfig, comparisonFn);