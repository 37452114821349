import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";

const data = [
  {
    year: "FY-2023",
    surface_water: 16329,
    ground_water: 562644,
    third_party_water: 41163.21,
  },
  {
    year: "FY-2024",
    surface_water: 0,
    ground_water: 0,
    third_party_water: 0,
  },
];

const DemoWaterWithdrawal = () => {
  return (
    <div style={{ height: 500 }}>
      <h3 style={{ fontSize: "18px", margin: "25px" }}>Water Withdrawal</h3>
      <ResponsiveContainer>
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="year" />

          <YAxis>
            <Label
              value="Kilo Liters(KL)"
              angle={-90}
              position="insideLeft"
              style={{ textAnchor: "middle", fill: "#333", fontSize: "14px" }}
            />
          </YAxis>
          <Tooltip />
          <Legend verticalAlign="bottom" />

          <Bar
            dataKey="surface_water"
            name="Surface Water"
            fill="#8FC3E8"
            barSize={60}
          />
          <Bar
            dataKey="ground_water"
            name="Ground Water"
            fill="#5B8FF7"
            barSize={60}
          />
          <Bar
            dataKey="third_party_water"
            name="Third Party Water"
            fill="#4569B2"
            barSize={60}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DemoWaterWithdrawal;
