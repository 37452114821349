import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Button } from "primereact/button";

// Sample data for injury trends over time by location
const data = {
  India: [
    { time: "Apr-23", fatalities: 0, recordable: 1, highConsequence: 0 },
    { time: "May-23", fatalities: 0, recordable: 1, highConsequence: 0 },
    { time: "Jun-23", fatalities: 0, recordable: 1, highConsequence: 0 },
    { time: "Jul-23", fatalities: 0, recordable: 0, highConsequence: 0 },
    { time: "Aug-23", fatalities: 0, recordable: 0, highConsequence: 0 },
    { time: "Sep-23", fatalities: 0, recordable: 0, highConsequence: 0 },
    { time: "Oct-23", fatalities: 0, recordable: 1, highConsequence: 0 },
    { time: "Nov-23", fatalities: 0, recordable: 0, highConsequence: 0 },
    { time: "Dec-23", fatalities: 0, recordable: 0, highConsequence: 0 },
    { time: "Jan-24", fatalities: 0, recordable: 1, highConsequence: 0 },
    { time: "Feb-24", fatalities: 0, recordable: 0, highConsequence: 0 },
    { time: "Mar-24", fatalities: 0, recordable: 0, highConsequence: 0 },
  ],
  Indonesia: [
    { time: "Apr-23", fatalities: 0, recordable: 0, highConsequence: 0 },
    { time: "May-23", fatalities: 0, recordable: 0, highConsequence: 0 },
    { time: "Jun-23", fatalities: 0, recordable: 0, highConsequence: 0 },
    { time: "Jul-23", fatalities: 0, recordable: 0, highConsequence: 0 },
    { time: "Aug-23", fatalities: 0, recordable: 0, highConsequence: 0 },
    { time: "Sep-23", fatalities: 0, recordable: 0, highConsequence: 0 },
    { time: "Oct-23", fatalities: 0, recordable: 0, highConsequence: 0 },
    { time: "Nov-23", fatalities: 0, recordable: 0, highConsequence: 0 },
    { time: "Dec-23", fatalities: 0, recordable: 0, highConsequence: 0 },
    { time: "Jan-24", fatalities: 0, recordable: 0, highConsequence: 0 },
    { time: "Feb-24", fatalities: 0, recordable: 0, highConsequence: 0 },
    { time: "Mar-24", fatalities: 0, recordable: 0, highConsequence: 0 },
  ],
  "United Kingdom": [
    { time: "Apr-23", fatalities: 0, recordable: 0, highConsequence: 0 },
    { time: "May-23", fatalities: 0, recordable: 0, highConsequence: 0 },
    { time: "Jun-23", fatalities: 0, recordable: 0, highConsequence: 0 },
    { time: "Jul-23", fatalities: 0, recordable: 0, highConsequence: 0 },
    { time: "Aug-23", fatalities: 0, recordable: 0, highConsequence: 0 },
    { time: "Sep-23", fatalities: 0, recordable: 0, highConsequence: 0 },
    { time: "Oct-23", fatalities: 0, recordable: 0, highConsequence: 0 },
    { time: "Nov-23", fatalities: 0, recordable: 0, highConsequence: 0 },
    { time: "Dec-23", fatalities: 0, recordable: 0, highConsequence: 0 },
    { time: "Jan-24", fatalities: 0, recordable: 0, highConsequence: 0 },
    { time: "Feb-24", fatalities: 0, recordable: 0, highConsequence: 0 },
    { time: "Mar-24", fatalities: 0, recordable: 0, highConsequence: 0 },
  ],
};

const InjuryTrendsBarChart = () => {
  const [location, setLocation] = useState("India");

  return (
    <div style={{ fontFamily: "Lato", margin: "25px" }}>
      <h3 style={{ fontSize: "18px" }}>
        Injury Trends of Employees by Location
      </h3>
      <p>
        Tracking injury trends of employees broken down by type: fatalities,
        recordable injuries, and High consequence injury, for different
        locations.
      </p>

      {/* Location Selection Buttons */}
      <div style={{ marginBottom: "10px" }}>
        {Object.keys(data).map((locationName) => (
          <button
            key={locationName}
            onClick={() => setLocation(locationName)}
            style={{
              padding: "8px 16px",
              backgroundColor:
                location === locationName ? "#41AA95" : "#F0F0F0",
              color: location === locationName ? "white" : "gray",
              border: "none",
              borderRadius: "8px",
              height: "2.5rem",
              marginRight: "2rem",
              cursor: "pointer",
            }}
          >
            {locationName}
          </button>
        ))}
      </div>

      {/* Responsive Container for Recharts */}
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={data[location]}
          margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="time"
            label={{ value: "Time", position: "insideBottom", offset: -5 }}
          />
          <YAxis
            label={{
              value: "Number of Injuries",
              angle: -90,
              position: "insideLeft",
            }}
          />
          <Tooltip />
          <Legend />

          {/* Injury Types */}
          <Bar
            dataKey="fatalities"
            fill="#e74c3c"
            name="Fatalities"
            barSize={20}
          />
          <Bar
            dataKey="recordable"
            fill="#f39c12"
            name="Recordable Injuries"
            barSize={20}
          />
          <Bar
            dataKey="highConsequence"
            fill="#16a085"
            name="High Consequence"
            barSize={20}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default InjuryTrendsBarChart;
