import React, { useState, useEffect, useRef } from "react";
import { API } from "../../constants/api_url";
import APIServices from "../../service/APIService";
import { hardcoded } from "../constants/hardcodedid";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { useHistory } from "react-router-dom";
import { TabMenu } from 'primereact/tabmenu';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column"
import { Tooltip } from "primereact/tooltip";
import Swal from 'sweetalert2'
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { OverlayPanel } from 'primereact/overlaypanel';
import { MultiSelect } from "primereact/multiselect";
import { BlockUI } from 'primereact/blockui';
import { DateTime } from "luxon";
import { Paginator } from "primereact/paginator";
import * as XLSX from 'xlsx'
import { getFiscalYearsFromStartDate } from "../../components/BGHF/helper";
const crypto = require("crypto-js");
const OverallQuantitativeSubmissions = () => {
    const { fymonth } = useSelector((state) => state.user.fyStartMonth);
    const [activetab, setActiveTab] = useState(null)
    const [statusCodes, setStatusCodes] = useState({ '0': 0, '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0, '7': 0, '8': 0 })

    const SECRET_KEY = "e!sq6kee4submitId";
    const ALGORITHM = "aes-256-cbc";
    const IV_LENGTH = 16;
    const dpnamerefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)]
    const formref = useRef(null)
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(5);
    const entityref = useRef(null)
    const userList = useSelector(state => state.userlist.userList)
    const [tempload, setTempLoad] = useState(false)
    const [assignedlist, setAssignedList] = useState([])
    const [quantitativesubmission, setQuantiativeSubmission] = useState([])
    const [stdlist, setStdList] = useState([])
    const [subcat1, setSubCat1] = useState([])
    const [subcat2, setSubCat2] = useState([])
    const [subcat3, setSubCat3] = useState([])
    const [subcat4, setSubCat4] = useState([])
    const statusref = useRef(null)
    const login_data = useSelector((state) => state.user.userdetail);
    const admin_data = useSelector((state) => state.user.admindetail);

    const [entityoption, setEntityOption] = useState([])
    const [formoption, setFormOption] = useState([])
    const [yearoption, setYearOption] = useState([])
    const [statusoption, setStatusOption] = useState([])
    const navigate = useHistory();
    const [submittedQuantitative, setSubmittedQuantitative] = useState([]);
    const [overallsubmittedQuantitative, setOverallSubmittedQuantitative] =
        useState([]);
    const [rawsitelist, setRawSitelist] = useState([]);
    const [filter, setFilter] = useState({ year: null, entity: [], form: [], status: [] });
    const [refineddata, setRefinedData] = useState([]);
    const [refineddatabk, setRefinedDataBk] = useState([]);
    const [dcflist, setDcfList] = useState([]);
    const [dplist, setDPList] = useState([]);
    const [load, setLoading] = useState(false);
    const forceUpdate = useForceUpdate();
    const [remarksdialog, setRemarksDialog] = useState(false)
    const [remarksdata, setRemarksData] = useState([])
    const frequency_list = [
        { name: "Monthly", id: 1 },
        { name: "Bi-Monthly", id: 2 },
        { name: "Quartely", id: 3 },
        { name: "Annually", id: 4 },
        { name: "Bi-Annually", id: 5 },
        { name: "Undefined", id: 6 },
    ];

useEffect(()=>{
    console.log(statusCodes)
},[statusCodes])

    useEffect(() => {

        let uriString = {
            include: [
                {
                    relation: "locationTwos",
                    scope: { include: [{ relation: "locationThrees" }] },
                },
            ],
        };
        let efstd = {
            include: [
                {
                    relation: "newEfCategories",
                    scope: {
                        include: [
                            {
                                relation: "newEfSubcategory1s",
                                scope: {
                                    include: [
                                        {
                                            relation: "newEfSubcategory2s",
                                            scope: {
                                                include: [
                                                    {
                                                        relation: "newEfSubcategory3s",
                                                        scope: {
                                                            include: [{ relation: "newEfSubcategory4s" }],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],
        };
        let uriString2 = {
            include: [
                {
                    relation: "newTopics",
                    scope: {
                        include: [
                            {
                                relation: "newMetrics",
                                scope: { include: [{ relation: "newDataPoints" }] },
                            },
                        ],
                    },
                },
            ],
        };
        let Overall = API.Categories + `?filter=${encodeURIComponent(JSON.stringify(uriString2))}`;
        const promise0 = APIServices.get(API.DCF);
        const promise1 = APIServices.get(
            API.QN_Submit_UP(admin_data.id)
        );
        const promise2 = APIServices.get(
            API.LocationOne_UP(admin_data.id) +
            `?filter=${encodeURIComponent(JSON.stringify(uriString))}`
        );
        const promise3 = APIServices.get(
            API.DCF_Entity_UP(admin_data.id)
        );
        const promise4 = APIServices.get(
            API.DF_Entity_UP(admin_data.id)
        );
        const promise5 = APIServices.get(
            API.SRF_Entity_UP(admin_data.id)
        );
        const promise6 = APIServices.get(
            API.DCF_Entity_User_UP(admin_data.id)
        );
        const promise7 = APIServices.get(
            API.DF_Entity_User_UP(admin_data.id)
        );
        const promise8 = APIServices.get(
            API.SRF_Entity_User_UP(admin_data.id)
        );
        const promise9 = APIServices.get(API.DataPoint);
        const promise10 = APIServices.get(API.EF_Std + `?filter=${encodeURIComponent(JSON.stringify(efstd))}`)
        const promise11 = APIServices.get(API.EF_SC1);
        const promise12 = APIServices.get(API.EF_SC2);
        const promise13 = APIServices.get(API.EF_SC3);
        const promise14 = APIServices.get(API.EF_SC4);
        const promise15 = APIServices.get(
            API.AssignDCFClient_UP(admin_data.id)
        );
        const promise16 = APIServices.get(
            Overall
        );
        Promise.all([
            promise0,
            promise1,
            promise2,
            promise3,
            promise4,
            promise5,
            promise6,
            promise7,
            promise8,
            promise9, promise10, promise11, promise12, promise13, promise14, promise15, promise16
        ]).then((values) => {
            let curated_dcf_ids = []
            if (values[15].data && values[15].data.length > 0) {
                values[16].data.forEach((cat) => {
                    if (cat.newTopics !== undefined) {

                        cat.newTopics.forEach((topic) => {

                            if (topic.newMetrics !== undefined && (topic.tag === null || parseFloat(topic.tag) === admin_data.id)) {

                                topic.newMetrics.forEach((metric) => {
                                    if (Array.isArray(metric.data1) && metric.data1[0].type !== undefined && metric.data1[0].type === 0 && (metric.tag === null || metric.tag === admin_data.id)) {
                                        if (metric.newDataPoints !== undefined) {
                                            metric.newDataPoints.forEach((dp) => {
                                                if (Array.isArray(dp.data1) && dp.data1.length !== 0 && dp.data1[0].datasource !== null && typeof dp.data1[0].datasource === 'number') {
                                                    let dcf_index = values[0].data.findIndex(i => i.id === dp.data1[0].datasource)
                                                    if (!curated_dcf_ids.includes(dp.data1[0].datasource) && dcf_index !== -1) {

                                                        if (values[0].data[dcf_index].tags === null || !values[0].data[dcf_index].tags.length || values[0].data[dcf_index].tags.includes(admin_data.id)) {
                                                            curated_dcf_ids.push(dp.data1[0].datasource)
                                                        }

                                                    }
                                                }
                                            })
                                        }
                                    }
                                })
                            }
                        })
                    }


                })
            }
            setSubCat1(values[11].data)
            setSubCat2(values[12].data)
            setSubCat3(values[13].data)
            setSubCat4(values[14].data)

            let stdList = values[10].data
            let quantitative_submitted = values[1].data,
                qn_submit_list = [],
                overall_qn_submit_list = [],
                dcf_list = values[0].data,
                quantitative_ass_list = values[6].data,
                qualitative_ass_list = values[7].data,
                srf_ass_list = values[8].data;
            let quantitative_entity_list = values[3].data,
                qualitative_entity_list = values[4].data,
                srf_entity_list = values[5].data;
            const shapedSite = values[2].data
                .map((item) => {
                    if (item.locationTwos) {
                        item.locationTwos = item.locationTwos.filter(
                            (locationTwo) =>
                                locationTwo.locationThrees &&
                                locationTwo.locationThrees.length > 0
                        );
                    }
                    return item;
                })
                .filter((item) => item.locationTwos && item.locationTwos.length > 0);
            let filtered_qn_ass = quantitative_ass_list.filter(
                (i) =>
                    dcf_list.map((j) => j.id).includes(i.dcfId) && curated_dcf_ids.includes(i.dcfId) &&
                    checkEnity(i, quantitative_entity_list, shapedSite, "dcfId")
            );


            setDcfList(dcf_list);
            setDPList(values[9].data);
            setRawSitelist(shapedSite);
            setStdList(values[10].data)
            quantitative_submitted.forEach((item) => {
                let filtered_qn_ass_index = filtered_qn_ass.findIndex((i) => {

                    return (
                        i.id === item.entityUserAssId && i.entityAssId === item.entityAssId
                    );
                });
                if (filtered_qn_ass_index !== -1) {
                    overall_qn_submit_list.push(item);
                    qn_submit_list.push(item);
                }

            });
            setAssignedList(filtered_qn_ass)
            setQuantiativeSubmission(quantitative_submitted)

            let yearOption = getFiscalYearsFromStartDate(admin_data.information.startdate, fymonth)

            if (yearOption.length) {

                let currentYr = Math.max(...yearOption.map(i => i.name))
                let filteredQnAssByYear = filterAssignmentBasedOnYear(filtered_qn_ass, currentYr)

                if (filteredQnAssByYear.length) {
                    calculateAndCreateYearData(
                        filteredQnAssByYear,
                        values[0].data,
                        quantitative_submitted.filter(i => isDateInFiscalYear(currentYr, getRPTextFormat(i.reporting_period))), stdList, { cat1: values[11].data, cat2: values[12].data, cat3: values[13].data, cat4: values[14].data }, DateTime.utc().toLocal().year
                    ).then((res) => {

                        let entity_option = Array.from(new Set(res.map(i => getCoverageTextById(i, shapedSite)))).map(i => ({ title: i, id: i }))
                        setFormOption(dcf_list.filter(i => res.map(i => i.dcfId).includes(i.id)))
                        setEntityOption(entity_option)
                        setYearOption(yearOption)



                        if (res.length) {

                            onSelectedChangeIntial('year', currentYr, res)
                        } else {
                            setFilter((prev) => ({ ...prev, year: currentYr }))
                            setLoading(true);
                        }
                        // setRefinedData(res);
                        setRefinedDataBk(res);

                    });
                }
            }
            setOverallSubmittedQuantitative(
                overall_qn_submit_list.filter((i) => i.type !== 0)
            );
            setSubmittedQuantitative(qn_submit_list);
            extractDPFromForms(qn_submit_list.filter((i) => i.type === 1));

        });
    }, []);
    const filterAssignmentBasedOnYear = (filtered_qn_ass, selectedYear) => {



        return filterAssignmentsByFiscalYear(filtered_qn_ass, selectedYear)
    }
    const getDueMonth = (dateStr) => {
        const [from, to] = dateStr.split(' to ')
        if (to) {
            return to
        }
        return from
    }
    function isDateInFiscalYear(year, dateString) {
        const { start, end } = parseDateString(dateString);
        let curYear = year
        if (fymonth !== 1) {
            curYear = year - 1

        }
        const startDate = DateTime.fromFormat(start.trim(), 'MMM-yyyy');
        const endDate = DateTime.fromFormat(end.trim(), 'MMM-yyyy');
        const fiscalYearStart = DateTime.fromObject({ year: curYear, month: fymonth, day: 1 }); // April 1 of the previous year
        const fiscalYearEnd = DateTime.fromObject({ year: year, month: fymonth - 1 || 12, day: DateTime.fromObject({ year: year, month: fymonth - 1 || 12 }).daysInMonth }); // March 31 of the given year

        return (
            (startDate >= fiscalYearStart && startDate <= fiscalYearEnd) ||
            (endDate >= fiscalYearStart && endDate <= fiscalYearEnd) ||
            (startDate <= fiscalYearStart && endDate >= fiscalYearEnd)
        );
    }
    const filterAssignmentsByFiscalYear = (assignments, year) => {
        const { startDate, endDate } = getFiscalYearRange(year, fymonth);
        const currentDate = DateTime.local().startOf('day');

        return assignments.filter(assignment => {

            const assignmentStartDate = assignment.start_date ? DateTime.fromISO(assignment.start_date, { zone: 'utc' }).startOf('day').plus({ month: 1 }) : currentDate;
            const assignmentEndDate = assignment.end_date ? DateTime.fromISO(assignment.end_date, { zone: 'utc' }).startOf('day').plus({ month: 1 }) : currentDate;

            return (assignmentStartDate >= startDate && assignmentStartDate <= endDate) ||
                (assignmentEndDate >= startDate && assignmentEndDate <= endDate) ||
                (assignmentStartDate <= startDate && assignmentEndDate >= endDate);
        });
    };
    function getFiscalYearRange_YearOption(fymonth, startDate, endDate) {
        const start = DateTime.fromISO(startDate);
        const end = endDate ? DateTime.fromISO(endDate) : DateTime.local();
        const result = [];

        let startYear = start.year;
        let endYear = end.year;

        if (fymonth !== 1) {
            if (start.month < fymonth) {
                startYear -= 1;
            }
            if (end.month < fymonth) {
                endYear -= 1;
            }
        }

        for (let year = startYear; year <= endYear; year++) {
            if (fymonth === 1) {
                result.push({ value: year, title: year.toString() });
            } else {
                result.push({ value: year, title: `${year}-${(year + 1).toString().slice(-2)}` });
            }
        }

        return result;
    }
    const getFiscalYearRange = (year, fymonth) => {
        let startDate, endDate;


        if (fymonth === 1) {
            startDate = DateTime.fromObject({ year, month: 1, day: 1 }).startOf('day');
            endDate = DateTime.fromObject({ year, month: 12, day: 31 }).endOf('day');
        } else {
            startDate = DateTime.fromObject({ year: year - 1, month: fymonth, day: 1 }).startOf('day');
            endDate = DateTime.fromObject({ year, month: fymonth - 1, day: 1 }).endOf('month');
        }

        return { startDate, endDate };
    };
    function generateFiscalYearsByAssignment(assignments, fymonth) {
        const result = [];

        assignments.forEach(assignment => {
            const { start_date, end_date } = assignment;
            const fiscalYears = getFiscalYearRange_YearOption(fymonth, start_date, end_date);

            fiscalYears.forEach(fy => {
                if (!result.some(r => r.value === fy.value && r.title === fy.title)) {
                    result.push(fy);
                }
            });
        });

        return result;
    }

    function getMonthsBetween(startDate, endDate, frequency, requiredYear, fyMonth) {

        // Default to the current date if startDate or endDate is null
        const currentDate = DateTime.now();
        startDate = startDate ? DateTime.fromISO(startDate).startOf('month') : currentDate;
        endDate = endDate ? DateTime.fromISO(endDate) : currentDate;

        // Define fiscal year start and end months based on fyMonth
        const fiscalStartMonth = fyMonth - 1; // 0-based month
        const fiscalEndMonth = (fyMonth + 11) % 12; // Fiscal year ends 11 months after fyMonth

        // Define start and end date based on requiredYear and fyMonth
        let fiscalStartDate = DateTime.fromObject({ year: requiredYear, month: fiscalStartMonth + 1, day: 1 });
        let fiscalEndDate = DateTime.fromObject({ year: requiredYear + 1, month: fiscalEndMonth + 1, day: 1 }).minus({ days: 1 }); // Last day of the fiscal year

        if (fyMonth !== 1) { // Special case for fiscal year starting in April
            fiscalStartDate = DateTime.fromObject({ year: requiredYear - 1, month: fiscalStartMonth + 1, day: 1 });
            fiscalEndDate = DateTime.fromObject({ year: requiredYear, month: fiscalEndMonth + 1, day: 1 }).minus({ days: 1 });
        }

        // Adjust startDate and endDate to be within the fiscal year
        startDate = startDate < fiscalStartDate ? fiscalStartDate : startDate;
        endDate = endDate > fiscalEndDate ? fiscalEndDate : endDate;

        // List to store the months
        const months = [];
        let current = startDate.startOf('month');

        // Loop to add months based on frequency
        while (current <= endDate) {
            let nextDate = current.plus({ months: frequency - 1 }).startOf('month');
            if (current > endDate) {
                nextDate = endDate;
            }
            if (frequency === 1) {
                months.push(current.toFormat('MMM-yyyy'));
            } else {
                months.push(`${current.toFormat('MMM-yyyy')} to ${nextDate.toFormat('MMM-yyyy')}`);
            }
            current = nextDate.plus({ months: 1 }).startOf('month');
        }

        return months;
    }
    function parseDateString(dateString) {
        if (dateString.includes('to')) {
            const [start, end] = dateString.split('to');
            return { start, end };
        } else {
            return { start: dateString, end: dateString };
        }
    }
    function isDateInFiscalYear_(year, dateString) {
        const { start, end } = parseDateString(dateString);

        const startDate = DateTime.fromFormat(start.trim(), 'MMM-yyyy');
        const endDate = DateTime.fromFormat(end.trim(), 'MMM-yyyy');

        const fiscalYearStart = DateTime.fromObject({ year: year - 1, month: 4, day: 1 }); // April 1 of the previous year
        const fiscalYearEnd = DateTime.fromObject({ year: year, month: 3, day: 31 }); // March 31 of the given year

        return (
            (startDate >= fiscalYearStart && startDate <= fiscalYearEnd) ||
            (endDate >= fiscalYearStart && endDate <= fiscalYearEnd) ||
            (startDate <= fiscalYearStart && endDate >= fiscalYearEnd)
        );
    }
    function isDateInFiscalYear(year, dateString) {
        const { start, end } = parseDateString(dateString);
        let curYear = year
        if (fymonth !== 1) {
            curYear = year - 1

        }
        const startDate = DateTime.fromFormat(start.trim(), 'MMM-yyyy');
        const endDate = DateTime.fromFormat(end.trim(), 'MMM-yyyy');
        const fiscalYearStart = DateTime.fromObject({ year: curYear, month: fymonth, day: 1 }); // April 1 of the previous year
        const fiscalYearEnd = DateTime.fromObject({ year: year, month: fymonth - 1 || 12, day: DateTime.fromObject({ year: year, month: fymonth - 1 || 12 }).daysInMonth }); // March 31 of the given year

        return (
            (startDate >= fiscalYearStart && startDate <= fiscalYearEnd) ||
            (endDate >= fiscalYearStart && endDate <= fiscalYearEnd) ||
            (startDate <= fiscalYearStart && endDate >= fiscalYearEnd)
        );
    }
    const getStatusCode = (arr) => {

        return new Promise((resolve, err) => {
            let codes = []
            arr.forEach((i) => {
                Object.entries(i).forEach((j) => {
                    if (!j[0].includes('name')) {
                        if (j[1].length) {
                            if (j[1][0].id !== undefined) {
                                if (j[1][0].status === 5) {
                                    if (!codes.includes(7)) {
                                        codes.push(7)
                                    }
                                } else if (j[1][0].status === 4) {
                                    if (!codes.includes(6)) {
                                        codes.push(6)
                                    }
                                } else if (j[1][0].status === 3) {
                                    if (!codes.includes(5)) {
                                        codes.push(5)
                                    }
                                } else if (j[1][0].status === 2) {
                                    if (!codes.includes(8)) {
                                        codes.push(8)
                                    }
                                }
                                else if (j[1][0].status === 1) {
                                    if (!codes.includes(1)) {
                                        codes.push(1)
                                    }
                                } else if (getOverdueDays(j[0]) <= -15 && (j[1][0].status === null || j[1][0].status === 0)) {
                                    if (!codes.includes(2)) {
                                        codes.push(2)
                                    }
                                } else if (getOverdueDays(j[0]) <= 0 && (j[1][0].status === null || j[1][0].status === 0)) {
                                    if (!codes.includes(3)) {
                                        codes.push(3)
                                    }
                                } else if (getOverdueDays(j[0]) > 0 && (j[1][0].status === null || j[1][0].status === 0)) {
                                    if (!codes.includes(4)) {
                                        codes.push(4)
                                    }
                                }
                            } else {

                                if (getOverdueDays(j[0]) <= -15) {
                                    if (!codes.includes(2)) {
                                        codes.push(2)
                                    }
                                } else if (getOverdueDays(j[0]) <= 0) {
                                    if (!codes.includes(3)) {
                                        codes.push(3)
                                    }
                                } else if (getOverdueDays(j[0]) > 0) {
                                    if (!codes.includes(4)) {
                                        codes.push(4)
                                    }
                                }
                            }
                        } else {

                            if (getOverdueDays(j[0]) <= -15) {
                                if (!codes.includes(2)) {
                                    codes.push(2)
                                }
                            } else if (getOverdueDays(j[0]) <= 0) {
                                if (!codes.includes(3)) {
                                    codes.push(3)
                                }
                            } else if (getOverdueDays(j[0]) > 0) {
                                if (!codes.includes(4)) {
                                    codes.push(4)
                                }
                            }

                        }
                    }
                })
            })
            if (!codes.length) {
                codes.push(0)
            }
            resolve(codes)
        })



    }
    const getDPNameHC = (arr) => {
        let result = [], count = 0

        arr.forEach((i) => {
            Object.values(i).forEach((j) => {
                j.forEach((k) => {
                    if (result.findIndex(x => x.label === k.dp) === -1) {
                        count++
                        result.push({ label: k.dp, order: count })
                    }
                })

            })
        })
        return result
    }
    async function calculateAndCreateYearData(
        assignment,
        dcf_list,
        submission_list, efstdlist, subcat, requiredyear
    ) {
        const yearData = [];
        const uniqueCombinations = [];
        return new Promise((resolve, reject) => {
            assignment.forEach((item) => {

                // Extract necessary information
                const {
                    start_date,
                    end_date,
                    tier0_id,
                    tier1_id,
                    tier2_id,
                    tier3_id,
                    dcfId,
                    frequency,
                    standard
                } = item;

                // Convert start_date and end_date to Luxon DateTime objects
                const startDate = DateTime.fromISO(start_date);
                const endDate = end_date ? DateTime.fromISO(end_date) : DateTime.utc();

                // Generate year array based on start_date and end_date
                const startDateYear = startDate.year;
                const endDateYear = endDate.year;
                const yearArray = Array.from(
                    { length: endDateYear - startDateYear + 1 },
                    (_, index) => startDateYear + index
                );

                // Check if the combination already exists
                const existingCombinationIndex = uniqueCombinations.findIndex(
                    (combination) => {
                        return (
                            combination.tier0_id === tier0_id &&
                            combination.tier1_id === tier1_id &&
                            combination.tier2_id === tier2_id &&
                            combination.tier3_id === tier3_id && combination.standard === standard &&
                            combination.dcfId === dcfId
                        );
                    }
                );

                if (existingCombinationIndex !== -1) {
                    const existingYears = new Set(
                        uniqueCombinations[existingCombinationIndex].year
                    );

                    // Add new years from yearArray to the existingYears Set
                    yearArray.forEach((year) => {
                        existingYears.add(year);
                    });

                    uniqueCombinations[existingCombinationIndex].year =
                        Array.from(existingYears);
                    // Convert the Set back to an array and update the uniqueCombinations entry
                    uniqueCombinations[existingCombinationIndex].dataobject = [
                        ...uniqueCombinations[existingCombinationIndex].dataobject,
                        ...getMonthsBetween(
                            start_date,
                            end_date,
                            frequency === 4 ? 12 : frequency === 5 ? 6 : frequency, requiredyear, fymonth
                        ),
                    ];
                } else {
                    // If the combination doesn't exist, create a new object
                    uniqueCombinations.push({
                        ...item,
                        year: [...yearArray, DateTime.utc().toLocal().year + 1],
                        dataobject: getMonthsBetween(
                            start_date,
                            end_date,
                            frequency === 4 ? 12 : frequency === 5 ? 6 : frequency, requiredyear, fymonth
                        ),
                    });
                }
            });
            uniqueCombinations.forEach((item) => {

                const {
                    start_date,
                    end_date,
                    tier0_id,
                    tier1_id,
                    tier2_id,
                    tier3_id,
                    dcfId, locationId, entityAssId, frequency, level,
                    standard,
                    dataobject
                } = item;
                let newObj = { standard, company_id: login_data.clientId, level, locationId, entityAssId, entityUserAssId: item.id, frequency, self: item.reviewer_ids.length !== 0 ? false : true }

                const dcfItem = dcf_list.find((item) => item.id === dcfId);


                if (dcfItem) {
                    let data1ForYear = [],
                        msn = [
                            {
                                name: JSON.parse(
                                    JSON.stringify(
                                        dcfItem.data1.filter((obj) => obj.type === "number")
                                    )
                                ),
                            },
                        ];
                    data1ForYear = JSON.parse(
                        JSON.stringify(dcfItem.data1.filter((obj) => obj.type === "number"))
                    );
                    item.year.forEach((y) => {
                        console.log(
                            item.dataobject.filter((i) => i.includes(y.toString())).length,
                            y
                        );
                        item.dataobject
                            .filter((i) => { return isDateInFiscalYear(y, i) })
                            .forEach((xy, xindx) => {
                                if (hardcoded.dcf.includes(dcfId.toString()) || hardcoded.dcf2.includes(dcfId.toString())) {
                                    if (dcfId === 11 || dcfId === 257) {
                                        msn.push(getValueByMonthlyHC3Sub(dcfId,
                                            tier0_id,
                                            tier1_id,
                                            tier2_id,
                                            tier3_id,
                                            [xy],
                                            dcfId === 11 ? ['DPA0131', 'DPA0132', 'DPA0336'] : ['DPAN096', 'DPAN099', 'DPAN098'],
                                            submission_list.filter(i => i.dcfId === dcfId), efstdlist, subcat, standard))
                                    } else if (dcfId === 10 || dcfId === 16) {
                                        msn.push(getValueByMonthlyHC1Sub(dcfId,
                                            tier0_id,
                                            tier1_id,
                                            tier2_id,
                                            tier3_id,
                                            [xy],
                                            dcfId === 10 ? ['DPA0136', 'DPA0138'] : ['DPA0287', 'DPA0289'],
                                            submission_list, efstdlist, subcat, standard, dcfId === 10 ? 'kg' : 'USD'))

                                    }
                                    else if (dcfId === 275 || dcfId === 263) {

                                        msn.push(getValueByMonthlyEmpCat275(dcfId,
                                            tier0_id,
                                            tier1_id,
                                            tier2_id,
                                            tier3_id,
                                            [xy],
                                            submission_list.filter(i => i.dcfId === dcfId), 'nos', newObj.self, newObj))

                                    }
                                    else {
                                        msn.push(getValueByMonthlyHC(
                                            dcfId,
                                            tier0_id,
                                            tier1_id,
                                            tier2_id,
                                            tier3_id,
                                            [xy],
                                            data1ForYear.map((i) => i.name),
                                            submission_list.filter(i => i.dcfId === dcfId)
                                        ))
                                    }


                                } else {
                                    msn.push(
                                        getValueByMonthly(
                                            dcfId,
                                            tier0_id,
                                            tier1_id,
                                            tier2_id,
                                            tier3_id,

                                            [xy],
                                            data1ForYear.map((i) => i.name),
                                            submission_list.filter(i => i.dcfId === dcfId)
                                        )
                                    );
                                }

                            });
                    });

                    item.year.forEach(async (y) => {
                        if (dcfId === 11 || dcfId === 10 || dcfId === 16 || dcfId === 257 || dcfId === 263 || dcfId === 275) {
                            let msn_ = msn.filter((i) => {
                                return isDateInFiscalYear(y, Object.keys(i)[0])

                            })
                            let names_ = getDPNameHC(msn_)
                            msn_.forEach((i) => {
                                Object.values(i).forEach((j) => {
                                    let oldObj = {}
                                    let oldObjInd = j.findIndex(l => { return (l.id !== null && l.id !== undefined) })
                                    if (oldObjInd !== -1) {

                                        oldObj = { id: j[oldObjInd].id, type: j[oldObjInd].type, status: j[oldObjInd].status, remarks: j[oldObjInd].remarks === undefined ? null : j[oldObjInd].remarks }
                                    }
                                    names_.forEach((k) => {
                                        let nameindex = j.findIndex(l => l.dp === k.label)

                                        if (nameindex === -1 && k.label !== null) {
                                            j.push({ ...oldObj, category: null, diff: false, form_type: 2, current: '-', dp: k.label, order: k.order })
                                        } else if (k.label !== null) {
                                            j[nameindex].order = k.order
                                        }
                                    })
                                    j.sort((a, b) => { return a.order - b.order }, 0)
                                })
                            })

                            msn_.unshift({ name: names_.filter(i => { return i.label !== null }) })
                            yearData.push({
                                ...newObj,
                                year: y,
                                tier0_id,
                                tier1_id,
                                tier2_id,
                                tier3_id,
                                dcfId,
                                form_type: hardcoded.dcf.includes(dcfId.toString()) || hardcoded.dcf2.includes(dcfId.toString()) ? 2 : 1,
                                status: await getStatusCode(msn_.filter((i) => {
                                    return (
                                        Object.keys(i)[0] === "name" ||
                                        isDateInFiscalYear(y, Object.keys(i)[0])
                                    );
                                }),),
                                statusArray: await getAllStatusCode(msn_.filter((i) => {
                                    return (
                                        Object.keys(i)[0] === "name" ||
                                        isDateInFiscalYear(y, Object.keys(i)[0])
                                    );
                                })),
                                dp_array: msn_.filter((i) => {
                                    return (
                                        Object.keys(i)[0] === "name" ||
                                        isDateInFiscalYear(y, Object.keys(i)[0])
                                    );
                                }),
                            });
                        } else {

                            yearData.push({
                                ...newObj,
                                year: y,
                                tier0_id,
                                tier1_id,
                                tier2_id,
                                tier3_id,
                                dcfId,
                                form_type: hardcoded.dcf.includes(dcfId.toString()) || hardcoded.dcf2.includes(dcfId.toString()) ? 2 : 1,
                                status: await getStatusCode(msn.filter((i) => {
                                    return (
                                        Object.keys(i)[0] === "name" ||
                                        isDateInFiscalYear(y, Object.keys(i)[0])
                                    );
                                }),),
                                statusArray: await getAllStatusCode(msn.filter((i) => {
                                    return (
                                        Object.keys(i)[0] === "name" ||
                                        isDateInFiscalYear(y, Object.keys(i)[0])
                                  
                                    );
                                })),
                                dp_array: msn.filter((i) => {
                                    return (
                                        Object.keys(i)[0] === "name" ||
                                        isDateInFiscalYear(y, Object.keys(i)[0])
                                    );
                                }),
                            });
                        }


                    });
                }
                // yearData.forEach((i) => {
                //     i.dp_array.forEach((j,indx) => {
                //         Object.keys(j).forEach(async (k) => {
                //             if (k.includes('-'+i.year)) {

                //                 i.dp_array[indx][k]  =  await getValueByMonthly(i.dcfId,i.tier0_id,i.tier1_id,i.tier2_id,i.tier3_id,k,j.name,submission_list )

                //             }
                //         })
                //     })

                // })

            });

            resolve(yearData);
        });
    }

    function getKey(frequency, year) {
        const months = Array.from({ length: 12 }, (_, i) =>
            DateTime.local(year, i + 1, 1).toFormat("LLL")
        );
        const keys = [];

        switch (frequency) {
            case 1:
                months.forEach((month) => {
                    const key = month + "-" + year;
                    keys.push({ [key]: {} });
                });
                break;
            case 2:
                for (let i = 0; i < months.length; i += 2) {
                    const startMonthIndex = i;
                    const endMonthIndex = Math.min(
                        startMonthIndex + 1,
                        months.length - 1
                    );
                    const key =
                        months[startMonthIndex] +
                        "-" +
                        year +
                        " to " +
                        months[endMonthIndex] +
                        "-" +
                        year;
                    keys.push({ [key]: {} });
                }
                break;
            case 3:
                for (let i = 0; i < months.length; i += 3) {
                    const startMonthIndex = i;
                    const endMonthIndex = Math.min(
                        startMonthIndex + 2,
                        months.length - 1
                    );
                    const key =
                        months[startMonthIndex] +
                        "-" +
                        year +
                        " to " +
                        months[endMonthIndex] +
                        "-" +
                        year;
                    keys.push({ [key]: {} });
                }
                break;
            case 4:
                keys.push({
                    [`${months[0]}-${year} to ${months[months.length - 1]}-${year}`]: {},
                });
                break;
            case 5:
                const midIndex = Math.ceil(months.length / 2);
                const firstHalf = months.slice(0, midIndex);
                const secondHalf = months.slice(midIndex);
                const key1 =
                    firstHalf[0] +
                    "-" +
                    year +
                    " to " +
                    firstHalf[firstHalf.length - 1] +
                    "-" +
                    year;
                const key2 =
                    secondHalf[0] +
                    "-" +
                    year +
                    " to " +
                    secondHalf[secondHalf.length - 1] +
                    "-" +
                    year;
                keys.push({ [key1]: {} }, { [key2]: {} });
                break;
            default:
                break;
        }

        // Create the final object
        const result = {};
        keys.forEach((keyObj) => {
            Object.assign(result, keyObj);
        });

        return result;
    }
    function getPreviousPeriod(frequency, key) {
        if (frequency === 1) {
            const [month, year] = key.split("-");
            const previousDate = DateTime.fromFormat(
                `${month}-1-${year}`,
                "LLL-d-yyyy"
            ).minus({ months: 1 });
            const previousMonth = previousDate.toFormat("LLL");
            const previousYear = previousDate.year;
            return previousMonth + "-" + previousYear;
        } else {
            const endMonth = key.split(" to ")[0].split("-")[0].trim();
            const endYear = key.split(" to ")[0].split("-")[1].trim();
            const previousDate = DateTime.fromFormat(
                `${endMonth}-1-${endYear}`,
                "LLL-d-yyyy"
            ).minus({ months: 1 });
            const previousMonth = previousDate.toFormat("LLL");
            const previousYear = previousDate.year;
            return previousMonth + "-" + previousYear;
        }
    }
    const getRPTextFormat = (item) => {
        if (item.length !== 0) {
            if (item.length >= 2) {
                const startDate = DateTime.fromFormat(item[0], "MM-yyyy").toFormat(
                    "LLL-yyyy"
                );
                const endDate = DateTime.fromFormat(
                    item[item.length - 1],
                    "MM-yyyy"
                ).toFormat("LLL-yyyy");
                return `${startDate} to ${endDate}`;
            } else {
                return DateTime.fromFormat(item[0], "MM-yyyy").toFormat("LLL-yyyy");
            }
        }
    };
    function calculatePercentage(value1, value2) {
        if ((value1 === null || value1 === 0) && (value2 === null || value2 === 0)) {
            return '-'
        }
        return parseInt(Math.abs(((value1 - value2) / value2) * 100)) + '%'
    }

    const getValueByMonthly = (
        dcfId,
        tier0,
        tier1,
        tier2,
        tier3,
        arr,
        dpnames,
        qn_submit_list
    ) => {
        // let months = ['Jan-' + yr, 'Feb-' + yr, 'Mar-' + yr, 'Apr-' + yr, 'May-' + yr, 'Jun-' + yr, 'Jul-' + yr, 'Aug-' + yr, 'Sep-' + yr, 'Oct-' + yr, 'Nov-' + yr, 'Dec-' + yr]
        let final_result = {};

        arr.forEach((obj) => {

            let result = {
                [obj]: dpnames.map((i) => ({

                    remarks: null,
                    category: null,
                    form_type: 1,
                    current: "",
                    percentage: "",
                    text: "Pending Submission",
                    col: getNumberOfColumn(obj),
                    status: null,
                })),
            };
            let result_arr = [];
            let index = qn_submit_list.findIndex((i) => {

                return (
                    i.dcfId === dcfId &&

                    i.tier0_id === tier0 &&
                    i.tier1_id === tier1 &&
                    i.tier2_id === tier2 &&
                    i.tier3_id === tier3 &&
                    getRPTextFormat(i.reporting_period) === obj
                );
            });

            if (index !== -1) {
                let index2 = qn_submit_list.findIndex((i) => {

                    return (
                        i.dcfId === dcfId &&
                        (i.type == 0 ? i.reject === 1 : true) &&
                        i.tier0_id === tier0 &&
                        i.tier1_id === tier1 &&
                        i.tier2_id === tier2 &&
                        i.tier3_id === tier3 &&
                        getRPTextFormat(
                            [i.reporting_period[i.reporting_period.length - 1]]
                        ) === getPreviousPeriod(obj.includes("to") ? 2 : 1, obj)
                    );
                });

                if (index2 !== -1 && qn_submit_list[index2].response.length && qn_submit_list[index].response.length) {
                    const { type1, reject1 } = qn_submit_list[index2];
                    const { type, reject } = qn_submit_list[index];
                    let value = null,
                        value2 = null;
                    result_arr = [];
                    dpnames.forEach((dpname) => {
                        let dp_index = qn_submit_list[index].response.findIndex(
                            (item) => item.name === dpname
                        );
                        if (dp_index !== -1) {
                            value = parseFloat(
                                qn_submit_list[index].response[dp_index].value
                            );
                        } else {
                            value = '-'
                        }
                        let dp_index2 = qn_submit_list[index2].response.findIndex(
                            (item) => item.name === dpname
                        );
                        if (dp_index2 !== -1) {
                            value2 = parseFloat(
                                qn_submit_list[index2].response[dp_index2].value
                            );
                        }

                        result_arr.push({
                            remarks: qn_submit_list[index].return_remarks,
                            col: getNumberOfColumn(obj),
                            id: qn_submit_list[index].id,
                            category: 2,
                            dp: dpname,
                            form_type: 1,
                            diff: value === value2 ? null : value > value2 ? false : true,
                            current: value,
                            month: getRPTextFormat(qn_submit_list[index].reporting_period),
                            percentage: calculatePercentage(value, value2),
                            type,
                            text:
                                type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                    type === 0 && reject === 1
                                        ? "Requires Resubmission"
                                        : type === 1 && reject === 2
                                            ? "Requires Re-Review"
                                            : type === 1
                                                ? "Pending Review"
                                                : type === 2
                                                    ? "Pending Approval"
                                                    : type === 3
                                                        ? "Approved & Locked"
                                                        : "Pending Submission",
                            status:
                                type === 0 && (reject === 0 || reject === null) ? 0 :
                                    type === 0 && reject === 1
                                        ? 1
                                        : type === 1 && reject === 2
                                            ? 2
                                            : type === 1
                                                ? 3
                                                : type === 2
                                                    ? 4
                                                    : type === 3
                                                        ? 5
                                                        : null
                        });
                    });
                    result = { [obj]: result_arr };
                } else {
                    const { type, reject } = qn_submit_list[index];
                    let value = null;
                    result_arr = [];

                    dpnames.forEach((dpname) => {
                        let dp_index = qn_submit_list[index].response.findIndex(
                            (item) => item.name === dpname
                        );
                        if (dp_index !== -1) {
                            value = parseFloat(
                                qn_submit_list[index].response[dp_index].value
                            );
                        } else {
                            value = '-'
                        }
                        result_arr.push({
                            remarks: qn_submit_list[index].return_remarks,
                            col: getNumberOfColumn(obj),
                            id: qn_submit_list[index].id,
                            category: 1,
                            form_type: 1,
                            diff: false,
                            dp: dpname,
                            current: value,
                            month: getRPTextFormat(qn_submit_list[index].reporting_period),
                            percentage: "-",
                            type,
                            text:
                                type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                    type === 0 && reject === 1
                                        ? "Requires Resubmission"
                                        : type === 1 && reject === 2
                                            ? "Requires Re-Review"
                                            : type === 1
                                                ? "Pending Review"
                                                : type === 2
                                                    ? "Pending Approval"
                                                    : type === 3
                                                        ? "Approved & Locked"
                                                        : "Pending Submission",
                            status:
                                type === 0 && (reject === 0 || reject === null) ? 0 :
                                    type === 0 && reject === 1
                                        ? 1
                                        : type === 1 && reject === 2
                                            ? 2
                                            : type === 1
                                                ? 3
                                                : type === 2
                                                    ? 4
                                                    : type === 3
                                                        ? 5
                                                        : null
                        });
                    });
                    result = { [obj]: result_arr };
                }
            }
            final_result = { ...final_result, ...result };
        });

        return final_result;
    };
    const getValueByMonthlyHC = (
        dcfId,
        tier0,
        tier1,
        tier2,
        tier3,
        arr,
        dpnames,
        qn_submit_list
    ) => {
        // let months = ['Jan-' + yr, 'Feb-' + yr, 'Mar-' + yr, 'Apr-' + yr, 'May-' + yr, 'Jun-' + yr, 'Jul-' + yr, 'Aug-' + yr, 'Sep-' + yr, 'Oct-' + yr, 'Nov-' + yr, 'Dec-' + yr]
        let final_result = {};

        arr.forEach((obj) => {

            let result = {
                [obj]: [{
                    remarks: null,
                    category: null,
                    form_type: 3,
                    current: "",
                    percentage: "",
                    text: "Pending Submission",
                    col: getNumberOfColumn(obj),
                    status: null,
                }]
            };
            let result_arr = [];
            let index = qn_submit_list.findIndex((i) => {

                return (
                    i.dcfId === dcfId &&

                    i.tier0_id === tier0 &&
                    i.tier1_id === tier1 &&
                    i.tier2_id === tier2 &&
                    i.tier3_id === tier3 &&
                    getRPTextFormat(i.reporting_period) === obj
                );
            });

            if (index !== -1) {


                const { type, reject } = qn_submit_list[index];
                let value = null;
                result_arr = [];
                let list_arr = [{
                    remarks: qn_submit_list[index].return_remarks,
                    form_type: 3,
                    col: getNumberOfColumn(obj),
                    id: qn_submit_list[index].id,
                    category: 1,
                    diff: false,
                    current: '',
                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                    percentage: "-",
                    type,
                    text:
                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                            type === 0 && reject === 1
                                ? "Unlocked for Resubmission"
                                : type === 1 && reject === 2
                                    ? "Unlocked for Review Again"
                                    : type === 1
                                        ? "Pending Review"
                                        : type === 2
                                            ? "Pending Approval"
                                            : type === 3
                                                ? "Approved & Locked"
                                                : "Pending Submission",
                    status:
                        type === 0 && (reject === 0 || reject === null) ? 0 :
                            type === 0 && reject === 1
                                ? 1
                                : type === 1 && reject === 2
                                    ? 2
                                    : type === 1
                                        ? 3
                                        : type === 2
                                            ? 4
                                            : type === 3
                                                ? 5
                                                : null
                }
                ]





                result = { [obj]: list_arr };

            }
            final_result = { ...final_result, ...result };
        });

        return final_result;
    };



    const getValueByMonthlyHC3Sub = (
        dcfId,
        tier0,
        tier1,
        tier2,
        tier3,
        arr,
        dpnames,
        qn_submit_list, efstdlist, subcat, standardId, categoryId
    ) => {
        // let months = ['Jan-' + yr, 'Feb-' + yr, 'Mar-' + yr, 'Apr-' + yr, 'May-' + yr, 'Jun-' + yr, 'Jul-' + yr, 'Aug-' + yr, 'Sep-' + yr, 'Oct-' + yr, 'Nov-' + yr, 'Dec-' + yr]

        let stdindx = efstdlist.findIndex(i => i.id === standardId)

        const [a, b, x] = dpnames

        let final_result = {};
        if (stdindx !== -1) {
            arr.forEach((obj) => {

                let result = {
                    [obj]: [],
                };
                let result_arr = [];
                let index = qn_submit_list.findIndex((i) => {

                    return (
                        i.dcfId === dcfId && i.standard === standardId &&

                        i.tier0_id === tier0 &&
                        i.tier1_id === tier1 &&
                        i.tier2_id === tier2 &&
                        i.tier3_id === tier3 &&
                        getRPTextFormat(i.reporting_period) === obj
                    );
                });

                if (index !== -1) {
                    let index2 = qn_submit_list.findIndex((i) => {

                        return (
                            i.dcfId === dcfId && i.standard === standardId &&
                            (i.type == 0 ? i.reject === 1 : true) &&
                            i.tier0_id === tier0 &&
                            i.tier1_id === tier1 &&
                            i.tier2_id === tier2 &&
                            i.tier3_id === tier3 &&
                            getRPTextFormat(
                                [i.reporting_period[i.reporting_period.length - 1]]
                            ) === getPreviousPeriod(obj.includes("to") ? 2 : 1, obj)
                        );
                    });

                    if (index2 !== -1 && qn_submit_list[index2].response.length && qn_submit_list[index].response.length) {
                        const { type1, reject1 } = qn_submit_list[index2];
                        const { type, reject } = qn_submit_list[index];
                        let value = null,
                            value2 = null;
                        result_arr = [];
                        let list_arr = []
                        let list_arr2 = []
                        qn_submit_list[index2].response.forEach((i) => {
                            let ind = list_arr2.findIndex((j) => j[a] === i[a] && j[b] === i[b])
                            if (ind !== -1) {
                                list_arr2[ind][x] += i[x]
                            } else {
                                list_arr2.push(i)
                            }
                        })
                        qn_submit_list[index].response.forEach((i) => {
                            let ind = list_arr.findIndex((j) => j[a] === i[a] && j[b] === i[b])
                            if (ind !== -1) {
                                list_arr[ind][x] += i[x]
                                list_arr[ind].current = list_arr[ind][x]
                                let value = list_arr[ind][x], value2 = null
                                let oldIndex = list_arr2.findIndex((j) => j[a] === i[a] && j[b] === i[b])
                                if (oldIndex !== -1) {
                                    value2 = list_arr2[oldIndex][x]
                                    list_arr[ind].percentage = calculatePercentage(value, value2)
                                    list_arr[ind].diff = value === value2 ? null : value > value2 ? false : true
                                    list_arr[ind].category = 2
                                }

                            } else {
                                let value = i[x], value2 = null
                                let oldIndex = list_arr2.findIndex((j) => j[a] === i[a] && j[b] === i[b])
                                if (oldIndex !== -1) {
                                    value2 = list_arr2[oldIndex][x]
                                    list_arr.push({
                                        ...i, remarks: qn_submit_list[index].return_remarks,
                                        col: getNumberOfColumn(obj),
                                        id: qn_submit_list[index].id,
                                        category: 2,
                                        form_type: 2,
                                        diff: value === value2 ? null : value > value2 ? false : true,
                                        current: i[x],
                                        month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                        percentage: calculatePercentage(value, value2),
                                        type,
                                        text:
                                            type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                type === 0 && reject === 1
                                                    ? "Unlocked for Resubmission"
                                                    : type === 1 && reject === 2
                                                        ? "Unlocked for Review Again"
                                                        : type === 1
                                                            ? "Pending Review"
                                                            : type === 2
                                                                ? "Pending Approval"
                                                                : type === 3
                                                                    ? "Approved & Locked"
                                                                    : "Pending Submission",
                                        status:
                                            type === 0 && (reject === 0 || reject === null) ? 0 :
                                                type === 0 && reject === 1
                                                    ? 1
                                                    : type === 1 && reject === 2
                                                        ? 2
                                                        : type === 1
                                                            ? 3
                                                            : type === 2
                                                                ? 4
                                                                : type === 3
                                                                    ? 5
                                                                    : null
                                    })
                                } else {
                                    list_arr.push({
                                        ...i, remarks: qn_submit_list[index].return_remarks,
                                        col: getNumberOfColumn(obj),
                                        id: qn_submit_list[index].id,
                                        category: 1,
                                        form_type: 2,
                                        diff: false,
                                        current: i[x],
                                        month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                        percentage: '-',
                                        type,
                                        text:
                                            type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                type === 0 && reject === 1
                                                    ? "Unlocked for Resubmission"
                                                    : type === 1 && reject === 2
                                                        ? "Unlocked for Review Again"
                                                        : type === 1
                                                            ? "Pending Review"
                                                            : type === 2
                                                                ? "Pending Approval"
                                                                : type === 3
                                                                    ? "Approved & Locked"
                                                                    : "Pending Submission",
                                        status:
                                            type === 0 && (reject === 0 || reject === null) ? 0 :
                                                type === 0 && reject === 1
                                                    ? 1
                                                    : type === 1 && reject === 2
                                                        ? 2
                                                        : type === 1
                                                            ? 3
                                                            : type === 2
                                                                ? 4
                                                                : type === 3
                                                                    ? 5
                                                                    : null
                                    })
                                }

                            }
                        })

                        result = { [obj]: list_arr };
                    } else {
                        const { type, reject } = qn_submit_list[index];
                        let value = null;
                        result_arr = [];
                        let list_arr = []
                        if (qn_submit_list[index].response.length) {
                            qn_submit_list[index].response.forEach((i) => {
                                let ind = list_arr.findIndex((j) => j[a] === i[a] && j[b] === i[b])
                                if (ind !== -1) {
                                    list_arr[ind][x] += i[x]
                                    list_arr[ind].current = list_arr[ind][x]
                                } else {
                                    list_arr.push(
                                        {
                                            ...i, remarks: qn_submit_list[index].return_remarks,
                                            col: getNumberOfColumn(obj),
                                            id: qn_submit_list[index].id,
                                            form_type: 2,
                                            category: 1,
                                            diff: false,
                                            current: i[x],
                                            month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                            percentage: "-",
                                            type,
                                            text:
                                                type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                    type === 0 && reject === 1
                                                        ? "Unlocked for Resubmission"
                                                        : type === 1 && reject === 2
                                                            ? "Unlocked for Review Again"
                                                            : type === 1
                                                                ? "Pending Review"
                                                                : type === 2
                                                                    ? "Pending Approval"
                                                                    : type === 3
                                                                        ? "Approved & Locked"
                                                                        : "Pending Submission",
                                            status:
                                                type === 0 && (reject === 0 || reject === null) ? 0 :
                                                    type === 0 && reject === 1
                                                        ? 1
                                                        : type === 1 && reject === 2
                                                            ? 2
                                                            : type === 1
                                                                ? 3
                                                                : type === 2
                                                                    ? 4
                                                                    : type === 3
                                                                        ? 5
                                                                        : null
                                        })
                                }
                            })
                        } else {
                            list_arr.push({
                                edit: 1,
                                remarks: qn_submit_list[index].return_remarks,
                                col: getNumberOfColumn(obj),
                                id: qn_submit_list[index].id,
                                form_type: 2,
                                category: 1,
                                diff: false,
                                current: '-',
                                month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                percentage: "-",
                                type,
                                text:
                                    type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                        type === 0 && reject === 1
                                            ? "Unlocked for Resubmission"
                                            : type === 1 && reject === 2
                                                ? "Unlocked for Review Again"
                                                : type === 1
                                                    ? "Pending Review"
                                                    : type === 2
                                                        ? "Pending Approval"
                                                        : type === 3
                                                            ? "Approved & Locked"
                                                            : "Pending Submission",
                                status:
                                    type === 0 && (reject === 0 || reject === null) ? 0 :
                                        type === 0 && reject === 1
                                            ? 1
                                            : type === 1 && reject === 2
                                                ? 2
                                                : type === 1
                                                    ? 3
                                                    : type === 2
                                                        ? 4
                                                        : type === 3
                                                            ? 5
                                                            : null
                            })
                        }

                        result = { [obj]: list_arr };
                    }
                }

                final_result = { ...final_result, ...result };
            });
        } else {
            arr.forEach((obj) => {
                let result = {
                    [obj]: [],
                };
                final_result = { ...final_result, ...result };
            })

        }
        Object.values(final_result).forEach((j) => {
            let find = Object.values(j).filter(i => i[a] !== undefined && i[b] !== undefined)

            Object.values(j).forEach((k) => {
                if (k.category !== null && find.length) {
                    let name1 = subcat['cat2'].find(i => i.id === k[a])
                    let name2 = subcat['cat3'].find(i => i.id === k[b])
                    k.dp = (name1 === undefined ? 'NA' : name1.title) + ' / ' + (name2 === undefined ? 'NA' : name2.title)
                } else {
                    k.dp = null
                }
            })
        })
        return final_result;
    };
    function getOverdueDays(monthString) {

        const [startMonth, endMonth] = monthString.split(' to ');

        const month = endMonth ? endMonth : startMonth;
        const [monthValue, year] = month.split('-');
        const endOfMonth = DateTime.fromObject({ year: parseInt(year), month: DateTime.fromFormat(monthValue, 'LLL').month }).endOf('month');
        const currentDate = DateTime.local();

        return endOfMonth.diff(currentDate, 'days').days;
    }
    const getValueByMonthlyHC1Sub = (
        dcfId,
        tier0,
        tier1,
        tier2,
        tier3,
        arr,
        dpnames,
        qn_submit_list, efstdlist, subcat, standardId, unit
    ) => {
        // let months = ['Jan-' + yr, 'Feb-' + yr, 'Mar-' + yr, 'Apr-' + yr, 'May-' + yr, 'Jun-' + yr, 'Jul-' + yr, 'Aug-' + yr, 'Sep-' + yr, 'Oct-' + yr, 'Nov-' + yr, 'Dec-' + yr]

        let stdindx = efstdlist.findIndex(i => i.id === standardId)

        const [a, x] = dpnames

        let final_result = {};
        if (stdindx !== -1) {
            arr.forEach((obj) => {

                let result = {
                    [obj]: [],
                };
                let result_arr = [];
                let index = qn_submit_list.findIndex((i) => {

                    return (
                        i.dcfId === dcfId && i.standard === standardId &&

                        i.tier0_id === tier0 &&
                        i.tier1_id === tier1 &&
                        i.tier2_id === tier2 &&
                        i.tier3_id === tier3 &&
                        getRPTextFormat(i.reporting_period) === obj
                    );
                });

                if (index !== -1) {
                    let index2 = qn_submit_list.findIndex((i) => {

                        return (
                            i.dcfId === dcfId && i.standard === standardId &&
                            (i.type == 0 ? i.reject === 1 : true) &&
                            i.tier0_id === tier0 &&
                            i.tier1_id === tier1 &&
                            i.tier2_id === tier2 &&
                            i.tier3_id === tier3 &&
                            getRPTextFormat(
                                [i.reporting_period[i.reporting_period.length - 1]]
                            ) === getPreviousPeriod(obj.includes("to") ? 2 : 1, obj)
                        );
                    });

                    if (index2 !== -1 && qn_submit_list[index2].response.length && qn_submit_list[index].response.length) {
                        const { type1, reject1 } = qn_submit_list[index2];
                        const { type, reject } = qn_submit_list[index];
                        let value = null,
                            value2 = null;
                        result_arr = [];
                        let list_arr = []
                        let list_arr2 = []
                        qn_submit_list[index2].response.forEach((i) => {
                            let ind = list_arr2.findIndex((j) => j[a] === i[a])
                            if (ind !== -1) {
                                list_arr2[ind][x] += i[x]
                            } else {
                                list_arr2.push(i)
                            }
                        })
                        qn_submit_list[index].response.forEach((i) => {
                            let ind = list_arr.findIndex((j) => j[a] === i[a])
                            if (ind !== -1) {
                                list_arr[ind][x] += i[x]
                                list_arr[ind].current = list_arr[ind][x]
                                let value = list_arr[ind][x], value2 = null
                                let oldIndex = list_arr2.findIndex((j) => j[a] === i[a])
                                if (oldIndex !== -1) {
                                    value2 = list_arr2[oldIndex][x]
                                    list_arr[ind].percentage = calculatePercentage(value, value2)
                                    list_arr[ind].diff = value === value2 ? null : value > value2 ? false : true
                                    list_arr[ind].category = 2
                                }

                            } else {
                                let value = i[x], value2 = null
                                let oldIndex = list_arr2.findIndex((j) => j[a] === i[a])
                                if (oldIndex !== -1) {
                                    value2 = list_arr2[oldIndex][x]
                                    list_arr.push({
                                        ...i, remarks: qn_submit_list[index].return_remarks,
                                        col: getNumberOfColumn(obj),
                                        id: qn_submit_list[index].id,
                                        category: 2,
                                        form_type: 2,
                                        diff: value === value2 ? null : value > value2 ? false : true,
                                        current: i[x],
                                        month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                        percentage: calculatePercentage(value, value2),
                                        type,
                                        text:
                                            type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                type === 0 && reject === 1
                                                    ? "Unlocked for Resubmission"
                                                    : type === 1 && reject === 2
                                                        ? "Unlocked for Review Again"
                                                        : type === 1
                                                            ? "Pending Review"
                                                            : type === 2
                                                                ? "Pending Approval"
                                                                : type === 3
                                                                    ? "Approved & Locked"
                                                                    : "Pending Submission",
                                        status:
                                            type === 0 && (reject === 0 || reject === null) ? 0 :
                                                type === 0 && reject === 1
                                                    ? 1
                                                    : type === 1 && reject === 2
                                                        ? 2
                                                        : type === 1
                                                            ? 3
                                                            : type === 2
                                                                ? 4
                                                                : type === 3
                                                                    ? 5
                                                                    : null
                                    })
                                } else {
                                    list_arr.push({
                                        ...i, remarks: qn_submit_list[index].return_remarks,
                                        col: getNumberOfColumn(obj),
                                        id: qn_submit_list[index].id,
                                        category: 1,
                                        form_type: 2,
                                        diff: false,
                                        current: i[x],
                                        month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                        percentage: '-',
                                        type,
                                        text:
                                            type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                type === 0 && reject === 1
                                                    ? "Unlocked for Resubmission"
                                                    : type === 1 && reject === 2
                                                        ? "Unlocked for Review Again"
                                                        : type === 1
                                                            ? "Pending Review"
                                                            : type === 2
                                                                ? "Pending Approval"
                                                                : type === 3
                                                                    ? "Approved & Locked"
                                                                    : "Pending Submission",
                                        status:
                                            type === 0 && (reject === 0 || reject === null) ? 0 :
                                                type === 0 && reject === 1
                                                    ? 1
                                                    : type === 1 && reject === 2
                                                        ? 2
                                                        : type === 1
                                                            ? 3
                                                            : type === 2
                                                                ? 4
                                                                : type === 3
                                                                    ? 5
                                                                    : null
                                    })
                                }

                            }
                        })

                        result = { [obj]: list_arr };
                    } else {
                        const { type, reject } = qn_submit_list[index];
                        let value = null;
                        result_arr = [];
                        let list_arr = []
                        if (qn_submit_list[index].response) {
                            qn_submit_list[index].response.forEach((i) => {
                                let ind = list_arr.findIndex((j) => j[a] === i[a])
                                if (ind !== -1) {
                                    list_arr[ind][x] += i[x]
                                    list_arr[ind].current = list_arr[ind][x]
                                } else {
                                    list_arr.push({
                                        ...i, remarks: qn_submit_list[index].return_remarks,
                                        col: getNumberOfColumn(obj),
                                        id: qn_submit_list[index].id,
                                        form_type: 2,
                                        category: 1,
                                        diff: false,
                                        current: i[x],
                                        month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                        percentage: "-",
                                        type,
                                        text:
                                            type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                type === 0 && reject === 1
                                                    ? "Unlocked for Resubmission"
                                                    : type === 1 && reject === 2
                                                        ? "Unlocked for Review Again"
                                                        : type === 1
                                                            ? "Pending Review"
                                                            : type === 2
                                                                ? "Pending Approval"
                                                                : type === 3
                                                                    ? "Approved & Locked"
                                                                    : "Pending Submission",
                                        status:
                                            type === 0 && (reject === 0 || reject === null) ? 0 :
                                                type === 0 && reject === 1
                                                    ? 1
                                                    : type === 1 && reject === 2
                                                        ? 2
                                                        : type === 1
                                                            ? 3
                                                            : type === 2
                                                                ? 4
                                                                : type === 3
                                                                    ? 5
                                                                    : null
                                    })
                                }
                            })
                        }
                        else {
                            list_arr.push({
                                edit: 1,
                                remarks: qn_submit_list[index].return_remarks,
                                col: getNumberOfColumn(obj),
                                id: qn_submit_list[index].id,
                                form_type: 2,
                                category: 1,
                                diff: false,
                                current: '-',
                                month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                percentage: "-",
                                type,
                                text:
                                    type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                        type === 0 && reject === 1
                                            ? "Unlocked for Resubmission"
                                            : type === 1 && reject === 2
                                                ? "Unlocked for Review Again"
                                                : type === 1
                                                    ? "Pending Review"
                                                    : type === 2
                                                        ? "Pending Approval"
                                                        : type === 3
                                                            ? "Approved & Locked"
                                                            : "Pending Submission",
                                status:
                                    type === 0 && (reject === 0 || reject === null) ? 0 :
                                        type === 0 && reject === 1
                                            ? 1
                                            : type === 1 && reject === 2
                                                ? 2
                                                : type === 1
                                                    ? 3
                                                    : type === 2
                                                        ? 4
                                                        : type === 3
                                                            ? 5
                                                            : null
                            })
                        }



                        result = { [obj]: list_arr };
                    }
                }

                final_result = { ...final_result, ...result };
            });
        } else {
            arr.forEach((obj) => {
                let result = {
                    [obj]: [],
                };
                final_result = { ...final_result, ...result };
            })

        }
        Object.values(final_result).forEach((j) => {
            let find = Object.values(j).filter(i => i[a] !== undefined)
            Object.values(j).forEach((k) => {
                if (k.category !== null && find.length) {
                    let name1 = subcat['cat1'].find(i => i.id === k[a])

                    k.dp = (name1 === undefined ? 'NA' : name1.title + '/' + unit)
                } else {
                    k.dp = null
                }
            })
        })
        return final_result;
    };
    const getValueByMonthlyEmpCat275 = (
        dcfId,
        tier0,
        tier1,
        tier2,
        tier3,
        arr, qn_submit_list, unit, self, refobj
    ) => {
        // let months = ['Jan-' + yr, 'Feb-' + yr, 'Mar-' + yr, 'Apr-' + yr, 'May-' + yr, 'Jun-' + yr, 'Jul-' + yr, 'Aug-' + yr, 'Sep-' + yr, 'Oct-' + yr, 'Nov-' + yr, 'Dec-' + yr]
        let land_veh_list = ['Standard Taxi / Sedan', 'Hybrid Vehicle', 'Executive Taxi', 'Dual Purpose 4 x 4 (SUV)', 'Electric Vehicle (EV)', 'Van/ Coach']
        const value_arr = ['a1', 'b1', 'c1', 'd1', 'e1', 'f1']
        let final_result = {};


        arr.forEach((obj) => {

            let result = {
                [obj]: [refobj],
            };
            let result_arr = [];
            let index = qn_submit_list.findIndex((i) => {

                return (
                    i.dcfId === dcfId &&
                    i.tier0_id === tier0 &&
                    i.tier1_id === tier1 &&
                    i.tier2_id === tier2 &&
                    i.tier3_id === tier3 &&
                    getRPTextFormat(i.reporting_period) === obj
                );
            });

            if (index !== -1) {
                let index2 = qn_submit_list.findIndex((i) => {

                    return (
                        i.dcfId === dcfId &&
                        (i.type == 0 ? i.reject === 1 : true) &&
                        i.tier0_id === tier0 &&
                        i.tier1_id === tier1 &&
                        i.tier2_id === tier2 &&
                        i.tier3_id === tier3 &&
                        getRPTextFormat(
                            [i.reporting_period[i.reporting_period.length - 1]]
                        ) === getPreviousPeriod(obj.includes("to") ? 2 : 1, obj)
                    );
                });

                if (index2 !== -1 && qn_submit_list[index2].response.length && qn_submit_list[index].response.length) {
                    const { type1, reject1 } = qn_submit_list[index2];
                    const { type, reject } = qn_submit_list[index];
                    let value = null,
                        value2 = null;
                    result_arr = [];
                    let list_arr = []
                    let list_arr2 = []
                    qn_submit_list[index2].response.forEach((i) => {
                        let abc = {}

                        abc['a1'] = i['DPAN613'];
                        abc['b1'] = i['DPAN614'];
                        abc['c1'] = i['DPAN615'];
                        abc['d1'] = i['DPAN616'];
                        abc['e1'] = i['DPAN617'];
                        abc['f1'] = i['DPAN618'];
                        value_arr.forEach((x) => {
                            list_arr2.push({
                                ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                col: getNumberOfColumn(obj),
                                id: qn_submit_list[index].id,
                                category: 1,
                                form_type: 2,
                                diff: false,
                                current: abc[x],
                                month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                percentage: '-',
                                type, self,
                                text:
                                    type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                        type === 0 && reject === 1
                                            ? "Unlocked for Resubmission"
                                            : type === 1 && reject === 2
                                                ? "Unlocked for Review Again"
                                                : type === 1
                                                    ? "Pending Review"
                                                    : type === 2
                                                        ? "Pending Approval"
                                                        : type === 3
                                                            ? "Approved & Locked"
                                                            : "Pending Submission",
                                status:
                                    type === 0 && (reject === 0 || reject === null) ? 0 :
                                        type === 0 && reject === 1
                                            ? 1
                                            : type === 1 && reject === 2
                                                ? 2
                                                : type === 1
                                                    ? 3
                                                    : type === 2
                                                        ? 4
                                                        : type === 3
                                                            ? 5
                                                            : null
                            })
                        })


                    })
                    qn_submit_list[index].response.forEach((i) => {
                        let abc = {}
                        abc['a1'] = i['DPAN613'];
                        abc['b1'] = i['DPAN614'];
                        abc['c1'] = i['DPAN615'];
                        abc['d1'] = i['DPAN616'];
                        abc['e1'] = i['DPAN617'];
                        abc['f1'] = i['DPAN618'];

                        value_arr.forEach((x) => {

                            let oldIndex = list_arr2.findIndex((j) => j[x] !== undefined)
                            if (oldIndex !== -1) {
                                value2 = list_arr2[oldIndex][x]

                                list_arr.push({
                                    ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    category: 2,
                                    form_type: 2,
                                    diff: abc[x] === value2 ? null : abc[x] > value2 ? false : true,
                                    current: abc[x],
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: calculatePercentage(abc[x], value2),
                                    type, self,
                                    text:
                                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                            type === 0 && reject === 1
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || reject === null) ? 0 :
                                            type === 0 && reject === 1
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })

                            } else {

                                list_arr.push({
                                    ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    category: 2,
                                    form_type: 2,
                                    diff: false,
                                    current: abc[x],
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: '100%',
                                    type, self,
                                    text:
                                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                            type === 0 && reject === 1
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || reject === null) ? 0 :
                                            type === 0 && reject === 1
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })
                            }
                        })





                    })


                    result = { [obj]: list_arr };
                } else {

                    const { type, reject } = qn_submit_list[index];
                    let value = null;
                    result_arr = [];
                    let list_arr = []

                    if (qn_submit_list[index].response.length) {

                        qn_submit_list[index].response.forEach((i) => {
                            let abc = {}

                            abc['a1'] = i['DPAN613'];
                            abc['b1'] = i['DPAN614'];
                            abc['c1'] = i['DPAN615'];
                            abc['d1'] = i['DPAN616'];
                            abc['e1'] = i['DPAN617'];
                            abc['f1'] = i['DPAN618'];
                            value_arr.forEach((x) => {
                                list_arr.push({
                                    ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    form_type: 2,
                                    category: 1,
                                    diff: false,
                                    current: abc[x],
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: "-",
                                    type, self,
                                    text:
                                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                            type === 0 && reject === 1
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || reject === null) ? 0 :
                                            type === 0 && reject === 1
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })
                            })




                        })
                    }
                    else {
                        list_arr.push({
                            edit: 1,
                            remarks: qn_submit_list[index].return_remarks,
                            col: getNumberOfColumn(obj),
                            id: qn_submit_list[index].id,
                            form_type: 2,
                            category: 1,
                            diff: false,
                            current: '-',
                            month: getRPTextFormat(qn_submit_list[index].reporting_period),
                            percentage: "-",
                            type, self,
                            text:
                                type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                    type === 0 && reject === 1
                                        ? "Unlocked for Resubmission"
                                        : type === 1 && reject === 2
                                            ? "Unlocked for Review Again"
                                            : type === 1
                                                ? "Pending Review"
                                                : type === 2
                                                    ? "Pending Approval"
                                                    : type === 3
                                                        ? "Approved & Locked"
                                                        : "Pending Submission",
                            status:
                                type === 0 && (reject === 0 || reject === null) ? 0 :
                                    type === 0 && reject === 1
                                        ? 1
                                        : type === 1 && reject === 2
                                            ? 2
                                            : type === 1
                                                ? 3
                                                : type === 2
                                                    ? 4
                                                    : type === 3
                                                        ? 5
                                                        : null
                        })
                    }



                    result = { [obj]: list_arr };
                }
            }

            final_result = { ...final_result, ...result };
        });

        Object.values(final_result).forEach((j) => {

            let find = Object.values(j).filter(i => (i['a1'] !== undefined));

            Object.values(j).forEach((k) => {

                if (k.category !== null && find.length) {
                    let name1 = 'NA'
                    if (k['a1'] || k['a1'] === 0) {
                        name1 = 'Senior Management (Male)'
                    } else if (k['b1'] || k['b1'] === 0) {

                        name1 = 'Senior Management (Female)'
                    } else if (k['c1'] || k['c1'] === 0) {
                        name1 = 'Middle Management (Male)'
                    } else if (k['d1'] || k['d1'] === 0) {
                        name1 = 'Middle Management (Female)'
                    }
                    else if (k['e1'] || k['e1'] === 0) {
                        name1 = 'Non-Management (Male)'
                    }
                    else if (k['f1'] || k['f1'] === 0) {
                        name1 = 'Non-Management (Female)'
                    }


                    k.dp = (name1 === undefined ? 'NA' : name1 + ' ^ ' + unit)



                } else {
                    k.dp = null
                }
            })
        })

        return final_result;
    };
    function arraysAreEqual(arr1, arr2) {
        // Check if the arrays have the same length
        if (arr1.length !== arr2.length) {
            return false;
        }

        // Check if each element in arr1 matches the corresponding element in arr2
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) {
                return false;
            }
        }

        // If all elements match and lengths are the same, arrays are equal
        return true;
    }
    function getUniqueYearsArray(array1, array2) {
        // Convert arrays to sets to ensure uniqueness
        const set1 = new Set(array1);
        const set2 = new Set(array2);

        // Merge the sets
        const mergedSet = new Set([...set1, ...set2]);

        // Convert the merged set back to an array
        const uniqueYearsArray = Array.from(mergedSet);

        return uniqueYearsArray;
    }

    function getYearsArray(start_date, end_date = null) {
        // Parse the start date
        const startDate = DateTime.fromISO(start_date);

        // Parse the end date or use current UTC local date if end_date is null
        const endDate = end_date ? DateTime.fromISO(end_date) : DateTime.utc();

        // Get the years between start and end dates
        const yearsArray = [];
        for (let year = startDate.year; year <= endDate.year; year++) {

            yearsArray.push(year);

        }

        return yearsArray;
    }
    const checkEnity = (rowData, entity_list, rawsite, obj) => {
        let index = entity_list.findIndex((k) => k[obj] === rowData[obj]);
        if (index !== -1) {
            let entity = entity_list[index];

            if (rowData.level === 0) {
                return entity.tier0_ids.includes(0);
            } else if (rowData.level === 1) {
                return (
                    entity.tier1_ids.includes(rowData.locationId) &&
                    getCoverageText(rowData, rawsite)
                );
            } else if (rowData.level === 2) {
                return (
                    entity.tier2_ids.includes(rowData.locationId) &&
                    getCoverageText(rowData, rawsite)
                );
            } else if (rowData.level === 3) {
                return (
                    entity.tier3_ids.includes(rowData.locationId) &&
                    getCoverageText(rowData, rawsite)
                );
            }
        } else {
            return false;
        }
    };
    const getDataPointUnit = (rowData, dcfId) => {

        let text = "Not Found";
        let filteredList = dplist
            .filter(
                (i) =>
                    i.suffix !== null &&
                    i.suffix.trim().toLowerCase() === rowData.name.trim().toLowerCase()
            )
            .findIndex(
                (i) => Array.isArray(i.data1)
            );
        console.log(rowData.dcfId, dplist
            .filter(
                (i) =>
                    i.suffix !== null &&
                    i.suffix.trim().toLowerCase() === rowData.name.trim().toLowerCase()
            ))
        if (filteredList !== -1) {
            text = dplist.filter(
                (i) =>
                    i.suffix !== null &&
                    i.suffix.trim().toLowerCase() === rowData.name.trim().toLowerCase()
            )[filteredList].data1[0].unit;
        }

        return text;
    };
    const getCoverageText = (rowData, rawsitelist) => {
        let text = "";

        if (rowData.level === 0) {
            text = "Corporate";
        } else if (rowData.level === 1) {
            let country_index = rawsitelist.findIndex(
                (i) => i.id === rowData.locationId
            );
            if (country_index !== -1) {
                text = rawsitelist[country_index].name;
            }
        } else if (rowData.level === 2) {
            let city_index = rawsitelist
                .flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )
                .findIndex((i) => {
                    return i.city_id === rowData.locationId;
                });
            if (city_index !== -1) {
                text = rawsitelist.flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )[city_index].city_name;
            }
        } else if (rowData.level === 3) {
            let site_index = rawsitelist
                .flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )
                .findIndex((i) => {
                    return i.site_id === rowData.locationId;
                });
            if (site_index !== -1) {
                text = rawsitelist.flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )[site_index].site_name;
            }
        }
        return text;
    };
    function getNumberOfColumn(monthString) {
        if (monthString.includes("to")) {
            const [startMonth, endMonth] = monthString.split(" to ");

            const startDate = DateTime.fromFormat(startMonth, "LLL-yyyy");
            let endDate = DateTime.local(); // Default to current month and year
            if (endMonth) {
                endDate = DateTime.fromFormat(endMonth, "LLL-yyyy");
            }

            // Calculate the difference in months
            const diffMonths = endDate.diff(startDate, "months").months + 1;

            return diffMonths;
        } else {
            return 1;
        }
    }

    const getCoverageTextById = (rowData, rawsitelist) => {
        let text = "";

        if (rowData.tier0_id !== null) {
            text = "Corporate";
        } else if (rowData.tier1_id !== null) {
            let country_index = rawsitelist.findIndex(
                (i) => i.id === rowData.tier1_id
            );
            if (country_index !== -1) {
                text = rawsitelist[country_index].name;
            }
        } else if (rowData.tier2_id !== null) {
            let city_index = rawsitelist
                .flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )
                .findIndex((i) => {
                    return i.city_id === rowData.tier2_id;
                });
            if (city_index !== -1) {
                text = rawsitelist.flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )[city_index].city_name;
            }
        } else if (rowData.tier3_id !== null) {
            let site_index = rawsitelist
                .flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )
                .findIndex((i) => {
                    return i.site_id === rowData.tier3_id;
                });
            if (site_index !== -1) {
                text = rawsitelist.flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )[site_index].site_name;
            }
        }
        return text;
    };
    const titleTemplate = (rowData) => {
        let text = "Not Found";
        let dcf_index = dcflist.findIndex((i) => i.id === rowData.dcfId);
        if (dcf_index !== -1) {
            text = dcflist[dcf_index].title;
        }
        return (
            <div
                className="text-underline cur-pointer clr-navy fw-5"
                onClick={() => {
                    navigate.push({
                        pathname:
                            "/data_input_reviewer/" + rowData.dcfId + "/" + rowData.id,
                    });
                }}
            >
                {text}
            </div>
        );
    };
    const getFormTitle = (rowData) => {
        let text = "Not Found";
        let dcf_index = dcflist.findIndex((i) => i.id === rowData.dcfId);
        if (dcf_index !== -1) {
            text = dcflist[dcf_index].title;
        }
        return text;
    };
    const dpNameTemplate = (rowData) => {

        return (
            <>
                {rowData.label
                    .replace(/(<([^>]+)>)/gi, "")
                    .replace(/\n/g, " ")
                    .replace(/&nbsp;/g, " ")
                    .replace("&amp;", "&")}
            </>
        );
    };
    const coverageTemplate = (rowData) => {
        return <>{getCoverageText(rowData, rawsitelist)}</>;
    };
    const getUser = (id) => {
        let user_name = 'Not Found'
        let index = userList.findIndex(i => i.id === id)
        if (index !== -1) {
            user_name = userList[index].information.empname
        }
        return user_name
    };
    const submitterTemplate = (rowData) => {
        return <>{getUser(rowData.reporter_modified_by)}</>;
    };
    const submittedTemplate = (rowData) => {
        return (
            <>
                {DateTime.fromISO(rowData.last_modified_on, { zone: "utc" })
                    .toLocal()
                    .toFormat("dd-LLL-yyyy HH:mm")}
            </>
        );
    };
    const rpFrequencyTemplate = (rowData) => {
        return <span>{getFrequencyText(rowData.frequency)}</span>;
    };
    const janTemplate = (rowData) => {
        return <span>{rowData.locationId}</span>;
    };
    const getColumn = (rowData) => {

        return 1;
    };

    const getFrequencyText = (id) => {
        return frequency_list.find((i) => {
            return i.id === id;
        }).name;
    };
    const sortRP = (e) => {

        if (e.order === 1) {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromFormat(a.reporting_period, "MMM-yyyy");
                let dateB = DateTime.fromFormat(b.reporting_period, "MMM-yyyy");
                if (a.reporting_period.includes("to")) {
                    dateA = DateTime.fromFormat(
                        a.reporting_period.split("to")[0].trim(),
                        "MMM-yyyy"
                    );
                }
                if (b.reporting_period.includes("to")) {
                    dateB = DateTime.fromFormat(
                        b.reporting_period.split("to")[0].trim(),
                        "MMM-yyyy"
                    );
                }

                // Compare the parsed dates
                if (dateA < dateB) return -1;
                if (dateA > dateB) return 1;
                return 0;
            });
        } else {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromFormat(a.reporting_period, "MMM-yyyy");
                let dateB = DateTime.fromFormat(b.reporting_period, "MMM-yyyy");
                if (a.reporting_period.includes("to")) {
                    dateA = DateTime.fromFormat(
                        a.reporting_period.split("to")[0].trim(),
                        "MMM-yyyy"
                    );
                }
                if (b.reporting_period.includes("to")) {
                    dateB = DateTime.fromFormat(
                        b.reporting_period.split("to")[0].trim(),
                        "MMM-yyyy"
                    );
                }
                // Compare the parsed dates in descending order
                if (dateA > dateB) return -1;
                if (dateA < dateB) return 1;
                return 0;
            });
        }
    };
    const getStatusText = (val, rowData) => {
        let text = "NOT SUBMITTED";

        if (rowData !== undefined) {
            if (rowData.type === 1) {
                text = "Not Reviewed";
            } else if (rowData.type === 2) {
                if (rowData.self) {
                    text = "Self Reviewed";
                } else {
                    text = "Reviewed";
                }
            } else if (rowData.type === 3) {
                text = "APPROVED";
            }
        }
        return text;
    };
    const sortStatus_ = (e) => {
        if (e.order === 1) {
            return e.data.sort((a, b) => {
                const dateA = getStatusText("", a);
                const dateB = getStatusText("", b);

                // Compare the parsed dates
                if (dateA < dateB) return -1;
                if (dateA > dateB) return 1;
                return 0;
            });
        } else {
            return e.data.sort((a, b) => {
                const dateA = getStatusText("", a);
                const dateB = getStatusText("", b);

                // Compare the parsed dates in descending order
                if (dateA > dateB) return -1;
                if (dateA < dateB) return 1;
                return 0;
            });
        }
    };
    const statusTemplate = (rowData) => {
        let type = 0;
        let text = "NOT SUBMITTED";
        let classtext = "status-tag-gray";
        if (rowData !== undefined) {
            if (rowData.type === 0) {
                if (rowData.reject === 1) {
                    type = 1;
                    text = "RETURNED";
                    classtext = "status-tag-red";
                } else {
                    type = 2;
                    text = "DRAFT";
                    classtext = "status-tag-orange";
                }
            } else if (rowData.type === 1) {
                if (rowData.reject === 1) {
                    type = 1;
                    text = "RETURNED";
                    classtext = "status-tag-red";
                } else {
                    type = 3;
                    text = "SUBMITTED";
                    classtext = "status-tag-green";
                }
            } else if (rowData.type === 2) {
                type = 3;
                if (rowData.self) {
                    text = "Self Reviewed";
                } else {
                    text = "Reviewed";
                }

                classtext = "status-tag-green";
            } else if (rowData.type === 3) {
                type = 4;
                text = "APPROVED";
                classtext = "status-tag-green";
            }
        }
        return (
            <Tag style={{ padding: "4px 8px" }} className={classtext}>
                {text}
            </Tag>
        );
    };
    const extractDPFromForms = (list) => {
        let form = JSON.parse(JSON.stringify(list));

        const updatedResponses = form.flatMap((submission) => {
            return submission.response
                .filter((response) => response.type === "number")
                .map((response) => ({
                    ...response,
                    colSpan: submission.reporting_period.length,
                    ...submission,
                }));
        });

        // setRefinedData(updatedResponses)
    };
    function encryptNumber(number, secret) {
        return crypto.AES.encrypt(number, SECRET_KEY).toString();  // Return IV and encrypted text
    }

    function decryptNumber(encrypted) {
        var bytes = crypto.AES.decrypt(encrypted, SECRET_KEY);
        var originalText = bytes.toString(crypto.enc.Utf8);
    }


    const exportExcelReport = (xls_data) => {

        const workbook = XLSX.utils.book_new();

        xls_data.dp_array.forEach((i) => {
            Object.entries(i).forEach((j) => {
                if (j[0] !== 'name') {
                    if (j[1][0].category !== null && j[1][0].id !== undefined && j[1][0].id !== null) {
                        let index = submittedQuantitative.findIndex(x => x.id === j[1][0].id)
                        if (index !== -1) {
                            const sheetName = j[0]
                            const dataArray = submittedQuantitative[index].response.filter(x => x.type === 'number')

                            const transformedData = dataArray.map((item) => ({
                                'reference#': encryptNumber((j[1][0].id).toString()),
                                "dp_reference": encryptNumber((j[1][0].dp).toString()),
                                'Data Point': item.label.replace(/(<([^>]+)>)/gi, "")
                                    .replace(/\n/g, " ")
                                    .replace(/&nbsp;/g, " ")
                                    .replace("&amp;", "&"),
                                Value: item.value,
                            }));
                            const worksheet = XLSX.utils.json_to_sheet(transformedData);
                            worksheet['!protect'] = {
                                selectLockedCells: false,
                                selectLockedCells: false,
                                selectLockedCells: true, // Prevents selection of locked cells
                                selectUnlockedCells: true, // Allows selection of unlocked cells
                            };

                            worksheet['!cols'] = [
                                { locked: true, hidden: true },
                                { locked: true, hidden: true },
                                { locked: false }, // Lock the first column (DP)
                                { locked: false }, // Unlock the second column (Value)
                            ];
                            XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);


                            // Append the worksheet to the workbook


                        }
                    }
                }
            })
        })
        // Create a binary representation of the workbook
        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        // Create a Blob from the binary data
        const blob = new Blob([wbout], { type: 'application/octet-stream' });

        // Create a downloadable link
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'download.xlsx'; // Set the file name for the download

        // Trigger the download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up
    }
    const updateSelectedItem = (item, index) => {
        let list_loc = JSON.parse(JSON.stringify(refineddata));
        list_loc.forEach((item, j) => {
            if (index === j) {
                item.expanded = item.expanded === undefined ? true : !item.expanded;
            }
        });
        setRefinedData(list_loc);

        forceUpdate();
    };
    function filterAndSortByYear(data, year) {
        const keys = Object.keys(data)
            .filter((key) => key.includes(year))
            .sort((a, b) => {
                const [monthA] = a.split("-");
                const [monthB] = b.split("-");
                return (
                    DateTime.fromFormat(monthA, "LLL").month -
                    DateTime.fromFormat(monthB, "LLL").month
                );
            });

        return keys.reduce((result, key) => {
            result[key] = data[key];
            return result;
        }, {});
    }
    const getMonthKey = (key) => {
        return key
        if (key.includes("to")) {
            const [from, to] = key.split(" to ");

            return from.split("-")[0] + " to " + to.split("-")[0];
        } else {
            return key.split("-")[0];
        }
    };
    const updateRefindedDataByFilter = (val) => {
        let loc = JSON.parse(JSON.stringify(refineddatabk));
        let data_ = []
        if (val) {

            setFilter((prev) => ({ ...prev, [val]: [] }));
            if (val === 'entity') {
                data_ = loc.filter((i) => { return (i.year === filter.year || filter.year === null) && (filter.form.includes(i.dcfId) || filter.form.length === 0) && (i.status.some(status => filter.status.includes(status)) || filter.status.length === 0) })

                setRefinedData(loc.filter((i) => { return (i.year === filter.year || filter.year === null) && (filter.form.includes(i.dcfId) || filter.form.length === 0) && (i.status.some(status => filter.status.includes(status)) || filter.status.length === 0) }));
            } else if (val === 'form') {

                data_ = loc.filter((i) => { return (i.year === filter.year || filter.year === null) && (filter.entity.includes(getCoverageTextById(i, rawsitelist)) || filter.entity.length === 0) && (i.status.some(status => filter.status.includes(status)) || filter.status.length === 0) })

                setRefinedData(loc.filter((i) => { return (i.year === filter.year || filter.year === null) && (filter.entity.includes(getCoverageTextById(i, rawsitelist)) || filter.entity.length === 0) && (i.status.some(status => filter.status.includes(status)) || filter.status.length === 0) }));
            } else {
                data_ = loc.filter((i) => { return (i.year === filter.year || filter.year === null) && (filter.entity.includes(getCoverageTextById(i, rawsitelist)) || filter.entity.length === 0) && (filter.form.includes(i.dcfId) || filter.form.length === 0) })

                setRefinedData(loc.filter((i) => { return (i.year === filter.year || filter.year === null) && (filter.entity.includes(getCoverageTextById(i, rawsitelist)) || filter.entity.length === 0) && (filter.form.includes(i.dcfId) || filter.form.length === 0) }));
            }
            let statusCounter = { '0': 0, '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0, '7': 0, '8': 0 }
            data_.forEach(i => {

                Object.keys(statusCounter).forEach((key) => {
                    statusCounter[key] = statusCounter[key] + i.statusArray.filter(x => x === parseFloat(key)).length
                })

            })
            setStatusCodes(statusCounter)

        } else {
            setRefinedData(loc.filter((i) => { return (i.year === filter.year || filter.year === null) && (filter.entity.includes(getCoverageTextById(i, rawsitelist)) || filter.entity.length === 0) && (filter.form.includes(i.dcfId) || filter.form.length === 0) && (i.status.some(status => filter.status.includes(status)) || filter.status.length === 0) }));
            data_ = loc.filter((i) => { return (i.year === filter.year || filter.year === null) && (filter.entity.includes(getCoverageTextById(i, rawsitelist)) || filter.entity.length === 0) && (filter.form.includes(i.dcfId) || filter.form.length === 0) && (i.status.some(status => filter.status.includes(status)) || filter.status.length === 0) })
            let statusCounter = { '0': 0, '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0, '7': 0, '8': 0 }
            data_.forEach(i => {

                Object.keys(statusCounter).forEach((key) => {
                    statusCounter[key] = statusCounter[key] + i.statusArray.filter(x => x === parseFloat(key)).length
                })

            })
            setStatusCodes(statusCounter)
        }



    }
    const onFilterChange = (obj, val) => {

        setFilter((prev) => ({ ...prev, [obj]: val }));
    };
    const onPageChange = (event) => {

        setFirst(event.first);
        setRows(event.rows);
    };
    const getAllStatusCode = (arr) => {

        return new Promise((resolve, err) => {
            let codes = []
            arr.forEach((i, inx) => {
                Object.entries(i).forEach((j) => {
                    if (!j[0].includes('name')) {

                        if (j[1].length) {
                            if (j[1][0].id !== undefined) {
                                if (j[1][0].status === 5) {


                                    codes.push(7)


                                } else if (j[1][0].status === 4) {


                                    codes.push(6)


                                } else if (j[1][0].status === 3) {


                                    codes.push(5)


                                } else if (j[1][0].status === 2) {


                                    codes.push(8)


                                }
                                else if (j[1][0].status === 1) {


                                    codes.push(1)


                                } else if (getOverdueDays(j[0]) <= -15 && (j[1][0].status === null || j[1][0].status === 0)) {


                                    codes.push(2)


                                } else if (getOverdueDays(j[0]) <= 0 && (j[1][0].status === null || j[1][0].status === 0)) {


                                    codes.push(3)


                                } else if (getOverdueDays(j[0]) > 0 && (j[1][0].status === null || j[1][0].status === 0)) {


                                    codes.push(4)


                                }
                            } else {

                                if (getOverdueDays(j[0]) <= -15) {


                                    codes.push(2)


                                } else if (getOverdueDays(j[0]) <= 0) {


                                    codes.push(3)


                                } else if (getOverdueDays(j[0]) > 0) {


                                    codes.push(4)


                                }
                            }
                        } else {

                            if (getOverdueDays(j[0]) <= -15) {



                                codes.push(2)


                            } else if (getOverdueDays(j[0]) <= 0) {


                                codes.push(3)


                            } else if (getOverdueDays(j[0]) > 0) {


                                codes.push(4)


                            }

                        }
                    }
                })
            })

            resolve(codes)
        })



    }
    const onSelectedChangeIntial = (obj, val, refloc) => {
        let loc = JSON.parse(JSON.stringify(refloc));

        if (obj === 'year') {
            let data_ = loc.filter((i) => { return (i.year === val || val === null) && (filter.entity.includes(getCoverageTextById(i, rawsitelist)) || filter.entity.length === 0) && (filter.form.includes(i.dcfId) || filter.form.length === 0) && (i.status.some(status => filter.status.includes(status)) || filter.status.length === 0) })
            let statusCounter = { '0': 0, '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0, '7': 0, '8': 0 }
            data_.forEach(i => {

                Object.keys(statusCounter).forEach((key) => {
                    statusCounter[key] = statusCounter[key] + i.statusArray.filter(x => x === parseFloat(key)).length
                })

            })
            setStatusCodes(statusCounter)
            setRefinedData(data_);
        }


        setLoading(true);

        setFilter((prev) => ({ ...prev, [obj]: val }));
    };
    const onSelectedChange = (obj, val) => {
        let loc = JSON.parse(JSON.stringify(refineddatabk));
        let qnloc = JSON.parse(JSON.stringify(quantitativesubmission))

        if (obj === 'year') {
            let filteredQnAssByYear = filterAssignmentBasedOnYear(assignedlist, val)
            calculateAndCreateYearData(filteredQnAssByYear, dcflist, qnloc, stdlist, { cat1: subcat1, cat2: subcat2, cat3: subcat3, cat4: subcat4 }, val).then((res) => {
                setRefinedDataBk(res)


                let data_ = res.filter((i) => { return (i.year === val || val === null) && (filter.entity.includes(getCoverageTextById(i, rawsitelist)) || filter.entity.length === 0) && (filter.form.includes(i.dcfId) || filter.form.length === 0) && (i.status.some(status => filter.status.includes(status)) || filter.status.length === 0) })
                let statusCounter = { '0': 0, '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0, '7': 0, '8': 0 }

                data_.forEach(i => {
                    console.log(i)
                    Object.keys(statusCounter).forEach((key) => {
                        console.log(i.statusArray.filter(x => x === parseFloat(key)).length)
                        statusCounter[key] = statusCounter[key] + i.statusArray.filter(x => x === parseFloat(key)).length
                    })

                })
                console.log(statusCounter)
                setStatusCodes(statusCounter)
                let entity_option = Array.from(new Set(res.map(i => getCoverageTextById(i, rawsitelist)))).map(i => ({ title: i, id: i }))
                setFormOption(dcflist.filter(i => res.map(i => i.dcfId).includes(i.id)))
                setEntityOption(entity_option)
                setRefinedData(res.filter((i) => { return (i.year === val || val === null) && (filter.entity.includes(getCoverageTextById(i, rawsitelist)) || filter.entity.length === 0) && (filter.form.includes(i.dcfId) || filter.form.length === 0) && (i.status.some(status => filter.status.includes(status)) || filter.status.length === 0) }));

            }

            )

        }
        setFirst(0)
        setRows(5)
        setFilter((prev) => ({ ...prev, [obj]: val }));
    };
    const getRPLuxon = (months) => {
        if (months.includes('to')) {
            let startDate = DateTime.fromFormat(months.split('to')[0].trim(), 'LLL-yyyy')
            let endDate = DateTime.fromFormat(months.split('to')[1].trim(), 'LLL-yyyy')
            let rp = []
            while (startDate <= endDate) {
                rp.push(startDate.toFormat('LL-yyyy'));
                startDate = startDate.plus({ months: 1 })
            }
            return rp
        } else {
            return [DateTime.fromFormat(months, 'LLL-yyyy').toFormat('LL-yyyy')]
        }
    }
    const sortStatus = (a, b) => {
        if (filter.status === 0) {
            return a.status - b.status
        }
        else if (filter.status === 1) {
            return b.status - a.status
        } else {

            return true
        }
    }
    const getRoles = (info) => {
        if (info.role !== undefined || info.role !== null) {
            return Object.entries(info.role).filter(i => i[1] === true).map(i => i[0]).join('/ ')
        } else {
            return 'Unknown'
        }
    }
    const activeTab = (status) => {

    }
    return (
        <div className="bg-smoke font-lato">
            <div className="col-12">
                <div>

                    <div
                        className="col-12 "
                        style={{
                            justifyContent: "center",
                        }}
                    >
                        <label className="text-big-one clr-navy flex fs-16 flex justify-content-start">

                            Raw Data Repository
                        </label>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-6 grid align-items-center">
                            <div className="col-3">
                                <label className="fs-16 fw-5">Select Year</label>
                            </div>
                            <div className="col-8">
                                <Dropdown
                                    optionLabel="label"
                                    optionValue="name"
                                    style={{ width: 200 }}
                                    options={yearoption.sort((a, b) => { return a.name - b.name }, 0)}
                                    value={filter.year}
                                    onChange={(e) => {
                                        onSelectedChange("year", e.value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>


                    {load ? <div >
                        <div
                            className="col-12 flex justify-content-between"
                            style={{ flexDirection: "row", background: "#31597530" }}
                        >
                            <div className="col-11 flex" style={{ flexDirection: "row" }}>
                                <div className="col-2">
                                    <div className="grid m-0" >
                                        <div className=" fw-7 fs-16">Reporting Entity    </div>
                                        <div className="ml-1" > <i style={{ background: filter.entity.length !== 0 && 'aliceblue' }} className="material-icons cur-pointer" onClick={(e) => entityref.current.toggle(e)} >{filter.entity.length !== 0 ? 'filter_alt_off' : 'filter_alt'}</i> </div>
                                        <OverlayPanel ref={entityref} style={{ width: 250 }} >

                                            <MultiSelect value={filter.entity} onChange={(e) => onFilterChange('entity', e.value)} options={entityoption} optionLabel="title" optionValue="id"
                                                filterBy="title" filter maxSelectedLabels={3} placeholder="Select Entity" className="w-full md" panelClassName={'hidefilter'} />

                                            <div className="col-12 mt-2 flex justify-content-between ">
                                                <div >
                                                    <Button label='Clear' outlined onClick={() => { updateRefindedDataByFilter('entity') }} />
                                                </div>
                                                <div >
                                                    <Button label='Apply' disabled={filter.entity.length === 0} onClick={() => { updateRefindedDataByFilter() }} />
                                                </div>
                                            </div>
                                        </OverlayPanel>
                                    </div>
                                </div>
                                <div className="col-8">
                                    <div className="grid m-0">
                                        <div className=" fw-7 fs-16">Form </div>
                                        <div className="ml-1" > <i style={{ background: filter.form.length !== 0 && 'aliceblue' }} className="material-icons cur-pointer" onClick={(e) => formref.current.toggle(e)} >{filter.form.length !== 0 ? 'filter_alt_off' : 'filter_alt'}</i> </div>
                                        <OverlayPanel ref={formref} style={{ width: 250 }} >

                                            <MultiSelect value={filter.form} onChange={(e) => onFilterChange('form', e.value)} options={formoption} optionLabel="title" optionValue="id"
                                                filterBy="title" filter maxSelectedLabels={3} placeholder="Select Form" className="w-full md" panelClassName={'hidefilter'} />

                                            <div className="col-12 mt-2 flex justify-content-between ">
                                                <div >
                                                    <Button label='Clear' outlined onClick={() => { updateRefindedDataByFilter('form') }} />
                                                </div>
                                                <div >
                                                    <Button label='Apply' disabled={filter.form.length === 0} onClick={() => { updateRefindedDataByFilter() }} />
                                                </div>
                                            </div>
                                        </OverlayPanel>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="grid m-0">
                                        <div className=" fw-7 fs-16" >Submission Status </div>
                                        <div className="ml-1" > <i style={{ background: filter.status.length !== 0 && 'aliceblue' }} className="material-icons cur-pointer" onClick={(e) => statusref.current.toggle(e)} >{filter.status.length !== 0 ? 'filter_alt_off' : 'filter_alt'}</i> </div>
                                        <OverlayPanel ref={statusref} style={{ width: 250 }} >

                                            <MultiSelect value={filter.status} onChange={(e) => onFilterChange('status', e.value)} options={[{ title: 'Resubmission', id: 1 }, { title: 'Review Again', id: 8 }, { title: 'Submission Overdue', id: 2 }, { title: 'Submission Due', id: 3 }, { title: 'Pending Review', id: 5 }, { title: 'Pending Approval', id: 6 }, { title: 'Approved', id: 7 }, { title: 'Upcoming', id: 4 }, { title: 'None', id: 0 }]} optionLabel="title" optionValue="id"
                                                placeholder="Select Status" className="w-full md" panelClassName={'hidefilter'} />

                                            <div className="col-12 mt-2 flex justify-content-between ">
                                                <div >
                                                    <Button label='Clear' outlined onClick={() => { updateRefindedDataByFilter('status') }} />
                                                </div>
                                                <div >
                                                    <Button label='Apply' disabled={filter.status.length === 0} onClick={() => { updateRefindedDataByFilter() }} />
                                                </div>
                                            </div>
                                        </OverlayPanel>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex p-flex-wrap submission-display-card-container">
                            <div className="submission-display-card m-1 p-shadow-2 p-p-3 br-1" style={{ border: activetab === 6 ? '2px solid #EE5724' : 'none' }} onClick={() => { activeTab(6) }}><div className="p-0 m-0 "><div className=' card-number-1  col-12'>{statusCodes[2]} </div> <div className='clr-navy card-label-1  col-12'>Submissions Due  </div></div> </div>
                            <div className="submission-display-card m-1 p-shadow-2 p-p-3 br-1" style={{ border: activetab === 7 ? '2px solid #EE5724' : 'none' }} onClick={() => { activeTab(7) }}><div className="p-0 m-0 "><div className=' card-number-1  col-12'>{statusCodes[3]} </div> <div className='clr-navy card-label-1  col-12'>Submissions Overdue</div></div> </div>
                            <div className="submission-display-card m-1 p-shadow-2 p-p-3 br-1" style={{ border: activetab === 1 ? '2px solid #EE5724' : 'none' }} onClick={() => { activeTab(1) }}><div className="p-0 m-0 "><div className=' card-number-1  col-12'>{statusCodes[1]} </div> <div className='clr-navy card-label-1  col-12'> Resubmission Required</div></div> </div>
                            <div className="submission-display-card m-1 p-shadow-2 p-p-3 br-1" style={{ border: activetab === 3 ? '2px solid #EE5724' : 'none' }} onClick={() => { activeTab(3) }}><div className="p-0 m-0 "><div className=' card-number-1  col-12'>{statusCodes[5]} </div> <div className='clr-navy card-label-1  col-12'>Under Review</div></div> </div>
                            <div className="submission-display-card m-1 p-shadow-2 p-p-3 br-1" style={{ border: activetab === 4 ? '2px solid #EE5724' : 'none' }} onClick={() => { activeTab(4) }}><div className="p-0 m-0 "><div className=' card-number-1  col-12'>{statusCodes[6]} </div> <div className='clr-navy card-label-1  col-12'>Under Approval</div></div> </div>
                            <div className="submission-display-card m-1 p-shadow-2 p-p-3 br-1"><div className="p-0 m-0 align-items-center"><div className=' card-number-1  col-12'> 0 </div> <div className='clr-navy card-label-1  col-12'>Unlocked for Resubmission</div></div> </div>
                            <div className="submission-display-card m-1 p-shadow-2 p-p-3 br-1" style={{ border: activetab === 5 ? '2px solid #EE5724' : 'none' }} onClick={() => { activeTab(5) }}><div className="p-0 m-0"><div className=' card-number-1  col-12'>{statusCodes[7]} </div> <div className='clr-navy card-label-1  col-12'>Approved</div></div> </div>

                        </div>
                        {refineddata.length ?
                            <BlockUI blocked={tempload}>
                                <div style={{ height: 'calc(100vh - 300px)', overflow: "auto" }}>

                                    {
                                        refineddata.slice(first, first + rows).map((item, index) => {

                                            return (
                                                <div >
                                                    <div className="m-1">
                                                        <div className="p-card">
                                                            <div>
                                                                <div
                                                                    className="col-12 flex justify-content-between cur-pointer"
                                                                    onClick={() => {
                                                                        updateSelectedItem(item, first + index);
                                                                    }}
                                                                    style={{
                                                                        flexDirection: "row",
                                                                        background: item.expanded && 'rgba(18, 52, 75, 0.063)',
                                                                        borderBottom:
                                                                            item.expanded === true
                                                                                ? "1px solid gray"
                                                                                : "unset",
                                                                    }}
                                                                >
                                                                    <div
                                                                        className="col-11 flex"
                                                                        style={{ flexDirection: "row" }}
                                                                    >
                                                                        <div className="col-2">
                                                                            <div className="col-12 fw-7 fs-16 text-three-dot">
                                                                                {getCoverageTextById(item, rawsitelist)}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-8">
                                                                            <div className="col-12 fw-5 fs-16 text-three-dot">
                                                                                {getFormTitle(item)}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-2 ">
                                                                            {!item.status.includes(0) &&
                                                                                <Tag className={item.status.includes(1) ? "status-tag-red" : item.status.includes(2) ? 'status-tag-orange' : item.status.includes(3) ? 'status-tag-pink' : 'status-tag-blue'}>

                                                                                    {item.status.includes(1) ? 'Resubmission Required' : item.status.includes(2) ? 'Submission Overdue' : item.status.includes(3) ? 'Submission Due' : 'Upcoming'}
                                                                                </Tag>}
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-1 flex align-items-center">
                                                                        {item.expanded === true ? (
                                                                            <>
                                                                                {!hardcoded.dcf.includes(item.dcfId.toString()) && !hardcoded.dcf2.includes(item.dcfId.toString()) && <i className="material-icons" onClick={(e) => { e.stopPropagation(); exportExcelReport(item) }}>
                                                                                    download
                                                                                </i>}
                                                                                <i className="material-icons">
                                                                                    expand_less
                                                                                </i>
                                                                            </>

                                                                        ) : (
                                                                            <i className="material-icons">
                                                                                expand_more
                                                                            </i>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                {item.expanded === true && (
                                                                    <div
                                                                        className="col-12 flex "
                                                                        style={{ flexDirection: "row" }}
                                                                    >
                                                                        <div className="col-3 p-0">
                                                                            <div
                                                                                className="p-card m-1 p-1  flex "
                                                                                style={{
                                                                                    flexDirection: "column",
                                                                                    width: '100%',
                                                                                }}
                                                                            >
                                                                                <div className='flex col-12 p-0 m-1' style={{ flexDirection: 'row', height: item.form_type !== 3 ? 30 : 25, borderBottom: '1px solid gray' }}>

                                                                                    <div className="fw-7 fs-14 flex col-6 p-0 m-1 " >
                                                                                        Datapoint(s)
                                                                                    </div>
                                                                                    <hr
                                                                                        style={{
                                                                                            borderWidth: 2,
                                                                                            margin: 1,
                                                                                        }}
                                                                                    />
                                                                                    <div className="fw-7 fs-14 flex col-6 p-0 m-1 " >
                                                                                        Unit of Measure
                                                                                    </div>
                                                                                </div>
                                                                                {item.dp_array.map((dp, index2) => {

                                                                                    if (index2 === 0) {
                                                                                        {
                                                                                            return dp.name.map((x, index3) => {

                                                                                                return (
                                                                                                    <div>
                                                                                                        <div className=" justify-content-center">
                                                                                                            <div className="col-12 flex p-0 m-1" style={{ flexDirection: 'row', height: 30 }}>
                                                                                                                <div
                                                                                                                    className="col-6 fw-4 fs-14 p-0 text-three-dot block m-1 align-items-center cur-pointer"
                                                                                                                    onClick={(e) => { dpnamerefs[index3].current.toggle(e) }}
                                                                                                                >

                                                                                                                    {item.form_type === 2 ? x.label.split('/')[0] : dpNameTemplate(x)}
                                                                                                                </div>
                                                                                                                <hr
                                                                                                                    style={{
                                                                                                                        borderWidth: 1,
                                                                                                                        margin: 1,
                                                                                                                    }}
                                                                                                                />
                                                                                                                <div
                                                                                                                    className="col-6 fw-4 fs-14 p-0 block m-1 text-three-dot align-items-center"

                                                                                                                >

                                                                                                                    {item.form_type === 2 ? x.label.split('/')[1] : getDataPointUnit(x, item.dcfId)}
                                                                                                                </div>

                                                                                                            </div>
                                                                                                            <OverlayPanel key={index} ref={(ref) => { dpnamerefs[index3].current = ref }}>
                                                                                                                {dpNameTemplate(x)}
                                                                                                            </OverlayPanel>


                                                                                                            <hr
                                                                                                                style={{
                                                                                                                    borderWidth: 2,
                                                                                                                    margin: 1,
                                                                                                                }}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                );
                                                                                            });
                                                                                        }
                                                                                    }
                                                                                })}

                                                                                <div style={{ marginTop: 3 }}>
                                                                                    <div className="text-right">
                                                                                        <div className="flex align-items-center fs-16 clr-navy justify-content-end"

                                                                                            style={{ height: 30 }}

                                                                                        >
                                                                                            Current Status
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="text-right">
                                                                                        <div className="flex align-items-center fs-16 clr-navy justify-content-end"

                                                                                            style={{ height: 30 }}

                                                                                        >
                                                                                            Remarks (if any )
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="text-right">
                                                                                        <div className="flex align-items-center fs-16 clr-navy justify-content-end"

                                                                                            style={{ height: 30 }}

                                                                                        >
                                                                                            Action
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/* <div
                                                                            className="p-card m-1 p-1  flex "
                                                                            style={{
                                                                                flexDirection: "column",
                                                                                width: 120,
                                                                            }}
                                                                        >
                                                                            <div className="fw-7 fs-16 flex " style={{ height: item.form_type !== 3 ? 30 : 25 }}>
                                                                                Unit(s)
                                                                            </div>
                                                                            {item.dp_array.map((dp, index2) => {
                                                                                
                                                                                if (index2 === 0) {
                                                                                    {
                                                                                        return dp.name.map((x, index3) => {
                                                                                            
                                                                                            return (
                                                                                                <div>
                                                                                                    <div className=" justify-content-center">
                                                                                                        <div
                                                                                                            className="fw-4 fs-14 text-three-dot m-1 align-items-center cur-pointer"
                                                                                                            style={{ height: 30 }}
                                                                                                        >

                                                                                                            {item.form_type === 2 ? x.label.split('/')[1] : ''}
                                                                                                        </div>




                                                                                                        <hr
                                                                                                            style={{
                                                                                                                borderWidth: 2,
                                                                                                                margin: 1,
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            );
                                                                                        });
                                                                                    }
                                                                                }
                                                                            })}


                                                                            <div className="text-right">
                                                                                <div className="flex align-items-center fs-16 clr-navy justify-content-end"

                                                                                    style={{ height: 30 }}

                                                                                >

                                                                                </div>
                                                                            </div>
                                                                            <div className="text-right">
                                                                                <div className="flex align-items-center fs-16 clr-navy justify-content-end"

                                                                                    style={{ height: 30 }}

                                                                                >

                                                                                </div>
                                                                            </div>
                                                                            <div className="text-right">
                                                                                <div className="flex align-items-center fs-16 clr-navy justify-content-end"

                                                                                    style={{ height: 30 }}

                                                                                >

                                                                                </div>
                                                                            </div>
                                                                        </div> */}
                                                                        <div className="col-9 p-0">
                                                                            <div
                                                                                style={{
                                                                                    flexDirection: "row",
                                                                                    overflowX: "auto",
                                                                                    display: "-webkit-box", justifyContent: 'space-between'
                                                                                }}
                                                                            >
                                                                                {item.dp_array.map((dp, index2) => {
                                                                                    if (index2 !== 0) {
                                                                                        {
                                                                                            return Object.entries(dp).map((x) => {

                                                                                                return (
                                                                                                    <div
                                                                                                        className="p-card m-1 p-1  flex"
                                                                                                        style={{
                                                                                                            flexDirection: "column",
                                                                                                            width: 180,
                                                                                                        }}
                                                                                                    >
                                                                                                        <div className="status-tag-gray flex justify-content-center align-items-center p-0 m-1" style={{ height: 30, fontSize: 14, fontWeight: 700 }}>
                                                                                                            {getMonthKey(x[0])}
                                                                                                        </div>
                                                                                                        {x[1].map((z) => {

                                                                                                            if ((z.form_type === 1 || z.form_type === 2) && (z.dp !== undefined ? z.dp !== null : true)) {


                                                                                                                return (
                                                                                                                    <div>
                                                                                                                        <div className="">
                                                                                                                            <div
                                                                                                                                className="fw-5 fs-14 flex m-1 justify-content-center align-items-center"
                                                                                                                                style={{ height: 30 }}
                                                                                                                            >
                                                                                                                                {z.current}
                                                                                                                                {z.category === 2 && z.diff !== null &&
                                                                                                                                    <label className="ml-2 flex">
                                                                                                                                        (
                                                                                                                                        <span className="flex align-items-center" style={{ marginLeft: 1, marginRight: 1 }}>
                                                                                                                                            {z.diff ?
                                                                                                                                                <>
                                                                                                                                                    <small style={{ color: 'green' }}>{z.percentage}</small>
                                                                                                                                                    <i
                                                                                                                                                        className="material-icons"
                                                                                                                                                        style={{
                                                                                                                                                            color: z.current
                                                                                                                                                                ? "green"
                                                                                                                                                                : "white",
                                                                                                                                                            fontSize: 14,
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        arrow_downward
                                                                                                                                                    </i>
                                                                                                                                                </>
                                                                                                                                                :
                                                                                                                                                <>
                                                                                                                                                    <small style={{ color: 'red' }}>{z.percentage}</small>
                                                                                                                                                    <i
                                                                                                                                                        className="material-icons"
                                                                                                                                                        style={{
                                                                                                                                                            color: z.current
                                                                                                                                                                ? "red"
                                                                                                                                                                : "white",
                                                                                                                                                            fontSize: 14,
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        arrow_upward
                                                                                                                                                    </i>
                                                                                                                                                </>
                                                                                                                                            }
                                                                                                                                        </span>
                                                                                                                                        )
                                                                                                                                    </label>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <hr
                                                                                                                            style={{
                                                                                                                                borderWidth: 2,
                                                                                                                                margin: 1,
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                );
                                                                                                            }
                                                                                                        })}

                                                                                                        {x[1].length !== 0 && x[1][0].id !== undefined
                                                                                                            ? (
                                                                                                                <div style={{ marginTop: 3 }}>
                                                                                                                    <div className="flex justify-content-center" style={{ height: 30 }}>
                                                                                                                        <Tag
                                                                                                                            className={((x[1][0].status === null || x[1][0].status === 0) ? 'status-tag-red'
                                                                                                                                : x[1][0].status === 1 ? 'status-tag-orange'
                                                                                                                                    : (x[1][0].status === 2 || x[1][0].status === 3) ? 'status-tag-blue'
                                                                                                                                        : x[1][0].status === 4 ? "status-tag-yellow" : "status-tag-green") + ' flex align-items-center'
                                                                                                                            } >

                                                                                                                            {x[1][0].status === null ? 'Pending Submissions' : x[1][0].status === 0 ? 'Drafted' : x[1][0].status === 1 ? 'Requires Resubmissions' : x[1][0].status === 2 ? 'Requires Re-review' : x[1][0].status === 3 ? 'Pending Review' : x[1][0].status === 4 ? 'Pending Approval' : 'Approved'}
                                                                                                                        </Tag>
                                                                                                                    </div>
                                                                                                                    {x[1][0].remarks !== null ?
                                                                                                                        <div className="flex justify-content-center">
                                                                                                                            <div
                                                                                                                                className="cur-pointer text-underline flex align-items-center"
                                                                                                                                style={{ height: 30 }}
                                                                                                                                onClick={() => {
                                                                                                                                    setRemarksData(x[1][0].remarks)
                                                                                                                                    setRemarksDialog(true)
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                View
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        :
                                                                                                                        <div className="flex justify-content-center">
                                                                                                                            <div
                                                                                                                                style={{ height: 30 }}
                                                                                                                            >

                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                    }
                                                                                                                    {(x[1][0].status === 0 || x[1][0].status === 1 || x[1][0].status === 2 || x[1][0].status === 3 || x[1][0].status === 4 || x[1][0].status === 5) ?
                                                                                                                        <div className="flex justify-content-center">
                                                                                                                            <div
                                                                                                                                className="cur-pointer text-underline fw-5 fs-16 clr-navy flex align-items-center"
                                                                                                                                style={{ height: 30 }}
                                                                                                                                onClick={() => {

                                                                                                                                    if (x[1][0].id !== undefined) {
                                                                                                                                        window.open(
                                                                                                                                            window.location
                                                                                                                                                .origin +
                                                                                                                                            "/data_input_status/" +
                                                                                                                                            item.dcfId +
                                                                                                                                            "/" +
                                                                                                                                            x[1][0].id
                                                                                                                                        );
                                                                                                                                    }



                                                                                                                                }}
                                                                                                                            >
                                                                                                                                {'View'}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        :

                                                                                                                        <div className="flex justify-content-center">
                                                                                                                            <div

                                                                                                                                style={{ height: 30 }}

                                                                                                                            >

                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    }


                                                                                                                </div>
                                                                                                            ) :
                                                                                                            (
                                                                                                                <div style={{ marginTop: 3 }}>
                                                                                                                    <div className="flex justify-content-center" style={{ height: 30 }}>
                                                                                                                        <Tag
                                                                                                                            className="status-tag-red"
                                                                                                                        >

                                                                                                                            {'Pending Submission'}
                                                                                                                        </Tag>
                                                                                                                    </div>
                                                                                                                    <div className="flex justify-content-center">
                                                                                                                        <div

                                                                                                                            style={{ height: 30 }}

                                                                                                                        >

                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                    <div className="flex justify-content-center">
                                                                                                                        <div className="cur-pointer text-underline fw-5 fs-16 clr-navy flex align-items-center"
                                                                                                                            style={{ height: 30 }}

                                                                                                                        >

                                                                                                                            NA


                                                                                                                        </div>
                                                                                                                    </div>




                                                                                                                </div>
                                                                                                            )


                                                                                                        }
                                                                                                    </div>
                                                                                                );
                                                                                            });
                                                                                        }
                                                                                    }
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })


                                    }

                                </div >
                            </BlockUI>
                            :
                            <div className="col-12 flex justify-content-center" style={{ height: 'calc(100vh - 300px)' }}>

                                No Data Found
                            </div>
                        }
                        <Paginator first={first} rows={rows} totalRecords={refineddata.length} onPageChange={onPageChange} rowsPerPageOptions={[5, 10, 20]} />
                    </div> :
                        <div>
                            <div className="col-12 flex justify-content-center">
                                <i className="pi pi-spin pi-spinner fs-24" />
                            </div>
                        </div>
                    }

                </div>
            </div>
            <Dialog visible={remarksdialog} modal
                className="p-fluid"
                onHide={() => {
                    setRemarksDialog(false);
                }} style={{ width: '65%' }} header={'Remarks'} >
                <div>
                    {
                        remarksdata.map((cmnt) => {
                            return (
                                <div className="col-12 grid " style={{ marginBottom: 10, borderBottom: '1px solid gray' }}>
                                    <div className="col-5">
                                        <div>   {cmnt.user_id === login_data.id ? 'You' : getUser(cmnt.user_id)}</div>
                                        <div className="mt-2" > {DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy')} </div>
                                    </div>
                                    <div className="col-5">
                                        {cmnt.remarks}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </Dialog>
        </div>
    )

}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};
export default React.memo(OverallQuantitativeSubmissions, comparisonFn);
