import React, { useState, useEffect } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import SuppliersTable from './SuppliersTable';
import DealersTable from './DealersTable';

import APIServices from '../../../../service/APIService';
import { API } from '../../../../constants/api_url';
import { DateTime } from 'luxon';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { useSelector } from 'react-redux';
import useForceUpdate from 'use-force-update';
import { MultiSelect } from 'primereact/multiselect';
import { supplierAssessmentMail } from '../../../../components/MailTemplate/emailtemplate';
import SuppliersTableManual from './SuppliersTableManual';
import SupplierMSIDashboard from './SupplierDashboard/Dashboard';


const MSIDashboard = () => {
    // Mock authorization states
    const admin_data = useSelector((state) => state.user.admindetail);
    const login_data = useSelector((state) => state.user.userdetail);
    const allRoles = useSelector((state) => state.user.allRoles);
    const [show, setShow] = useState(false)
    const [isSupplierScheduler, setIsSupplierScheduler] = useState((login_data.role === 'clientadmin' || allRoles.some((el) => [12, 21].includes(el))));   // Display supplier buttons if true
    const [isDealerScheduler, setIsDealerScheduler] = useState((login_data.role === 'clientadmin' || allRoles.some((el) => [13, 22].includes(el))));       // Display dealer button if true
    const [assobj, setAssObj] = useState({ supplierId: null, assessmentEndDate: null, auditStartDate: null, auditEndDate: null, group1: { sections: [], assessors: [], updated_on: '', updated_by: null }, group2: { sections: [], assessors: [], updated_on: '', updated_by: null }, group3: { sections: [], assessors: [], updated_on: '', updated_by: null }, group4: { sections: [], assessors: [], updated_on: '', updated_by: null } })
    const [dealerassobj, setDealerAssObj] = useState({ dealerId: null, assessmentStartDate: null, assessors: [], auditStartDate: null })
    const supplierList = useSelector(state => state.userlist.supplierList)
    const dealerList = useSelector(state => state.userlist.dealerList)

    const userList = useSelector(state => state.userlist.userList)

    const forceUpdate = useForceUpdate();

    const [activeIndex, setActiveIndex] = useState(0);
    const [globalFilter, setGlobalFilter] = useState('');
    const [adddialog, setAddDialog] = useState(false)
    const [assauditdialog, setAssAuditDialog] = useState(false)
    const [assdealerdialog, setAssDealerDialog] = useState(false)

    const [submitted, setSubmitted] = useState(false);
    const [supplierData, setSupplierData] = useState([]);
    const [dealerData, setDealerData] = useState([]);
    const [assessmentsections, setAssessmentSections] = useState([])
    const [supplierassessorlist, setSupplierAssessorList] = useState([])
    const [dealerassessorlist, setDealerAssessorList] = useState([])


    useEffect(() => {
        let dealerAssUri = {
            "include": ['dealer','dealerAuditorChecklistSubmission']
        }
        const promise0 = APIServices.get(API.SupplierAssessmentAss_Up(admin_data.id))
        const promise1 = APIServices.get(API.AssessmentSection)
        const promise2 = APIServices.get(API.GetRole_Up(admin_data.id))
        const promise3 = APIServices.get(API.DealerAssessmentAss_Up(admin_data.id) + `?filter=${encodeURIComponent(JSON.stringify(dealerAssUri))}`)


        Promise.all([promise0, promise1, promise2, promise3]).then((values) => {

            setSupplierData(values[0].data)
            setDealerData(values[3].data)
            setAssessmentSections(values[1].data.sort((a, b) => { return a.order - b.order }, 0))
            setSupplierAssessorList(userList.filter(i => values[2].data.some(x => x.user_id === i.id && x.roles && x.roles.includes(17))))
            setDealerAssessorList(userList.filter(i => values[2].data.some(x => x.user_id === i.id && x.roles && x.roles.includes(19))))

        })
    }, []);
    const checkGroup1 = (obj) => {

        return obj?.group1 ? ['sections', 'assessors'].every((key) => Array.isArray(obj?.group1[key]) && obj?.group1[key].length > 0) : false
    }
    const saveAuditorCalibration = () => {
        setSubmitted(true)
        let group1 = assobj?.group1 ? ['sections', 'assessors'].every((key) => Array.isArray(assobj?.group1[key]) && assobj?.group1[key].length > 0) : false
        let group2 = assobj?.group2 ? ['sections', 'assessors'].every((key) => Array.isArray(assobj?.group2[key]) && assobj?.group2[key].length > 0) : false
        let group3 = assobj?.group3 ? ['sections', 'assessors'].every((key) => Array.isArray(assobj?.group3[key]) && assobj?.group3[key].length > 0) : false
        let group4 = assobj?.group4 ? ['sections', 'assessors'].every((key) => Array.isArray(assobj?.group4[key]) && assobj?.group4[key].length > 0) : false

        if (assobj.id && assobj.auditStartDate && assobj.auditEndDate && group1) {
            let newObj = { auditStartDate: assobj.auditStartDate, auditEndDate: assobj.auditEndDate }
            const group1Clear = assobj?.group1 ? ['sections', 'assessors'].every((key) => Array.isArray(assobj?.group1[key])) : false
            const group2Clear = assobj?.group2 ? ['sections', 'assessors'].every((key) => Array.isArray(assobj?.group2[key])) : false
            const group3Clear = assobj?.group3 ? ['sections', 'assessors'].every((key) => Array.isArray(assobj?.group3[key])) : false
            const group4Clear = assobj?.group4 ? ['sections', 'assessors'].every((key) => Array.isArray(assobj?.group4[key])) : false
            if (group1Clear) {
                newObj['group1'] = assobj.group1
            }
            if (group2Clear) {
                newObj['group2'] = assobj.group2
            }
            if (group3Clear) {
                newObj['group3'] = assobj.group3
            }
            if (group4Clear) {
                newObj['group4'] = assobj.group4
            }
            let dt = DateTime.utc()
            APIServices.patch(API.SupplierAssessmentAss_Edit(assobj.id), { ...newObj, modified_on: dt, modified_by: login_data.id }).then((res) => {
                let loc = JSON.parse(JSON.stringify(supplierData))
                let index = loc.findIndex(i => i.id === assobj.id)
                if (index !== -1) {
                    loc[index] = { ...assobj, modified_on: dt, modified_by: login_data.id }
                    setSupplierData(loc)
                }
                setAssAuditDialog(false)
            })
        }
    }
    const saveDealerCalibration = () => {
        setSubmitted(true)
        if (dealerassobj.assessors && dealerassobj.assessors.length !== 0 && dealerassobj.dealerId && dealerassobj.auditStartDate) {

            if (dealerassobj.id) {
                let newObj = {
                    assessors: dealerassobj.assessors
                }
                APIServices.patch(API.DealerAssessmentAss_Edit(dealerassobj.id), { ...newObj, modified_on: DateTime.utc(), modified_by: login_data.id }).then((res) => {
                    let loc = JSON.parse(JSON.stringify(dealerData))
                    let index = loc.findIndex(i => i.id === dealerassobj.id)
                    if (index !== -1) {
                        loc[index] = { ...dealerassobj, modified_on: DateTime.utc(), modified_by: login_data.id }
                        setDealerData(loc)
                    }
                    setAssDealerDialog(false)
                })
            } else {
                let newObj = {
                    assessors: dealerassobj.assessors,
                    auditStartDate: dealerassobj.auditStartDate,
                    dealerId: dealerassobj.dealerId, formId: 33
                }
                APIServices.post(API.DealerAssessmentAss_Up(admin_data.id), { ...newObj, id: assobj.id, created_on: DateTime.utc(), created_by: login_data.id }).then((res) => {
                    let loc = JSON.parse(JSON.stringify(dealerData))
                    loc.push({ ...res.data, dealer: dealerList.find(x => x.id === dealerassobj.dealerId) })
                    setDealerData(loc)
                    setAssDealerDialog(false)
                })

            }
        }
    }
    const addfooter = () => {
        return (
            <Button label="Save" onClick={saveAssignment} />
        )
    }
    const assauditfooter = () => {

        return (assobj.id ? <Button label="Save" onClick={saveAuditorCalibration} /> : <></>)
    }
    const assdealerfooter = () => {

        return <Button label="Save" onClick={saveDealerCalibration} />
    }
    const onGlobalFilterChange = (e) => {
        setGlobalFilter(e.target.value);
    };
    const updateAssObj = (obj, val) => {
        let loc = assobj
        loc[obj] = val
        setAssObj(loc)
        forceUpdate()

    }
    const updateDealerAssObj = (obj, val) => {
        let loc = dealerassobj
        loc[obj] = val
        setDealerAssObj(loc)
        forceUpdate()

    }
    const saveAssignment = () => {
        setSubmitted(true)
        if (assobj.supplierId && assobj.assessmentEndDate) {
            let newObj = {
                assessmentStartDate: DateTime.utc(),
                assessmentEndDate: assobj.assessmentEndDate,
                supplierId: assobj.supplierId
            }
            console.log(assobj.id)
            if (assobj.id) {

                APIServices.patch(API.SupplierAssessmentAss_Edit(assobj.id), { ...newObj, modified_on: DateTime.utc(), modified_by: login_data.id }).then((res) => {
                    let loc = JSON.parse(JSON.stringify(supplierData))
                    let index = loc.findIndex(i => i.id === assobj.id)
                    if (index !== -1) {
                        loc[index] = { ...assobj, modified_on: DateTime.utc(), modified_by: login_data.id }
                        setSupplierData(loc)
                    }
                    setAddDialog(false)
                })
            } else {
                APIServices.post(API.SupplierAssessmentAss_Up(admin_data.id), { ...newObj, id: assobj.id, created_on: DateTime.utc(), created_by: login_data.id }).then((res) => {
                    let loc = JSON.parse(JSON.stringify(supplierData))
                    let supplier = supplierList.find(x => x.id === assobj.supplierId)
                    if (supplier) {
                        try {

                            console.log(supplierAssessmentMail(supplier?.information?.supplierName, admin_data.supplierPortalUrl, DateTime.fromISO(res.data.assessmentEndDate, { zone: 'Asia/Calcutta' }).toLocal().toFormat('dd-MMM-yyyy'), 'Droni.Pancholi@tvsmotor.com'))
                            APIServices.post(API.SendMail, { to: supplier.email, subject: `"My Sustainability Index" Self-Assessment Form - TVSM`, body: supplierAssessmentMail(supplier?.information?.supplierName, admin_data.supplierPortalUrl, DateTime.fromISO(res.data.assessmentEndDate, { zone: 'Asia/Calcutta' }).toLocal().toFormat('dd-MMM-yyyy'), 'Droni.Pancholi@tvsmotor.com') })
                        } catch (e) {
                            console.log(e)
                        }
                    }

                    loc.push(res.data)
                    setSupplierData(loc)
                    setAddDialog(false)
                })

            }

        }
    }
    const scheduleAssessment = () => {
        setSubmitted(false)
        setAddDialog(true)

    }
    const scheduleAuditorCalibration = () => {
        setAssObj({ supplierId: null, assessmentEndDate: null, auditStartDate: null, auditEndDate: null, group1: { sections: [], assessors: [], updated_on: '', updated_by: null }, group2: { sections: [], assessors: [], updated_on: '', updated_by: null }, group3: { sections: [], assessors: [], updated_on: '', updated_by: null }, group4: { sections: [], assessors: [], updated_on: '', updated_by: null } })
        setShow(true)
        setSubmitted(false)
        setAssAuditDialog(true)
    }
    const scheduleDealerCalibration = () => {
        setDealerAssObj({ dealerId: null, assessmentStartDate: null, assessors: [], auditStartDate: null })

        setSubmitted(false)
        setAssDealerDialog(true)
    }
    const valueTemplate = (rowData) => {
        console.log(rowData)
        return <>Hi</>
    }
    const msiTemplate = (rowData) => {


        return (
            <div>
                {'MSI-' + (supplierList.find(i => i.id === rowData?.supplierId)?.supplierCode || 'NA') + '-' + DateTime.fromISO(rowData?.created_on, { zone: 'utc' }).toLocal().toFormat('ddMyyyy') + ' (' + (supplierList.find(i => i.id === rowData?.supplierId)?.information?.supplierName || '') + ')'}

            </div>
        )
    }
    const editSupplierCalibration = (data) => {
        let item = JSON.parse(JSON.stringify(data))
        if (item.assessmentStartDate) {
            item.assessmentStartDate = DateTime.fromISO(item.assessmentStartDate, { zone: 'utc' }).toLocal().toJSDate()
        }
        if (item.assessmentEndDate) {
            item.assessmentEndDate = DateTime.fromISO(item.assessmentEndDate, { zone: 'utc' }).toLocal().toJSDate()
        }
        if (item.auditStartDate) {
            item.auditStartDate = DateTime.fromISO(item.auditStartDate, { zone: 'utc' }).toLocal().toJSDate()
        }
        if (item.auditEndDate) {
            item.auditEndDate = DateTime.fromISO(item.auditEndDate, { zone: 'utc' }).toLocal().toJSDate()
        }
        setShow(false)
        console.log(item)
        setAssObj(item)
        setSubmitted(false)
        setAssAuditDialog(true)
    }
    const updateSupplierCalibration = (data) => {
        let item = JSON.parse(JSON.stringify(data))

        if (item.assessmentStartDate) {
            item.assessmentStartDate = DateTime.fromISO(item.assessmentStartDate, { zone: 'utc' }).toLocal().toJSDate()
        }
        if (item.assessmentEndDate) {
            item.assessmentEndDate = DateTime.fromISO(item.assessmentEndDate, { zone: 'utc' }).toLocal().toJSDate()
        }
        if (item.auditStartDate) {
            item.auditStartDate = DateTime.fromISO(item.auditStartDate, { zone: 'utc' }).toLocal().toJSDate()
        }
        if (item.auditEndDate) {
            item.auditEndDate = DateTime.fromISO(item.auditEndDate, { zone: 'utc' }).toLocal().toJSDate()
        }

        console.log(item)
        setAssObj(item)
        forceUpdate()
    }
    const editDealerCalibration = (data) => {
        let item = JSON.parse(JSON.stringify(data))
        if (item.assessmentStartDate) {
            item.assessmentStartDate = DateTime.fromISO(item.assessmentStartDate, { zone: 'utc' }).toLocal().toJSDate()
        }
        if (item.auditStartDate) {
            item.auditStartDate = DateTime.fromISO(item.auditStartDate, { zone: 'utc' }).toLocal().toJSDate()
        }
        setDealerAssObj(item);
        setSubmitted(false)
        setAssDealerDialog(true)
    }
    const calibrationIdBodyTemplate = (rowData) => {
        return (
            <div className="p-fluid grid m-0 ">
                <div className="p-field col-6 clr-navy fw-7 fs-16">
                    {'MSI-' + (supplierList.find(i => i.id === rowData.supplierId)?.supplierCode || 'NA') + '-' + DateTime.fromISO(rowData.created_on, { zone: 'utc' }).toLocal().toFormat('ddMyyyy')}
                </div>
            </div>
        );
    };
    return (
        <div className="p-4">
            <h1>MSI Calibration Status & Scheduling </h1>


            {/* Search box aligned to the right */}
            {/* <div className="mb-2 text-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" placeholder="Search" onInput={onGlobalFilterChange} />
                </span>
            </div> */}

            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                {isSupplierScheduler && <TabPanel header="Suppliers">
                    {isSupplierScheduler && (
                        <div className='col-12 flex justify-content-end m-2'>
                            <Button label="Schedule Supplier Self-Assessment" className="p-button-outlined p-button-secondary mr-2" onClick={() => { scheduleAssessment() }} />
                            {/* <Button label="Schedule Supplier MSI Calibration" className="p-button-outlined p-button-secondary mr-2" onClick={() => { scheduleAuditorCalibration() }} /> */}
                        </div>
                    )}
                    {/* <SuppliersTable data={supplierData} assessorList={userList} supplierList={supplierList} globalFilter={globalFilter} editSupplier={editSupplierCalibration} /> */}
                    <SuppliersTableManual />
                </TabPanel>}
                {isDealerScheduler && <TabPanel header="Dealers">
                    {isDealerScheduler && (
                        <div className='col-12 flex justify-content-end m-2'>
                            <Button label="Schedule Dealer MSI Calibration" className="p-button-outlined p-button-secondary" onClick={() => { scheduleDealerCalibration() }} />
                        </div>
                    )}
                    <DealersTable data={dealerData} assessorList={userList} globalFilter={globalFilter} editDealer={editDealerCalibration} />
                </TabPanel>}
           
                {isSupplierScheduler && <TabPanel header="Supplier Assessment Scheduler">
                    {isSupplierScheduler && (
                        <div className='col-12 flex justify-content-end m-2'>
                            <Button label="Schedule Supplier Self-Assessment" className="p-button-outlined p-button-secondary mr-2" onClick={() => { scheduleAssessment() }} />
                            {/* <Button label="Schedule Supplier MSI Calibration" className="p-button-outlined p-button-secondary mr-2" onClick={() => { scheduleAuditorCalibration() }} /> */}
                        </div>
                    )}
                    <SuppliersTable data={supplierData} assessorList={userList} supplierList={supplierList} globalFilter={globalFilter} editSupplier={editSupplierCalibration} />
                  
                </TabPanel>}
            </TabView>
            <Dialog header={'Schedule Supplier Assessment'} style={{ width: '50%' }} visible={adddialog} footer={addfooter} onHide={() => setAddDialog(false)}>
                <div className="p-fluid grid m-0 p-3">
                    <div className="p-field col-6 ">
                        <label htmlFor="status">Select Supplier<span className="mandatory"> *</span>  </label>
                        <Dropdown id="status" filter className='mt-2' value={assobj.supplierId} optionLabel="information.supplierName" optionValue='id' options={supplierList} onChange={(e) => { updateAssObj("supplierId", e.target.value) }} placeholder="Select Supplier" />

                        {submitted && !assobj.supplierId && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Select Supplier
                            </small>
                        )}
                    </div>
                    <div className="p-field col-6 ">
                        <label htmlFor="status">Self-Assessment Due Date </label>
                        <Calendar id="status" dateFormat="dd/mm/yy" className='mt-2' value={assobj.assessmentEndDate} onChange={(e) => { updateAssObj("assessmentEndDate", e.value) }} placeholder="Select Self-Assessment Due Date" />
                        {submitted && !assobj.assessmentEndDate && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Set Self-Assessment End Date
                            </small>
                        )}
                    </div>

                </div>
            </Dialog>
            <Dialog header={'Assign Auditor for Calibration'} style={{ width: '50%' }} visible={assauditdialog} footer={assauditfooter} onHide={() => setAssAuditDialog(false)}>
                {show ? <div className="p-fluid grid m-0 p-3">
                    <div className="p-field col-6 ">
                        <label htmlFor="status">Select MSI ID </label>
                        <Dropdown options={supplierData} value={assobj} optionLabel='id' valueTemplate={msiTemplate} itemTemplate={msiTemplate} onChange={(e) => { console.log(e); setAssObj(e.value) }} />

                    </div>
                </div> :
                    calibrationIdBodyTemplate(assobj)
                }

                {assobj.id && <div className="p-fluid grid m-0 p-3">
                    <div className="p-field col-6 ">
                        <label htmlFor="status">Calibration Start Date </label>
                        <Calendar id="status" minDate={DateTime.fromISO(assobj.assessmentEndDate, { zone: 'utc' }).toLocal().toJSDate()} dateFormat="dd/mm/yy" className='mt-2' value={assobj.auditStartDate} onChange={(e) => { updateAssObj("auditStartDate", e.value) }} placeholder="Select Start Date" />
                        {submitted && !assobj.auditStartDate && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Set Calibration Start Date
                            </small>
                        )}
                    </div>
                    <div className="p-field col-6 ">
                        <label htmlFor="status">Calibration Due Date </label>
                        <Calendar id="status" minDate={DateTime.fromISO(assobj?.auditStartDate, { zone: 'utc' }).toLocal().toJSDate()} dateFormat="dd/mm/yy" className='mt-2' value={assobj.auditEndDate} onChange={(e) => { updateAssObj("auditEndDate", e.value) }} placeholder="Select Due Date" />
                        {submitted && !assobj.auditEndDate && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Set Calibration Due Date
                            </small>
                        )}
                    </div>
                    <label className='col-12 fw-6 fs-18 clr-navy mb-2'>Calibration Team Member 1  <span style={{ color: 'red' }}>*</span> </label>
                    <div className="p-field col-6 ">
                        <label htmlFor="status">Select Section(s) </label>
                        <MultiSelect
                            display="chip"
                            value={assobj.group1?.sections || []}
                            selectAll={false}
                            options={assessmentsections}
                            onChange={(e) => {
                                setAssObj((prev) => ({
                                    ...prev,
                                    group1: {
                                        ...prev.group1, updated_on: DateTime.utc(), updated_by: login_data.id,
                                        sections: e.value,
                                    },
                                }));
                            }}
                            optionLabel="title"
                            optionValue="id"
                            filter={true}
                            placeholder="Select Section"
                            className="w-full "
                            panelClassName={"hidefilter"}
                        />

                    </div>
                    <div className="p-field col-6 ">
                        <label htmlFor="status">Select Assessor(s)</label>
                        <MultiSelect
                            display="chip"
                            value={assobj.group1?.assessors || []}
                            selectAll={false}
                            options={supplierassessorlist}
                            onChange={(e) => {
                                setAssObj((prev) => ({
                                    ...prev,
                                    group1: {
                                        ...prev.group1, updated_on: DateTime.utc(), updated_by: login_data.id,
                                        assessors: e.value,
                                    },
                                }));
                            }}
                            optionLabel="information.empname"
                            optionValue="id"
                            filter={true}
                            placeholder="Select Assessor"
                            className="w-full "
                            panelClassName={"hidefilter"}
                        />
                    </div>
                    {submitted && checkGroup1(assobj) === false && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Required Section & Assessor for Calibration Team Member 1
                        </small>
                    )}
                    <label className='col-12 fw-6 fs-18 clr-navy mb-2'>Calibration Team Member 2   </label>
                    <div className="p-field col-6 ">
                        <label htmlFor="status">Select Section(s) </label>
                        <MultiSelect
                            display="chip"
                            value={assobj.group2?.sections || []}
                            selectAll={false}
                            options={assessmentsections}
                            onChange={(e) => {
                                setAssObj((prev) => ({
                                    ...prev,
                                    group2: {
                                        ...prev.group2, updated_on: DateTime.utc(), updated_by: login_data.id,
                                        sections: e.value,
                                    },
                                }));
                            }}
                            optionLabel="title"
                            optionValue="id"
                            filter={true}
                            placeholder="Select Section"
                            className="w-full "
                            panelClassName={"hidefilter"}
                        />

                    </div>
                    <div className="p-field col-6 ">
                        <label htmlFor="status">Select Assessor(s)</label>
                        <MultiSelect
                            display="chip"
                            value={assobj.group2?.assessors || []}
                            selectAll={false}
                            options={supplierassessorlist}
                            onChange={(e) => {
                                setAssObj((prev) => ({
                                    ...prev,
                                    group2: {
                                        ...prev.group2, updated_on: DateTime.utc(), updated_by: login_data.id,
                                        assessors: e.value,
                                    },
                                }));
                            }}
                            optionLabel="information.empname"
                            optionValue="id"
                            filter={true}
                            placeholder="Select Assessor"
                            className="w-full "
                            panelClassName={"hidefilter"}
                        />
                    </div>
                    <label className='col-12 fw-6 fs-18 clr-navy mb-2'>Calibration Team Member 3  </label>
                    <div className="p-field col-6 ">
                        <label htmlFor="status">Select Section(s) </label>
                        <MultiSelect
                            display="chip"
                            value={assobj.group3?.sections || []}
                            selectAll={false}
                            options={assessmentsections}
                            optionLabel="title"
                            optionValue="id"
                            filter={true}
                            onChange={(e) => {
                                setAssObj((prev) => ({
                                    ...prev,
                                    group3: {
                                        ...prev.group3, updated_on: DateTime.utc(), updated_by: login_data.id,
                                        sections: e.value,
                                    },
                                }));
                            }}
                            placeholder="Select Section"
                            className="w-full "
                            panelClassName={"hidefilter"}
                        />

                    </div>
                    <div className="p-field col-6 ">
                        <label htmlFor="status">Select Assessor(s)</label>
                        <MultiSelect
                            display="chip"
                            value={assobj.group3?.assessors || []}
                            selectAll={false}
                            options={supplierassessorlist}
                            onChange={(e) => {
                                setAssObj((prev) => ({
                                    ...prev,
                                    group3: {
                                        ...prev.group3, updated_on: DateTime.utc(), updated_by: login_data.id,
                                        assessors: e.value,
                                    },
                                }));
                            }}
                            optionLabel="information.empname"
                            optionValue="id"
                            filter={true}
                            placeholder="Select Assessor"
                            className="w-full "
                            panelClassName={"hidefilter"}
                        />
                    </div>
                    <label className='col-12 fw-6 fs-18 clr-navy mb-2'>Calibration Team Member 4  </label>
                    <div className="p-field col-6 ">
                        <label htmlFor="status">Select Section(s) </label>
                        <MultiSelect
                            display="chip"
                            value={assobj.group4?.sections || []}
                            selectAll={false}
                            options={assessmentsections}
                            optionLabel="title"
                            optionValue="id"
                            filter={true}
                            onChange={(e) => {
                                setAssObj((prev) => ({
                                    ...prev,
                                    group4: {
                                        ...prev.group4, updated_on: DateTime.utc(), updated_by: login_data.id,
                                        sections: e.value,
                                    },
                                }));
                            }}
                            placeholder="Select Section"
                            className="w-full "
                            panelClassName={"hidefilter"}
                        />

                    </div>
                    <div className="p-field col-6 ">
                        <label htmlFor="status">Select Assessor(s)</label>
                        <MultiSelect
                            display="chip"
                            value={assobj.group4?.assessors || []}
                            selectAll={false}
                            options={supplierassessorlist}
                            onChange={(e) => {
                                setAssObj((prev) => ({
                                    ...prev,
                                    group4: {
                                        ...prev.group4, updated_on: DateTime.utc(), updated_by: login_data.id,
                                        assessors: e.value,
                                    },
                                }));
                            }}
                            optionLabel="information.empname"
                            optionValue="id"
                            filter={true}
                            placeholder="Select Assessor"
                            className="w-full "
                            panelClassName={"hidefilter"}
                        />
                    </div>
                </div>}
            </Dialog>
            <Dialog header={'Scheduling Checklist for Dealer'} style={{ width: '40%' }} visible={assdealerdialog} footer={assdealerfooter} onHide={() => setAssDealerDialog(false)}>

                <div className="p-fluid grid m-0 p-3">
                    <div className="p-field col-8 ">
                        <label htmlFor="status">Select Dealer </label>
                        <Dropdown filter options={dealerList} value={dealerassobj.dealerId} disabled={dealerassobj.id} optionLabel='information.dealerName' optionValue='id' onChange={(e) => { console.log(e); setDealerAssObj((prev) => ({ ...prev, dealerId: e.value })) }} />
                    </div>

                    <div className="p-field col-6 ">
                        <label htmlFor="status">Calibration Start Date </label>
                        <Calendar id="status" minDate={DateTime.fromISO(dealerassobj?.auditStartDate, { zone: 'utc' }).toLocal().toJSDate()} dateFormat="dd/mm/yy" className='mt-2' value={dealerassobj.auditStartDate} onChange={(e) => { updateDealerAssObj("auditStartDate", e.value) }} placeholder="Select Due Date" />
                        {submitted && !dealerassobj.auditStartDate && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Set Calibration Due Date
                            </small>
                        )}
                    </div>
                    <div className="p-field col-6 ">
                        <label htmlFor="status">Select Assessor </label>

                        <MultiSelect
                            display="chip"
                            value={dealerassobj?.assessors || []}
                            selectAll={false}
                            options={dealerassessorlist}
                            onChange={(e) => {
                                setDealerAssObj((prev) => ({
                                    ...prev,
                                    assessors: e.value,

                                }));
                            }}
                            optionLabel="information.empname"
                            optionValue="id"
                            filter={true}
                            placeholder="Select Assessor"
                            className="w-full "
                            panelClassName={"hidefilter"}
                        />
                        {

                        }
                    </div>
                </div>

            </Dialog>
        </div>
    );
};

export default MSIDashboard;
