import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";

const data = [
  {
    year: "FY-2023",
    plastic_waste: 620.69,
    construction_and_demolition_waste: 0,
    other_non_hazardous_waste: 12988.21,
  },
  {
    year: "FY-2024",
    plastic_waste: 0,
    construction_and_demolition_waste: 0,
    other_non_hazardous_waste: 0,
  },
];

const DemoNonHazardous = () => {
  return (
    <div style={{ height: 500 }}>
      <h3 style={{ fontSize: "18px", margin: "25px" }}>
        Non-Hazardous Waste Disposed
      </h3>
      <ResponsiveContainer>
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="year"/>

          <YAxis>
            <Label
              value="Million Tons (MT)"
              angle={-90}
              position="insideLeft"
              style={{ textAnchor: "middle", fill: "#333", fontSize: "14px" }}
            />
          </YAxis>
          <Tooltip />
          <Legend verticalAlign="bottom" />

          <Bar
            dataKey="plastic_waste"
            name="Plastic Waste"
            fill="#D9C46F"
            barSize={60}
          />
          <Bar
            dataKey="construction_and_demolition_waste"
            name="Construction and Demolition Waste"
            fill="#BBAA5F"
            barSize={60}
          />
          <Bar
            dataKey="other_non_hazardous_waste"
            name="Other Non-Hazardous Waste"
            fill="#9C8F4F"
            barSize={60}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DemoNonHazardous;
