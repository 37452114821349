import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";

const data = {
  "<30 Years": [
    {
      year: "FY-2024",
      senior: 4.0,
      middle: 64.0,
      nonManagement: 32.7,
    },
    {
      year: "FY-2025",
      senior: 0,
      middle: 0,
      nonManagement: 0,
    },
  ],
  "30-50 Years": [
    {
      year: "FY-2024",
      senior: 45.8,
      middle: 53.7,
      nonManagement: 1.3,
    },
    {
      year: "FY-2025",
      senior: 0,
      middle: 0,
      nonManagement: 0,
    },
  ],
  ">50 Years": [
    {
      year: "FY-2024",
      senior: 55.5,
      middle: 44.3,
      nonManagement: 0.2,
    },
    {
      year: "FY-2025",
      senior: 0,
      middle: 0,
      nonManagement: 0,
    },
  ],
};

const AgeManagementIndia = () => {
  const [location, setLocation] = useState("<30 Years");

  return (
    <div
      style={{
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "19.2px",
        textAlign: "left",
        margin: "18px 10px",
      }}
    >
      <h3 style={{ fontSize: "18px", margin: "25px" }}>
        Management Diversity by Age Group in India
      </h3>
      <div style={{ fontWeight: 200, fontSize: "14px", marginBottom: "10px" }}>
        Select a Age Group to view the management diversity data in India.
      </div>
      {/* Location Selection Buttons */}
      <div style={{ marginBottom: "20px" }}>
        {Object.keys(data).map((locationName) => (
          <button
            key={locationName}
            onClick={() => setLocation(locationName)}
            style={{
              padding: "8px 16px",
              backgroundColor: location === locationName ? "#8888" : "#F0F0F0",
              color: location === locationName ? "white" : "gray",
              border: "none",
              borderRadius: "8px",
              height: "2.5rem",
              marginRight: "2rem",
              cursor: "pointer",
            }}
          >
            {locationName}
          </button>
        ))}
      </div>
      {/* Responsive Container for Recharts */}
      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          data={data[location]}
          margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="year"></XAxis>
          <YAxis>
            <Label
              value="Percentage"
              angle={-90}
              position="insideLeft"
              style={{
                textAnchor: "middle",
                fill: "#333",
                fontSize: "14px",
              }}
            />
          </YAxis>
          <Tooltip />
          <Legend verticalAlign="bottom" />
          <Bar
            dataKey="senior"
            name="Senior Management"
            fill="#47CC"
            barSize={60}
          />
          <Bar
            dataKey="middle"
            name="Middle Management"
            fill="#88CC"
            barSize={60}
          />
          <Bar
            dataKey="nonManagement"
            name=" Non Management"
            fill="#47BB"
            barSize={60}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AgeManagementIndia;
