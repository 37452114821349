import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Editor } from "primereact/editor";
import APIServices from "../../service/APIService";
import Swal from "sweetalert2";
import { API } from "../../constants/api_url";
import { useSelector } from "react-redux";

const FeedbackSystem = () => {
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const admin_data = useSelector((state) => state.user.admindetail);
    const login_data = useSelector((state) => state.user.userdetail);
    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle email submission logic
        console.log("Subject:", subject);
        console.log("Message:", message, checkEditorValue(message));
        if (!checkEditorValue(message) && subject.trim().length) {
            APIServices.post(API.SendMail, { to: 'support@eisqr.com', subject, body: `<div><p>User Email Id: ${login_data.email}</p> <p>Enterprise Name: ${admin_data?.information?.companyname}</p> ${message}<div>` }).then((res) => {
                setMessage("");
                setSubject("");
                Swal.fire({
                    title: "Ticket raised successfully, will get back to you soon",

                    confirmButtonText: 'Close Popup',
                    allowOutsideClick: false,
                })

            }).catch((err) => {
                console.log(err)
                Swal.fire({
                    title: "Something went wrong",

                    confirmButtonText: 'Close Popup',
                    allowOutsideClick: false,
                })

            })

        }

    };
    function checkEditorValue(htmlString) {
        if (!htmlString) {
            return true
        }
        const regex = /^<p>\s*<\/p>$/;
        return regex.test(htmlString);
    }
    return (
        <div className="flex justify-center" style={{ flexDirection: "column" }}>
            <div
                className="flex col-12 flex-start"
                style={{ flexDirection: "column" }}
            >
                <span className="text-big-one">
                    NAVIGOS Ticketing System
                </span>
                <p className="ml-1">We value your feedback and are here to help with any issues you’re facing! Share the issues you’ve encountered, and feel free to include any details that might help us understand better. Your input is important, and we’ll ensure to follow up with you promptly. Thank you for helping us improve ! </p>
                {/* <Tag className="ml-3 p-tag-blue">

              {login_data.role === "clientadmin"
                ? "Enterprise Admin"
                : getRoles(login_data.information)}
            </Tag> */}
            </div>
            <div className="p-2">
                <form
                    onSubmit={handleSubmit}
                    className="p-fluid rounded-lg"
                    style={{ width: "100%" }}
                >
                    <div className="field">
                        <label htmlFor="subject" className="block text-lg font-medium mb-2">
                            Subject
                        </label>
                        <InputText
                            id="subject"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            placeholder="Enter subject"

                        />
                    </div>

                    <div className="field mt-4">
                        <label htmlFor="message" className="block fs-14 text-lg  font-medium mb-2">
                            Message
                        </label>
                        <Editor
                            id="message"
                            value={message}
                            style={{ resize: "none", height: 300, overflowY: 'scroll' }}
                            onTextChange={(e) => setMessage(e.htmlValue)}
                            rows={5}
                            placeholder="Enter your message"
                            className="html-editor"
                        />
                    </div>

                    <div className="col-12 flex justify-content-end ">
                        <Button
                        disabled={!subject.length ||  checkEditorValue(message)}
                            type="submit"
                            label="Raise Ticket"
                            icon="pi pi-send"
                            style={{ width: 'auto', padding: 10 }}
                        />
                    </div>
                </form>
            </div>

        </div>
    );
};

export default FeedbackSystem;
