import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Button } from "primereact/button";

const data = {
  India: [
    { year: "FY-2023", emission: 4393.12 },
    { year: "FY-2024", emission: 0 },
  ],
  Indonesia: [
    { year: "FY-2023", emission: 1287.15 },
    { year: "FY-2024", emission: 0 },
  ],
  "United Kingdom": [
    { year: "FY-2023", emission: 184.22 },
    { year: "FY-2024", emission: 0 },
  ],
};

const DemoScope2 = () => {
  const [location, setLocation] = useState("India");

  return (
    <div
      style={{
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "19.2px",
        textAlign: "left",
        margin: "18px 10px 18px 10px",
      }}
    >
      Scope 2 Emissions
      {/* Location Selection Buttons */}
      <div style={{ marginBottom: "10px" }}>
        {Object.keys(data).map((locationName) => (
          <button
            key={locationName}
            onClick={() => setLocation(locationName)}
            style={{
              padding: "8px 16px",
              backgroundColor:
                location === locationName ? "#41AA95" : "#F0F0F0",
              color: location === locationName ? "white" : "gray",
              border: "none",
              borderRadius: "8px",
              height: "2.5rem",
              marginRight: "2rem",
              cursor: "pointer",
            }}
          >
            {locationName}
          </button>
        ))}
      </div>
      {/* Responsive Container for Recharts */}
      <ResponsiveContainer width={1600} height={400}>
        <BarChart
          data={data[location]}
          margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="year" />
          <YAxis
            label={{
              value: "Million tCO2e",
              angle: -90,
              position: "insideLeft",
            }}
          />
          <Tooltip />
          <Legend />

          <Bar
            dataKey="emission"
            fill="#e74c3c"
            name="Emissions"
            barSize={40}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DemoScope2;
