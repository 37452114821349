import React, { useState, useEffect } from "react";
import "../reports/toc_style.css";
import { useHeadsObserver } from "./hooks";
import * as XLSX from "xlsx";
import useForceUpdate from "use-force-update";
import $ from "jquery";
import Axios from "axios";
import { API } from "../../constants/api_url";
import { useSelector } from "react-redux";
import { Button } from "primereact/button";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import { saveAs } from 'file-saver';
import moment from "moment";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Dropdown } from "primereact/dropdown";

import APIServices from "../../service/APIService";
import BRSR_Core_24 from "./components/BRSR_Core/BRSR_Core_24";


// pdfMake.vfs = pdfFonts.pdfMake.vfs;
// pdfMake.fonts = {
//     Roboto: {
//         normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
//         bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
//         italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
//         bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
//       }
// }
const { DateTime } = require('luxon');

window.jQuery = $;
window.$ = $;

const BRSRReport = () => {
    const [headings, setHeadings] = useState([]);
    const { fymonth } = useSelector((state) => state.user.fyStartMonth);
    const { activeId } = useHeadsObserver();
    const [tableData, setTableData] = useState([]);
    const [workbook, setWorkbook] = useState(null);
    const [firstSheet, setFirstSheet] = useState(null);
    const [year, setYear] = useState(2023);
    const [rfData, setRFData] = useState({});
    const selector = useSelector((state) => state.user.userdetail);
    const emissionFactor = useSelector((state) => state.emissionfactor.emissionFactor)
    const locationList = useSelector(state => state.sitelist.locationList)
    const siteList = useSelector((state) => state.sitelist.siteList)
    const clientlogo = useSelector((state) => state.user.companylogo)
    const rflibrary = useSelector((state) => state.library.rf)
    const [dcflist, setDcfList] = useState([])
    const [response, setResponse] = useState([])
    const [report, setReport] = useState({})
    const [dpreport, setDpReport] = useState([])
    let months_ = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const forceUpdate = useForceUpdate();



    async function exportTable2Excel(type) {
        let initialData = [{
            alignment: 'center', // Center the text horizontally
            margin: [0, 250], // Adjust the top margin to vertically center the text
            text: [
                { text: 'BRSR Core Report' + '\n', fontSize: 30, bold: true },
                { text: 'FY ' + year + ' - ' + (year + 1).toString().substr(2, 3) + '\n', fontSize: 20 },
                { text: DateTime.local().toFormat('MMMM dd, yyyy'), fontSize: 20, color: 'blue' }, // Customize the font size and color
            ],
            pageBreak: 'after'
        },
        {
            toc: {
                id: 'sectionHeader',
                title: { text: 'Table of Content', style: 'tdHead' },

            }
        }], data = []
        const div = document.getElementById('main')
        for (let i = 0; i < div.children.length; i++) {

            if (div.childNodes[i].tagName.toLowerCase() === 'sectionheader') {
                if (i == 0) {
                    data.push(
                        {
                            text: '', // Empty text
                            pageBreak: 'before',


                        })
                } else if (div.childNodes[i].id === 'secprin1') {
                    data.push(
                        {
                            text: '', // Empty text

                            margin: [0, 10], // Adjust the margin for horizontal space

                        })
                }

                data.push({
                    table: {

                        widths: ['*'],
                        body: [
                            [{ tocItem: 'sectionHeader', text: div.childNodes[i].textContent, style: 'secHead', border: [false, false, false, false] }],
                        ],
                    }
                })
            } else if (div.childNodes[i].tagName.toLowerCase() === 'sectionheader0') {
                for (let child = 0; child < div.childNodes[i].children.length; child++) {
                    console.log(div.childNodes[i].childNodes[0], child)
                    if (div.childNodes[i].childNodes[child].className === 'gridlines-container') {
                        let content = generatePdfMakeContentFromTable(div.childNodes[i].childNodes[child].childNodes[0])

                        data.push({
                            table: {

                                widths: JSON.parse(JSON.stringify(content))[0].map((a, b) => { return b == 0 ? "*" : 'auto' }),
                                body: content,
                                style: 'tableStyle'
                            }
                        })

                        data.push(
                            {
                                text: '', // Empty text

                                margin: [0, 10], // Adjust the margin for horizontal space

                            })
                        console.log(content[0].length !== JSON.parse(JSON.stringify(content))[0].map((a, b) => { return b == 0 ? "*" : 'auto' }).length)
                    } else if (div.childNodes[i].childNodes[child].className === 'm-3 para') {
                        data.push({ text: div.childNodes[i].childNodes[child].textContent });
                        data.push({
                            text: "", // Empty text

                            margin: [0, 5], // Adjust the margin for horizontal space
                        });
                    }

                }
            } else if (div.childNodes[i].tagName.toLowerCase() === 'textonly') {

                data.push(
                    {
                        text: div.childNodes[i].childNodes[0].textContent, // Empty text

                        margin: [0, 10], // Adjust the margin for horizontal space

                    })
                data.push(
                    {
                        text: '', // Empty text

                        margin: [0, 5], // Adjust the margin for horizontal space

                    })
            }
        }

        data.forEach((k) => {
            if (k.table) {
                if (!haveSameSubarrayLengths(k.table.body)) {
                    console.log(k.table.body)
                }
            }
        })

        let images = {
            clientlogo: document.getElementById('clientlogo').src
        }
        const header = (currentPage, pageCount, pageSize) => {
            if (currentPage !== 1) {
                return {
                    columns: [
                        {
                            text: `BRSR Core Report FY` + year.toString().substr(2, 3),
                            style: 'headerText',
                            margin: [30, 20],
                            fit: [40, 40],
                            alignment: 'left'
                        },
                        {
                            image: 'clientlogo',
                            fit: [40, 40],
                            margin: [0, 5, 15, 0],
                            alignment: 'right'
                        },
                    ],
                    // Add margins to the header
                }

            }
        }
        const documentDefinition = {
            info: {
                title: 'BRSR Core Report as of ' + DateTime.local().toFormat('MMMM dd, yyyy'),
                author: 'Navigos',
                subject: 'BRSR Core Report',
                keywords: 'Dont share unless people within same organization',
                producer: 'EiSqr',


            },
            // userPassword: 'Report@',
            // ownerPassword: '123456',
            permissions: {
                printing: 'highResolution', //'lowResolution'
                modifying: false,
                copying: false,
                annotating: true,
                fillingForms: true,
                contentAccessibility: true,
                documentAssembly: true
            },
            pageSize: 'A4',
            pageMargins: [30, 70, 30, 30], // [left, top, right, bottom] margins
            header,
            footer: function (currentPage, pageCount) {
                return {
                    text: 'Page ' + currentPage + ' of ' + pageCount,
                    alignment: 'center', fontSize: 8
                };
            },
            content: [...initialData, ...data],
            // defaultStyle: {
            //     font: 'Roboto'
            //   },

            images,
            styles: {

                tdHead: {
                    bold: true, alignment: 'center', valign: 'middle', fillColor: 'lightgray'
                },

                secHead: {
                    bold: true, fillColor: 'lightgray', alignment: 'center', padding: [10, 10]
                },
                headerText: {
                    fontSize: 14,
                    bold: true,
                    color: 'black', // Text color
                },
                'text-start': {
                    alignment: 'center'
                },
                'text-center': {
                    alignment: 'justify'
                }
            },
        };
        console.log(documentDefinition)
        if (type === 0) {

            const pdf = pdfMake.createPdf(documentDefinition);

            pdf.download('BRSR_Report.pdf');

        } else {
            pdfMake.createPdf(documentDefinition).open({}, window.open('', '_blank'));

        }


    }
    function haveSameSubarrayLengths(data) {
        if (data.length < 2) {
            // If there are fewer than 2 subarrays, they are considered to have the same length.
            return true;
        }

        const firstSubarrayLength = data[0].length;

        for (let i = 1; i < data.length; i++) {
            if (data[i].length !== firstSubarrayLength) {
                return false;
            }
        }

        return true;
    }
    function generatePdfMakeContentFromTable(table) {
        console.log(table)
        if (!table) {
            console.error(`Table  not found.`);
            return [];
        }

        let contentArray = [], maxCol = 0
        for (let i = 0; i < table.rows.length; i++) {
            if (maxCol <= table.rows[i].cells.length) {
                maxCol = table.rows[i].cells.length
            }
        }

        for (let i = 0; i < table.rows.length; i++) {
            const rowArray = [];

            for (let j = 0; j < table.rows[i].cells.length; j++) {
                const cell = table.rows[i].cells[j];
                const colSpan = cell.getAttribute('colspan');
                const rowSpan = cell.getAttribute('rowspan');
                const cellText = cell.textContent.trim();

                const cellObject = { text: cellText };
                cellObject.colSpan = parseInt(colSpan)
                cellObject.rowSpan = parseInt(rowSpan)
                cellObject.style = cell.getAttribute('class');
                rowArray.push(cellObject);
                if (parseInt(colSpan) > 1) {
                    console.log(rowArray)
                    for (let j = 0; j < parseInt(colSpan) - 1; j++) {
                        rowArray.push({});
                    }

                }

            }

            contentArray.push(rowArray);


        }
        contentArray.forEach((i, index) => {
            if (i.length !== maxCol) {
                console.log(i)
                if (contentArray[index - 1]) {
                    contentArray[index - 1].forEach((k, ind) => {
                        if (k.rowSpan) {
                            if (k.rowSpan > 1) {
                                if (k.colSpan === 1) {
                                    i.splice(ind, 0, { text: '', colSpan: k.colSpan, rowSpan: k.rowSpan - 1 })
                                } else {
                                    let newind = ind
                                    for (let j = 0; j < parseInt(k.colSpan); j++) {
                                        i.splice(newind, 0, {})
                                        newind++
                                    }
                                }
                            }
                        } else {
                            for (let j = 0; j < (maxCol - Object.keys(i).length); j++) {
                                console.log(i)
                                // i.push({id:1});
                            }
                        }
                    })
                }
            }
        })
        contentArray.forEach((i, index) => {
            if (i.length !== maxCol) {
                let len = Object.keys(i).length
                for (let j = 0; j < (maxCol - len); j++) {

                    i.push({});
                }

            }
        })
        return contentArray;
    }




    return (
        <div>
            <div className="p-card grid">
                <div className="col-8" style={{ display: "flex", flexDirection: "column" }}>
                    {/* <img id='ass' src='https://mpower-s3.s3-ap-southeast-1.amazonaws.com/uploads/hazards/1690426362525Pushing%20and%20Pulling.png' width={'100%'} /> */}
                    <div>
                        <div className="col-12 grid" style={{ margin: 5, color: "white" }}>
                            <div>
                                <label
                                    style={{
                                        color: "black",
                                        padding: 15,
                                        justifyContent: "flex-start",
                                        display: "flex",
                                    }}
                                >
                                    Select Year :
                                </label>
                            </div>

                            <div className="col-4">
                                <Dropdown options={[{ name: 2022 }, { name: 2023 }, { name: 2024 }]} value={year} optionLabel="name" optionValue="name" onChange={(e) => { setYear(e.value) }} />
                            </div>
                        </div>

                    </div>
                </div >
                <div className="col-4 flex justify-content-end align-items-center"  >
                    <Button icon='pi pi-eye' style={{ marginRight: 10 }} rounded text raised aria-label="Filter" onClick={() => { exportTable2Excel(1) }}> </Button>
                    <Button icon='pi pi-cloud-download' rounded text raised aria-label="Filter" onClick={() => { exportTable2Excel(0) }}> </Button>
                </div>
            </div>
            <BRSR_Core_24 year={year} />
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(BRSRReport, comparisonFn);
