import React, { useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Card } from 'primereact/card';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import supplyData from '../supply.json'

                            //icons
import SupplierCategoryChart from './SupplierCategoryChart';
import SupplierMSIRatingsChart from './SupplierMSIRatingsChart';
import SpendMSIRatingChart from './SpendMSIRatingChart';
import SupplierMSIDistributionChart from './SupplierMSIDistributionChart';


const SupplierMSIDashboardNew = () => {
  const [country, setCountry] = useState('All Countries');
  const [businessUnit, setBusinessUnit] = useState('All BU');
  const [category, setCategory] = useState('All');
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [tableData, setTableData] = useState([]);

  const calculateMetrics = () => {
    const totalAudits = supplyData.length;
    const completedAudits = supplyData.filter((item) => item.status === 'completed').length;
    const plannedMSICompletion = ((completedAudits / totalAudits) * 100).toFixed(2);
    const avgMSIScore = (supplyData.reduce((acc, item) => acc + item.msi_score, 0) / totalAudits).toFixed(2);
    const avgEnvironmentScore = (supplyData.reduce((acc, item) => acc + item.environment, 0) / totalAudits).toFixed(2);
    const avgSocialScore = (supplyData.reduce((acc, item) => acc + item.social, 0) / totalAudits).toFixed(2);
    const avgGovernanceScore = (supplyData.reduce((acc, item) => acc + item.governance, 0) / totalAudits).toFixed(2);

    return {
      plannedMSICompletion,
      avgMSIScore,
      avgEnvironmentScore,
      avgSocialScore,
      avgGovernanceScore
    };
  };

  const { plannedMSICompletion, avgMSIScore, avgEnvironmentScore, avgSocialScore, avgGovernanceScore } = calculateMetrics();

  const handleCardClick = (title, field) => {
    setDialogTitle(title);
    setTableData(supplyData.map(item => ({ company_name: item.company_name, value: item[field] })).sort((a, b) => b.value - a.value));
    setVisibleDialog(true);
  };

  return (
    <div className="container">
      <div className="row mb-4">
        <div className="col-md-3">
          <Dropdown value={country} options={['All Countries', 'India', 'USA']} onChange={(e) => setCountry(e.value)} placeholder="Select Country" className="w-100" />
        </div>
        <div className="col-md-3">
          <Dropdown value={businessUnit} options={['All BU', 'BU1', 'BU2']} onChange={(e) => setBusinessUnit(e.value)} placeholder="Select BU" className="w-100" />
        </div>
        <div className="col-md-3">
          <Dropdown value={category} options={['All', 'Forging', 'Casting']} onChange={(e) => setCategory(e.value)} placeholder="Select Category" className="w-100" />
        </div>
      </div>

      <div className="row">
        <div className="col-md-4 mb-5">
          <Card title="Planned self-assessment Completion % age" className="shadow p-3" onClick={() => handleCardClick('Planned self-assessment Completion', 'msi_score')}>
            <h3>{plannedMSICompletion}%</h3>
          </Card>
        </div>
        <div className="col-md-4 mb-5">
          <Card title="Planned MSI Completion % age" className="shadow p-3" onClick={() => handleCardClick('Planned MSI Completion', 'msi_score')}>
            <h3>{plannedMSICompletion}%</h3>
          </Card>
        </div>
        <div className="col-md-4 mb-5">
          <Card title="Average Supplier Overall MSI Score" className="shadow p-3" onClick={() => handleCardClick('Average Supplier Overall MSI Score', 'msi_score')}>
            <h3>{avgMSIScore}%</h3>
          </Card>
        </div>
        <div className="col-md-4 mb-5">
          <Card title="Average MSI Score (Environment Category)" className="shadow p-3" onClick={() => handleCardClick('Average MSI Score (Environment)', 'environment')}>
            <h3>{avgEnvironmentScore}%</h3>
          </Card>
        </div>
        <div className="col-md-4 mb-5">
          <Card title="Average MSI Score (Social Category)" className="shadow p-3" onClick={() => handleCardClick('Average MSI Score (Social)', 'social')}>
            <h3>{avgSocialScore}%</h3>
          </Card>
        </div>
        <div className="col-md-4 mb-5">
          <Card title="Average MSI Score (Governance Category)" className="shadow p-3" onClick={() => handleCardClick('Average MSI Score (Governance)', 'governance')}>
            <h3>{avgGovernanceScore}%</h3>
          </Card>
        </div>
      </div>


      <div className='row'>
        <div className='card p-2 col-md-6 border'>
          <SupplierCategoryChart />
        </div>

        <div className='card p-2 col-md-6 border'>
          <SupplierMSIRatingsChart />
        </div>
      </div>
    <br />
      <div className='row'>
        <div className='card p-2 col-md-6 border'>
          <SpendMSIRatingChart />
        </div>

        <div className='card p-2 col-md-6 border'>
          <SupplierMSIDistributionChart />
        </div>


      </div>
      <Dialog header={dialogTitle} visible={visibleDialog} style={{ width: '50vw' }} onHide={() => setVisibleDialog(false)}>
        <DataTable value={tableData} sortMode="multiple" paginator rows={5} responsiveLayout="scroll">
          <Column sortable field="company_name" header="Company Name"></Column>
          <Column sortable field="value" header="Score"></Column>
        </DataTable>
      </Dialog>
    </div>
  );
};

export default SupplierMSIDashboardNew;
