import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";

const data = {
  "Senior Management": [
    {
      year: "FY-2024",
      malePercent: 97.2,
      femalePercent: 2.8,
      male: 242,
      female: 7,
    },
    {
      year: "FY-2025",
      male: 0,
      female: 0,
      malePercent: 0,
      femalePercent: 0,
    },
  ],
  "Middle Management": [
    {
      year: "FY-2024",
      male: 1548,
      female: 172,
      malePercent: 90.0,
      femalePercent: 10.0,
    },
    {
      year: "FY-2025",
      male: 0,
      female: 0,
      malePercent: 0,
      femalePercent: 0,
    },
  ],
  "Non Management": [
    {
      year: "FY-2024",
      male: 4167,
      female: 791,
      malePercent: 84,
      femalePercent: 16,
    },
    {
      year: "FY-2025",
      male: 0,
      female: 0,
      malePercent: 0,
      femalePercent: 0,
    },
  ],
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "#fff",
          border: "1px solid #ccc",
          borderRadius: "8px",
          padding: "10px",
          fontSize: "14px",
          fontFamily: "Lato",
          lineHeight: "1.5",
        }}
      >
        <p style={{ margin: 0, fontWeight: "bold" }}>{label}</p>
        {payload.map((entry) => (
          <p
            key={entry.name}
            style={{ margin: 0, color: entry.color }}
          >{`${entry.name}:${entry.value}`}</p>
        ))}
      </div>
    );
  }

  return null;
};

const GenderManagementIndiaDemo = () => {
  const [location, setLocation] = useState("Senior Management");

  return (
    <div
      style={{
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "19.2px",
        textAlign: "left",
        margin: "18px 10px",
      }}
    >
      <h3 style={{ fontSize: "18px", margin: "25px" }}>
        Gender Diversity by Management Level in India
      </h3>
      <div style={{ fontWeight: 200, fontSize: "14px", marginBottom: "10px" }}>
        Select a Management level to view the gender diversity data in india.
      </div>
      {/* Location Selection Buttons */}
      <div style={{ marginBottom: "20px" }}>
        {Object.keys(data).map((locationName) => (
          <button
            key={locationName}
            onClick={() => setLocation(locationName)}
            style={{
              padding: "8px 16px",
              backgroundColor: location === locationName ? "#8888" : "#F0F0F0",
              color: location === locationName ? "white" : "gray",
              border: "none",
              borderRadius: "8px",
              height: "2.5rem",
              marginRight: "2rem",
              cursor: "pointer",
            }}
          >
            {locationName}
          </button>
        ))}
      </div>
      {/* Responsive Container for Recharts */}
      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          data={data[location]}
          margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="year"></XAxis>
          <YAxis>
            <Label
              value="Percentage"
              angle={-90}
              position="insideLeft"
              style={{
                textAnchor: "middle",
                fill: "#333",
                fontSize: "14px",
              }}
            />
          </YAxis>
          <Tooltip content={CustomTooltip} />
          <Legend verticalAlign="bottom" />
          <Bar dataKey="male" name="Male" fill="#47CC" barSize={60} />
          <Bar dataKey="female" name="Female" fill="#88CC" barSize={60} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default GenderManagementIndiaDemo;
