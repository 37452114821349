import React, { useEffect, useState, useRef, createRef } from "react";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { FileUpload } from "primereact/fileupload";

import { MultiSelect } from 'primereact/multiselect';
import { FaRegSquare, FaRegCheckSquare, FaMinusSquare } from "react-icons/fa";
import { MdPlayArrow, MdCheckBoxOutlineBlank, MdOutlineIndeterminateCheckBox } from 'react-icons/md'
import { TbCheckbox } from "react-icons/tb"
import { IoMdArrowDropright, IoMdCheckboxOutline } from "react-icons/io";
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from "react-image-crop";
import { Button } from "primereact/button";
import { Route, useHistory, useLocation } from "react-router-dom";
import { Sidebar } from "primereact/sidebar";
import { TabView, TabPanel } from "primereact/tabview";
import { OrderList } from "primereact/orderlist";
import useForceUpdate from "use-force-update";
import { ListBox } from "primereact/listbox";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import { Checkbox } from "primereact/checkbox";
import moment from "moment";
import $, { data } from 'jquery'
import axios from "axios";
import IATACodes from "../../assets/dummy/IATACodes";
import { AutoComplete } from "primereact/autocomplete";
import { GoogleMap, useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import readXlsxFile from "read-excel-file";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import { setRef } from "@fullcalendar/core";
import { SplitButton } from "primereact/splitbutton";
import Swal from "sweetalert2";
import "react-image-crop/dist/ReactCrop.css";

import { EditText, EditTextarea } from "react-edit-text";
import "react-edit-text/dist/index.css";

import { useSelector } from "react-redux";

import cx from "classnames";

import { render } from "react-dom";
import { API } from "../../constants/api_url";
import APIServices from "../../service/APIService";
import FormBuilderTwo from "../../components/FormBuilder2";
const google = window.google;



const DealerCollectionFormEdit = (props) => {


    const admin_data = useSelector((state) => state.user.admindetail)
    const login_data = useSelector((state) => state.user.userdetail)
    const categoryList = [{ name: "Dealer Checklist", id: 1 }, { name: "Type 2", id: 2 }, { name: "Type 3", id: 3 }]
    const loc = useLocation();
    const [clienttag, setClientTag] = useState([])
    const [visibleright, setVisibleRight] = useState(false)
    const [indicatorsuggestion, setIndicatorSuggestion] = useState([])
    const [selected, setSelected] = useState([])
    const his = useHistory()
    const [indicator, setIndicator] = useState()
    const [indicatorlist, setIndicatorList] = useState([])

    const forceUpdate = useForceUpdate()
    const [form, setForm] = useState(loc.state)
    const [submitted, setSubmitted] = useState(false)
    const [dplist, setDPList] = useState([])
    const [dp, setDP] = useState(null)
    const [dpsuggestion, setDPSuggestion] = useState([])
    const [prevdialog, setPrevDialog] = useState(false)


    const prevDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => { setPrevDialog(false) }} />
        </>
    );
    const updateForm = (obj, val) => {
        let loc = form;
        loc[obj] = val;
        setForm(loc);
        forceUpdate()
    }
    const search = (event) => {

        let _items = dplist.filter((k) => { return k.name.trim().toLowerCase().includes(event.query.trim().toLowerCase()) });
        setDPSuggestion(_items);
        forceUpdate()
    }
    const search_ = (event) => {
        console.log(indicatorlist)
        let _items = indicatorlist.filter((k) => { return k.title.trim().toLowerCase().includes(event.query.trim().toLowerCase()) });
        setIndicatorSuggestion(_items);
        forceUpdate()
    }
    function hasNoDuplicateKeyValues(data, key) {

        const seenValues = new Set();

        if (Array.isArray(data)) {
            // Check for duplicates in an array of objects
            for (const obj of data) {
                if (obj[key] !== undefined) {
                    // Convert the value to lowercase for case-insensitive comparison
                    const value = obj[key].toString().toLowerCase();

                    if (seenValues.has(value)) {
                        return false; // Duplicate value found
                    }
                    if (value.trim().length === 0) {
                        return false;
                    } else {
                        seenValues.add(value);
                    }

                }
            }
        } else if (typeof data === 'object') {
            // Check for duplicates in an object
            for (const prop in data) {
                if (data.hasOwnProperty(prop) && prop === key) {
                    if (data[prop] !== undefined && data[prop] !== '' && data[prop].trim() !== '') {
                        // Convert the value to lowercase for case-insensitive comparison
                        const value = data[prop].toString().toLowerCase();
                        if (seenValues.has(value)) {
                            return false; // Duplicate value found
                        }
                        seenValues.add(value);
                    }
                }
            }
        }

        return true; // No duplicates found
    }
    const checkForm = (data) => {
        let check = true;
        let count = 0
        data.forEach((field) => {

            if (field.type === 'paragraph') {
                if (field.label === 'Paragraph') {
                    check = false
                    count = count + 1
                }
            } else if (field.type === 'section') {
                if (field.label === 'Section') {
                    check = false
                    count = count + 1
                }
            } else if (field.type === 'number') {
                if (field.label === 'Number') {

                    check = false
                    count = count + 1
                } else {
                    if (field.name === undefined || !field.name.startsWith("DP")) {
                        check = false
                        count = count + 1
                    }
                }
            } else if (field.type === 'checkbox-group') {
                if (field.label === 'Checkbox Group') {
                    check = false
                    count = count + 1
                } else {
                    if (field.name === undefined || !field.name.startsWith("DP")) {
                        check = false
                        count = count + 1
                    }
                }
            } else if (field.type === 'date') {
                if (field.label === 'Date Field') {
                    check = false
                    count = count + 1
                }
            } else if (field.type === 'radio-group') {
                if (field.label === 'Radio Group') {
                    check = false
                    count = count + 1
                } else {
                    if (field.name === undefined || !field.name.startsWith("DP")) {
                        check = false
                        count = count + 1
                    }
                }
            } else if (field.type === 'select') {
                if (field.label === 'Select') {
                    check = false
                    count = count + 1
                } else {
                    if (field.name === undefined || !field.name.startsWith("DP")) {
                        check = false
                        count = count + 1
                    }
                }
            } else if (field.type === 'text') {
                if (field.label === 'Text Field') {
                    check = false
                    count = count + 1
                } else {
                    if (field.name === undefined || !field.name.startsWith("DP")) {
                        check = false
                        count = count + 1
                    }
                }
            } else if (field.type === 'textarea') {
                if (field.label === 'Text Area') {
                    check = false
                    count = count + 1
                } else {
                    if (field.name === undefined || !field.name.startsWith("DP")) {
                        check = false
                        count = count + 1
                    }
                }
            } else if (field.type === 'file') {
                if (field.label === 'File Upload') {
                    check = false
                    count = count + 1
                }
            }
        })
        return { result: check, count }
    }
    const addDCF = (formdata) => {
        setSubmitted(true)
        console.log(form)
        if (form.title.trim().length !== 0 && formdata.length !== 0 && form.category) {

            if (formdata.length) {
                let loc_ = JSON.parse(JSON.stringify(form));

                loc_.category = form.category


                loc_.data1 = JSON.stringify(formdata)
                delete loc_.created_on
                delete loc_.created_by
                delete loc_.type
                if (loc_.comments === null) {
                    delete loc_.comments
                }
                loc_['modified_on'] = moment.utc()
                loc_['modified_by'] = login_data.id
                APIServices.patch(API.DealerForm_Edit(loc.state.id), loc_).then((res) => {
                    his.goBack()
                    setSubmitted(false)
                    forceUpdate()

                })
            } else {
                Swal.fire({
                    position: "center",
                    icon: "info",
                    title: checkForm(formdata).count + " field(s) found with default label name",
                    showConfirmButton: false,
                    timer: 1800,
                });
            }


        } else if (formdata.length === 0 && form.category !== null && form.title.trim().length !== 0) {
            Swal.fire({
                position: "center",
                icon: "warning",
                title: "Atleast one field is required to submit form",
                showConfirmButton: false,
                timer: 1500,
            });
        }
    }
    const removeHTMLTag = (html) => {
        return html.replace(/(<([^>]+)>)/gi, "")
            .replace(/\n/g, " ")
            .replace(/&nbsp;/g, " ")
    }

    const onChangeIndicator = (item) => {
        setIndicator(item)
        if (item !== null) {
            if (item.newDataPoints !== undefined) {
                setSelected(item.newDataPoints)
            }

        } else {
            setSelected([])
        }

    }
    return (
        <div className="grid p-fluid">

            <div className="col-12 md:col-12">
                <div className="card ">
                    <div
                        className="col-12 md:col-12"
                        style={{
                            textDecoration: "underline",
                            textAlign: "center",
                            textDecorationColor: "green",
                        }}
                    >
                        <h5>Edit Dealer Form</h5>

                    </div>

                    <div>
                        <div
                            className="col-12 "
                            style={{
                                display: "flex",
                                flexDirection: "row"
                            }}
                        >
                            <div
                                style={{
                                    width: "20%",
                                    display: "flex",
                                    alignSelf: "center",
                                }}
                            >
                                <span>
                                    Dealer Form ID

                                </span>
                            </div>
                            <div
                                style={{
                                    width: "80%",
                                }}
                                className="p-inputgroup"
                            >
                                <label>{form.id}</label>

                            </div>

                        </div>
                        <div
                            className="col-12 "
                            style={{
                                display: "flex",
                                flexDirection: "row"
                            }}
                        >
                            <div
                                style={{
                                    width: "20%",
                                    display: "flex",
                                    alignSelf: "center",
                                }}
                            >
                                <span>
                                    Dealer Form Title
                                    <span
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        &nbsp;*
                                    </span>
                                </span>
                            </div>
                            <div
                                style={{
                                    width: "80%",
                                }}
                                className="p-inputgroup"
                            >
                                <InputText
                                    value={form.title}
                                    type={'text'}
                                    min={1}
                                    onChange={(e) => {
                                        updateForm("title", e.target.value);
                                    }}

                                    placeholder="Form Title"
                                />

                            </div>

                        </div>
                        {submitted && form.title.trim().length === 0 && (
                            <div
                                className="col-12 " style={{
                                    display: 'flex',
                                    marginLeft: '16%'
                                }}>
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >

                                    Title is mandatory
                                </small>
                            </div>
                        )}
                        <div
                            className="col-12 "
                            style={{
                                display: "flex",
                                flexDirection: "row"
                            }}
                        >
                            <div
                                style={{
                                    width: "20%",
                                    display: "flex",
                                    alignSelf: "center",
                                }}
                            >
                                <span>
                                    Form Category
                                    <span
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        &nbsp;*
                                    </span>
                                </span>
                            </div>
                            <div
                                style={{
                                    width: "80%",
                                }}
                                className="p-inputgroup"
                            >
                                <Dropdown
                                    value={form.category}
                                    optionLabel="name"
                                    optionValue="id"
                                    options={categoryList}
                                    onChange={(e) => {
                                        updateForm("category", e.value);
                                    }}

                                    placeholder="Category"
                                />

                            </div>

                        </div>
                        {submitted && form.category === null && (
                            <div
                                className="col-12 " style={{
                                    display: 'flex',
                                    marginLeft: '16%'
                                }}>
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >

                                    Form Category is mandatory
                                </small>
                            </div>
                        )}
                        <div
                            className="col-12 "
                            style={{
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <div
                                style={{
                                    width: "20%",
                                    display: "flex",
                                    alignSelf: "center",
                                }}
                            >
                                <span>
                                    Comments

                                </span>
                            </div>
                            <div
                                style={{
                                    width: "80%",
                                }}
                                className="p-inputgroup"
                            >
                                <InputTextarea
                                    value={form.comments}
                                    type={'text'}
                                    min={1}
                                    onChange={(e) => {
                                        updateForm("comments", e.target.value);
                                    }}

                                    placeholder="Comments"
                                />

                            </div>

                        </div>

                    </div>
                    <div style={{ marginTop: 20 }}>
                        <FormBuilderTwo onSubmit={(e) => { updateForm('data1', e); addDCF(e); }} values={form.data1} />
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "column", alignItems: 'flex-end'
                    }}>


                    </div>
                    <div>

                    </div>

                    {/* 
                    <div className="col-12 md:col-12">

                        <Button label="Save" className="mr-2 mb-2" style={{ marginTop: 10, width: 100 }} onClick={(e) => { addClientApi() }}></Button>

                    </div> */}
                </div>
            </div>


        </div>
    );
};

export default DealerCollectionFormEdit;
