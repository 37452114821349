import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { DateTime } from 'luxon';
import supplyData from './supply.json';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Accordion, AccordionTab } from 'primereact/accordion';
import SupplierPanel from '../SupplierScreen/SupplierPanel';

const SuppliersTableManual = () => {
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [isDialogVisible, setDialogVisible] = useState(false);

    useEffect(() => {
        // Load data from supply.json and format it if needed
        setData(supplyData);
    }, []);

    const handleIdClick = (rowData) => {
        setSelectedRow(rowData);
        setDialogVisible(true);
    };

    const calibrationIdBodyTemplate = (rowData) => {
        const formattedDate = rowData.audit_start_date
            ? DateTime.fromFormat(rowData.audit_start_date.trim(), 'dd.MM.yyyy').toFormat('ddMMyyyy')
            : 'Not Available';

        return (
            <a href="#" onClick={(e) => { e.preventDefault(); handleIdClick(rowData); }}>
                {'MSI-' + rowData.vendor_code + '-' + formattedDate}
            </a>
        );
    };
    const getCalibrationIdBodyTemplate = (rowData) => {
        const formattedDate = rowData.audit_start_date
            ? DateTime.fromFormat(rowData.audit_start_date.trim(), 'dd.MM.yyyy').toFormat('ddMMyyyy')
            : 'Not Available';

        return 'MSI-' + rowData.vendor_code + '-' + formattedDate

    };
    const hideDialog = () => {
        setDialogVisible(false);
        setSelectedRow(null);
    };

    const dateTemplate = (date) => {
        return date ? DateTime.fromFormat(date.trim(), 'dd.MM.yyyy').toFormat('dd-MM-yyyy') : 'Not Available';
    };

    const spentTemplate = (rowData) => {
        return `${rowData.spent.toFixed(2)} Cr. INR`;
    };

    const auditorsTemplate = (rowData) => {
        return rowData.audiors.replace(/\n/g, ', ');
    };

    const threeDecimalTemplate = (rowData, field) => {
        return rowData[field] ? rowData[field].toFixed(3) : '0.000';
    };

    // Helper function to split auditors and handle missing values
    const splitAuditors = (rowData, index) => {
        const auditorsList = rowData.audiors ? rowData.audiors.split(',').map(auditor => auditor.trim()) : [];
        return auditorsList[index] || '-';
    };

    // Determine the max number of auditors across all data
    const maxAuditors = Math.max(...supplyData.map(item => (item.audiors ? item.audiors.split(',').length : 0)));

    const getMsiRatingClass = (rating) => {
        switch (rating.toLowerCase()) {
            case 'gold': return 'bg-success text-white';
            case 'silver': return 'bg-secondary text-white';
            case 'bronze': return 'bg-warning text-dark';
            case 'needs improvement': return 'bg-danger text-white';
            default: return 'bg-light text-dark';
        }
    };
    const assessmentHeader = (item) => {
        return (
            <div className="flex align-items-center justify-content-between gap-2 w-full">
                <div className="font-bold ">{getCalibrationIdBodyTemplate(item)}</div>
          

                <div >Status : <tag className="status-tag-green">Completed</tag> </div>
            </div>

        )

    }

    return (
        <div className="card">
            <DataTable value={data} paginator rows={10} responsiveLayout="scroll" className="mt-2 h-500">
                <Column field="calibrationId" header="ID" body={calibrationIdBodyTemplate}></Column>

                <Column field="company_name" header="Company Name"></Column>
                <Column field="spent" header="Spent (Cr. INR)" body={spentTemplate}></Column>
                <Column field="category" header="Category"></Column>
                <Column field="vendor_code" header="Vendor Code"></Column>
                <Column field="audit_start_date" header="Audit Start Date" body={(rowData) => dateTemplate(rowData.audit_start_date)}></Column>
                <Column field="audit_end_date" header="Audit End Date" body={(rowData) => dateTemplate(rowData.audit_end_date)}></Column>
                <Column field="location" header="Location"></Column>
                {[...Array(maxAuditors)].map((_, index) => (
                    <Column key={index} field={`auditor_${index + 1}`} header={`Auditor ${index + 1}`}
                        body={(rowData) => splitAuditors(rowData, index)}></Column>
                ))}
                <Column field="environment" header="Environment" body={(rowData) => threeDecimalTemplate(rowData, 'environment')}></Column>
                <Column field="social" header="Social" body={(rowData) => threeDecimalTemplate(rowData, 'social')}></Column>
                <Column field="governance" header="Governance" body={(rowData) => threeDecimalTemplate(rowData, 'governance')}></Column>
                <Column field="msi_score" header="MSI Score"></Column>
                <Column field="msi_rating" header="MSI Rating"></Column>
                <Column field="status" header="Status"></Column>
                        
               
            </DataTable>

            {/* Popup Modal for Detailed Report */}
            <Dialog header="Supplier Audit Report" visible={isDialogVisible} style={{ width: '60vw' }} onHide={hideDialog}>
                {selectedRow && (
                    <div>
                        <div className=" p-4 rounded bg-light">
                            <h3 className="text-center mb-4">{selectedRow.company_name}</h3>

                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <p><strong>Vendor Code:</strong> {selectedRow.vendor_code}</p>
                                    <p><strong>Audit Start Date:</strong> {selectedRow.audit_start_date}</p>
                                    <p><strong>Audit End Date:</strong> {selectedRow.audit_end_date || '-'}</p>
                                    <p><strong>Location:</strong> {selectedRow.location}</p>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <p><strong>Auditors:</strong> {selectedRow.audiors.replace(/\n/g, ', ')}</p>
                                    <p><strong>Status:</strong> {selectedRow.status}</p>
                                    <p><strong>Spent:</strong> {selectedRow.spent.toFixed(2)} Cr. INR</p>
                                </div>
                            </div>

                            <h4 className="mt-4">Performance Scores</h4>
                            <div className="row">
                                <div className="col-md-6">
                                    <ul className="list-group">
                                        <li className="list-group-item"><strong>Environment:</strong> {selectedRow.environment.toFixed(3)} / 60</li>
                                        <li className="list-group-item"><strong>Social:</strong> {selectedRow.social.toFixed(3)} / 10</li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="list-group">
                                        <li className="list-group-item"><strong>Governance:</strong> {selectedRow.governance.toFixed(3)} / 30</li>
                                        <li className="list-group-item"><strong>MSI Score:</strong> {selectedRow.msi_score} </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="mt-4 p-3 text-center rounded shadow-sm" style={{ fontSize: '1.2rem' }}>
                                <strong>MSI Rating:</strong>
                                <span className={`badge ${getMsiRatingClass(selectedRow.msi_rating)} mx-2 p-2 rounded`}>
                                    {selectedRow.msi_rating}
                                </span>
                            </div>
                        </div>
                         <Accordion  >
                        <AccordionTab headerClassName="accfull" header={assessmentHeader(selectedRow)}>
                            <SupplierPanel id={'s'} submittedDate={"24-Dec-2024"} vendorCode={selectedRow.vendor_code} users={[]} updateData={(e) => { console.log(e) }} readOnly={true} auditId={
                                {
                                    "id": "2094cc1a-ea96-4baf-892d-b2bdc27c9a1c",
                                    "auditor_ids": [
                                        374,
                                        377
                                    ],
                                    "assessmentStartDate": "2024-12-23T17:06:38.470Z",
                                    "assessmentEndDate": "2024-12-23T18:30:00.000Z",
                                    "auditStartDate": "2024-12-24T18:30:00.000Z",
                                    "auditEndDate": "2024-12-27T18:30:00.000Z",
                                    "supplierScore": null,
                                    "auditorScore": null,
                                    "supplierSubmittedOn": "2024-12-23T18:30:00.000Z",
                                    "auditorSubmittedOn": "2024-12-25T18:30:00.000Z",
                                    "supplierSubmittedBy": 378,
                                    "auditorSubmittedBy": 377,
                                    "supplierModifiedBy": 378,
                                    "auditorModifiedBy": 377,
                                    "created_on": "2024-12-04T17:19:22.663Z",
                                    "modified_on": "2024-12-24T05:25:02.139Z",
                                    "created_by": 374,
                                    "modified_by": 377,
                                    "userProfileId": 17,
                                    "group1": {
                                        "updated_on": "2024-12-24T05:22:25.154Z",
                                        "updated_by": 377,
                                        "sections": [
                                            "8d1a0217-4a6a-4d4a-922e-46c345dc7291"
                                        ],
                                        "assessors": [
                                            377
                                        ]
                                    },
                                    "group2": null,
                                    "group3": null,
                                    "group4": null,
                                    "supplierId": 378
                                }
                            } />

                        </AccordionTab>
                        </Accordion>
                    </div>
                )}
            </Dialog>

        </div>
    );
};

export default SuppliersTableManual;
