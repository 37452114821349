import React, { useState, useRef } from "react";
import "./TextEditor.css";

const TextEditor = ({
  value = "",
  onSave,
  onCancel,
  overlayOptions = [],
  saveIcon,
  cancelIcon,
  optionIcon,
  containerStyle = {},
  inputStyle = {},
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);
  const [showOverlay, setShowOverlay] = useState(false);
  const [error, setError] = useState(false);
  const containerRef = useRef(null);

  const handleSave = () => {
    if (currentValue.trim() === "") {
      setError(true);
      return;
    }
    onSave(currentValue);
    setIsEditing(false);
    setError(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setCurrentValue(value);
    setError(false);
    onCancel();
  };

  const handleOutsideClick = (e) => {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      if (isEditing) handleCancel();
      setShowOverlay(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isEditing]);

  const defaultOptions = [
    {
      label: "Edit",
      command: () => setIsEditing(true),
    },
    ...overlayOptions,
  ];

  return (
    <div
      className="text-editor-container"
      style={containerStyle}
      ref={containerRef}
    >
      {!isEditing ? (
        <div className="text-view">
          <div className="text-content">{value}</div>
          <div
            className={`option-icon ${showOverlay ? "active" : ""}`}
            onClick={() => setShowOverlay(!showOverlay)}
          >
            {optionIcon}
            {showOverlay && (
              <div className="overlay-options">
                {defaultOptions.map((option, index) => (
                  <div
                    key={index}
                    className={`overlay-option ${
                      option.disabled ? "disabled" : ""
                    }`}
                    onClick={
                      !option.disabled
                        ? (e) => {
                          e.stopPropagation();
                            option.command();
                            setShowOverlay(false);
                          }
                        : undefined
                    }
                  >
                    <i className={option.gicon || "pi"}>{option.icon}</i>
                    {option.label}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="text-editor">
          <input
            type="text"
            className={`text-input ${error ? "error" : ""}`}
            value={currentValue}
            placeholder={error ? "Value cannot be empty" : ""}
            onChange={(e) => setCurrentValue(e.target.value)}
            style={inputStyle}
          />
          <div className="edit-actions">
            <div className="save-icon" onClick={handleSave}>
              {saveIcon}
            </div>
            <div className="cancel-icon" onClick={handleCancel}>
              {cancelIcon}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TextEditor;
