
import Axios from "axios"
import moment from "moment"
import { API } from "../../constants/api_url"
import { setPPFListData } from "../../RTK/Background/pendingData"
import APIServices from "../../service/APIService"
import { useSelector } from "react-redux"
import { DateTime } from "luxon"
import * as XLSX from "xlsx";


export const sectionsList = [
    { title: "Sustainability", id: 8 },
    { title: "Health", id: 9 },
    { title: "Safety Central", id: 10 },
    { title: "Safety Operational", id: 11 },
    { title: "Supply Chain", id: 12 },
    { title: "Dealership Sustainability", id: 13 },
    { title: "Environmental", id: 14 },
    { title: "Social", id: 15 },
    { title: "Governance", id: 16 },
];

export const convertReportingMonthsToLetters = (yearString) => {
    const monthId = yearString?.split("-")[0];
    const year = yearString?.split("-")[1];
    switch (monthId) {
        case "01":
            return `Jan-${year}`;
        case "02":
            return `Feb-${year}`;
        case "03":
            return `Mar-${year}`;
        case "04":
            return `Apr-${year}`;
        case "05":
            return `May-${year}`;
        case "06":
            return `Jun-${year}`;
        case "07":
            return `Jul-${year}`;
        case "08":
            return `Aug-${year}`;
        case "09":
            return `Sep-${year}`;
        case "10":
            return `Oct-${year}`;
        case "11":
            return `Nov-${year}`;
        case "12":
            return `Dec-${year}`;
        default:
            return null;
    }
};
//get location hierarchy data based on location [],roleassigned [], $roles[]
const getLocationData = (locationData, roleAssignments, roles) => {
    // Filter role assignments based on roles
    const relevantAssignments = roleAssignments.filter(assignment =>
        assignment.roles.some(role => roles.includes(role))
    );

    // Initialize the result as an empty array
    let result = [];

    // Loop through each relevant assignment
    relevantAssignments.forEach(assignment => {
        if (assignment.tier1_id === 0) {
            // If tier1_id is 0, return all locations
            result = locationData;
        } else {
            // Find the matching tier1 (country)
            let country = locationData.find(loc => loc.id === assignment.tier1_id);
            if (!country) return;

            if (assignment.tier2_id === 0) {
                // If tier2_id is 0, return the country and its regions
                result.push(country);
            } else {
                // Find the matching tier2 (region)
                let region = country.locationTwos.find(loc => loc.id === assignment.tier2_id);
                if (!region) return;

                if (assignment.tier3_id === 0) {
                    // If tier3_id is 0, return the region and its sites
                    result.push({
                        ...country,
                        locationTwos: [region]
                    });
                } else {
                    // Find the matching tier3 (site)
                    let site = region.locationThrees.find(loc => loc.id === assignment.tier3_id);
                    if (!site) return;

                    result.push({
                        ...country,
                        locationTwos: [{
                            ...region,
                            locationThrees: [site]
                        }]
                    });
                }
            }
        }
    });

    // Remove duplicate locations from the result
    result = result.filter((item, index, self) =>
        index === self.findIndex(t => t.id === item.id)
    );

    return result;
}
function checkRoleAccessByRoleIds(user_ids, roles, level, tier_id, roleAssignments, locationData, adminId) {
    console.log(user_ids, roles, level, tier_id, roleAssignments, locationData, adminId)
    // Step 1: Filter roleAssignments based on user_ids and roles
    const filteredAssignments = roleAssignments.filter(assignment =>
        user_ids.includes(assignment.user_id) &&
        assignment.roles.some(role => roles.includes(role))
    );

    // Step 2: Create a set of user IDs who have access
    const userIdsWithAccess = new Set();
    if (adminId) {
        userIdsWithAccess.add(adminId);
    }

    // Step 3: Check each filtered assignment for access
    filteredAssignments.forEach(assignment => {
        const { user_id, tier1_id, tier2_id, tier3_id } = assignment;

        // Get valid tier IDs for this specific assignment
        const validTierIds = getValidTierIdsForAssignment(tier1_id, tier2_id, tier3_id, locationData);

        if (level === 0) {
            // Global access
            userIdsWithAccess.add(user_id);
        } else if (level === 1 && validTierIds.countries.includes(tier_id) && !assignment.tier2_id) {
            // Country level access
            userIdsWithAccess.add(user_id);
        } else if (level === 2 && (validTierIds.regions.includes(tier_id) || validTierIds.countries.includes(tier_id)) && !assignment.tier3_id) {
            // Region level access
            userIdsWithAccess.add(user_id);
        } else if (level === 3 && (validTierIds.businessUnits.includes(tier_id) || validTierIds.regions.includes(tier_id) || validTierIds.countries.includes(tier_id))) {
            // Business Unit level access
            userIdsWithAccess.add(user_id);
        }
    });

    // Step 4: Return the list of user IDs who have access
    return Array.from(userIdsWithAccess);
}

function getValidTierIdsForAssignment(tier1_id, tier2_id, tier3_id, locationData) {
    let countries = new Set();
    let regions = new Set();
    let businessUnits = new Set();

    locationData.forEach(country => {
        if (tier1_id === country.id || tier1_id === 0 || tier1_id === null) {
            countries.add(country.id);
            country.locationTwos.forEach(region => {
                if (tier2_id === 0 || tier2_id === region.id || tier2_id === null) {
                    regions.add(region.id);
                    region.locationThrees.forEach(bu => {
                        if (tier3_id === 0 || tier3_id === bu.id || tier3_id === null) {
                            businessUnits.add(bu.id);
                        }
                    });
                }
            });
        }
    });

    return {
        countries: Array.from(countries),
        regions: Array.from(regions),
        businessUnits: Array.from(businessUnits)
    };
}
function getFiscalYearsFromStartDate_(start_date, fymonth) {

    const startDate = DateTime.fromISO(start_date, { zone: 'utc' }).plus({ months: 1 });
    const currentDate = DateTime.now();

    let startFiscalYear, currentFiscalYear;
    const fiscalYears = [];

    if (fymonth === 1) {
        // When fiscal month is January, it's a single year
        startFiscalYear = startDate.year;
        currentFiscalYear = currentDate.year;

        for (let year = startFiscalYear; year <= currentFiscalYear; year++) {
            fiscalYears.push({ name: year, label: `${year}` });
        }
    } else {
        // Normal fiscal year spanning two calendar years
        startFiscalYear = startDate.month >= fymonth ? startDate.year : startDate.year - 1;
        currentFiscalYear = currentDate.month >= fymonth ? currentDate.year : currentDate.year - 1;

        for (let year = startFiscalYear; year <= currentFiscalYear; year++) {
            const label = `${year}-${(year + 1).toString().slice(-2)}`;
            fiscalYears.push({ name: year + 1, label });
        }

        // Include the current fiscal year only if the current month is before the fiscal year start month
        if (currentDate.month < fymonth) {
            fiscalYears.pop();
        }
    }

    return fiscalYears;
}
function getFiscalYearsFromStartDate(start_date, fymonth) {
    const startDate = DateTime.fromISO(start_date, { zone: "Asia/Calcutta" })
    const currentDate = DateTime.local();

    let startFiscalYear, currentFiscalYear;
    const fiscalYears = [];

    if (fymonth === 1) {
        // When fiscal month is January, fiscal year matches the calendar year
        startFiscalYear = startDate.year;
        currentFiscalYear = currentDate.year;

        // Add fiscal years only up to the current or next year if already in January
        for (let year = startFiscalYear; year <= currentFiscalYear; year++) {
            fiscalYears.push({ name: year, label: `${year}` });
        }
    } else {
        // Normal fiscal year spanning two calendar years
        startFiscalYear = startDate.month >= fymonth ? startDate.year : startDate.year - 1;
        currentFiscalYear = currentDate.month >= fymonth ? currentDate.year : currentDate.year - 1;

        // Add fiscal years from the starting fiscal year to the current fiscal year + 1
        for (let year = startFiscalYear; year <= currentFiscalYear + 1; year++) {
            const label = `${year}-${(year + 1).toString().slice(-2)}`;
            fiscalYears.push({ name: year + 1, label });
        }

        // Exclude future fiscal years if the current month is before the fiscal start month
        if (currentDate.month < fymonth) {
            fiscalYears.pop();
        }
    }

    return fiscalYears;
}
const getRPTextFormat = (item) => {
    if (item.length !== 0) {
        if (item.length >= 2) {

            const startDate = DateTime.fromFormat(item[0], 'MM-yyyy').toFormat('LLL-yyyy');
            const endDate = DateTime.fromFormat(item[item.length - 1], 'MM-yyyy').toFormat('LLL-yyyy');
            return `${startDate} to ${endDate}`;
        } else {
            return DateTime.fromFormat(item[0], 'MM-yyyy').toFormat('LLL-yyyy');
        }
    }
};
const filterSubmissionsByFiscalYear = (submissions, year, fymonth) => {
    const { startDate, endDate } = getFiscalYearRange(year, fymonth);

    return submissions.filter(submission => {
        const allDatesWithinRange = submission.reporting_period.every(period => {
            const periodDate = DateTime.fromFormat(period, 'MM-yyyy').startOf('month');
            return periodDate >= startDate && periodDate <= endDate;
        });

        return allDatesWithinRange;
    });
};

function generateFiscalQuarters(year, fymonth) {
    const quarters = [];

    // If fymonth is not January (1), adjust the year to start from the previous year
    if (fymonth !== 1) {
        year--;
    }

    let startMonth = fymonth;

    // Iterate over the quarters
    for (let i = 0; i < 4; i++) {
        const startDate = DateTime.local(year, startMonth, 1);
        const endDate = startDate.plus({ months: 2 }).endOf('month');
        const start_date = startDate.toFormat('ddMMyyyy')
        const end_date = endDate.toFormat('ddMMyyyy')

        const name = `${startDate.toFormat('MMM-yyyy')} to ${endDate.toFormat('MMM-yyyy')}`;
        const label = `Q${i + 1}`;

        quarters.push({ label, name, startDate: start_date, endDate: end_date });

        // Move to the next quarter
        startMonth += 3;

        // Adjust the year if the start month exceeds December
        if (startMonth > 12) {
            startMonth -= 12;
            year++;
        }
    }

    return quarters;
}
const getFiscalYearRange = (year, fymonth) => {
    let startDate, endDate;


    if (fymonth === 1) {
        startDate = DateTime.fromObject({ year, month: 1, day: 1 }).startOf('day');
        endDate = DateTime.fromObject({ year, month: 12, day: 31 }).endOf('day');
    } else {
        startDate = DateTime.fromObject({ year: year - 1, month: fymonth, day: 1 }).startOf('day');
        endDate = DateTime.fromObject({ year, month: fymonth - 1, day: 1 }).endOf('month');
    }

    return { startDate, endDate };
};
const getValidTierIds = (locationData, tier1_id, tier2_id, tier3_id) => {
    const countries = new Set();
    const regions = new Set();
    const businessUnits = new Set();

    locationData.forEach(country => {
        if (tier1_id === 0 || tier1_id === country.id) {
            countries.add(country.id);

            country.locationTwos.forEach(region => {
                if (tier2_id === 0 || tier2_id === region.id) {
                    regions.add(region.id);

                    region.locationThrees.forEach(businessUnit => {
                        if (tier3_id === 0 || (tier2_id === 0 && tier3_id === null) || tier3_id === businessUnit.id) {
                            businessUnits.add(businessUnit.id);
                        }
                    });
                }
            });
        }
    });

    return { countries: Array.from(countries), regions: Array.from(regions), businessUnits: Array.from(businessUnits) };
};
const filterAssignmentsByFiscalYear = (assignments, year, fymonth) => {
    const { startDate, endDate } = getFiscalYearRange(year, fymonth);
    const currentDate = DateTime.local().startOf('day');

    return assignments.filter(assignment => {

        const assignmentStartDate = assignment.start_date ? DateTime.fromISO(assignment.start_date, { zone: 'utc' }).startOf('day') : currentDate;
        const assignmentEndDate = assignment.end_date ? DateTime.fromISO(assignment.end_date, { zone: 'utc' }).startOf('day') : currentDate;

        return (assignmentStartDate >= startDate && assignmentStartDate <= endDate) ||
            (assignmentEndDate >= startDate && assignmentEndDate <= endDate) ||
            (assignmentStartDate <= startDate && assignmentEndDate >= endDate);
    });
};
const filterDataByTierAndLocationByLevel = (data, locationData, tier1_id, tier2_id, tier3_id) => {
    console.log(tier1_id, tier2_id, tier3_id)
    if (tier1_id === 0 && tier2_id === null && tier3_id === null) {
        return data; // If tier is 0, null, null return the given data
    }

    const { countries, regions, businessUnits } = getValidTierIds(locationData, tier1_id, tier2_id, tier3_id);
    console.log(countries, regions, businessUnits)

    return data.filter(item => {
        if (tier1_id !== 0 && tier2_id === 0 && tier3_id === null) {
            // Case when we want all regions and sites under a country
            return (item.level === 1 && countries.includes(item.locationId)) ||
                (item.level === 2 && regions.includes(item.locationId)) ||
                (item.level === 3 && businessUnits.includes(item.locationId));
        } else if (tier1_id !== 0 && tier2_id !== 0 && tier3_id === 0) {
            // Case when we want a specific region and all its sites
            return (item.level === 2 && regions.includes(item.locationId)) ||
                (item.level === 3 && businessUnits.includes(item.locationId));
        } else if (tier1_id !== 0 && tier2_id !== 0 && tier3_id !== 0) {
            // Case when we want a specific site
            return item.level === 3 && businessUnits.includes(item.locationId);
        } else {
            // Case when we want only the specific country
            return item.level === 1 && countries.includes(item.locationId);
        }
    });
};
const filterDataByTierAndLocationByTierId = (data, locationData, tier1_id, tier2_id, tier3_id) => {
    // If tier1_id is 0, null, or undefined, return all data (global level)
    if (!tier1_id) {
        return data;
    }

    // Get valid tier IDs for filtering
    const { countries, regions, businessUnits } = getValidTierIds(locationData, tier1_id, tier2_id, tier3_id);
    console.log("Filtered Tier IDs:", { countries, regions, businessUnits });

    return data.filter(item => {
        if (tier1_id !== 0 && (!tier2_id || tier2_id === 0) && (!tier3_id || tier3_id === null)) {
            // Case 1: Country level (tier2_id is 0 or null, and tier3_id is null)
            return countries.includes(item.tier1_id) ||
                regions.includes(item.tier2_id) ||
                businessUnits.includes(item.tier3_id);
        } else if (tier1_id !== 0 && tier2_id !== 0 && (!tier3_id || tier3_id === 0)) {
            // Case 2: Region level (tier2_id is specific, tier3_id is 0 or null)
            return regions.includes(item.tier2_id) || businessUnits.includes(item.tier3_id);
        } else if (tier1_id !== 0 && tier2_id !== 0 && tier3_id !== 0 && tier3_id !== null) {
            // Case 3: City level (tier3_id is specific)
            return businessUnits.includes(item.tier3_id);
        } else {
            // Case 4: Default to country level if tier1_id is valid but others are not specific
            return countries.includes(item.tier1_id);
        }
    });
};
function groupArrayByKeys(dataArray, keys) {
    return dataArray.reduce((result, item) => {
        // Create a group key by concatenating the values of the specified keys
        const groupKey = keys.map(key => item[key]).join('-');

        // If the group key doesn't exist in the result, initialize it as an empty array
        if (!result[groupKey]) {
            result[groupKey] = [];
        }

        // Push the current item into the group
        result[groupKey].push(item);

        return result;
    }, {});
}
function removeDuplicatesFromArrayByKey(array, key) {
    const uniqueItems = new Map();

    return array.filter(item => {
        const keyValue = item[key];
        if (!uniqueItems.has(keyValue)) {
            uniqueItems.set(keyValue, true);
            return true;
        }
        return false;
    });
}
const generateFiscalYearsByAssignment = (assignments, fymonth) => {
    const fiscalYears = [];

    assignments.forEach(({ start_date, end_date }) => {
        const startDate = DateTime.fromISO(start_date, { zone: "utc" });
        const endDate = end_date
            ? DateTime.fromISO(end_date, { zone: "utc" })
            : DateTime.utc(); // Use current UTC date if end_date is null

        // Determine the fiscal year of the start date
        let startFY = startDate.year;
        if (fymonth !== 1 && startDate.month < fymonth) {
            startFY -= 1;
        }

        // Determine the fiscal year of the end date
        let endFY = endDate.year;
        if (fymonth !== 1 && endDate.month < fymonth) {
            endFY -= 1;
        }

        // Add all fiscal years in the range to the result
        for (let year = startFY; year <= endFY; year++) {
            const value = fymonth === 1 ? year : year + 1;
            const title = fymonth === 1
                ? `${year}`
                : `${year}-${String(year + 1).slice(-2)}`;

            // Avoid duplicates
            if (!fiscalYears.some(fy => fy.value === value)) {
                fiscalYears.push({ value, title });
            }
        }
    });

    // Sort fiscal years by value
    return fiscalYears.sort((a, b) => a.value - b.value);
}
function getReportingFiscalYearByReportingperiod(reporting_period, fymonth) {
    const parseDate = (str) => {
        // Try both formats to parse different date strings
        return DateTime.fromFormat(str, "MMM-yyyy").isValid
            ? DateTime.fromFormat(str, "MMM-yyyy")
            : DateTime.fromFormat(str, "MM-yyyy");
    };

    const determineFiscalYear = (date) => {
        // Determine fiscal year based on fymonth
        if (fymonth === 1) {
            return date.year; // Calendar year if fiscal year starts in January
        } else {
            const fiscalYearStart = DateTime.fromObject({ year: date.year, month: fymonth, day: 1 });
            return date >= fiscalYearStart ? date.year + 1 : date.year;
        }
    };

    // If reporting_period is an array, parse the last date in the array
    if (Array.isArray(reporting_period)) {
        const lastPeriod = reporting_period[reporting_period.length - 1];
        const date = parseDate(lastPeriod);
        return date.isValid ? determineFiscalYear(date) : null;
    }

    // If reporting_period is a single date or range string
    if (typeof reporting_period === "string") {
        if (reporting_period.includes(" to ")) {
            // Parse the end date in the range "Jan-2022 to Mar-2022"
            const [, endDateStr] = reporting_period.split(" to ");
            const endDate = parseDate(endDateStr.trim());
            return endDate.isValid ? determineFiscalYear(endDate) : null;
        } else {
            // Single date format, e.g., "Jan-2022" or "02-2022"
            const date = parseDate(reporting_period);
            return date.isValid ? determineFiscalYear(date) : null;
        }
    }

    return null; // Return null if format is invalid
}
function filterSubmissionsByGivenPeriod(period, submissions) {
    //period:  "Jul-2022 to Sep-2022"

    const [startLabel, endLabel] = period.split(' to ');
    const startDate = DateTime.fromFormat(startLabel, 'MMM-yyyy').startOf('month');
    const endDate = DateTime.fromFormat(endLabel, 'MMM-yyyy').endOf('month');


    return submissions.filter((submission) => {
        return submission.reporting_period.some((dateStr) => {
            const date = DateTime.fromFormat(dateStr, 'MM-yyyy');
            return date >= startDate && date <= endDate;
        });
    });
}
function getMonthsBetween(start_date, end_date, frequency, filterYear, fymonth) {
    let fyStartDate, fyEndDate;

    if (filterYear === 0) {
        // No fiscal year filtering
        fyStartDate = start_date ? DateTime.fromISO(start_date, { zone: "utc" }).toLocal() : null;
        fyEndDate = end_date ? DateTime.fromISO(end_date, { zone: "utc" }).toLocal() : DateTime.utc().toLocal()
    } else {
        // Set fiscal year boundaries based on filterYear and fymonth
        if (fymonth === 1) {
            // Calendar year: Jan to Dec of the filterYear
            fyStartDate = DateTime.fromObject({ year: filterYear, month: 1, day: 1 });
            fyEndDate = DateTime.fromObject({ year: filterYear, month: 12, day: 31 });
        } else {
            // Fiscal year: Apr (fymonth) of the previous year to Mar (fymonth - 1) of the filterYear
            fyStartDate = DateTime.fromObject({
                year: filterYear - 1,
                month: fymonth,
                day: 1,
            });
            fyEndDate = DateTime.fromObject({
                year: filterYear,
                month: fymonth - 1,
                day: 1,
            }).endOf("month");
        }
    }

    // Use assignment start and end dates
    const userStartDate = start_date
        ? DateTime.fromISO(start_date, { zone: "utc" }).toLocal()
        : fyStartDate;
    const userEndDate = end_date
        ? DateTime.fromISO(end_date, { zone: "utc" }).toLocal()
        : fyEndDate;

    // Adjusted final start and end dates
    const finalStartDate =
        filterYear !== 0 && userStartDate < fyStartDate ? fyStartDate : userStartDate;
    const finalEndDate =
        filterYear !== 0 && userEndDate > fyEndDate ? fyEndDate : userEndDate;

    const months = [];
    let currentMonth = userStartDate;  // Start from the actual assignment start date

    // Generate reporting periods from the assignment's start date, within the fiscal year boundaries
    while (currentMonth <= finalEndDate) {
        const periodEnd = currentMonth.plus({ months: frequency - 1 });

        // Only include the period if it falls within the fiscal year range
        if (periodEnd >= finalStartDate && periodEnd <= finalEndDate) {
            if (frequency === 1) {
                months.push(currentMonth.toFormat("LLL-yyyy"));
            } else {
                months.push(
                    currentMonth.toFormat("LLL-yyyy") +
                    " to " +
                    periodEnd.toFormat("LLL-yyyy")
                );
            }
        }

        // Move to the next period based on frequency
        currentMonth = currentMonth.plus({ months: frequency });
    }

    return months;
}

function tempEFCalcForSap(dataArray) {
    let ef = [
        {

            UOM: 'KG',
            Fuel: 'LPG',
            conversion: (val) => { return parseFloat((((val / 1000) * 2939.36) / 1000).toFixed(2)) },
            'Emission factor': 2939.36,

        },
        {

            UOM: 'KG',
            Fuel: 'Propane',
            conversion: (val) => { return parseFloat((((val / 1000) * 2997.63) / 1000).toFixed(2)) },

            'Emission factor': 2997.63,

        },
        {

            UOM: 'L',
            Fuel: 'HSD',
            conversion: (val) => { return parseFloat(((val * 2.66) / 1000).toFixed(2)) },

            'Emission factor': 2.66,

        },
        {

            UOM: 'L',
            Fuel: 'Furnace oil / HFO',
            conversion: (val) => { return parseFloat(((val * 3.17) / 1000).toFixed(2)) },

            'Emission factor': 3.17,

        }
    ]
    for (const item of dataArray) {
        let index = ef.find((i) => item.FuelType === i.Fuel && item.UoM === i.UOM)
        if (index) {
            item.emission = index.conversion(item.Quantity)
        } else {
            item.emission = 0
        }
    }
    return dataArray
}
function summationOfKeys_Values(data, keysToRetain, keysToSum) {
    const result = {};

    data.forEach(item => {
        // Extract the retained key values to create a unique identifier
        const retainKey = keysToRetain.map(key => item[key]).join('_');

        if (!result[retainKey]) {
            // Initialize a new object with retained keys and summable keys
            result[retainKey] = { ...Object.fromEntries(keysToRetain.map(key => [key, item[key]])) };

            keysToSum.forEach(key => {
                result[retainKey][key] = 0; // Initialize summable keys
            });
        }

        // Sum the values for the summable keys
        keysToSum.forEach(key => {
            result[retainKey][key] = parseFloat(
                (result[retainKey][key] + (item[key] || 0)).toFixed(2)
            )
        });
    });

    // Convert the result object back to an array
    return Object.values(result);
}
const adjustColumnWidths = (worksheet) => {
    const colWidths = [];

    // Calculate maximum width for each column
    for (const [key, value] of Object.entries(worksheet)) {
        if (key[0] === '!') continue;

        const col = XLSX.utils.decode_cell(key).c;

        const cellValue = value.v != null ? value.v.toString() : '';
        const cellWidth = cellValue.length;

        if (!colWidths[col] || colWidths[col] < cellWidth) {
            colWidths[col] = cellWidth;
        }
    }

    // Apply calculated widths to worksheet columns
    worksheet['!cols'] = colWidths.map(width => ({ width: width + 2 })); // Add some padding
};
export { getLocationData, checkRoleAccessByRoleIds,filterDataByTierAndLocationByTierId, getFiscalYearsFromStartDate, getRPTextFormat, filterSubmissionsByFiscalYear, filterDataByTierAndLocationByLevel, filterAssignmentsByFiscalYear, groupArrayByKeys, removeDuplicatesFromArrayByKey, generateFiscalYearsByAssignment, getReportingFiscalYearByReportingperiod, generateFiscalQuarters, filterSubmissionsByGivenPeriod, getMonthsBetween, summationOfKeys_Values, tempEFCalcForSap, adjustColumnWidths }