import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";

const data = [
  { trainingArea: "Leadership", male: 40, female: 30 },
  { trainingArea: "Technical Skills", male: 35, female: 25 },
  { trainingArea: "Soft Skills", male: 50, female: 45 },
  { trainingArea: "Management", male: 60, female: 50 },
  { trainingArea: "Compliance", male: 30, female: 20 },
];

const TrainingCoverageByGender = () => {
  return (
    <div>
      <h3 style={{ margin: "25px", fontSize: "18px" }}>
        Training Coverage by Gender
      </h3>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="trainingArea">
            <Label value="Training Areas" offset={30} position="bottom" />
          </XAxis>
          <YAxis>
            <Label
              value="Training Hours"
              angle={-90}
              position="center"
              style={{ textAnchor: "middle" }}
            />
          </YAxis>
          <Tooltip />
          <Legend />
          <Bar dataKey="male" stackId="a" fill="#3498db" barSize={60} />
          <Bar dataKey="female" stackId="a" fill="#e74c3c" barSize={60} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TrainingCoverageByGender;
