import React from "react";
import GaugeChart from "react-gauge-chart";

const dummyData = {
  totalEmployees: 1200, // Total number of employees
  coveredEmployees: 960, // Number of employees covered under OHS
};

const OHSManagementCoverageGauge = () => {
  const progress = 100;

  return (
    <div style={{ fontFamily: "Lato" }}>
      {/* Gauge Chart */}
      <div
        style={{
          textAlign: "center",
          marginBottom: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <GaugeChart
          id="ohs-coverage-gauge"
          nrOfLevels={30}
          arcsLength={[0.2, 0.3, 0.5]} // Define color zones
          colors={["#e74c3c", "#f39c12", "#16a085"]} // Red, Yellow, Green
          percent={progress / 100} // Progress as a percentage (0-1 scale)
          needleColor="#000000"
          arcPadding={0.02}
          textColor="#000000"
          style={{ width: 600, height: 355 }} // Adjusted size
        />

        {/* Label for the gauge */}
        <div
          style={{
            marginTop: "10px",
            fontSize: "16px",
            fontWeight: "bold",
            color:
              progress >= 70
                ? "#16a085" // Green for high coverage
                : progress >= 40
                ? "#f39c12" // Yellow for moderate coverage
                : "#e74c3c", // Red for low coverage
          }}
        >
          Percentage of Employees Covered: {progress.toFixed(2)}%
        </div>
      </div>
    </div>
  );
};

export default OHSManagementCoverageGauge;
