import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import supplyData from '../supply.json';
// Sample colors for MSI ratings
const COLORS = {
    PLATINUM: '#003f5c',
    GOLD: '#ff7f0e',
    SILVER: '#2ca02c',
    BRONZE: '#1f77b4',
    'Not Rated': '#9467bd'
};

// Function to fetch and process data from supply.json
const fetchData = async () => {
    try {
         // Adjust path if needed
        const rawData = supplyData;

        // Count occurrences of each MSI rating
        const ratingCounts = rawData.reduce((acc, item) => {
            acc[item.msi_rating] = (acc[item.msi_rating] || 0) + 1;
            return acc;
        }, {});

        // Convert to Recharts-friendly format
        return Object.keys(ratingCounts).map(rating => ({
            name: rating,
            value: ratingCounts[rating]
        }));
    } catch (error) {
        console.error('Error fetching data:', error);
        return [];
    }
};

const SupplierMSIRatingsChart = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        fetchData().then(setData);
    }, []);

    return (
        <div className="container mt-4">
            <h5 className="text-center text-danger mb-4">Distribution of Suppliers MSI Ratings</h5>
            <ResponsiveContainer width="100%" height={400}>
                <PieChart>
                    <Pie
                        data={data}
                        cx="50%"
                        cy="50%"
                        outerRadius={150}
                        fill="#8884d8"
                        dataKey="value"
                        label
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[entry.name] || '#cccccc'} />
                        ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
};

export default SupplierMSIRatingsChart;
