import React, { useState, useEffect } from "react";
import "../reports/toc_style.css";
import { useHeadsObserver } from "./hooks";
import * as XLSX from "xlsx";
import useForceUpdate from "use-force-update";
import $ from "jquery";
import Axios from "axios";
import { API } from "../../constants/api_url";
import { useSelector } from "react-redux";
import { Button } from "primereact/button";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import { saveAs } from 'file-saver';
import moment from "moment";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import logo from '../../assets/images/eisqr_logo.png'
import ImageToDataUrl from "../../components/imagetodataurl";
import APIServices from "../../service/APIService";

// pdfMake.vfs = pdfFonts.pdfMake.vfs;
// pdfMake.fonts = {
//     Roboto: {
//         normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
//         bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
//         italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
//         bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
//       }
// }
const { DateTime } = require('luxon');

window.jQuery = $;
window.$ = $;

const Djsi = () => {
    const [headings, setHeadings] = useState([]);
    const { activeId } = useHeadsObserver();
    const [tableData, setTableData] = useState([]);
    const [workbook, setWorkbook] = useState(null);
    const [firstSheet, setFirstSheet] = useState(null);
    const [year, setYear] = useState(2022);
    const [rfData, setRFData] = useState({});
    const admin_data = useSelector((state) => state.user.admin_detail);
    const emissionFactor = useSelector((state) => state.emissionfactor.emissionFactor)
    const locationList = useSelector(state => state.sitelist.locationList)
    const siteList = useSelector((state) => state.sitelist.siteList)
    const clientlogo = useSelector((state) => state.user.companylogo)

    const [dcflist, setDcfList] = useState([])
    const [response, setResponse] = useState([])
    const [report, setReport] = useState({})
    const [dpreport, setDpReport] = useState([])
    let months_ = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const forceUpdate = useForceUpdate();


    async function exportTable2Excel(id) {
        let initialData = [{
            alignment: 'center', // Center the text horizontally
            margin: [0, 250], // Adjust the top margin to vertically center the text
            text: [
                { text: 'BRSR Report' + '\n', fontSize: 30, bold: true },
                { text: 'FY ' + year + ' - ' + (year + 1).toString().substr(2, 3) + '\n', fontSize: 20 },
                { text: DateTime.local().toFormat('MMMM dd, yyyy'), fontSize: 20, color: 'blue' }, // Customize the font size and color
            ],
            pageBreak: 'after'
        },
        {
            toc: {
                id: 'sectionHeader',
                title: { text: 'Table of Content', style: 'tdHead' },

            }
        }], data = []
        const div = document.getElementById('main')
        for (let i = 0; i < div.children.length; i++) {

            if (div.childNodes[i].tagName.toLowerCase() === 'sectionheader') {
                if (div.childNodes[i].id !== 'secprin1') {
                    data.push(
                        {
                            text: '', // Empty text
                            pageBreak: 'before',


                        })
                } else if (div.childNodes[i].id === 'secprin1') {
                    data.push(
                        {
                            text: '', // Empty text

                            margin: [0, 10], // Adjust the margin for horizontal space

                        })
                }

                data.push({
                    table: {

                        widths: ['*'],
                        body: [
                            [{ tocItem: 'sectionHeader', text: div.childNodes[i].textContent, style: 'secHead', border: [false, false, false, false] }],
                        ],
                    }
                })
            } else if (div.childNodes[i].tagName.toLowerCase() === 'sectionheader1') {
                for (let child = 0; child <= div.childNodes[i].children.length; child++) {
                    console.log(div.childNodes[i].id === 'secai', child)
                    if (div.childNodes[i].childNodes[child].tagName) {
                        let content = generatePdfMakeContentFromTable(div.childNodes[i].childNodes[child].childNodes[0])

                        data.push({
                            table: {

                                widths: JSON.parse(JSON.stringify(content))[0].map((a, b) => { return b == 0 ? "*" : 'auto' }),
                                body: content,
                                style: 'tableStyle'
                            }
                        })

                        data.push(
                            {
                                text: '', // Empty text

                                margin: [0, 10], // Adjust the margin for horizontal space

                            })


                    } else {
                        console.log(div.childNodes[i].childNodes[child])
                    }
                }
            }
        }

        data.forEach((k) => {
            if (k.table) {
                if (!haveSameSubarrayLengths(k.table.body)) {
                    console.log(k.table.body)
                }
            }
        })

        let images = {
            clientlogo: document.getElementById('clientlogo').src
        }
        const header = (currentPage, pageCount, pageSize) => {
            if (currentPage !== 1) {
                return {
                    columns: [
                        {
                            text: `BRSR Report FY` + year.toString().substr(2, 3),
                            style: 'headerText',
                            margin: [10, 20],
                            fit: [40, 40],
                            alignment: 'left'
                        },
                        {
                            image: 'clientlogo',
                            fit: [40, 40],
                            margin: [0, 5, 5, 0],
                            alignment: 'right'
                        },
                    ],
                    // Add margins to the header
                }

            }
        }
        const documentDefinition = {
            info: {
                title: 'BRSR Report as of ' + DateTime.local().toFormat('MMMM dd, yyyy'),
                author: 'Navigos',
                subject: 'BRSR Report',
                keywords: 'Dont share unless people within same organization',
                producer: 'EiSqr',


            },
            // userPassword: 'Report@',
            // ownerPassword: '123456',
            permissions: {
                printing: 'highResolution', //'lowResolution'
                modifying: false,
                copying: false,
                annotating: true,
                fillingForms: true,
                contentAccessibility: true,
                documentAssembly: true
            },
            pageSize: 'A4',
            pageMargins: [30, 70, 30, 30], // [left, top, right, bottom] margins
            header,
            footer: function (currentPage, pageCount) {
                return {
                    text: 'Page ' + currentPage + ' of ' + pageCount,
                    alignment: 'center', fontSize: 8
                };
            },
            content: [...initialData, ...data],
            // defaultStyle: {
            //     font: 'Roboto'
            //   },

            images,
            styles: {

                tdHead: {
                    bold: true, alignment: 'center', valign: 'middle', fillColor: 'lightgray'
                },

                secHead: {
                    bold: true, fillColor: 'lightgray', alignment: 'center', padding: [10, 10]
                },
                headerText: {
                    fontSize: 14,
                    bold: true,
                    color: 'black', // Text color
                },
                'text-start': {
                    alignment: 'center'
                },
            },
        };
        console.log(documentDefinition)
        const pdf = pdfMake.createPdf(documentDefinition);
        pdf.download('BRSR_Report.pdf');


    }
    function haveSameSubarrayLengths(data) {
        if (data.length < 2) {
            // If there are fewer than 2 subarrays, they are considered to have the same length.
            return true;
        }

        const firstSubarrayLength = data[0].length;

        for (let i = 1; i < data.length; i++) {
            if (data[i].length !== firstSubarrayLength) {
                return false;
            }
        }

        return true;
    }
    function generatePdfMakeContentFromTable(table) {
        console.log(table)
        if (!table) {
            console.error(`Table  not found.`);
            return [];
        }

        let contentArray = [], maxCol = 0
        for (let i = 0; i < table.rows.length; i++) {
            if (maxCol <= table.rows[i].cells.length) {
                maxCol = table.rows[i].cells.length
            }
        }

        for (let i = 0; i < table.rows.length; i++) {
            const rowArray = [];

            for (let j = 0; j < table.rows[i].cells.length; j++) {
                const cell = table.rows[i].cells[j];
                const colSpan = cell.getAttribute('colspan');
                const rowSpan = cell.getAttribute('rowspan');
                const cellText = cell.textContent.trim();

                const cellObject = { text: cellText };
                cellObject.colSpan = parseInt(colSpan)
                cellObject.rowSpan = parseInt(rowSpan)
                cellObject.style = cell.getAttribute('class');
                rowArray.push(cellObject);
                if (parseInt(colSpan) > 1) {
                    console.log(rowArray)
                    for (let j = 0; j < parseInt(colSpan) - 1; j++) {
                        rowArray.push({});
                    }

                }

            }

            contentArray.push(rowArray);


        }
        contentArray.forEach((i, index) => {
            if (i.length !== maxCol) {
                console.log(i)
                if (contentArray[index - 1]) {
                    contentArray[index - 1].forEach((k, ind) => {
                        if (k.rowSpan) {
                            if (k.rowSpan > 1) {
                                if (k.colSpan === 1) {
                                    i.splice(ind, 0, { text: '', colSpan: k.colSpan, rowSpan: k.rowSpan - 1 })
                                } else {
                                    let newind = ind
                                    for (let j = 0; j < parseInt(k.colSpan); j++) {
                                        i.splice(newind, 0, {})
                                        newind++
                                    }
                                }
                            }
                        } else {
                            for (let j = 0; j < (maxCol - Object.keys(i).length); j++) {
                                console.log(i)
                                // i.push({id:1});
                            }
                        }
                    })
                }
            }
        })
        contentArray.forEach((i, index) => {
            if (i.length !== maxCol) {
                let len = Object.keys(i).length
                for (let j = 0; j < (maxCol - len); j++) {

                    i.push({});
                }

            }
        })
        return contentArray;
    }

    const getSum = (subset) => {
        let i = 0
        subset.forEach((item) => {
            i = i + item[1]
        })
        return i
    }
    const checkReportingPeriod = (rp, filter) => {

        let count = 0, rps = []
        filter.forEach((item) => {

            if (rp.includes(item)) {
                count = count + 1
                rps.push(item)
            }
        })
        return { result: count === rp.length, count: count, rps }
    }
    const getCellColSpan = (rowIndex, colIndex) => {
        const merge = workbook.Sheets[firstSheet]["!merges"] || [];
        for (const range of merge) {
            if (rowIndex >= range.s.r && rowIndex <= range.e.r && colIndex >= range.s.c && colIndex <= range.e.c) {
                return range.e.c - range.s.c + 1;
            }
        }
        return 1;
    };

    const getCellRowSpan = (rowIndex, colIndex) => {
        const merge = workbook.Sheets[firstSheet]["!merges"] || [];
        for (const range of merge) {
            if (rowIndex >= range.s.r && rowIndex <= range.e.r && colIndex >= range.s.c && colIndex <= range.e.c) {
                return range.e.r - range.s.r + 1;
            }
        }
        return 1;
    };
    function findValueByKey(object, key) {
        if (key in object) {
            return object[key];
        } else {
            return null; // or any other default value you want to return if the key is not found
        }
    }
    const getLastResponseByRFID = (key, uid) => {
        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, key);
        if (result) {
            if (result[0].type === 0) {
                if (typeof uid === "string") {
                    let index = result[0].response.findIndex((k) => {
                        return k.name === uid;
                    });
                    if (index !== -1) {
                        return result[0].response[index].value;
                    } else {
                        return null;
                    }
                } else {
                    let str = "";
                    uid.forEach((id) => {
                        let index = result[0].response.findIndex((k) => {
                            return k.name === id;
                        });
                        if (index !== -1) {
                            str = str + " " + result[0].response[index].value;
                        }
                    });
                    if (str.trim().length !== 0) {
                        return str;
                    } else {
                        return null;
                    }
                }
            } else {
                return result[0].data2;
            }
        }
        return "";
    };
    const updateDataByYear = (val) => {
        setYear(val);
        forceUpdate();
    };

    useEffect(() => {
        const elements = Array.from(document.querySelectorAll("sectionheader,sectionheader1,sectionheader2")).map((elem) => ({
            id: elem.id,
            text: elem.childNodes[0].textContent.trim(),
            level: Number(elem.nodeName.charAt(13)),
        }));

        let as = [],
            indx = 0;
        elements.forEach((item, ind) => {
            if (item.level === 0) {
                as[indx] = item;
                indx = indx + 1;
            } else if (elements[ind - 1].level === 0) {
                as[indx] = { item: [item], level: 1 };
                if (elements[ind + 1] !== undefined && elements[ind + 1].level === 0) {
                    indx = indx + 1;
                }
            } else {
                as[indx].item.push(item);
                if (elements[ind + 1] !== undefined && elements[ind + 1].level === 0) {
                    indx = indx + 1;
                }
            }
        });

        setHeadings(as);
    }, []);
    const groupArrayObject = (array, obj) => {
        return array.reduce(
            (group, arr) => {
                let key = arr[obj];

                group[key] = group[key] ?? [];

                group[key].push(arr);

                return group;
            },

            {}
        );
    };


    const getScopeData = (year, area) => {
        if (report[year]) {
            return report[year].filter((k) => { return k.scope === area }).map((j) => { return j.ghg }).reduce((a, b) => { return a + b }, 0).toFixed(2)

        }
        return 0
    }
    const checkYear = (rp, yr) => {
        let betweenMonths = []



        let endDate = moment.utc(getDateObjectByMonth_Year(2, yr + 1)).local()
        let startDate = moment.utc(getDateObjectByMonth_Year(2, yr)).local()
        while (startDate.startOf('month') <= endDate.startOf('month')) {

            betweenMonths.push(startDate.format('MM-YYYY'));
            startDate.add(1, 'month');

        }
        return betweenMonths.filter((i) => { return rp.includes(i) }).length === rp.length ? 1 : betweenMonths.filter((i) => { return rp.includes(i) }).length

    }
    const getDataByDP = (dpid, yr) => {
        let dpreport_ = JSON.parse(JSON.stringify(dpreport))
        let d = 0

        dpreport_.filter((i) => { return i.dpId === dpid }).forEach((k) => {


            if (checkYear(k.rp, yr) !== 0) {
                d = d + k.value
            }

        })
        console.log(d)
        return d
    }
    const getDataByDCFDPID = (dcfid, dpid, yr) => {
        let response_ = JSON.parse(JSON.stringify(response))
        let d = 0

        response_.forEach((k) => {
            if (k.dcf === dcfid) {

                if (checkYear(k.rp, yr) !== 0) {
                    let result = k.response.filter((k) => { return k.name === dpid })
                    if (result.length !== 0) {
                        d = d + parseInt(result[0].value.match(/\d+/)[0])
                    }
                }
            }
        })
        console.log(d)
        return d
    }

    function getDateObjectByMonth_Year(month, year) {

        if (isNaN(month) || isNaN(year)) {
            throw new Error('Invalid month or year');
        }

        const normalizedMonth = Math.max(1, Math.min(12, month));

        const date = DateTime.fromObject({ year, month: normalizedMonth, day: 1 });

        return date.toJSDate();
    }
    const getRP_ = (rp) => {
        if (rp.length === 1) {

            return months_[parseInt(rp[0].split('-')[0]) - 1] + "-" + rp[0].split('-')[1].slice(-2)
        } else {
            return months_[parseInt(rp[0].split('-')[0]) - 1] + "-" + rp[0].split('-')[1].slice(-2) + "to" + months_[parseInt(rp[rp.length - 1].split('-')[0]) - 1] + "-" + rp[rp.length - 1].split('-')[1].slice(-2)
        }
    }
    const getClassName = (level) => {
        switch (level) {
            case 1:
                return "head1";
            case 2:
                return "head2";
            case 3:
                return "head3";
            default:
                return null;
        }
    };

    const checkSite = (id, filter) => {
        let idlist = []

        siteList.forEach((country) => {

            if (filter.a.id === 0 || filter.a.id === country.id) {

                country.locationTwos.forEach((city) => {
                    if (filter.b.id === 0 || filter.b.id === city.id) {
                        city.locationThrees.forEach((loc) => {
                            if (filter.c.id == 0 || filter.c.id === loc.id) {
                                idlist.push(loc.id)
                            }
                        })
                    }
                })
            }
        })

        return idlist.includes(id)
    }

    const renderData = (search) => {
        let betweenMonths = [], betweenYears = []
        let monthly_fg = []


        let endDate = moment.utc(search.to).local()
        let startDate = moment.utc(search.from).local()
        let year = moment(moment.utc()).format('YYYY')


        if (search.to !== null && search.from !== null) {
            while (startDate.startOf('month') <= endDate.startOf('month')) {

                betweenMonths.push(startDate.format('MM-YYYY'));
                !betweenYears.includes(startDate.format('YYYY')) && betweenYears.push(startDate.format('YYYY'))

                startDate.add(1, 'month');

            }


            let res = JSON.parse(JSON.stringify(response))

            res.forEach((report, rind) => {
                let sc_total = 0, fg_total = 0

                if (checkSite(report.site, search.location)) {
                    report.sitename = locationList.filter((loc) => { return loc.id === report.site })[0].name
                    if (report.dcf === 16 && (search.indicator.id === 0 || search.indicator.id === 122)) {


                        report.response.forEach((fg, ind) => {

                            let date = fg.filter((i) => { return i.dpId === "DPA0285" })[0].value
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


                            if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {


                                let item_class = fg.filter((i) => { return i.dpId === "DPA0287" })[0].value.name
                                let item_qty = fg.filter((i) => { return i.dpId === "DPA0288" })[0].value
                                let price_per_item = fg.filter((i) => { return i.dpId === "DPA0289" })[0].value

                                let total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[0].data1[0].importedData.filter((k) => { return k.item_classification === item_class })[0]['co2e_in_kg'] / 1000) * item_qty * price_per_item

                                fg_total = total + fg_total


                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.item_class === item_class })

                                if (updateind === -1) {

                                    monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, item_class: item_class })

                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                }

                            }
                        })

                    }
                    if (report.dcf === 36 && (search.indicator.id === 0 || search.indicator.id === 123)) {


                        report.response.forEach((fg, ind) => {

                            let date = fg.filter((i) => { return i.dpId === "DPA0290" })[0].value
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


                            if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {




                                let total = 0


                                let travel_mode = fg.filter((i) => { return i.dpId === "DPA0291" })[0].value.name
                                let passenger = fg.filter((i) => { return i.dpId === "DPA0292" })[0].value

                                if (travel_mode.toLowerCase() === 'air') {

                                    total = fg.filter((i) => { return i.dpId === "DP_co2e_mt" })[0].value * passenger

                                    let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

                                    if (updateind === -1) {

                                        monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



                                    } else {

                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                    }

                                    fg_total = total + fg_total
                                } else if (travel_mode.toLowerCase() === 'road') {

                                    let veh_cat = fg.filter((i) => { return i.dpId === "DPA0295" })[0].value.name
                                    let veh_type = fg.filter((i) => { return i.dpId === "DPA0337" })[0].value
                                    let fuel = fg.filter((i) => { return i.dpId === "DPA0338" })[0].value
                                    let km = fg.filter((i) => { return i.dpId === "DP_KM" })[0].value
                                    if (veh_cat.includes('Cars') || veh_cat.includes('Motor')) {
                                        if (veh_cat.includes('Cars')) {
                                            total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.fuel_type === fuel.name && k.unit === 'km' })[0]['co2e_in_kg'] / 1000) * km * passenger

                                        } else {
                                            total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.unit === 'km' })[0]['co2e_in_kg'] / 1000) * km * passenger

                                        }

                                    } else {


                                        total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.unit === 'passenger.km' })[0]['co2e_in_kg'] / 1000) * km * passenger


                                    }


                                    let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

                                    if (updateind === -1) {

                                        monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



                                    } else {

                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                    }

                                    fg_total = total + fg_total


                                } else if (travel_mode.toLowerCase() === 'rail') {



                                    total = fg.filter((i) => { return i.dpId === "DP_KM" })[0].value * passenger * 0.00116

                                    let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

                                    if (updateind === -1) {

                                        monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



                                    } else {

                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                    }

                                    fg_total = total + fg_total
                                }





                            }
                        })

                    }

                    if (report.dcf === 11 && (search.indicator.id === 0 || search.indicator.id === 93)) {

                        let addedMonth = checkReportingPeriod(report.rp, betweenMonths)

                        let total = 0, co2 = 0, ch4 = 0, n2o = 0, sc_data = []
                        report.response.forEach((fg, ind) => {

                            let fuel_cat = fg.filter((i) => { return i.dpId === "DPA0130" })[0].value.name
                            let fuel_type = fg.filter((i) => { return i.dpId === "DPA0131" })[0].value.name
                            let unit = fg.filter((i) => { return i.dpId === "DPA0132" })[0].value.name
                            let consumed = fg.filter((i) => { return i.dpId === "DPA0336" })[0].value
                            let fuel_cat_ind = sc_data.findIndex((k) => { return k.fuel_cat === fuel_cat })

                            if (fuel_cat === "Solid Fuels" || fuel_cat === "Biomass") {
                                let carbon = fg.filter((i) => { return i.dpId === "DPA0134" })[0].value
                                let cv = fg.filter((i) => { return i.dpId === "DPA0133" })[0].value
                                if (carbon > 0 && cv > 0) {
                                    let gj = (carbon * 3.664 * 1000) / cv
                                    total = (gj * 0.000004184 * consumed) / 1000
                                    fg['value'] = ['Emission Factor-', 3.664, ' ,EnergyProduced-', 0.000004184]
                                    co2 = 0
                                    ch4 = 0
                                    n2o = 0
                                    if (fuel_cat_ind === -1) {
                                        sc_data.push({ fuel_cat: fuel_cat, ghg: total })
                                    } else {
                                        sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total

                                    }

                                } else {
                                    total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2e_in_kg'] / 1000) * consumed
                                    co2 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2_in_kg'] / 1000) * consumed
                                    ch4 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['ch4_in_kg'] / 1000) * consumed
                                    n2o = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['n2o_in_kg'] / 1000) * consumed
                                    fg['value'] = ['co2-', co2 / consumed, ' ,ch4-', ch4 / consumed, ' ,n2o-', n2o / consumed]
                                    if (fuel_cat_ind === -1) {
                                        sc_data.push({ fuel_cat: fuel_cat, ghg: total })
                                    } else {
                                        sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total

                                    }
                                }

                            } else {
                                total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2e_in_kg'] / 1000) * consumed
                                co2 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2_in_kg'] / 1000) * consumed
                                ch4 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['ch4_in_kg'] / 1000) * consumed
                                n2o = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['n2o_in_kg'] / 1000) * consumed
                                fg['value'] = ['co2-', co2 / consumed, ' ,ch4-', ch4 / consumed, ' ,n2o-', n2o / consumed]
                                if (fuel_cat_ind === -1) {
                                    sc_data.push({ fuel_cat: fuel_cat, ghg: total })
                                } else {
                                    sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total

                                }
                            }


                        })
                        addedMonth.rps.forEach((fm) => {
                            let date = moment(fm, 'MM-YYYY').toDate()
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })

                            sc_data.forEach((item) => {
                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.fuel_cat === item.fuel_cat })

                                if (updateind === -1) {

                                    monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: (item.ghg / addedMonth.count), dcf: report.dcf, site: report.site, fuel_cat: item.fuel_cat })


                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + (item.ghg / addedMonth.count)
                                }
                            })


                        })




                    }
                    if (report.dcf === 10 && (search.indicator.id === 0 || search.indicator.id === 116)) {
                        let add = []

                        report.response.forEach((fg, ind) => {

                            let date = fg.filter((i) => { return i.dpId === "DPA0137" })[0].value
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


                            if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {






                                let gastype = fg.filter((i) => { return i.dpId === "DPA0136" })[0].value.name
                                let gasrefilled = fg.filter((i) => { return i.dpId === "DPA0138" })[0].value
                                let total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[2].data1[0].importedData.filter((k) => { return k.gas_type === gastype })[0]['co2e_in_kg'] / 1000) * gasrefilled

                                fg_total = total + fg_total

                                fg['gas'] = gastype
                                fg['gasfilled'] = gasrefilled
                                fg['value'] = ['EmissionFactor-', total / gasrefilled]
                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.gastype === gastype })

                                if (updateind === -1) {

                                    monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: 0, dcf: report.dcf, site: report.site, gastype: gastype, ghg: total })



                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                }

                            }
                        })

                    }
                    if (report.dcf === 72 && (search.indicator.id === 0 || search.indicator.id === 121)) {

                        report.rp.forEach((i) => {
                            if (checkReportingPeriod([i], betweenMonths).result) {
                                let yearind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') })
                                let monthind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') })
                                let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') && i.dcf === report.dcf })
                                let siteind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })

                                let ef = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[1].stdTopics[0].data1[0].importedData[0]['kwh_in_tco2e']

                                let renewable = report.response[0].filter((i) => { return i.dpId === "DPA0156" })[0].value / report.rp.length
                                let nonrenewable = (report.response[0].filter((i) => { return i.dpId === "DPA0157" })[0].value / report.rp.length)

                                if (yearind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })

                                } else if (monthind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })
                                } else if (dcfind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })

                                } else if (siteind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })

                                }
                            }
                        })

                    }
                    if (report.dcf === 15 && (search.indicator.id === 0 || search.indicator.id === 118)) {

                        let addedMonth = checkReportingPeriod(report.rp, betweenMonths)


                        let ef_by_fuel = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[4].data1[0].importedData
                        let ef_by_distance = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[1].data1[0].importedData

                        let total = 0, co2 = 0, ch4 = 0, n2o = 0, mc_data = []

                        report.response.forEach((fg, ind) => {
                            let mode = fg.filter((i) => { return i.dpId === "DP_MODE" })[0].value ? 'by distance' : 'by fuel'
                            let fuel_cat = fg.filter((i) => { return i.dpId === "DPA0140" })[0].value
                            let fuel_type = fg.filter((i) => { return i.dpId === "DPA0139" })[0].value
                            let fuel = fg.filter((i) => { return i.dpId === "DPA0141" })[0].value.name
                            let unit = fg.filter((i) => { return i.dpId === "DPA0339" })[0].value
                            let km = fg.filter((i) => { return i.dpId === "DPA0144" })[0].value
                            let fuel_filled = fg.filter((i) => { return i.dpId === "DPA0143" })[0].value
                            let fuel_cat_ind = mc_data.findIndex((k) => { return k.mode === mode })
                            if (mode === 'by distance') {

                                total = ef_by_distance.filter((k) => { return k.vehicle_category === fuel_cat.name && k.vehicle_type === fuel_type.name && k.fuel_type === fuel && k.unit === unit.name })[0]['co2e_in_kg']

                                if (fuel_cat_ind === -1) {

                                    mc_data.push({ mode: mode, ghg: total })
                                } else {
                                    mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total

                                }
                            } else {
                                total = ef_by_fuel.filter((k) => { return k.fuel.trim() === fuel.trim() })[0]['co2e_in_kg']

                                if (fuel_cat_ind === -1) {

                                    mc_data.push({ mode: mode, ghg: total })
                                } else {
                                    mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total

                                }
                            }

                        })
                        addedMonth.rps.forEach((fm) => {
                            let date = moment(fm, 'MM-YYYY').toDate()

                            mc_data.forEach((item) => {
                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.mode === item.mode })

                                if (updateind === -1) {
                                    let updateind2 = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.mode === item.mode })
                                    if (updateind2 !== -1) {
                                        monthly_fg[updateind2].ghg = (item.ghg / addedMonth.count) + monthly_fg[updateind2].ghg
                                    } else {
                                        monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: 0, dcf: report.dcf, site: report.site, ghg: (item.ghg / addedMonth.count), mode: item.mode })
                                    }




                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + (item.ghg / addedMonth.count)
                                }
                            })


                        })




                    }

                }


            })





            let scope12_array = groupArrayObject(monthly_fg.filter((i) => { return (i.scope === 1 || i.scope === 2) }), 'month')
            let scope3_array = groupArrayObject(monthly_fg.filter((i) => { return (i.scope === 3) }), 'month')
            let scope1_array = groupArrayObject(monthly_fg.filter((i) => { return (i.scope === 1) }), 'month')
            let scope2_array = groupArrayObject(monthly_fg.filter((i) => { return (i.scope === 2) }), 'month')
            let pie = []
            monthly_fg.filter((i) => { return (i.scope === 1) }).forEach((item) => {
                let index = pie.findIndex((j) => { return j.name === 'Scope ' + item.scope })
                if (index === -1) {
                    pie.push({ name: 'Scope ' + item.scope, y: item.ghg })
                } else {
                    pie[index].y = pie[index].y + item.ghg
                }
            })
            monthly_fg.filter((i) => { return (i.scope === 2) }).forEach((item) => {
                let index = pie.findIndex((j) => { return j.name === 'Scope ' + item.scope })
                if (index === -1) {
                    pie.push({ name: 'Scope ' + item.scope, y: item.ghg })
                } else {
                    pie[index].y = pie[index].y + item.ghg
                }
            })
            monthly_fg.filter((i) => { return (i.scope === 3) }).forEach((item) => {
                let index = pie.findIndex((j) => { return j.name === 'Scope ' + item.scope })
                if (index === -1) {
                    pie.push({ name: 'Scope ' + item.scope, y: item.ghg })
                } else {
                    pie[index].y = pie[index].y + item.ghg
                }
            })

            let index1 = pie.findIndex((j) => { return j.name === 'Scope 1' })
            let index2 = pie.findIndex((j) => { return j.name === 'Scope 2' })
            let index3 = pie.findIndex((j) => { return j.name === 'Scope 3' })

            if (index1 === -1) {
                pie.push({ name: 'Scope 1', y: 0 })
            }
            if (index2 === -1) {
                pie.push({ name: 'Scope 2', y: 0 })
            }
            if (index3 === -1) {
                pie.push({ name: 'Scope 3', y: 0 })
            }


            if (scope1_array.length !== 0) {
                let scope1_tier2 = [], final = []
                Object.keys(scope1_array).forEach((key) => {
                    scope1_array[key].forEach((item) => {
                        item.dcfId = item.dcf
                        item.dcf = dcflist.filter((l) => { return l.id === item.dcf }).length === 0 ? item.dcf : dcflist.filter((l) => { return l.id === item.dcf })[0].title

                        if (
                            scope1_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            }) === -1
                        ) {

                            scope1_tier2.push({
                                name: item.dcf,
                                drilldown: true,
                                color: 'red',
                                y: item.ghg,

                                subset: [[key, item.ghg]],
                            });
                        } else {
                            let ind = scope1_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            });
                            let subind = scope1_tier2[ind].subset.findIndex((j) => {
                                return j[0] === key;
                            });
                            scope1_tier2[ind].y = scope1_tier2[ind].y + item.ghg;
                            if (subind === -1) {
                                scope1_tier2[ind].subset.push([key, item.ghg]);
                            } else {
                                scope1_tier2[ind].subset[subind][1] = scope1_tier2[ind].subset[subind][1] + item.ghg;
                            }
                        }
                    })
                })

                betweenYears.forEach((j) => {

                    let dataset = JSON.parse(JSON.stringify(scope1_tier2)).map((i) => { i.subset.sort((a, b) => { return moment(a[0], 'MMM-YYYY').toDate() - moment(b[0], 'MMM-YYYY').toDate() }); i.subset = i.subset.filter((k) => { return k[0].split('-')[1] === j }); i.y = i.subset.length === 0 ? 0 : getSum(i.subset); i.incomplete = false; return i })


                    final.push(dataset)
                })

            }
            if (scope2_array.length !== 0) {
                let scope2_tier2 = [], final = []
                Object.keys(scope2_array).forEach((key) => {
                    scope2_array[key].forEach((item) => {
                        item.dcfId = item.dcf
                        item.dcf = dcflist.filter((l) => { return l.id === item.dcf }).length === 0 ? item.dcf : dcflist.filter((l) => { return l.id === item.dcf })[0].title
                        if (
                            scope2_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            }) === -1
                        ) {
                            scope2_tier2.push({
                                name: item.dcf,
                                drilldown: true,
                                color: 'red',
                                y: item.ghg,
                                subset: [[key, item.ghg]],
                            });
                        } else {
                            let ind = scope2_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            });
                            let subind = scope2_tier2[ind].subset.findIndex((j) => {
                                return j[0] === key;
                            });
                            scope2_tier2[ind].y = scope2_tier2[ind].y + item.ghg;
                            if (subind === -1) {
                                scope2_tier2[ind].subset.push([key, item.ghg]);
                            } else {
                                scope2_tier2[ind].subset[subind][1] = scope2_tier2[ind].subset[subind][1] + item.ghg;
                            }
                        }
                    })
                })
                betweenYears.forEach((j) => {

                    let dataset = JSON.parse(JSON.stringify(scope2_tier2)).map((i) => { i.subset.sort((a, b) => { return moment(a[0], 'MMM-YYYY').toDate() - moment(b[0], 'MMM-YYYY').toDate() }); i.subset = i.subset.filter((k) => { return k[0].split('-')[1] === j }); i.y = i.subset.length === 0 ? 0 : getSum(i.subset); i.incomplete = false; return i })

                    final.push(dataset)
                })


            }
            if (scope3_array.length !== 0) {
                let scope3_tier2 = [], final = []
                Object.keys(scope3_array).forEach((key) => {
                    scope3_array[key].forEach((item) => {
                        item.dcfId = item.dcf
                        item.dcf = dcflist.filter((l) => { return l.id === item.dcf }).length === 0 ? item.dcf : dcflist.filter((l) => { return l.id === item.dcf })[0].title
                        if (
                            scope3_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            }) === -1
                        ) {
                            scope3_tier2.push({
                                name: item.dcf,
                                drilldown: true,
                                color: 'red',
                                y: item.ghg,
                                subset: [[key, item.ghg]],
                            });
                        } else {
                            let ind = scope3_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            });
                            let subind = scope3_tier2[ind].subset.findIndex((j) => {
                                return j[0] === key;
                            });
                            scope3_tier2[ind].y = scope3_tier2[ind].y + item.ghg;
                            if (subind === -1) {
                                scope3_tier2[ind].subset.push([key, item.ghg]);
                            } else {
                                scope3_tier2[ind].subset[subind][1] = scope3_tier2[ind].subset[subind][1] + item.ghg;
                            }
                        }
                    })
                })
                betweenYears.forEach((j) => {

                    let dataset = JSON.parse(JSON.stringify(scope3_tier2)).map((i) => { i.subset.sort((a, b) => { return moment(a[0], 'MMM-YYYY').toDate() - moment(b[0], 'MMM-YYYY').toDate() }); i.subset = i.subset.filter((k) => { return k[0].split('-')[1] === j }); i.y = i.subset.length === 0 ? 0 : getSum(i.subset); i.incomplete = false; return i })

                    final.push(dataset)
                })

            }



        }
        return monthly_fg


    }
    return (
        <div>
            <div className="col-12" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div className="col-3 p-card" style={{ margin: 5, height: "calc(100vh - 9rem)", overflow: "scroll" }}>
                    <nav>
                        {headings.map((heading, ind) => {
                            let indexes = [];
                            return (
                                <>
                                    {heading.level === 0 ? (
                                        <label key={heading.id} style={{ display: "flex", margin: 5, fontWeight: activeId === heading.id ? "bold" : "normal", textDecoration: heading.text.includes("SECTION") && "underline" }} className={getClassName(heading.level)}>
                                            <a
                                                href={`#${heading.id}`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    document.querySelector(`#${heading.id}`).scrollIntoView({
                                                        behavior: "smooth",
                                                        block: "start",
                                                        inline: "nearest",
                                                    });
                                                }}
                                                style={{
                                                    fontWeight: activeId === heading.id ? "bold" : "normal",
                                                }}
                                            >
                                                {heading.text}
                                            </a>
                                        </label>
                                    ) : (
                                        <ul>
                                            {heading.item.map((item, ind2) => {
                                                return (
                                                    <li key={item.id} className={getClassName(item.level)}>
                                                        <a
                                                            href={`#${item.id}`}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                document.querySelector(`#${item.id}`).scrollIntoView({
                                                                    behavior: "smooth",
                                                                });
                                                            }}
                                                            style={{
                                                                fontWeight: activeId === item.id ? "bold" : "normal",
                                                            }}
                                                        >
                                                            {item.text}
                                                        </a>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    )}
                                </>
                            );
                        })}
                    </nav>
                </div>
                <div className="col-9 p-card" style={{ margin: 5, height: "calc(100vh - 9rem)", overflow: "scroll", color: "white" }}>
                    {/* <div className="col-12" style={{ display: 'flex', justifyContent: 'flex-end' }} >
                        <Button style={{ marginRight: 20 }} onClick={() => { exportTable2Excel('pid2') }}> Export Report</Button>


                    </div> */}
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        {/* <img id='ass' src='https://mpower-s3.s3-ap-southeast-1.amazonaws.com/uploads/hazards/1690426362525Pushing%20and%20Pulling.png' width={'100%'} /> */}

                        <div>
                            <div className="col-12 grid" style={{ margin: 5, color: "white" }}>
                                <div>
                                    <label
                                        style={{
                                            color: "black",
                                            padding: 15,
                                            justifyContent: "flex-start",
                                            display: "flex",
                                        }}
                                    >
                                        Select Year :
                                    </label>
                                </div>

                                <div className="col-1">
                                    <label
                                        style={{
                                            background: year === 2022 ? "dodgerblue" : "grey",
                                            padding: "10px",
                                            cursor: "pointer",
                                            borderRadius: "10px",
                                            justifyContent: "center",
                                            display: "flex",
                                        }}
                                        onClick={() => {
                                            updateDataByYear(2022);
                                        }}
                                    >
                                        2022
                                    </label>
                                </div>
                                <div className="col-1">
                                    <label
                                        onClick={() => {
                                            updateDataByYear(2023);
                                        }}
                                        style={{
                                            background: year === 2023 ? "dodgerblue" : "grey",
                                            padding: "10px",
                                            cursor: "pointer",
                                            borderRadius: "10px",
                                            justifyContent: "center",
                                            display: "flex",
                                        }}
                                    >
                                        2023
                                    </label>
                                </div>
                            </div>
                            {/* <input type="file" onChange={handleFileChange} /> */}
                            {tableData.length > 0 && (
                                <div className="gridlines-container">
                                    <table className="gridlines">
                                        {/* <thead>
                                            <tr>
                                                {Object.keys(tableData[0]).map((field, index) => (
                                                    <th key={index} colSpan={getCellColSpan(0, index)}>
                                                        {field}
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead> */}
                                        <tbody>
                                            {tableData.map((row, rowIndex) => (
                                                <tr key={rowIndex}>
                                                    {Object.keys(row).map((cellRef, colIndex) => {
                                                        const cellValue = row[cellRef];
                                                        const colSpan = getCellColSpan(rowIndex + 1, colIndex); // Increment rowIndex to exclude header
                                                        const rowSpan = getCellRowSpan(rowIndex + 1, colIndex); // Increment rowIndex to exclude header
                                                        return (
                                                            <td key={colIndex} colSpan={colSpan} rowSpan={rowSpan} className={cellValue === "" && workbook.Sheets[firstSheet][cellRef]?.s?.b ? "empty-cell" : ""}>
                                                                {cellValue}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>

                        <div id='main' style={{ flexDirection: 'column', display: 'flex' }}>
                            <sectionheader id={"sectiona"} className="secHead">
                                Environmental Dimension
                            </sectionheader>

                            <sectionheader1 id={"secai"}>
                                2.1 Environmental Policy & Management Systems

                                <div className="secHead1">
                                    2.1 Environmental Policy & Management Systems
                                </div>
                            </sectionheader1>

                            <sectionheader1 id={"secai"}>
                                2.1.1 Environmental Policy & Commitments
                                <div className="secHead1">
                                    2.1.1 Environmental Policy & Commitments
                                </div>
                            </sectionheader1>

                            <div className="m-3 para" >
                                <span className="text-bold">   To ensure a successful implementation of a reliable and robust Environmental Management System (EMS), key organisational elements as well as high level commitments need to be defined in a public policy. Does TVS have a public environmental policy which covers the following elements of an Environmental Management System?   </span>
                            </div>


                            <div class="display-box "> </div>


                            <div className="display-box">
                                <label className="checkbox-label">
                                    <input type="radio" name="policy" value="yes" /> Yes, we have a public environmental policy, and it defines the following elements.
                                </label>

                                <div style={{ marginTop: '15px' }}>
                                    <p>Commitment and oversight to implementation of environmental management policy and/or improving environmental performance. Please select the highest committing decision-making body</p>
                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element1" value="element1" />  Board of directors
                                    </label>
                                    <br />
                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element2" value="element2" />  Executive management
                                    </label>
                                    <br />
                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element3" value="element3" />  Roles and responsibilities for implementing environmental management policy
                                    </label>
                                    <br />
                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element2" value="element2" /> Ensuring compliance with relevant environmental laws and regulations
                                    </label>
                                    <br />
                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element2" value="element2" /> Commitment to continuous improvement of environmental performance
                                    </label>
                                    <br />
                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element2" value="element2" /> Commitment to set targets and objectives to reduce environmental impacts
                                    </label>
                                    <br />
                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" />  We do not have a public environmental policy or none of the elements are covered.
                                    </label>
                                </div>


                                <div style={{ marginTop: '15px' }}>
                                    <label className="checkbox-label">
                                        <input type="radio" name="notApplicable" value="notApplicable" /> Not applicable. Please provide explanations in the comment box below.
                                    </label>
                                    <br />
                                    <textarea
                                        placeholder="Provide your explanation here..."
                                        style={{ marginTop: '10px', width: '100%', height: '80px', padding: '5px' }}
                                    ></textarea>
                                </div>
                            </div>

                            <sectionheader1 id={"secai"}>
                                2.1.2 Coverage of Environmental Management Policy
                                <div className="secHead1">
                                    2.1.2 Coverage of Environmental Management Policy
                                </div>
                            </sectionheader1>

                            <div className="display-box">
                                <div style={{ marginTop: '20px' }}>
                                    <p>Which parts of your operations, corporate processes and supply chain are covered by your environmental policy? All chosen options should be clearly defined in the publicly available policy (i.e. formal policies and not different sections of a report or case study).</p>
                                    <textarea
                                        placeholder="Provide your explanation here..."
                                        style={{ marginTop: '10px', width: '100%', height: '80px', padding: '5px' }}
                                    ></textarea>
                                </div>
                                <div style={{ marginTop: '15px' }}>
                                    <p> Our public environmental policy covers the following:</p>
                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element1" value="element1" />Production operations and business facilities
                                    </label>
                                    <br />
                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element2" value="element2" /> Products and services
                                    </label>
                                    <br />
                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element3" value="element3" />Distribution and logistics
                                    </label>
                                    <br />
                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element2" value="element2" />  Management of waste
                                    </label>
                                    <br />
                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element2" value="element2" /> Suppliers, service providers and contractors
                                    </label>

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" />  We do not have an environmental policy publicly available
                                    </label>

                                </div>


                                <div style={{ marginTop: '15px' }}>
                                    <label className="checkbox-label">
                                        <input type="radio" name="notApplicable" value="notApplicable" />Not applicable. Please provide explanations in the comment box below.
                                    </label>
                                    <br />
                                    <textarea
                                        placeholder="Provide your explanation here..."
                                        style={{ marginTop: '10px', width: '100%', height: '80px', padding: '5px' }}
                                    ></textarea>
                                </div>
                            </div>

                            <sectionheader1 id={"secai"}>
                                2.1.3 EMS: Certification/ Audit/ Verification
                                <div className="secHead1">
                                    2.1.3 EMS: Certification/ Audit/ Verification
                                </div>
                            </sectionheader1>

                            <div className="display-box">
                                <div style={{ marginTop: '20px' }}>
                                    <p>Please indicate how your Environmental Management System (EMS) is certified / audited / verified and indicate the coverage of this verification for the selected standard.Please indicate where this information is available in your public reporting or corporate website. Please note that the total coverage for all three alternatives should not exceed 100% - to avoid doublecounting, for the parts of your operations with multiple certifications/types of verification, only mark one of the three options: indicating the coverage of international standards first, followed by third-party verification and then internal verification. Coverage should be relative to global operations and not only a single subsidiary, region or site. Please also note that the requested verification only pertains to your Environmental Management System(s), not to your environmental data or reporting.</p>
                                    <textarea
                                        placeholder="Provide your explanation here..."
                                        style={{ marginTop: '10px', width: '100%', height: '80px', padding: '5px' }}
                                    ></textarea>
                                </div>
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>

                                        <tr>
                                            <td colspan="4" rowspan="1" class="">
                                                {`Our Environmental Management System (EMS) is certified / audited / verified and the information is publicly available. Please indicate what the coverage figures below are based on (e.g. % of group-wide operations, group-wide revenues, group-wide production sites, total employees, etc.):`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Certification / Audit / Verification
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Coverage (%)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Examples of Certification documents
                                            </td>

                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                EMS is verified through
                                                international standards (e.g.
                                                ISO 14001, JIS Q 14001, EMAS
                                                certification). Please specify:
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>

                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Third party certification /audit /
                                                verification by specialized
                                                companies. Please specify:
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>

                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Internal certification /audit /
                                                verification by company's own
                                                specialists from headquarters.
                                                Please specify:
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>

                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Total (should not exceed 100%)
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>

                                        </tr>




                                    </tbody>
                                </table>
                            </div>

                            <div className=" display-box">
                                <label className="checkbox-label">
                                    <input type="radio" name="policy" value="yes" />  We do not have an environmental policy publicly available
                                </label>
                                <div style={{ marginTop: '15px' }}>
                                    <label className="checkbox-label">
                                        <input type="radio" name="notApplicable" value="notApplicable" /> Not applicable. Please provide explanations in the comment box below.
                                    </label>
                                    <br />
                                    <textarea
                                        placeholder="Provide your explanation here..."
                                        style={{ marginTop: '10px', width: '100%', height: '80px', padding: '5px' }}
                                    ></textarea>
                                </div>
                            </div>

                            <sectionheader1 id={"secai"}>
                                2.1.4 Environmental Violations
                                <div className="secHead1">
                                    2.1.4 Environmental Violations
                                </div>
                            </sectionheader1>

                            <div className="display-box">
                                <div style={{ marginTop: '15px' }}>
                                    <p>Has TVS paid significant fines or penalties related to the environment or ecology in the past four fiscal years?</p>

                                    <p> Yes, our company has paid significant fines or penalties related to the environment or ecological issues in the last four fiscal years. Please provide the corresponding figures in the table below for each of the four years and indicate where this is available in your public reporting. Please note that if you did not have any violations, fines or accrued liability in an individual year, 0 should be indicated in the corresponding box in the table.</p>

                                    <div class="gridlines-container">
                                        <table class="gridlines">
                                            <tbody>

                                                <tr>
                                                    <td colspan="1" rowspan="1" className="tdHead">

                                                    </td>
                                                    <td colspan="1" rowspan="1" className="tdHead">
                                                        FY 2019
                                                    </td>
                                                    <td colspan="1" rowspan="1" className="tdHead">
                                                        FY 2020
                                                    </td>
                                                    <td colspan="1" rowspan="1" className="tdHead">
                                                        FY 2021
                                                    </td>
                                                    <td colspan="1" rowspan="1" className="tdHead">
                                                        FY 2022
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td colspan="1" rowspan="1" class="">
                                                        Number of violations of legal obligations/
                                                        regulations

                                                    </td>
                                                    <td colspan="1" rowspan="1" className="text-center"></td>
                                                    <td colspan="1" rowspan="1" className="text-center"></td>
                                                    <td colspan="1" rowspan="1" className="text-center"></td>
                                                    <td colspan="1" rowspan="1" className="text-center"></td>
                                                </tr>

                                                <tr>
                                                    <td colspan="1" rowspan="1" class="">
                                                        Amount of fines/ penalties related to the above.
                                                        Currency:

                                                    </td>
                                                    <td colspan="1" rowspan="1" className="text-center"></td>
                                                    <td colspan="1" rowspan="1" className="text-center"></td>
                                                    <td colspan="1" rowspan="1" className="text-center"></td>
                                                    <td colspan="1" rowspan="1" className="text-center"></td>
                                                </tr>


                                                <tr>
                                                    <td colspan="1" rowspan="1" class="">

                                                        Environmental liability accrued at year end.
                                                        Currency:
                                                    </td>
                                                    <td colspan="1" rowspan="1" className="text-center"></td>
                                                    <td colspan="1" rowspan="1" className="text-center"></td>
                                                    <td colspan="1" rowspan="1" className="text-center"></td>
                                                    <td colspan="1" rowspan="1" className="text-center"></td>
                                                </tr>



                                            </tbody>
                                        </table>
                                    </div>

                                </div>

                                <label className="checkbox-label">
                                    <input type="radio" name="policy" value="yes" />  No, we have not paid any significant fines (> USD $10,000) related to environmental or ecological issues in the past four fiscal years. Please provide public evidence for the past four fiscal years.
                                </label>

                                <label className="checkbox-label">
                                    <input type="radio" name="policy" value="yes" />  No, we do not report on environmental violations or their associated fines/penalties.
                                </label>

                                <div style={{ marginTop: '15px' }}>
                                    <label className="checkbox-label">
                                        <input type="radio" name="notApplicable" value="notApplicable" />  Not applicable. Please provide explanations in the comment box below.
                                    </label>
                                    <br />
                                    <textarea
                                        placeholder="Provide your explanation here..."
                                        style={{ marginTop: '10px', width: '100%', height: '80px', padding: '5px' }}
                                    ></textarea>
                                </div>
                            </div>

                            <sectionheader1 id={"sectiona"} className="secHead">
                                2.2 Emissions
                            </sectionheader1>

                            <sectionheader1 id={"secai"}>
                                2.2.1 Direct Greenhouse Gas Emissions (Scope 1)
                                <div className="secHead1">
                                    2.2.1 Direct Greenhouse Gas Emissions (Scope 1)
                                </div>
                            </sectionheader1>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="5" rowspan="1" class="">
                                                {`Please provide TVS's total direct greenhouse gas emissions (DGHG SCOPE 1) for the part of TVS's operations for which you have a reliable and auditable data acquisition and aggregation system. Please refer to the information button for additional clarifications. For each row in the table, it is mandatory that the values provided are in the same unit. Also, please ensure that you have correctly filled in the Company Information section at the beginning of the questionnaire, and that the coverage in the table below is related to the denominator relevant for TVS as indicated in that section.`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Direct GHG (Scope 1)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Unit
                                            </td>

                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY 2020
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY 2021
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY 2022
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Total direct GHG emissions (Scope 1)
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">metric tonnes CO2 equivalents</td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>

                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Data coverage
                                                (as % of
                                                denominator)
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center"> percentage
                                                of:</td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>

                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            <div className="display-box">
                                <div style={{ marginTop: '15px' }}>
                                    <p className="text-bold"> PUBLIC REPORTING</p>
                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element1" value="element1" /> Our data is publicly available. Please provide supporting evidence or web link.
                                    </label>
                                    <br />

                                    <p className="text-bold">DATA CONSISTENCY</p>
                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element1" value="element1" />  We report publicly on this information, but the data in the table above differs from our publicly reported figures. Please provide an explanation in the comment box for this difference:
                                    </label>
                                    <br />


                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element1" value="element1" /> We have a temporary coverage reduction or target challenge due to corporate actions. Please briefly explain if a merger, acquisition, divestment, etc. has temporarily caused a reduction in your ability to report optimal coverage or caused your target to appear abnormal:
                                    </label>
                                    <br />


                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element1" value="element1" />  We are not able to report this information in absolute terms, the information provided in the table above is normalized data. For the purpose of this question, please always provide absolute figures if available.
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element1" value="element1" />  We only report combined on Scope 1 & Scope 2 emissions. Please provide the combined figures in the table above and mark "Not applicable" in the next question (EP - Indirect Greenhouse Gas Emissions (Scope 2)).
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" />  We do not track direct greenhouse gas emissions (Scope 1)
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" /> Not applicable. Please provide explanations in the comment box below.
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" /> Not known
                                    </label>
                                    <br />



                                </div>

                            </div>

                            <sectionheader1 id={"secai"}>
                                2.2.2 Indirect Greenhouse Gas Emissions (Scope 2)
                                <div className="secHead1">
                                    2.2.2 Indirect Greenhouse Gas Emissions (Scope 2)
                                </div>
                            </sectionheader1>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="6" rowspan="1" class="">
                                                {`Please provide TVS's indirect greenhouse gas emissions from energy purchased (purchased and consumed, i.e. without energy trading) (IGHG SCOPE 2) for the part of TVS's operations for which you have a reliable and auditable data acquisition and aggregation system. For each row in the table, it is mandatory that the values provided are in the same unit. Please ensure that you have correctly filled in the Company Information section at the beginning of the questionnaire, and that the coverage in the table below is related to the denominator relevant for TVS as indicated in that section. As prescribed by the GHG Protocol, we expect all companies to report both location-based and market-based emissions. Please refer to the information button for additional clarifications on how to report on market-based emissions.`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Indirect GHG (Scope 2)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Unit
                                            </td>

                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY 2020
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY 2021
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY 2022
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                What was your target for FY 2022?
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Location based
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">metric tonnes of CO2 equivalents</td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>

                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Data coverage
                                                (as % of
                                                denominator)
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center"> percentage
                                                of:</td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>

                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Market-based
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center"> metric tonnes of CO2 equivalents
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>

                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Data coverage (as % of denominator)
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center"> percentage
                                                of:</td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>

                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            <div className="display-box">
                                <div style={{ marginTop: '15px' }}>
                                    <p className="text-bold"> PUBLIC REPORTING</p>
                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element1" value="element1" /> Our data is publicly available. Please provide supporting evidence or web link.
                                    </label>
                                    <br />

                                    <p className="text-bold">THIRD-PARTY VERIFICATION</p>
                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element1" value="element1" /> Our data has been third-party verified in the most recent financial year reported. Please provide supporting evidence.
                                    </label>
                                    <br />

                                    <p className="text-bold">DATA CONSISTENCY</p>
                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element1" value="element1" />  We report publicly on this information, but the data in the table above differs from our publicly reported figures. Please provide an explanation in the comment box for this difference:
                                    </label>
                                    <br />


                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element1" value="element1" /> We have a temporary coverage reduction or target challenge due to corporate actions. Please briefly explain if a merger, acquisition, divestment, etc. has temporarily caused a reduction in your ability to report optimal coverage or caused your target to appear abnormal:
                                    </label>
                                    <br />


                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element1" value="element1" />  We are not able to report this information in absolute terms, the information provided in the table above is normalized data. For the purpose of this question, please always provide absolute figures if available.
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element1" value="element1" />  We only report combined on Scope 1 & Scope 2 emissions. Please provide the combined figures in the table above and mark "Not applicable" in the next question (EP - Indirect Greenhouse Gas Emissions (Scope 2)).
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" />  We do not track direct greenhouse gas emissions (Scope 1)
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" /> Not applicable. Please provide explanations in the comment box below.
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" /> Not known
                                    </label>
                                    <br />



                                </div>

                            </div>

                            <sectionheader1 id={"secai"}>
                                2.2.3 Indirect Greenhouse Gas Emissions (Scope 3)
                                <div className="secHead1">
                                    2.2.3 Indirect Greenhouse Gas Emissions (Scope 3)
                                </div>
                            </sectionheader1>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="6" rowspan="1" class="">
                                                {`Please provide TVS's total indirect greenhouse gas emissions from your upstream and downstream value chain (IGHG Scope 3) as the sum of the 15 Scope 3 categories calculated using the GHG Protocol Corporate Value Chain Standard. Please refer to the information button for clarification. For each row in the table, it is mandatory that the values provided are in the same unit.`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                GHG Scope 3
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Unit
                                            </td>

                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY 2020
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY 2021
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY 2022
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                What was your target for FY 2022?
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Total indirect GHG emissions(Scope 3)
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">metric tonnes of CO2 equivalents</td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="4" rowspan="1" class="">
                                                {`Please provide TVS's Scope 3 emissions split based on the 15 Scope 3 categories of the GHG Protocol Corporate Value Chain Standard. Please use the latest available data to calculate Scope 3 emissions. Disclose any information about the methodology used to calculate the emissions in each Scope 3 category and whether any emissions in each Scope 3 category has been excluded. Please refer to the information button for additional clarifications and requirements. For each row in the table, it is mandatory that the values provided are in the same unit.`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Scope 3 Category
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Emissions in the reporting year
                                                (Metric tons CO2e)
                                            </td>

                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Emissions calculation methodologyand exclusions
                                            </td>

                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                1. Purchased Goods and Services
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                2. Capital Goods
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                3. Fuel-and-energy-related activities (not included in Scope 1 or 2)
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                4. Upstream transportation and distribution
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                5. Waste generated in operations
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                6. Business travel
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                7. Employee commuting
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                8. Upstream leased assets
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                9. Downstream transportation and distribution
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                        </tr>


                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                10. Processing of sold products
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                11. Use of sold products
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                12. End of life treatment of sold products
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                13. Downstream leased assets
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                14. Franchises
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                15. Investments
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Other upstream
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Other downstream
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                        </tr>


                                    </tbody>
                                </table>
                            </div>

                            <div className="display-box">
                                <div style={{ marginTop: '15px' }}>
                                    <p className="text-bold"> PUBLIC REPORTING</p>
                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element1" value="element1" /> Our data is publicly available. Please provide supporting evidence or web link.
                                    </label>
                                    <br />

                                    <p className="text-bold">THIRD-PARTY VERIFICATION</p>
                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element1" value="element1" /> Our data has been third-party verified in the most recent financial year reported. Please provide supporting evidence.
                                    </label>
                                    <br />

                                    <p className="text-bold">DATA CONSISTENCY</p>
                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element1" value="element1" />  We report publicly on this information, but the data in the table above differs from our publicly reported figures. Please provide an explanation in the comment box for this difference:
                                    </label>
                                    <br />


                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element1" value="element1" /> We have a temporary coverage reduction or target challenge due to corporate actions. Please briefly explain if a merger, acquisition, divestment, etc. has temporarily caused a reduction in your ability to report optimal coverage or caused your target to appear abnormal:
                                    </label>
                                    <br />


                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element1" value="element1" />  We are not able to report this information in absolute terms, the information provided in the table above is normalized data. For the purpose of this question, please always provide absolute figures if available.
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element1" value="element1" />  We only report combined on Scope 1 & Scope 2 emissions. Please provide the combined figures in the table above and mark "Not applicable" in the next question (EP - Indirect Greenhouse Gas Emissions (Scope 2)).
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" />  We do not track direct greenhouse gas emissions (Scope 1)
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" /> Not applicable. Please provide explanations in the comment box below.
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" /> Not known
                                    </label>
                                    <br />



                                </div>

                            </div>

                            <sectionheader1 id={"secai"}>
                                2.3 Resource Efficiency and Circularity
                                <div className="secHead1">
                                    2.3 Resource Efficiency and Circularity
                                </div>
                            </sectionheader1>

                            <sectionheader1 id={"secai"}>
                                2.3.1 Energy Consumption
                                <div className="secHead1">
                                    2.3.1 Energy Consumption
                                </div>
                            </sectionheader1>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="6" rowspan="1" class="">
                                                {`Please complete the following table about total energy consumption. For each row in the table, it is mandatory that the values provided are in the same unit. Please see the Information Button for definitions of the cost options. Also, please ensure that you have correctly filled in the Company Information section at the beginning of the questionnaire, and that the coverage in the table below is related to the denominator relevant for TVS as indicated in that section.`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Total energy consumption
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Unit
                                            </td>

                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY 2020
                                            </td>

                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY 2021
                                            </td>

                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY 2022
                                            </td>

                                            <td colspan="1" rowspan="1" className="tdHead">
                                                What was your target for FY 2022?
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Total nonrenewable energy consumption

                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">MWh </td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Total renewable energy consumption

                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">MWh</td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Data coverage (as % of denominator)

                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">percentage
                                                of:</td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            <div className="display-box">
                                <div style={{ marginTop: '15px' }}>
                                    <p className="text-bold"> PUBLIC REPORTING</p>
                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element1" value="element1" /> Our data is publicly available. Please provide supporting evidence or web link.
                                    </label>
                                    <br />

                                    <p className="text-bold">THIRD-PARTY VERIFICATION</p>
                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element1" value="element1" /> Our data has been third-party verified in the most recent financial year reported. Please provide supporting evidence.
                                    </label>
                                    <br />

                                    <p className="text-bold">DATA CONSISTENCY</p>
                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element1" value="element1" />  We report publicly on this information, but the data in the table above differs from our publicly reported figures. Please provide an explanation in the comment box for this difference:
                                    </label>
                                    <br />


                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element1" value="element1" /> We have a temporary coverage reduction or target challenge due to corporate actions. Please briefly explain if a merger, acquisition, divestment, etc. has temporarily caused a reduction in your ability to report optimal coverage or caused your target to appear abnormal:
                                    </label>
                                    <br />


                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element1" value="element1" />  We are not able to report this information in absolute terms, the information provided in the table above is normalized data. For the purpose of this question, please always provide absolute figures if available.
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element1" value="element1" />  We only report combined on Scope 1 & Scope 2 emissions. Please provide the combined figures in the table above and mark "Not applicable" in the next question (EP - Indirect Greenhouse Gas Emissions (Scope 2)).
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" />  We do not track direct greenhouse gas emissions (Scope 1)
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" /> Not applicable. Please provide explanations in the comment box below.
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" /> Not known
                                    </label>
                                    <br />



                                </div>

                            </div>

                            <sectionheader1 id={"secai"}>
                                2.4 Waste
                                <div className="secHead1">
                                    2.4 Waste
                                </div>
                            </sectionheader1>

                            <sectionheader1 id={"secai"}>
                                2.4.1 Waste Disposal
                                <div className="secHead1">
                                    2.4.1 Waste Disposal
                                </div>
                            </sectionheader1>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="6" rowspan="1" class="">
                                                {`Please provide a breakdown of TVS's total solid waste recycled/reused and disposed, disaggregated by the type of disposal method, for the part of TVS's operations for which you have a reliable and auditable data acquisition and aggregation system. If you don’t use a specific waste disposal method, please indicate “0”. If you only have the breakdown of your waste disposed for some methods, please report these values in the respective rows and leave the other rows blank. For each row in the table, it is mandatory that the values provided are in the same unit. Please also ensure that you have correctly filled in the "Company Information" section at the beginning of the questionnaire and that the coverage in the table below is related to the denominator relevant for TVS as indicated in that section. If you have the EP - Hazardous Waste, EP – Ash & Gypsum Waste and/or EP - Mineral Waste questions in your industry questionnaire, please report information pertaining to these types of waste in those separate questions. If you do not have those questions in your industry questionnaire, please include all types of data here (e.g., including hazardous waste).`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Waste disposed
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Unit
                                            </td>

                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY 2020
                                            </td>

                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY 2021
                                            </td>

                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY 2022
                                            </td>

                                            <td colspan="1" rowspan="1" className="tdHead">
                                                What was your target for FY 2022?
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Total waste recycled/ reused
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">metric tonnes </td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Total waste
                                                disposed
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">metric tonnes </td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                        </tr>


                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Waste landfilled
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">metric tonnes </td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                        </tr>


                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Waste incinerated with energy recovery
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">metric tonnes </td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                        </tr>


                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Waste incinerated without energy recovery
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">metric tonnes </td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                        </tr>


                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Waste otherwise disposed, please specify:
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">metric tonnes </td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                        </tr>


                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Waste with unknown disposal
                                                method
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">metric tonnes </td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                        </tr>


                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Data coverage(as % of
                                                denominator)
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">metric tonnes </td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                        </tr>


                                    </tbody>
                                </table>
                            </div>

                            <div className="display-box">
                                <div style={{ marginTop: '15px' }}>
                                    <p className="text-bold"> PUBLIC REPORTING</p>
                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element1" value="element1" /> Our data is publicly available. Please provide supporting evidence or web link.
                                    </label>
                                    <br />

                                    <p className="text-bold">THIRD-PARTY VERIFICATION</p>
                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element1" value="element1" /> Our data has been third-party verified in the most recent financial year reported. Please provide supporting evidence.
                                    </label>
                                    <br />

                                    <p className="text-bold">DATA CONSISTENCY</p>
                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element1" value="element1" />  We report publicly on this information, but the data in the table above differs from our publicly reported figures. Please provide an explanation in the comment box for this difference:
                                    </label>
                                    <br />


                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element1" value="element1" /> We have a temporary coverage reduction or target challenge due to corporate actions. Please briefly explain if a merger, acquisition, divestment, etc. has temporarily caused a reduction in your ability to report optimal coverage or caused your target to appear abnormal:
                                    </label>
                                    <br />


                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element1" value="element1" />  We are not able to report this information in absolute terms, the information provided in the table above is normalized data. For the purpose of this question, please always provide absolute figures if available.
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element1" value="element1" />  We only report combined on Scope 1 & Scope 2 emissions. Please provide the combined figures in the table above and mark "Not applicable" in the next question (EP - Indirect Greenhouse Gas Emissions (Scope 2)).
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" />  We do not track direct greenhouse gas emissions (Scope 1)
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" /> Not applicable. Please provide explanations in the comment box below.
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" /> Not known
                                    </label>
                                    <br />



                                </div>

                            </div>

                            <sectionheader1 id={"secai"}>
                                2.5 Water
                                <div className="secHead1">
                                    2.5.1 Water Consumption
                                </div>
                            </sectionheader1>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="6" rowspan="1" class="">
                                                {`Please provide TVS's total net fresh water consumption, including data for fresh water extraction and consumption. Please refer to the information button for additional information. For each row in the table, it is mandatory that the values are provided in the same unit. Also, please ensure that you have correctly filled in the Company Information section at the beginning of the questionnaire, and that the coverage in the table below is related to the denominator relevant for TVS as indicated in that section. If TVS's final product is water (e.g. water utilities), please mark "Not applicable" in this question.`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Water Consumption
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Unit
                                            </td>

                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY 2020
                                            </td>

                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY 2021
                                            </td>

                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY 2022
                                            </td>

                                            <td colspan="1" rowspan="1" className="tdHead">
                                                What was your target for FY 2022?
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                A. Withdrawal: Total municipal water supplies (or from other water utilities)
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">Million cubic meters </td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                B. Withdrawal: Fresh surface water (lakes, rivers, etc.)
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">Million cubic meters </td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                C. Withdrawal: Fresh groundwater
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">Million cubic meters </td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                D. Discharge: Water returned to the source of extraction
                                                at similar or higher quality as raw water extracted (only applies to B and C)
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">Million cubic meters </td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                E. TOTAL NET FRESH WATER CONSUMPTION (A+B+C-D)
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">Million cubic meters </td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                            <td colspan="1" rowspan="1" className="text-center"></td>
                                        </tr>



                                    </tbody>
                                </table>
                            </div>

                            <div className="display-box">
                                <div style={{ marginTop: '15px' }}>
                                    <p className="text-bold"> PUBLIC REPORTING</p>
                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element1" value="element1" /> Our data is publicly available. Please provide supporting evidence or web link.
                                    </label>
                                    <br />

                                    <p className="text-bold">THIRD-PARTY VERIFICATION</p>
                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element1" value="element1" /> Our data has been third-party verified in the most recent financial year reported. Please provide supporting evidence.
                                    </label>
                                    <br />

                                    <p className="text-bold">DATA CONSISTENCY</p>
                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element1" value="element1" />  We report publicly on this information, but the data in the table above differs from our publicly reported figures. Please provide an explanation in the comment box for this difference:
                                    </label>
                                    <br />


                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element1" value="element1" /> We have a temporary coverage reduction or target challenge due to corporate actions. Please briefly explain if a merger, acquisition, divestment, etc. has temporarily caused a reduction in your ability to report optimal coverage or caused your target to appear abnormal:
                                    </label>
                                    <br />


                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element1" value="element1" />  We are not able to report this information in absolute terms, the information provided in the table above is normalized data. For the purpose of this question, please always provide absolute figures if available.
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="checkbox" name="element1" value="element1" />  We only report combined on Scope 1 & Scope 2 emissions. Please provide the combined figures in the table above and mark "Not applicable" in the next question (EP - Indirect Greenhouse Gas Emissions (Scope 2)).
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" />  We do not track direct greenhouse gas emissions (Scope 1)
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" /> Not applicable. Please provide explanations in the comment box below.
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" /> Not known
                                    </label>
                                    <br />



                                </div>

                            </div>

                            <sectionheader1 id={"secai"}>
                                2.6 Climate Strategy
                                <div className="secHead1">
                                    2.6.1 Climate Governance
                                </div>
                            </sectionheader1>

                            <div class="para m-3">
                                <label class="fb5"> Is TVS’s board of directors and/or executive management responsible for the oversight and management of climate-related issues? Please specify where this is available in your public reporting or corporate website.</label>
                                <ul class="pad30">
                                    <li class=""> Yes, there is board-level oversight and/or management-level responsibility of climate-related issues</li>

                                </ul>
                            </div>

                            <sectionheader1 id={"secai"}>
                                2.6.2 TCFD Disclosure
                                <div className="secHead1">
                                    2.6.2 TCFD Disclosure
                                </div>
                            </sectionheader1>

                            <div class="para m-3">
                                <label class="fb5"> Does your organization apply the TCFD framework in the management of climate-related risks and opportunities? Please indicate where this information is available in your public reporting.</label>
                                <ul class="pad30">
                                    <li class=""> Yes, we integrate the TCFD or are in the process of integrating it and publicly address the following requirements:</li>

                                </ul>
                            </div>

                            <div className="display-box">
                                <div style={{ marginTop: '10px' }}>
                                    <p className="text-bold"> Metrics & Targets</p>

                                    <br />

                                    <p>  a) Disclose the metrics used by the organization to assess climate-related risks and opportunities in line with its strategy and risk management process.</p>
                                    <p > b) Disclose Scope 1, Scope 2, and if appropriate, Scope 3 greenhouse gas (GHG) emissions, and the related risks.</p>
                                    <p >  c) Describe the targets used by the organization to manage climate-related risks and opportunities and performance against targets.</p>

                                    <p> We have publicly committed to integrating TCFD, but we do not yet report on any of the requirements. Please indicate the timeframe when you plan to disclose on the requirements:</p>

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" /> 2023
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" /> 2024 or later
                                    </label>
                                    <br />


                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" />  No, we do not apply the TCFD framework in our risk management. Please explain the reason.
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" />  Not applicable. Please provide explanations in the comment box below.
                                    </label>
                                    <br />


                                </div>

                            </div>

                            <sectionheader1 id={"secai"}>
                                2.6.3 Climate-Related Management Incentives
                                <div className="secHead1">
                                    2.6.3 Climate-Related Management Incentives
                                </div>
                            </sectionheader1>

                            <div class="para m-3">
                                <label class="fb5"> Does TVS provide incentives for the management of climate change issues, including the attainment of targets? Please indicate where this information is available in your public reporting or corporate website.</label>
                                <ul class="pad30">
                                    <li class=""> Yes, we provide details on the climate change-related incentives starting from the highest management level and the information is publicly available.</li>

                                </ul>
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>

                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Who is entitled to benefit from this incentive?
                                                Select each option only once
                                            </td>

                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Type of incentive
                                            </td>

                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Incentivized KPIs:
                                                Please provide a description of the
                                                KPI and how it is incentivized
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Chief Executive Officer (CEO)
                                            </td>
                                            <td colspan="1" rowspan="2" className="text-center">Monetary </td>
                                            <td colspan="1" rowspan="1" className="text-center">Emissions reduction</td>


                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Other Named Executive Officers
                                            </td>

                                            <td colspan="1" rowspan="1" className="text-center">Energy reduction</td>


                                        </tr>


                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Business Unit Managers
                                            </td>
                                            <td colspan="1" rowspan="2" className="text-center">Recognition </td>
                                            <td colspan="1" rowspan="1" className="text-center"> Efficiency</td>


                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Employees
                                            </td>

                                            <td colspan="1" rowspan="1" className="text-center">Purchasing</td>


                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="2" class="">
                                                Other, please specify
                                            </td>
                                            <td colspan="1" rowspan="2" className="text-center">OTHER </td>
                                            <td colspan="1" rowspan="1" className="text-center"> Supply chain engagement</td>


                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Other, please specify
                                            </td>

                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Chief Executive Officer (CEO)
                                            </td>
                                            <td colspan="1" rowspan="2" className="text-center"> Monetary</td>
                                            <td colspan="1" rowspan="1" className="text-center">Emissions reduction </td>


                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Other Named Executive Officers
                                            </td>

                                            <td colspan="1" rowspan="1" className="text-center">Energy reduction</td>


                                        </tr>


                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Business Unit Managers
                                            </td>
                                            <td colspan="1" rowspan="2" className="text-center"> Recognition</td>
                                            <td colspan="1" rowspan="1" className="text-center">Efficiency </td>


                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Employees
                                            </td>

                                            <td colspan="1" rowspan="1" className="text-center">Purchasing</td>


                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="2" class="">
                                                Other, please specify
                                            </td>
                                            <td colspan="1" rowspan="2" className="text-center">OTHER </td>
                                            <td colspan="1" rowspan="1" className="text-center"> Supply chain engagement</td>


                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Other, please specify
                                            </td>

                                        </tr>








                                    </tbody>
                                </table>
                            </div>

                            <sectionheader1 id={"secai"}>
                                2.6.4 Climate Risk Management
                                <div className="secHead1">
                                    2.6.4 Climate Risk Management
                                </div>
                            </sectionheader1>

                            <div class="para m-3">
                                <label class="fb5">Does TVS have a Climate Risk Management process? Please specify where this is available in your public reporting or corporate website.</label>
                                <ul class="pad30">
                                    <li class="">Yes, we publicly report on our Climate Risk Management process</li>

                                </ul>
                            </div>

                            <div className="display-box">
                                <div style={{ marginTop: '10px' }}>
                                    <p className="text-bold">Climate Risk Management</p>

                                    <br />
                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" /> Integrated into multi-disciplinary company-wide risk management processes, i.e. a documented process where climate change risks and opportunities are integrated into the company’s centralized enterprise risk management program covering all types/sources of risks and opportunities
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" /> A specific climate change risk management process, i.e. a documented process which considers climate change risks and opportunities separate from other business risks and opportunities
                                    </label>
                                    <br />
                                </div>

                                <p className="text-bold">Climate Risk Management</p>

                                <p> Current Regulation</p>
                                <p> Emerging Regulation</p>
                                <p>Technology Risk</p>
                                <p>Legal Risk</p>
                                <p>Market Risk</p>
                                <p>Reputational Risk</p>
                                <p>Acute Physical Risk</p>
                                <p>Chronic Physical Risk</p>

                                <p className="text-bold">Value chain stages covered by climate risk assessment</p>

                                <p>The assessment includes our own operations </p>
                                <p> The assessment includes our upstream activities</p>
                                <p>The assessment includes our downstream activities and/ or clients </p>




                            </div>

                            <sectionheader1 id={"secai"}>
                                2.6.5 Financial Risks of Climate Change
                                <div className="secHead1">
                                    2.6.5 Financial Risks of Climate Change
                                </div>
                            </sectionheader1>

                            <div class="para m-3">
                                <label class="fb5">Have you identified any climate change risks (current or future) that have potential to generate a substantive change in your business operations, revenue or expenditures?</label>
                                <ul class="pad30">
                                    <li class="">Yes, we have identified climate change-related risks with potential impact. Please estimate the financial impact for the most significant risk from each category and provide supporting evidence:</li>

                                </ul>
                            </div>

                            <div className="display-box">
                                <div style={{ marginTop: '10px' }}>
                                    <p className="text-bold">Risks driven by changes in regulation:</p>

                                    <br />

                                </div>


                                <p> Currency:</p>
                                <p> Brief description of the most significant risk and methods used to manage this risk:</p>
                                <p> Estimated financial implications of the risk before taking action:</p>
                                <p>Average estimated time frame (in number of years) for financial implications of this risk:</p>
                                <p>Estimated costs of these actions:</p>


                                <p className="text-bold">Risks driven by change in physical climate parameters or other climate-change related developments</p>

                                <p>Currency: </p>
                                <p> Brief description of the most significant risk and methods used to manage this risk:</p>
                                <p> Estimated financial implication of the risk before taking action:</p>
                                <p>Average estimated time frame (in number of years) for financial implications of the risk: </p>


                                <sectionheader1 id={"secai"}>
                                    2.6.6 Financial Opportunities Arising from Climate Change
                                    <div className="secHead1">
                                        2.6.6 Financial Opportunities Arising from Climate Change
                                    </div>
                                </sectionheader1>

                                <div class="para m-3">
                                    <label class="fb5"> Have you identified any climate change-related opportunities (current or future) that have the potential to generate a substantive positive change in your business operations, revenue, expenditure (i.e. opportunities driven by changes in regulation, physical, or other climate change-related developments)? </label>
                                    <ul class="pad30">
                                        <li class="">Yes, we have identified climate change-related opportunities. Please briefly describe the most significant opportunity resulting from climate change on your business operations, revenue growth, or expenditures and provide supporting evidence:
                                        </li>

                                    </ul>
                                </div>

                                <sectionheader1 id={"secai"}>
                                    2.6.7 Climate-Related Scenario Analysis
                                    <div className="secHead1">
                                        2.6.7 Climate-Related Scenario Analysis
                                    </div>
                                </sectionheader1>

                                <div class="para m-3">
                                    <label class="fb5">Has TVS conducted climate-related scenario analysis? Please specify where this is available in your public reporting or corporate website. </label>
                                    <ul class="pad30">
                                        <li class="">Yes, we have conducted climate-related scenario analysis
                                        </li>


                                        <label className="checkbox-label">
                                            <input type="radio" name="policy" value="yes" />We use qualitative climate-related scenario analysis
                                        </label>


                                        <label className="checkbox-label">
                                            <input type="radio" name="policy" value="yes" /> We use qualitative and quantitative climate-related scenario analysis
                                        </label>

                                        <label className="checkbox-label">
                                            <input type="radio" name="policy" value="yes" /> Not applicable. Please provide explanations in the comment box below.
                                        </label>

                                        <label className="checkbox-label">
                                            <input type="radio" name="policy" value="yes" />No we do not publicly report on our climate-related scenario analysis.
                                        </label>



                                    </ul>
                                </div>

                                <sectionheader1 id={"secai"}>
                                    2.6.8 Physical Climate Risk Adaptation
                                    <div className="secHead1">
                                        2.6.8 Physical Climate Risk Adaptation
                                    </div>
                                </sectionheader1>

                                <div class="para m-3">
                                    <label class="fb5">Based on your climate risk assessment, has TVS set up a plan to adapt to the identified physical climate risks? Please provide supporting evidence and indicate where this is available in the public domain. </label>
                                    <ul class="pad30">
                                        <li class="">Yes, we have a context-specific plan to adapt to physical climate risks in existing and/or new operations. Please provide supporting evidence and indicate if this is available in the public domain.
                                        </li>



                                    </ul>
                                </div>

                                <div className="display-box">
                                    <div style={{ marginTop: '15px' }}>
                                        <p className="text-bold">We publicly report on our context-specific plan to adapt to physical risks</p>
                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" /> Less than 5 years
                                        </label>
                                        <br />


                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" />5 to 10 years
                                        </label>
                                        <br />

                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" /> More than 10 years
                                        </label>
                                        <br />

                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" />  The risk assessment and plan to adapt to physical climate risks cover the following share of our new operations (Percentage of new operations):
                                        </label>
                                        <br />

                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" /> We have no new asset planned.
                                        </label>
                                        <br />

                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" />  Yes, we have an overall plan to adapt to potential physical climate risks. Please provide supporting evidence and indicate if this is available in the public domain.
                                        </label>
                                        <br />

                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" /> We publicly report on overall plan to adapt to physical risks
                                        </label>
                                        <br />

                                    </div>

                                </div>

                                <div className="display-box">
                                    <div style={{ marginTop: '15px' }}>
                                        <p className="text-bold">The plan includes a target to implement relevant adaptation measures within the following timeline:</p>
                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" /> Less than 5 years
                                        </label>
                                        <br />


                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" />5 to 10 years
                                        </label>
                                        <br />

                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" /> More than 10 years
                                        </label>
                                        <br />

                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" /> No, we have no existing plan to adapt to physical climate risks.
                                        </label>
                                        <br />

                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" /> ❍ Not applicable. We have performed a climate risk assessment for physical risks, but do not consider our company’s assets and operations to be exposed to any material physical risks. Please provide a detailed explanation in the comment box below.
                                        </label>
                                        <br />



                                    </div>

                                </div>


                                <sectionheader1 id={"secai"}>
                                    2.6.9 Emissions Reduction Targets
                                    <div className="secHead1">
                                        2.6.9 Emissions Reduction Targets
                                    </div>
                                </sectionheader1>

                                <div class="para m-3">
                                    <label class="fb5">Does TVS have any corporate-level emissions reduction targets publicly available? If TVS also answers the question Net-Zero Commitment, please do not provide your Net-Zero target in this question, but provide details of your related near-term emissions reduction target. </label>
                                    <ul class="pad30">
                                        <li class="">Yes, we have a company-wide absolute emissions target and/or an emissions intensity target publicly available that covers Scope 1, Scope 2, and/or Scope 3 emissions. Please provide details in the table below.
                                        </li>



                                    </ul>
                                </div>

                                <div className="display-box">
                                    <p className="text-bold"> Target Type and Metric </p>

                                    <p className="text-bold"> Absolute targets </p>
                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" />  Intensity targets
                                    </label>

                                    <p className="text-bold"> Intensity Metric</p>

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" />  Metric tons CO2e per square meter

                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" />  Metric tons CO2e per metric ton of aluminum
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" /> Metric tons CO2e per metric ton of steel
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" />  Metric tons CO2e per metric ton of cement
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" /> Metric tons CO2e per metric ton of cardboard
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" /> Grams CO2e per kilometer
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" /> Metric tons CO2e per unit revenue
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" /> Metric tons CO2e per unit FTE employee
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" /> Metric tons CO2e per unit hour worked
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" /> Metric tons CO2e per metric ton of product
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" /> Metric tons of CO2e per liter of product
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" /> Metric tons CO2e per unit of production
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" /> Metric tons CO2e per unit of service provided
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" />  Metric tons CO2e per square foot
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" /> Metric tons CO2e per kilometer
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" /> Metric tons CO2e per passenger kilometer
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" /> Metric tons CO2e per megawatt hour (MWh)
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" /> Metric tons CO2e per barrel of oil equivalent (BOE
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" /> Metric tons CO2e per vehicle produced
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" />  Metric tons CO2e per metric ton of ore processed
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" /> Metric tons CO2e per ounce of gold
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" /> Metric tons CO2e per ounce of platinum
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" /> Metric tons of CO2e per metric ton of aggregate
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" />  Metric tons of CO2e per billion (currency) funds under management
                                    </label>
                                    <br />

                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" />  Grams CO2e per revenue passenger kilometer
                                    </label>
                                    <br />
                                </div>

                                <div class="gridlines-container">
                                    <table class="gridlines">
                                        <tbody>

                                            <tr>

                                                <td colspan="1" rowspan="1" className="tdHead">
                                                    Scope covered by the target

                                                </td>

                                                <td colspan="1" rowspan="1" className="tdHead">
                                                    Target Timeframe
                                                </td>

                                                <td colspan="1" rowspan="1" className="tdHead">
                                                    Baseline year emissions covered and as a % of total base year emissions

                                                </td>


                                                <td colspan="1" rowspan="1" className="tdHead">
                                                    % reduction target from base year

                                                </td>


                                                <td colspan="1" rowspan="1" className="tdHead">
                                                    Is this target validated by the Science-based Targets Initiative?
                                                </td>
                                            </tr>

                                            <tr>
                                                <td colspan="1" rowspan="4" class="">
                                                    Scope 1 + 2 combined
                                                </td>
                                                <td colspan="1" rowspan="4" className="text-center">Base year </td>
                                                <td colspan="1" rowspan="4" className="text-center">Base year emissions</td>
                                                <td colspan="1" rowspan="4" class="">

                                                </td>

                                                <td colspan="1" rowspan="1" class="">
                                                    Yes
                                                </td>


                                            </tr>


                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                    No, but we have publicly committed to seek validation to the target by the SBTi
                                                </td>

                                            </tr>

                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                    No, but we consider the target to be science-based
                                                </td>
                                            </tr>
                                            <tr>

                                                <td colspan="1" rowspan="1" class="">
                                                    No, the target is not sciencebased
                                                </td>

                                            </tr>


                                            <tr>
                                                <td colspan="1" rowspan="4" class="">
                                                    Scope 1 + 2 + 3 combined
                                                </td>
                                                <td colspan="1" rowspan="4" className="text-center">Target year </td>
                                                <td colspan="1" rowspan="4" className="text-center">Percentage of total base year emissions</td>
                                                <td colspan="1" rowspan="4" class="">

                                                </td>

                                                <td colspan="1" rowspan="1" class="">
                                                    Yes
                                                </td>


                                            </tr>


                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                    No, but we have publicly committed to seek validation to the target by the SBTi
                                                </td>

                                            </tr>

                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                    No, but we consider the target to be science-based
                                                </td>
                                            </tr>
                                            <tr>

                                                <td colspan="1" rowspan="1" class="">
                                                    No, the target is not sciencebased
                                                </td>

                                            </tr>


                                            <tr>
                                                <td colspan="1" rowspan="4" class="">
                                                    Scope 1
                                                </td>
                                                <td colspan="1" rowspan="4" className="text-center">Target year </td>
                                                <td colspan="1" rowspan="2" className="text-center">Base year emissions</td>

                                                <td colspan="1" rowspan="4" class="">

                                                </td>

                                                <td colspan="1" rowspan="1" class="">
                                                    Yes
                                                </td>


                                            </tr>


                                            <tr>


                                                <td colspan="1" rowspan="1" class="">
                                                    No, but we have publicly committed to seek validation to the target by the SBTi
                                                </td>


                                            </tr>

                                            <tr>

                                                <td colspan="1" rowspan="2" class="">
                                                    Percentage of total base year emissions
                                                </td>
                                                <tr>

                                                    <td colspan="1" rowspan="1" class="">
                                                        No, but we consider the target to be science-based
                                                    </td>

                                                </tr>
                                            </tr>


                                            <tr>

                                                <td colspan="1" rowspan="1" class="">
                                                    No, the target is not sciencebased
                                                </td>

                                            </tr>


                                            <tr>
                                                <td colspan="1" rowspan="4" class="">
                                                    Scope 2
                                                </td>
                                                <td colspan="1" rowspan="4" className="text-center">Target year </td>
                                                <td colspan="1" rowspan="2" className="text-center">Base year emissions</td>

                                                <td colspan="1" rowspan="4" class="">

                                                </td>

                                                <td colspan="1" rowspan="1" class="">
                                                    Yes
                                                </td>


                                            </tr>


                                            <tr>


                                                <td colspan="1" rowspan="1" class="">
                                                    No, but we have publicly committed to seek validation to the target by the SBTi
                                                </td>


                                            </tr>

                                            <tr>

                                                <td colspan="1" rowspan="2" class="">
                                                    Percentage of total base year emissions
                                                </td>
                                                <tr>

                                                    <td colspan="1" rowspan="1" class="">
                                                        No, but we consider the target to be science-based
                                                    </td>

                                                </tr>
                                            </tr>


                                            <tr>

                                                <td colspan="1" rowspan="1" class="">
                                                    No, the target is not sciencebased
                                                </td>

                                            </tr>


                                            <tr>
                                                <td colspan="1" rowspan="4" class="">
                                                    Scope 3
                                                </td>
                                                <td colspan="1" rowspan="4" className="text-center">Target year </td>
                                                <td colspan="1" rowspan="2" className="text-center">Base year emissions</td>

                                                <td colspan="1" rowspan="4" class="">

                                                </td>

                                                <td colspan="1" rowspan="1" class="">
                                                    Yes
                                                </td>


                                            </tr>


                                            <tr>


                                                <td colspan="1" rowspan="1" class="">
                                                    No, but we have publicly committed to seek validation to the target by the SBTi
                                                </td>


                                            </tr>

                                            <tr>

                                                <td colspan="1" rowspan="2" class="">
                                                    Percentage of total base year emissions
                                                </td>
                                                <tr>

                                                    <td colspan="1" rowspan="1" class="">
                                                        No, but we consider the target to be science-based
                                                    </td>

                                                </tr>
                                            </tr>


                                            <tr>

                                                <td colspan="1" rowspan="1" class="">
                                                    No, the target is not sciencebased
                                                </td>

                                            </tr>




                                        </tbody>
                                    </table>
                                </div>

                                <sectionheader1 id={"secai"}>
                                    2.6.10 Low-Carbon Products
                                    <div className="secHead1">
                                        2.6.10 Low-Carbon Products
                                    </div>
                                </sectionheader1>

                                <div class="gridlines-container">
                                    <table class="gridlines">
                                        <tbody>

                                            <tr>
                                                <td colspan="4" rowspan="1" class="">
                                                    {`Please provide details of your products and/or services that you classify as low carbon products or that enable a third party to avoid GHG emissions. You may provide information on either low carbon products, avoided emission products or both, depending on the relevance of the product types to your business. Please attach supporting evidence.`}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="1" rowspan="1" className="tdHead">
                                                    Type & Description of product(s)
                                                </td>
                                                <td colspan="1" rowspan="1" className="tdHead">
                                                    Public Reporting Level of aggregation
                                                </td>

                                                <td colspan="1" rowspan="1" className="tdHead">
                                                    % of total revenues from "climate
                                                    change" product(s) in FY 2022

                                                </td>

                                                <td colspan="1" rowspan="1" className="tdHead">
                                                    Estimated total avoided emissions per year

                                                </td>

                                                <td colspan="1" rowspan="1" className="tdHead">
                                                    Comment
                                                </td>

                                            </tr>

                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                    Please specify a relevant example:
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center">Description publicly available</td>
                                                <td colspan="1" rowspan="1" className="text-center"> Product</td>

                                                <td colspan="1" rowspan="3" className="text-center"> </td>
                                                <td colspan="1" rowspan="3" className="text-center"> </td>
                                            </tr>



                                            <tr>
                                                <td colspan="1" rowspan="1" class="">

                                                </td>
                                                <td colspan="1" rowspan="2" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center">Group of products</td>

                                            </tr>

                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                    to be provided by client
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center">Companywide</td>


                                            </tr>


                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                    Please specify a relevant example:
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center">Description publicly available</td>
                                                <td colspan="1" rowspan="1" className="text-center"> Product</td>

                                                <td colspan="1" rowspan="3" className="text-center"> </td>
                                                <td colspan="1" rowspan="3" className="text-center"> </td>
                                            </tr>



                                            <tr>
                                                <td colspan="1" rowspan="1" class="">

                                                </td>
                                                <td colspan="1" rowspan="2" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center">Group of products</td>

                                            </tr>

                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                    to be provided by client
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center">Companywide</td>


                                            </tr>




                                        </tbody>
                                    </table>
                                </div>

                                <sectionheader1 id={"secai"}>
                                    2.6.11 Internal Carbon Pricing
                                    <div className="secHead1">
                                        2.6.11 Internal Carbon Pricing
                                    </div>
                                </sectionheader1>


                                <div class="gridlines-container">
                                    <table class="gridlines">
                                        <tbody>

                                            <tr>
                                                <td colspan="4" rowspan="1" class="">
                                                    {`Please indicate if TVS uses an internal price of carbon. If yes, please specify TVS’s objective to implement an internal carbon price and provide details of how this is being used within the organization and what the internal carbon price is. In case TVS uses more than one type of internal carbon prices, please report the price that has the greatest impact on your organization ( i.e. price * quantity of emissions generated).`}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="1" rowspan="1" className="tdHead">
                                                    GHG Scope
                                                </td>
                                                <td colspan="1" rowspan="1" className="tdHead">
                                                    Type of internal carbon price

                                                </td>

                                                <td colspan="1" rowspan="1" className="tdHead">
                                                    Application
                                                </td>

                                                <td colspan="1" rowspan="1" className="tdHead">
                                                    Price (per metric
                                                    tonne CO2e)
                                                </td>

                                                <td colspan="1" rowspan="1" className="tdHead">
                                                    Price setting
                                                    approach
                                                </td>

                                            </tr>

                                            <tr>
                                                <td colspan="1" rowspan="2" class="">
                                                    Scope 1
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center">Shadow price</td>
                                                <td colspan="1" rowspan="2" className="text-center">Companywide (with local variations accepted)</td>
                                                <td colspan="1" rowspan="1" class="">
                                                    currency
                                                </td>

                                                <td colspan="1" rowspan="1" class="">
                                                    External resources
                                                </td>


                                            </tr>

                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                    Internal fee
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center">currency</td>
                                                <td colspan="1" rowspan="1" className="text-center">Benchmarking against peers</td>

                                            </tr>


                                            <tr>
                                                <td colspan="1" rowspan="2" class="">
                                                    Scope 2
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center">Internal trading</td>
                                                <td colspan="1" rowspan="2" className="text-center">Selected business units</td>
                                                <td colspan="1" rowspan="1" class="">
                                                    currency
                                                </td>

                                                <td colspan="1" rowspan="2" class="">
                                                    Internal consultation
                                                </td>


                                            </tr>

                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                    Implicit price
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center">currency</td>

                                            </tr>


                                            <tr>
                                                <td colspan="1" rowspan="2" class="">
                                                    Scope 3
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center">Offsets</td>
                                                <td colspan="1" rowspan="2" className="text-center">Selected regions</td>
                                                <td colspan="1" rowspan="1" class="">
                                                    currency
                                                </td>

                                                <td colspan="1" rowspan="2" class="">
                                                    Technical
                                                </td>


                                            </tr>

                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                    Other, please specify:
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center">Technical</td>

                                            </tr>


                                        </tbody>
                                    </table>
                                </div>

                                <sectionheader1 id={"secai"}>
                                    2.6.12 Net-Zero Commitment
                                    <div className="secHead1">
                                        2.6.12 Net-Zero Commitment
                                    </div>
                                </sectionheader1>

                                <div class="para m-3">
                                    <label class="fb5">Has TVS publicly committed to reaching net-zero GHG emissions and set targets and programs to fulfil the commitment? Please note that this question should only be answered if a near-term absolute or relative emission reduction target is reported in the previous question Emissions Reduction Targets.</label>
                                    <ul class="pad30">
                                        <li class="">Yes, we have publicly committed to reaching net-zero emissions across our value chain. Please provide details of a long-term emission reduction target linked to your net-zero commitment and indicate where this is available in your public reporting.</li>

                                    </ul>
                                </div>

                                <div class="gridlines-container">
                                    <table class="gridlines">
                                        <tbody>

                                            <tr>

                                                <td colspan="1" rowspan="1" className="tdHead">
                                                    Target Time Frame

                                                </td>

                                                <td colspan="1" rowspan="1" className="tdHead">

                                                    Target scope & related emission reduction target (as % of base year emissions) ?
                                                </td>


                                                <td colspan="1" rowspan="1" className="tdHead">

                                                    Is the target validated by Science Based Targets initiative
                                                </td>

                                            </tr>

                                            <tr>
                                                <td colspan="1" rowspan="2" class="">
                                                    Base Year
                                                </td>
                                                <td colspan="1" rowspan="2" className="text-center">Scope 1 & 2</td>
                                                <td colspan="1" rowspan="1" className="text-center">Yes</td>


                                            </tr>


                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                    No, but we have publicly committed to seek validation to the target by SBTi
                                                </td>

                                            </tr>


                                            <tr>
                                                <td colspan="1" rowspan="2" class="">
                                                    Target Year
                                                </td>
                                                <td colspan="1" rowspan="2" className="text-center">Scope 3</td>
                                                <td colspan="1" rowspan="1" className="text-center">No, but we consider the target to be science-based</td>


                                            </tr>


                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                    No, the target is not sciencebased
                                                </td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className="display-box">
                                    <div style={{ marginTop: '15px' }}>
                                        <p className="text-bold"> Net-zero Strategy:</p>
                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" /> We have defined or already implemented programs or activities to achieve the emission reduction targets.
                                        </label>
                                        <br />

                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" />  Scope 1 & 2
                                        </label>
                                        <br />


                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" /> Scope 3
                                        </label>
                                        <br />


                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" /> We intend to neutralize residual emissions and/or further mitigate emissions beyond our value chain with the following activities:
                                        </label>
                                        <br />


                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" />  Offsetting, e.g., purchasing carbon credits
                                        </label>
                                        <br />


                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" />  Investing in permanent carbon removal
                                        </label>
                                        <br />


                                    </div>

                                </div>

                                <sectionheader1 id={"secai"}>
                                    2.7 Biodiversity
                                    <div className="secHead1">
                                        2.7 Biodiversity
                                    </div>
                                </sectionheader1>

                                <sectionheader1 id={"secai"}>
                                    2.7.1 Biodiversity Risk Assessment
                                    <div className="secHead1">
                                        2.7.1 Biodiversity Risk Assessment
                                    </div>
                                </sectionheader1>

                                <div class="para m-3">
                                    <label class="fb5"> Has TVS assessed dependency- and impact-related biodiversity risks covering all relevant activities?</label>
                                    <ul class="pad30">
                                        <li class=""> Yes, we have completed a biodiversity risk assessment. Please provide supporting evidence for options selected below and indicate if the evidence is publicly
                                            available. Process Description. Describe the processes used for identifying and assessing dependency- and impact-related biodiversity risks covering the elements below and indicate if this information is reported in the public domain. Please provide supporting evidence:</li>

                                    </ul>
                                </div>

                                <div className="display-box">
                                    <div style={{ marginTop: '15px' }}>
                                        <p className="text-bold"> We publicly report on the process steps of our biodiversity risk assessment</p>
                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" /> Use of location-specific approach
                                        </label>
                                        <br />

                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" />  References to methodologies or frameworks used for assessment
                                        </label>
                                        <br />


                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" /> Integrated into multi-disciplinary company-wide risk management processes
                                        </label>
                                        <br />


                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" /> Dependency-related biodiversity risks considered in risk assessment
                                        </label>
                                        <br />


                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" />  Impact-related biodiversity risks considered in risk assessment
                                        </label>
                                        <br />

                                        <p className="text-bold">Scope of biodiversity risk assessment Please indicate the scope of your biodiversity risk assessment, and indicate if this information is reported in the public domain. Please provide supporting evidence:</p>

                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" />  We publicly report on the scope of our biodiversity risk assessment
                                        </label>
                                        <br />

                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" />  Own operations
                                        </label>
                                        <br />

                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" /> Adjacent areas to own operations
                                        </label>
                                        <br />

                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" /> Upstream activities
                                        </label>
                                        <br />

                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" />  Downstream activities
                                        </label>
                                        <br />


                                        <p className="text-bold"> Risks identified</p>

                                        <p className="text-bold">Indicate if biodiversity risks were identified, and indicate if this information is reported in the public domain. Please provide supporting evidence:</p>

                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" /> We publicly report on the biodiversity-related risks which were identified.
                                        </label>
                                        <br />

                                        <label className="checkbox-label">
                                            <input type="radio" name="policy" value="yes" />Biodiversity-related risks identified
                                        </label>
                                        <br />

                                        <label className="checkbox-label">
                                            <input type="radio" name="policy" value="yes" /> No biodiversity-related risks
                                        </label>
                                        <br />


                                        <label className="checkbox-label">
                                            <input type="radio" name="policy" value="yes" />No, we have not done a biodiversity risk assessment
                                        </label>
                                        <br />


                                        <label className="checkbox-label">
                                            <input type="radio" name="policy" value="yes" /> Not applicable. Please provide explanations in the comment box below
                                        </label>
                                        <br />


                                        <label className="checkbox-label">
                                            <input type="radio" name="policy" value="yes" />Not known
                                        </label>
                                        <br />


                                    </div>

                                </div>

                                <sectionheader1 id={"secai"}>
                                    2.7.2 Biodiversity Commitment
                                    <div className="secHead1">
                                        2.7.2 Biodiversity Commitment
                                    </div>
                                </sectionheader1>

                                <div class="para m-3">
                                    <label class="fb5">Does TVS have a public policy or commitment on biodiversity? Please indicate where this information is available in your public reporting or corporate website.</label>
                                    <ul class="pad30">
                                        <li class="">Yes, we have a public policy or commitment in place on biodiversity</li>

                                    </ul>
                                </div>


                                <div className="display-box">
                                    <div style={{ marginTop: '15px' }}>
                                        <p className="text-bold"> Policy or Commitment aspects</p>
                                        <p> Our policy or commitment defines the following aspects:</p>
                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" /> Achievement of a net positive impact (NPI) on biodiversity
                                        </label>
                                        <br />

                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" />  Definition of biodiversity-related targets for priority areas to work towards no net loss
                                        </label>
                                        <br />


                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" />  Commitment required of value chain to avoid operational activities near sites containing globally or nationally important biodiversity
                                        </label>
                                        <br />


                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" /> Application of a mitigation hierarchy
                                        </label>
                                        <br />


                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" />  ❏Conducting a biodiversity risk assessment
                                        </label>
                                        <br />
                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" />  Engagement with stakeholders on biodiversity
                                        </label>
                                        <br />

                                        <p className="text-bold">Scope of Commitment</p>

                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" />   Own Operations
                                        </label>
                                        <br />

                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" />  Suppliers
                                        </label>
                                        <br />

                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" /> Partners
                                        </label>
                                        <br />


                                    </div>

                                </div>

                                <sectionheader1 id={"secai"}>
                                    2.7.3 No Deforestation Commitment
                                    <div className="secHead1">
                                        2.7.3 No Deforestation Commitment
                                    </div>
                                </sectionheader1>

                                <div class="para m-3">
                                    <label class="fb5">Does TVS have a publicly available no-deforestation policy or commitment for TVS's own operational activities, and is this commitment applicable to TVS's supply chain?</label>
                                    <ul class="pad30">
                                        <li class="">Yes, we have a public policy or commitment in place on no-deforestation</li>

                                    </ul>
                                </div>

                                <div className="display-box">
                                    <div style={{ marginTop: '15px' }}>
                                        <p className="text-bold"> Scope of commitment</p>
                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" /> Own Operations
                                        </label>
                                        <br />

                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" />  Suppliers
                                        </label>
                                        <br />


                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" /> Partners
                                        </label>
                                        <br />




                                        <p className="text-bold">Policy Endorsement</p>

                                        <label className="checkbox-label">
                                            <input type="checkbox" name="element1" value="element1" />   Is your policy or commitment endorsed by a member of either the Board of Directors, or Executive Managemen
                                        </label>
                                        <br />



                                        <label className="checkbox-label">
                                            <input type="radio" name="policy" value="yes" /> Board of Directors
                                        </label>
                                        <br />

                                        <label className="checkbox-label">
                                            <input type="radio" name="policy" value="yes" />Executive Management
                                        </label>
                                        <br />


                                        <label className="checkbox-label">
                                            <input type="radio" name="policy" value="yes" />No, we have not No, we don’t have a policy or commitment on no deforestation.
                                        </label>
                                        <br />


                                        <label className="checkbox-label">
                                            <input type="radio" name="policy" value="yes" /> Not applicable. Please provide explanations in the comment box below
                                        </label>
                                        <br />




                                    </div>

                                </div>

                                <sectionheader1 id={"secai"}>
                                    2.8 Product Stewardship
                                    <div className="secHead1">
                                        2.8 Product Stewardship
                                    </div>
                                </sectionheader1>

                                <sectionheader1 id={"secai"}>
                                    2.8.1 Life Cycle Assessment
                                    <div className="secHead1">
                                        2.8.1 Life Cycle Assessment
                                    </div>
                                </sectionheader1>

                                <div class="para m-3">
                                    <label class="fb5"> Please indicate what impacts are covered by your life cycle assessment. Please check all that apply and provide supporting evidence. We do not expect companies to include all the impacts listed below.</label>
                                    <ul class="pad30">
                                        <li class="">Yes, we indicate the impacts covered by our life cycle assessment.</li>

                                    </ul>
                                </div>

                                <div class="gridlines-container">
                                    <table class="gridlines">
                                        <tbody>

                                            <tr>

                                                <td colspan="1" rowspan="1" className="tdHead">
                                                    Resource Use

                                                </td>

                                                <td colspan="1" rowspan="1" className="tdHead">

                                                    Ecological Consequences
                                                </td>


                                                <td colspan="1" rowspan="1" className="tdHead">

                                                    Human Health
                                                </td>

                                            </tr>

                                            <tr>
                                                <td colspan="1" rowspan="3" class="">
                                                    Abiotic depletion (fossil fuels, minerals)
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center"> Acidification</td>
                                                <td colspan="1" rowspan="3" className="text-center">Human toxicity</td>
                                            </tr>

                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                    Dust & particulate matter
                                                </td>

                                            </tr>

                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                    Ecotoxicity
                                                </td>

                                            </tr>

                                            <tr>
                                                <td colspan="1" rowspan="3" class="">
                                                    Land use
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center"> Eutrophication</td>
                                                <td colspan="1" rowspan="4" className="text-center"> Ionizing radiation</td>
                                            </tr>

                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                    Global warming
                                                </td>

                                            </tr>

                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                    Ozone depletion
                                                </td>

                                            </tr>

                                        </tbody>
                                    </table>
                                </div>


                                <div className="m-3 para">
                                    <label class="fb5">Please indicate the percentage of total products covered by the following Life Cycle Assessment approaches. The total sum should not exceed 100%.</label>

                                </div>

                                <div class="gridlines-container">
                                    <table class="gridlines">
                                        <tbody>

                                            <tr>

                                                <td colspan="1" rowspan="1" className="tdHead">

                                                    Description of the Life Cycle

                                                </td>

                                                <td colspan="1" rowspan="1" className="tdHead">
                                                    Assessment approach

                                                </td>


                                                <td colspan="1" rowspan="1" className="tdHead">
                                                    % of Total Products Publicly available

                                                </td>

                                            </tr>

                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                    Full LCAs
                                                    Please provide a relevant description of the Full LCA assessment approach
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center"> </td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                            </tr>

                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                    Simplified LCAs
                                                    Please provide a relevant description of the Full LCA assessment approach
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center"> </td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                            </tr>

                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                    Other externally recognized tools (e.g. material flow accounting, ecological footprinting, MIPS)
                                                    Please provide a relevant description of the Other externally recognized tools
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center"> </td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                            </tr>



                                        </tbody>
                                    </table>
                                </div>


                                <sectionheader1 id={"secai"}>

                                    <div className="secHead1">
                                        2.8.2 Resource Efficiency Benefits of Products
                                    </div>
                                </sectionheader1>

                                <div class="gridlines-container">
                                    <table class="gridlines">
                                        <tbody>
                                            <tr>
                                                <td colspan="5" rowspan="1" class="">
                                                    {`What percent of your products provide resource efficiency benefits during their use phase to your customers and consumers? Benefits include decreased energy consumption, decreased water consumption, decreased waste generation, GHG emissions reduction, pollution reduction, decreased raw material consumption, or increased product durability/longevity. Please explain what and how much benefit is provided through the use of your products and provide supporting evidence.`}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="1" rowspan="1" className="tdHead">
                                                    % of Total Products
                                                </td>
                                                <td colspan="1" rowspan="1" className="tdHead">
                                                    Description of benefits (Provide description of benefits below)
                                                </td>


                                            </tr>

                                            <tr>
                                                <td colspan="1" rowspan="1" class="">

                                                </td>

                                                <td colspan="1" rowspan="1" class="">

                                                </td>


                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className="display-box">
                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" /> Not applicable. Please provide an explanation in the comment box below.
                                    </label>
                                    <br />
                                </div>


                                <sectionheader1 id={"secai"}>

                                    <div className="secHead1">
                                        2.8.3 Hazardous Substances Commitment
                                    </div>
                                </sectionheader1>

                                <div class="gridlines-container">
                                    <table class="gridlines">
                                        <tbody>
                                            <tr>
                                                <td colspan="5" rowspan="1" class="">
                                                    {`Does TVS have a commitment to reduce or phase-out hazardous substances in its products? The commitment can be specific to particular products or substances, or general to your product portfolio. Please indicate where this information is available in your public reporting or corporate website.
`}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" rowspan="1" className="tdHead">
                                                    <label className="checkbox-label">
                                                        <input type="radio" name="policy" value="yes" /> Not applicable. Please provide an explanation in the comment box below.
                                                    </label>
                                                </td>

                                            </tr>

                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                    Description of commitment:
                                                </td>

                                                <td colspan="1" rowspan="1" class="">
                                                    <label className="checkbox-label">
                                                        <input type="checkbox" name="policy" value="yes" /> Please specify:
                                                    </label>
                                                </td>
                                            </tr>


                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                    Target year:
                                                </td>

                                                <td colspan="1" rowspan="1" class="">

                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                    No, we do not have a public commitment to phase-out hazardous substances.
                                                </td>

                                                <td colspan="1" rowspan="1" class="">
                                                    <label className="checkbox-label">
                                                        <input type="checkbox" name="policy" value="yes" /> Please specify:
                                                    </label>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className="display-box">
                                    <label className="checkbox-label">
                                        <input type="radio" name="policy" value="yes" /> Not applicable. Please provide explanations in the comment box below.
                                    </label>
                                    <br />
                                </div>

                                <sectionheader1 id={"secai"}>

                                    <div className="secHead1">
                                        2.8.4 End of Life Cycle Responsibility
                                    </div>
                                </sectionheader1>

                                <div class="para m-3">
                                    <label class="fb5"> To what extent is TVS actively and directly involved in product take back programs (e.g. disassembly, re-manufacturing, reuse or recycling of the product and its components)?</label>
                                    <ul class="pad30">
                                        <li class=""> Yes, we calculate the end of life cycle of our products in:</li>

                                        <label className="checkbox-label">
                                            <input type="radio" name="policy" value="yes" />Metric Tonnes
                                        </label>
                                        <br />
                                        <label className="checkbox-label">
                                            <input type="radio" name="policy" value="yes" /> cubic Tonnes
                                        </label>
                                        <br />
                                    </ul>

                                </div>

                                <div class="gridlines-container">
                                    <table class="gridlines">
                                        <tbody>

                                            <tr>
                                                <td colspan="4" rowspan="1" class="">
                                                    {`Please complete the table below and provide supporting documents for each row. Please ensure that you review the data requirement section (See Additional information and question guidance).`}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="1" rowspan="1" className="tdHead">

                                                </td>
                                                <td colspan="1" rowspan="1" className="tdHead">
                                                    Reference
                                                </td>
                                                <td colspan="1" rowspan="1" className="tdHead">
                                                    FY 2019
                                                </td>

                                                <td colspan="1" rowspan="1" className="tdHead">
                                                    FY 2020
                                                </td>


                                                <td colspan="1" rowspan="1" className="tdHead">
                                                    FY 2021
                                                </td>

                                                <td colspan="1" rowspan="1" className="tdHead">
                                                    FY 2022
                                                </td>


                                            </tr>

                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                    Percentage of products sold last year that can be reused or recycled (if products are partly recyclable please estimate the proportion):
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>

                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>

                                            </tr>

                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                    Percentage of products and materials that were actually reused or recycled by TVS, or by a third party you have directly contracted with for this activity (i.e. not disposed of as waste, or incinerated for
                                                    energy recovery) relative to total products produced:
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>

                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>


                                            </tr>

                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                    If your take back programs generate a financial benefit
                                                    to the company, please quantify this benefit for
                                                    all the takeback programs combined e.g. revenues generated and/ or costs saved (leave blank or write 0 if take back programs only generate costs):
                                                    Currency:
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>

                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>


                                            </tr>


                                        </tbody>
                                    </table>
                                </div>


                                <div class="para m-3">
                                    <label class="fb5"> No, our company does not actively participate in take back programs.</label>
                                    <ul class="pad30">

                                        <label className="checkbox-label">
                                            <input type="radio" name="policy" value="yes" />Not applicable. Please provide explanations in the comment box below.
                                        </label>
                                        <br />
                                        <label className="checkbox-label">
                                            <input type="radio" name="policy" value="yes" />  Not known
                                        </label>
                                        <br />
                                    </ul>

                                </div>

                                <sectionheader1 id={"secai"}>

                                    <div className="secHead1">
                                        2.8.5 Environmental Labels & Declarations
                                    </div>
                                </sectionheader1>

                                <div class="gridlines-container">
                                    <table class="gridlines">
                                        <tbody>

                                            <tr>
                                                <td colspan="2" rowspan="1" class="">
                                                    {`Does TVS provide product environmental declarations or other types of eco-labelling to customers? Please indicate the types of labeling below and attach supporting documents. Do not double count products
with more than one label (e.g. products that are already counted under Type III should not also be included in Type I), the three figures should not exceed 100%.`}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="1" rowspan="1" className="tdHead">
                                                    Environmental Labels and Declarations
                                                </td>
                                                <td colspan="1" rowspan="1" className="tdHead">
                                                    % of Revenues Covered in FY 2022
                                                </td>

                                            </tr>

                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                    Total (should not exceed 100%)

                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>


                                            </tr>

                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                    Type III Environmental Product Declarations
                                                    (in accordance with ISO 14025 or the European
                                                    construction standard EN 15804)

                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>


                                            </tr>

                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                    Type II self-declared environmental claims (in
                                                    accordance with ISO 14021)

                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>


                                            </tr>

                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                    Type I or other ecolabels (in accordance with ISO
                                                    14024 or independent ecolabels e.g. WWF, national
                                                    and international labels e.g. energy star, LEED, or
                                                    accepted industry-specific best practices).

                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>


                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="para m-3">
                                    <label class="fb5"> No environmental product declarations or labels are available to customers.</label>
                                    <ul class="pad30">

                                        <label className="checkbox-label">
                                            <input type="radio" name="policy" value="yes" />Not applicable. Please provide explanations in the comment box below.
                                        </label>
                                        <br />
                                        <label className="checkbox-label">
                                            <input type="radio" name="policy" value="yes" />  Not known
                                        </label>
                                        <br />
                                    </ul>

                                </div>

                                <sectionheader1 id={"secai"}>

                                    <div className="secHead1">
                                        2.9 Automotive Use-phase Decarbonization
                                    </div>
                                </sectionheader1>

                                <sectionheader1 id={"secai"}>

                                    <div className="secHead1">
                                        2.9.1 Electric Vehicle (EV) Battery Degradation
                                    </div>
                                </sectionheader1>

                                <div class="para m-3">
                                    <label class="fb5"> Please select the applicable range for the average battery degradation of your EV offering in the form of average number of kilometers and years before the battery reaches 70-80% of its original total capacity. In case TVS cannot report this information, battery warranty information can be used as a proxy</label>
                                    <ul class="pad30">

                                        <label className="checkbox-label">
                                            <input type="checkbox" name="policy" value="yes" />This information is available publicly.
                                        </label>
                                        <br />
                                        <label className="checkbox-label">
                                            <input type="checkbox" name="policy" value="yes" /> The information reported is measured/estimated, and not based on battery warranties.
                                        </label>
                                        <br />
                                    </ul>

                                </div>


                                <div className="display-box">
                                    <div style={{ marginTop: '15px' }}>
                                        <p className="text-bold"> Battery Degradation - Distance driven (Kilometers)</p>

                                        <p > Please select the average number of kilometres driven before the EV battery reaches 70-80% of its original total capacity</p>



                                        <label className="checkbox-label">
                                            <input type="radio" name="element1" value="element1" /> 150,000 - 199,999 km
                                        </label>
                                        <br />

                                        <label className="checkbox-label">
                                            <input type="radio" name="element1" value="element1" /> 200,000 - 249,999 km
                                        </label>
                                        <br />

                                        <label className="checkbox-label">
                                            <input type="radio" name="element1" value="element1" />250,000 - 300,000 km
                                        </label>
                                        <br />


                                    </div>



                                </div>

                                <div className="display-box">
                                    <div style={{ marginTop: '15px' }}>
                                        <p className="text-bold"> Battery Degradation - Years of use</p>

                                        <p >Please select the average number of years before the EV battery reaches 70-80% of its original total capacity</p>



                                        <label className="checkbox-label">
                                            <input type="radio" name="element1" value="element1" /> 8 - 10 y
                                        </label>
                                        <br />

                                        <label className="checkbox-label">
                                            <input type="radio" name="element1" value="element1" />  11 - 12 y
                                        </label>
                                        <br />

                                        <label className="checkbox-label">
                                            <input type="radio" name="element1" value="element1" /> 13 - 15 y
                                        </label>
                                        <br />

                                        <label className="checkbox-label">
                                            <input type="radio" name="element1" value="element1" /> We do not measure battery degradation for electric vehicles
                                        </label>
                                        <br />


                                    </div>



                                </div>

                                <sectionheader1 id={"secai"}>

                                    <div className="secHead1">
                                        2.9.2 Electric Vehicle Efficiency
                                    </div>
                                </sectionheader1>

                                <div class="gridlines-container">
                                    <table class="gridlines">
                                        <tbody>

                                            <tr>
                                                <td colspan="5" rowspan="1" class="">
                                                    {`Please provide the sales-weighted average energy economy of your Battery Electric Vehicles (BEV) and Fuel Cell Electric Vehicle (FCEV) of the fleet under your brands for the past four years in the table below. We request the values for combined city and highway driving and are driving ranges in electric mode. Select whether your range test cycle is reported in WLTP, the EPA, and/or the NEDC. Please specify the unit of measurement (kWh/100km or kWh/100mi, or MPG-e) and make sure that you always use the same unit in one row. Please also indicate the percentage of cars sold represented by the data reported for each test cycle standard as the percentage of all BEV and FCEV sold globally, even if no efficiency figures are provided using the standard (hence 0% sales). Please note, if you do not offer BEV and/or FCEV, please select the option “we do not offer BEV and/or FCEV”.`}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="1" rowspan="1" className="tdHead">
                                                    NEDC
                                                </td>
                                                <td colspan="1" rowspan="1" className="tdHead">
                                                    FY 2019
                                                </td>
                                                <td colspan="1" rowspan="1" className="tdHead">
                                                    FY 2020
                                                </td>
                                                <td colspan="1" rowspan="1" className="tdHead">
                                                    FY 2021
                                                </td>
                                                <td colspan="1" rowspan="1" className="tdHead">
                                                    FY 2022
                                                </td>

                                            </tr>

                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                    Total percentage of the BEV & FCEV sold using the WLTP, EPA, and CAFC-NEDC energy economy standards (should not exceed 100%)
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>

                                            </tr>

                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                    Average BEV & FCEV energy economy. Please specify unit:
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>

                                            </tr>


                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                    % of new cars sold (as a percentage of all BEV and FCEV sold globally):
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>

                                            </tr>


                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                    Average BEV & FCEV energy economy. Please specify
                                                    unit:
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>

                                            </tr>


                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                    % of new cars sold (as a percentage of all BEV and FCEV sold globally):
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>

                                            </tr>


                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                    Total percentage of the BEV & FCEV sold using the
                                                    WLTP, EPA, and CAFC-NEDC energy economy standards (should not exceed 100%)
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>

                                            </tr>


                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                    Average BEV & FCEV energy economy.
                                                    Please specify unit:
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>

                                            </tr>


                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                    % of new cars sold (as a percentage of all BEV and FCEV sold globally):
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>

                                            </tr>


                                        </tbody>
                                    </table>
                                </div>

                                <div class="para m-3">
                                    <label class="fb5"> We do not measure the average energy consumption economy efficiency of our BEV or FCEV and/or we do not offer BEV or FCEV.</label>
                                    <ul class="pad30">

                                        <label className="checkbox-label">
                                            <input type="radio" name="policy" value="yes" />Not applicable. Please provide explanations in the comment box below.
                                        </label>
                                        <br />
                                        <label className="checkbox-label">
                                            <input type="radio" name="policy" value="yes" /> Not Know
                                        </label>
                                        <br />
                                    </ul>

                                </div>

                                <sectionheader1 id={"secai"}>

                                    <div className="secHead1">
                                        2.9.3 Governance Checks for Vehicle Efficiency & Emissions
                                    </div>
                                </sectionheader1>

                                <div class="para m-3">
                                    <label class="fb5"> Does TVS have management measures in place to analyze and act on possible discrepancies for data on vehicle efficiency and emissions between the below two datasets?
                                        a) Data reported to authorities (dataset 1)
                                        b) Data collected and analyzed in independent real-life driving situations or currently non-regulated off-cycle tests (dataset 2).</label>
                                    <ul class="pad30">
                                        <li class=""> Yes, our company has the following management measures in place. Please provide supporting evidence:</li>

                                        <label className="checkbox-label">
                                            <input type="checkbox" name="policy" value="yes" />The required information is publicly available.
                                        </label>
                                        <br />

                                        <label className="checkbox-label">
                                            <input type="checkbox" name="policy" value="yes" /> Our company collects dataset 2 data on vehicle efficiency, carbon emissions or at least one other emissions category (in the case of diesel this would be NOx-emissions) for vehicles/models currently developed or produced or sold (if produced by third parties) and analyzes possible discrepancies internally.
                                        </label>
                                        <br />


                                        <label className="checkbox-label">
                                            <input type="checkbox" name="policy" value="yes" /> Our company compares dataset 2 data with third party data (from the EPA in the US, automobile clubs, universities, independent research associations etc.)
                                        </label>
                                        <br />


                                        <label className="checkbox-label">
                                            <input type="checkbox" name="policy" value="yes" /> A report analyzing trends and discrepancies between datasets 1 and 2 is delivered to our risk management department at least annually.
                                        </label>
                                        <br />


                                        <label className="checkbox-label">
                                            <input type="checkbox" name="policy" value="yes" /> Trends and discrepancies between datasets 1 and 2 are reported to the board of directors at least annually.
                                        </label>
                                        <br />
                                        <label className="checkbox-label">
                                            <input type="radio" name="policy" value="yes" /> No, our company does not have such management measures in place.
                                        </label>
                                        <br />
                                        <label className="checkbox-label">
                                            <input type="radio" name="policy" value="yes" />  Not applicable. Please provide explanations in the comment box below.
                                        </label>

                                    </ul>

                                </div>

                                <sectionheader1 id={"secai"}>

                                    <div className="secHead1">
                                        2.9.4 Vehicle Fuel & Carbon Efficiency
                                    </div>
                                </sectionheader1>

                                <div class="para m-3">
                                    <label class="fb5"> Do you measure the average vehicle or carbon efficiency of your vehicles sold under your brands for the past four years in the EU, the US and China? The data should meet respective market regulations and be supported with percentage of cars sold in those regions.</label>
                                    <ul class="pad30">
                                        <li class="">Yes, we do measure the average vehicle or carbon efficiency of our sold vehicles in one or more of these regions. Please select any that apply below: Supporting evidence:</li>

                                    </ul>
                                </div>

                                <div class="gridlines-container">
                                    <table class="gridlines">
                                        <tbody>

                                
                                            <tr>
                                                <td colspan="1" rowspan="1" className="tdHead">
                                                EU-27 (CO2 regulation - NEDC/WLTP)

                                                </td>
                                                <td colspan="1" rowspan="1" className="tdHead">
                                                    FY 2019
                                                </td>
                                                <td colspan="1" rowspan="1" className="tdHead">
                                                    FY 2020
                                                </td>
                                                <td colspan="1" rowspan="1" className="tdHead">
                                                    FY 2021
                                                </td>
                                                <td colspan="1" rowspan="1" className="tdHead">
                                                    FY 2022
                                                </td>
                                                <td colspan="1" rowspan="1" className="tdHead">
                                                Applicability
                                                </td>


                                            </tr>

                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                USA (CAFE – US combined)
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>

                                            </tr>

                                           
                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                China (CAFC -NEDC)
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>

                                            </tr>



                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                Total percentage of the cars sold in EU-27, USA and China (should not exceed 100%
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>

                                            </tr>



                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                Average CO2- efficiency (or alternative fuel
                                                    efficiency): Please, specifyunit:
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>

                                            </tr>



                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                % of new cars sold (as a percentage of all cars soldglobally):
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>

                                            </tr>



                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                Average CO2- efficiency (or alternative fuel
                                                    efficiency): Please, specifyunit:
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>

                                            </tr>



                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                % of new cars sold (as a percentage of all cars sold
                                                    globally):
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>

                                            </tr>

                                        </tbody>
                                    </table>
                                </div>

                                  <sectionheader1 id={"secai"}>

                                    <div className="secHead1">
                                    2.9.5 Alternative Drive Trains
                                    </div>
                                </sectionheader1>

                                <div class="para m-3">
                                    <label class="fb5"> Please provide the total number of vehicles (combustion engine and alternative drive train) sold and the number of vehicles by alternative drive train type sold globally in the last fiscal year. Please also provide your
                                    target sales for each category, as a percentage of total vehicles sold, for the fiscal year 2030. Supporting evidence:</label>
                                    <ul class="pad30">
                                        <li class="">We track vehicles sold by drive train type.</li>

                                    </ul>
                                </div>

                                <div class="gridlines-container">
                                    <table class="gridlines">
                                        <tbody>

                                
                                            <tr>
                                                <td colspan="1" rowspan="1" className="tdHead">
                                                Type of alternative drive train 

                                                </td>
                                                <td colspan="1" rowspan="1" className="tdHead">
                                                Number of vehicles sold in the
past fiscal year

                                                </td>
                                                <td colspan="1" rowspan="1" className="tdHead">
                                                FY 2030 Projections (as a % of total vehicles sold)
                                                </td>
                                                


                                            </tr>

                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                Battery Electric Vehicles (BEVs) 
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                              
                                            </tr>

                                           
                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                Fuel Cell Electric Vehicles (FCEVs) 
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                               

                                            </tr>



                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                Plug-in Hybrid Electric Vehicles (PHEVs)
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                               

                                            </tr>



                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                Battery-assisted Hybrid Vehicles (BAHVs)
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                              

                                            </tr>



                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                Compressed Natural Gas Vehicles
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                

                                            </tr>



                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                Liquid Petroleum Gas Vehicles 
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                
                                            </tr>



                                            <tr>
                                                <td colspan="1" rowspan="1" class="">
                                                Flex Fuel Vehicles
                                                </td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                                <td colspan="1" rowspan="1" className="text-center"></td>
                                              
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>

                                <div class="para m-3">

                   <p className="text-bold"> Public Reporting</p>
                                    <ul class="pad30">

                                        <label className="checkbox-label">
                                            <input type="checkbox" name="policy" value="yes" /> Our data is publicly available. Please provide supporting evidence or web link.
                                        </label>
                                        <br />
                                        <label className="checkbox-label">
                                            <input type="radio" name="policy" value="yes" /> We did not sell any vehicles with alternative drive trains in the last fiscal year.
                                        </label>
                                        <br />

                                        <label className="checkbox-label">
                                            <input type="radio" name="policy" value="yes" /> Not applicable. Please provide explanations in the comment box below.
                                        </label>
                                        <br />

                                        <label className="checkbox-label">
                                            <input type="radio" name="policy" value="yes" /> Not Know
                                        </label>
                                        <br />
                                    </ul>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Djsi, comparisonFn);
