import React from "react";
import EmissionsCards from "../../Charts/EmissionsCard";
import Scopes from "./OverviewCharts/Scopes";
import DemoScope3 from "./OverviewCharts/DemoScope3Breakdown";
import RenewableNonRenewableBarChart from "../Overview/OverviewCharts/DemoEnergyBar";
import RenewableNonRenewablePie from "./OverviewCharts/DemoEnergyConsumption";
import DemoWaste from "./OverviewCharts/DemoWaste";
import DemoHazardous from "./OverviewCharts/DemoHazardous";
import DemoNonHazardous from "./OverviewCharts/DemoNonHazardous";
import DemoWaterWithdrawal from "./OverviewCharts/DemoWaterWithdrawal";
import DemoWaterConsumption from "./OverviewCharts/DemoWaterConsumption";
import DemoWaterDischarge from "./OverviewCharts/DemoWaterDischarge";
import LTIFChart from "../../ChartsOlderVersion/Ltif";
import { Card } from "primereact/card";
import DemoScope3Breakdown from "./OverviewCharts/DemoScope3Breakdown";
import RenewablePie from "./OverviewCharts/DemoEnergyConsumption";
import NonRenewablePie from "./OverviewCharts/NonRenewablePie";
export const CustomLegend = (props) => {
  const { payload } = props;
  return (
    <ul
      style={{
        display: "flex",
        listStyleType: "none",
        justifyContent: "center",
        padding: 0,
      }}
    >
      {payload.map((entry, index) => (
        <li
          key={`item-${index}`}
          style={{ color: entry.color, marginRight: 20 }}
        >
          <span
            style={{
              color: entry.color,
              marginRight: 4,
              fontSize: "20px",
            }}
          >
            ■
          </span>
          <span style={{ color: "#555", fontSize: "14px" }}>{entry.value}</span>
        </li>
      ))}
    </ul>
  );
};

export const DemoDashboard = () => {
  return (
    <div>
      <EmissionsCards />
      <div
        style={{
          fontFamily: "Lato",
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "19.2px",
          textAlign: "left",
          margin: "18px 10px 18px 10px",
        }}
      >
        Energy and Emissions
        <div style={{ fontWeight: 200, fontSize: "14px" }}>
          Energy mix and Carbon emissions across the organization
        </div>
        <Card>
          <Scopes />
        </Card>
        <Card>
          <DemoScope3Breakdown />
        </Card>
        <Card>
          <RenewableNonRenewableBarChart />
        </Card>
        <RenewablePie />
        <NonRenewablePie />
      </div>

      <div
        style={{
          fontFamily: "Lato",
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "19.2px",
          textAlign: "left",
          margin: "18px 10px 18px 10px",
        }}
      >
        Waste Management
        <div style={{ fontWeight: 200, fontSize: "14px" }}>
          Managing the collection and disposal of waste across the organization
        </div>
      </div>
      <Card>
        <DemoWaste />
      </Card>
      <Card>
        <DemoHazardous />
      </Card>
      <Card>
        <DemoNonHazardous />
      </Card>
      <div
        style={{
          fontFamily: "Lato",
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "19.2px",
          textAlign: "left",
          margin: "18px 10px 18px 10px",
        }}
      >
        Water Stewardship
        <div style={{ fontWeight: 200, fontSize: "14px" }}>
          Management of water withdrawal and disposal
        </div>
      </div>
      <Card>
        <DemoWaterWithdrawal />
      </Card>
      <Card>
        <DemoWaterConsumption />
      </Card>
      <Card>
        <DemoWaterDischarge />
      </Card>
      <div
        style={{
          fontFamily: "Lato",
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "19.2px",
          textAlign: "left",
          margin: "18px 10px 18px 10px",
        }}
      >
        Safety
        <div style={{ fontWeight: 200, fontSize: "14px" }}>
          Employee safety performance across the organization
        </div>
      </div>
      <Card>
        <LTIFChart />
      </Card>
    </div>
  );
};
