import React, { useEffect, useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { EditText } from "react-edit-text";
import EdiText from 'react-editext'
import { AutoComplete } from "primereact/autocomplete";
import Axios from "axios";
import { SplitButton } from "primereact/splitbutton";
import { MultiSelect } from 'primereact/multiselect';
import Swal from "sweetalert2";
import { Chips } from 'primereact/chips';
import useForceUpdate from "use-force-update";
import { useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from 'primereact/inputtextarea';
import { Tooltip } from 'primereact/tooltip';
import { ContextMenu } from 'primereact/contextmenu';
import { Dropdown } from "primereact/dropdown";
import { API } from "../../constants/api_url";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import { Checkbox } from "primereact/checkbox";
import { hardcoded } from "../../client/constants/hardcodedid";
import APIServices from "../../service/APIService";
const DataPointConfig = () => {
    const [module, setModule] = useState({
        tier1: "",
        tier2: "",
        tier3: "",
        tier0: "",
        tier4: ""
    });
    const tier4ref = useRef([]);
    const tier3ref = useRef([]);
    const tier2ref = useRef([]);
    const tier1ref = useRef([]);
    const [datasourcelist, setDataSourceList] = useState([])
    const [datasource2list, setDataSource2List] = useState([])

    const [sectionlist, setSectionList] = useState([])

    const [sectiondialog, setSectionDialog] = useState(false)
    const [sectionobj, setSectionObj] = useState({ userProfileId: null, newMetricId: null, indicatorSectionId: null })
    const [datasourcelist_, setDataSourceList_] = useState([])


    const [clienttag, setClientTag] = useState([])
    const [datapointconfigdialog, setDataPointConfigDialog] = useState(false)
    const [dpdcfmapdialog, setDPDCFMapDialog] = useState(false)
    const [metricconfigdialog, setMetricConfigDialog] = useState(false)
    const [dcfitems, setDCFItems] = useState([])
    const [sapitems, setSAPItems] = useState([])

    const [selected, setSelected] = useState({
        tier1: "",
        tier2: "",
        tier3: "",
        tier0: "",
        tier4: ""
    });
    const [metricList, setMetricList] = useState([])
    const [metricListBK, setMetricListBK] = useState([])
    const [selectedlist, setSelectedList] = useState({ title: '', data: [] })
    const [prevSListdialog, setPrevSListDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [config, setConfig] = useState({ unit: '', desc: '', tags: [], suffix: 'DP', datasource: null, datasource: null, datasource2: null, type: { name: 'Quantitative' } })
    const [metricconfig, setMetricConfig] = useState({ desc: '', type: 0, indicator: [], dcf: [], tags1: [], tags2: [], tags3: [], tags4: [], source: 1, rf: null, title: '', unit: '', general: false })
    const [draggedItem, setDraggedItem] = React.useState(null);
    const selector = useSelector((state) => state.user.admindetail);
    const [tier1, setTier1] = useState([]);
    const [tier2, setTier2] = useState([]);
    const [tier3, setTier3] = useState([]);
    const [tier4, setTier4] = useState([]);
    const [selectedDataPoint, setSelectedDataPoint] = useState('')
    const [metric, setSelectedMetric] = useState('')
    const [rfList, setRFList] = useState([])
    const [tier0, setTier0] = useState([])
    const [tierid, setTierID] = useState(null);
    const [orderlist, setOrderList] = useState([])
    const [orderdialog, setOrderDialog] = useState(false)


    const forceUpdate = useForceUpdate();
    useEffect(() => {
        APIServices.get(API.DCF + '?filter=%7B%22where%22%3A%7B%22or%22%3A%5B%7B%22type%22%3A1%7D,%7B%22type%22%3Anull%7D%5D%7D%7D').then((a) => {
            const hardcodeddcf = [...hardcoded.dcf, ...hardcoded.dcf2]
            setDataSourceList_(a.data.filter((i) => { return ((i.data1 !== null && i.data1.length !== 0) || (hardcodeddcf.includes(i.id.toString()))) }))
            a.data.forEach((a) => {
                a.name = a.suffix + ' ' + a.title
            })

            setDataSourceList(a.data.filter((i) => { return ((i.data1 !== null && i.data1.length !== 0) || (hardcodeddcf.includes(i.id.toString()))) }));
            forceUpdate()

        })
        APIServices.get(API.SapCollection).then((res) => {
            setDataSource2List(res.data.map(x => ({ name: x.sapId + ' : ' + x.title, id: x.id })))

            forceUpdate()

        })
        APIServices.get(API.UserProfile).then((res) => {
            let locuser = [];

            res.data.forEach((item) => {
                if (item.role === "clientadmin" && item.information.enterpriseid !== undefined) {
                    locuser.push({
                        name: item.information.enterpriseid,
                        id: item.id,
                    });
                }
            });

            setClientTag(locuser);
        });

        APIServices.get(API.Categories).then((res) => {
            setTier1(res.data);
        });
        APIServices.get(API.RF).then((res) => {
            const hardcodedrf = hardcoded.rf

            setRFList(res.data.filter((i) => { return ((i.data1 !== null && i.data1.length !== 0) || hardcodedrf.includes(i.id.toString())) }));
        });
        let uriString2 = {
            "include": [{ "relation": "newTopics", "scope": { "include": [{ "relation": "newMetrics", "scope": { "include": [{ "relation": "newDataPoints" }] } }] } }]


        }
        let Overall = API.Categories + `?filter=${encodeURIComponent(JSON.stringify(uriString2))}`;
        APIServices.get(Overall).then((res) => {
            let metric = []
            res.data.forEach((cat) => {
                if (cat.newTopics) {
                    cat.newTopics.forEach((top) => {
                        if (top.newMetrics) {
                            top.newMetrics.forEach((met) => {
                                met.category = cat.title
                                met.topic = top.title
                            })
                            metric.push(...top.newMetrics)
                        }
                    })
                }
            })

            setMetricList(metric);
            setMetricListBK(metric)
        });

    }, []);
    const deleteTier4 = (item) => {
        Swal.fire({
            title: `Are you sure want to delete ${item.title} ?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                let index = tier4.findIndex((i) => { return i.id === item.id })
                APIServices.delete(API.DataPoint_Edit(item.id)).then((res) => {
                    let loc = tier4
                    loc.splice(index, 1);

                    setTier4(loc);
                    forceUpdate();

                });
            }
        })

    }
    const setEditModeTier4 = (cyindex) => {
        tier4.map((i, j) => { if (j === cyindex) { i.edit = true } else { i.edit = false } })
        console.log(tier4)
        forceUpdate()
    }
    const setEditModeOffTier4 = (cyindex) => {
        tier4.map((i, j) => { i.edit = false })

        forceUpdate()
    }
    const setEditModeTier3 = (cyindex) => {
        tier3.map((i, j) => { if (j === cyindex) { i.edit = true } else { i.edit = false } })

        forceUpdate()
    }
    const cloneIndicator = async (obj) => {
        const { value: password } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Enter Password to clone Indicator</div>`,
            input: 'password',
            confirmButtonColor: 'red',
            inputPlaceholder: 'Enter your password',
            showCancelButton: true,

            inputAttributes: {
                maxlength: 15,
                autocapitalize: 'off',
                autocorrect: 'off',
                autocomplete: 'off',
            },
            didOpen: () => {



            }
        })
        if (password === 'clone_id' + obj.metric_id) {
            const result = await Swal.fire({
                title: 'Select Tag to Clone Indicator',
                input: 'select',
                inputOptions: clienttag.map(i => i.name).reduce((acc, option, index) => {
                    acc[index] = option;
                    return acc;
                }, {}),
                inputValidator: (value) => {
                    if (!value) {
                        return 'Tag is mandatory'
                    }
                },
                inputPlaceholder: 'Select Tag',
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Select',
            })
            console.log(result.isConfirmed)
            if (result.isConfirmed) {
                let metric_obj = tier3.find(i => i.id === obj.metric_id)

                if (metric_obj) {
                    if (Array.isArray(metric_obj.data1) && metric_obj.data1[0].type !== undefined && (metric_obj.data1[0].type === 0 && metric_obj.data1[0].source === 1)) {
                        APIServices.post(API.Indicator_With_Data_Point_Clone, { ...obj, tag: clienttag[parseFloat(result.value)].id }).then((res) => {
                            if (res.data.result === true) {
                                let loc3 = tier3
                                let metloc = metricListBK;
                                metloc.push(res.data.data)
                                setMetricListBK(metloc)
                                let metricbk = metricListBK
                                metricbk.push(res.data.data)
                                setMetricList(metricbk)
                                setMetricListBK(metricbk)
                                loc3.push(res.data.data)
                                setTier3(loc3)
                                forceUpdate()
                            }
                        })
                    } else if (Array.isArray(metric_obj.data1) && metric_obj.data1[0].type !== undefined && (metric_obj.data1[0].type === 1 || (metric_obj.data1[0].type === 0 && metric_obj.data1[0].source === 0))) {
                        APIServices.post(API.Indicator_Clone, { ...obj, tag: (clienttag[parseFloat(result.value)].id).toString() }).then((res) => {
                            if (res.data.result === true) {
                                let loc3 = tier3
                                let metloc = metricListBK;
                                metloc.push(res.data.data)
                                setMetricListBK(metloc)
                                let metricbk = metricListBK
                                metricbk.push(res.data.data)
                                setMetricList(metricbk)
                                setMetricListBK(metricbk)
                                loc3.push(res.data.data)
                                setTier3(loc3)
                                forceUpdate()
                            }
                        })
                    }
                }
            }
        }


    }
    const cloneTopic = async (obj) => {
        const { value: password } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Enter Password to clone Topic</div>`,
            input: 'password',
            confirmButtonColor: 'red',
            inputPlaceholder: 'Enter your password',
            showCancelButton: true,

            inputAttributes: {
                maxlength: 15,
                autocapitalize: 'off',
                autocorrect: 'off',
                autocomplete: 'off',
            },
            didOpen: () => {



            }
        })
        if (password === 'clone_id' + obj.topic_id) {
            const result = await Swal.fire({
                title: 'Select Tag to Clone Indicator',
                input: 'select',
                inputOptions: clienttag.map(i => i.name).reduce((acc, option, index) => {
                    acc[index] = option;
                    return acc;
                }, {}),
                inputValidator: (value) => {
                    if (!value) {
                        return 'Tag is mandatory'
                    }
                },
                inputPlaceholder: 'Select Tag',
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Select',
            })
            console.log(result.isConfirmed)
            if (result.isConfirmed) {
                let metric_obj = tier2.find(i => i.id === obj.topic_id)

                if (metric_obj) {
                    APIServices.post(API.Topic_Clone, { ...obj, tag: clienttag[parseFloat(result.value)].id }).then((res) => {
                        if (res.data.data !== undefined) {
                            let loc3 = tier2

                            loc3.push(res.data.data)
                            setTier2(loc3)
                            forceUpdate()
                        }
                    })
                }
            }
        }


    }
    const editTagSection = async (item, index) => {
        console.log(index)
        if (item.indicatorSectionAssignments) {
            let index = item.indicatorSectionAssignments.find(i => !i.userProfileId)
            console.log(index)
            if (index) {
                delete index.id
                setSectionObj(index)
                setSectionDialog(true)
                forceUpdate()
            } else {
                setSectionObj({ userProfileId: null, newMetricId: item.id, indicatorSectionId: null })
                setSectionDialog(true)
                forceUpdate()
            }

        } else {
            setSectionObj({ userProfileId: null, newMetricId: item.id, indicatorSectionId: null })
            setSectionDialog(true)
            forceUpdate()
        }

    }
    const editTagMetric = async (item, index) => {
        const result = await Swal.fire({
            title: 'Edit Tag',
            input: 'select',
            inputOptions: clienttag.map(i => i.name).reduce((acc, option, index) => {
                acc[index] = option;
                return acc;
            }, {}),
            inputValidator: (value) => {
                if (!value) {
                    return 'Tag is mandatory'
                }
            },
            inputPlaceholder: 'Select Tag',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Select',
        })
        if (result.isConfirmed) {
            APIServices.patch(
                API.Metric_Edit(item.id),
                {
                    tag: clienttag[parseFloat(result.value)].id
                },
            ).then((res) => {
                let loc = tier3;
                let index = tier3.findIndex((id) => { return id.id === item.id })
                loc[index]['tag'] = clienttag[parseFloat(result.value)].id;
                let metricbk = metricListBK
                let mbkind = metricListBK.findIndex((l) => { return l.id === item.id })
                if (mbkind !== -1) {
                    metricbk[index]['tag'] = clienttag[parseFloat(result.value)].id

                    setMetricListBK(metricbk)
                    setMetricList(metricbk)
                }
                setTier3(loc)
                setMetricConfigDialog(false)
                forceUpdate()
            });
        }
    }
    const editTagTopic = async (item, index) => {
        const result = await Swal.fire({
            title: 'Edit Tag',
            input: 'select',
            inputOptions: clienttag.map(i => i.name).reduce((acc, option, index) => {
                acc[index] = option;
                return acc;
            }, {}),
            inputValidator: (value) => {
                if (!value) {
                    return 'Tag is mandatory'
                }
            },
            inputPlaceholder: 'Select Tag',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Select',
        })
        if (result.isConfirmed) {
            APIServices.patch(
                API.Topic_Edit(item.id),
                {
                    tag: clienttag[parseFloat(result.value)].id
                },
            ).then((res) => {
                let loc = tier2;
                let index = tier2.findIndex((id) => { return id.id === item.id })
                loc[index]['tag'] = clienttag[parseFloat(result.value)].id;

                setTier2(loc)

                forceUpdate()
            });
        }
    }
    const setEditModeOffTier3 = (cyindex) => {
        tier3.map((i, j) => { i.edit = false })

        forceUpdate()
    }
    const setEditModeTier2 = (cyindex) => {
        tier2.map((i, j) => { if (j === cyindex) { i.edit = true } else { i.edit = false } })

        forceUpdate()
    }
    const setEditModeOffTier2 = (cyindex) => {
        tier2.map((i, j) => { i.edit = false })

        forceUpdate()
    }
    const setEditModeTier1 = (cyindex) => {
        tier1.map((i, j) => { if (j === cyindex) { i.edit = true } else { i.edit = false } })
        console.log(tier4)
        forceUpdate()
    }
    const setEditModeOffTier1 = (cyindex) => {
        tier1.map((i, j) => { i.edit = false })

        forceUpdate()
    }
    const deleteTier3 = (item) => {
        Swal.fire({
            title: `Are you sure want to delete ${item.title} ?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                let index = tier3.findIndex((i) => { return i.id === item.id })
                APIServices.delete(API.Metric_Edit(item.id)).then((res) => {
                    let sel = selected;
                    let metricbk = metricListBK
                    let mbkind = metricListBK.findIndex((l) => { return l.id === item.id })
                    if (mbkind !== -1) {
                        metricbk.splice(mbkind, 1);
                        setMetricListBK(metricbk)
                        setMetricList(metricbk)
                    }
                    sel.tier3 = ''

                    sel.tier4 = ''
                    setSelected(sel);
                    setTier4([])
                    let loc = tier3
                    loc.splice(index, 1);

                    setTier3(loc);
                    forceUpdate();

                });
            }
        })

    }
    const deleteTier2 = (item) => {
        Swal.fire({
            title: `Are you sure want to delete ${item.title} ?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                let index = tier2.findIndex((i) => { return i.id === item.id })
                APIServices.delete(API.Topic_Edit(item.id)).then((res) => {
                    let sel = selected;
                    sel.tier3 = ''
                    sel.tier2 = ''

                    sel.tier4 = ''
                    setSelected(sel);
                    setTier4([])
                    let loc = tier2
                    loc.splice(index, 1);

                    setTier2(loc);
                    forceUpdate();

                });
            }
        })

    }
    const deleteTier1 = (item) => {
        Swal.fire({
            title: `Are you sure want to delete ${item.title} ?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                let index = tier1.findIndex((i) => { return i.id === item.id })
                APIServices.delete(API.Categories_Edit(item.id)).then((res) => {
                    let sel = selected;
                    sel.tier3 = ''
                    sel.tier1 = ''
                    sel.tier2 = ''

                    sel.tier4 = ''
                    setSelected(sel);

                    let loc = tier1
                    loc.splice(index, 1);

                    setTier1(loc);
                    forceUpdate();

                });
            }
        })

    }
    const submitFooter = (
        <>
            <Button
                label="Close"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => {
                    setDataPointConfigDialog(false);
                }}
            />
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    updateDataPoints();
                }}
            />
        </>
    );
    const submitFooter_ = (
        <>
            <Button
                label="Close"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => {
                    setDPDCFMapDialog(false);
                }}
            />
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    updateDataPoints_();
                }}
            />
        </>
    );
    const submitFooter_2 = (
        <>
            <Button
                label="Close"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => {
                    setSectionDialog(false);
                }}
            />

        </>
    );
    const submitFooter_metric = (
        <>
            <Button
                label="Close"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => {
                    setMetricConfigDialog(false);
                }}
            />
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    updateMetric();
                }}
            />
        </>
    );
    const submitFooter_order = (
        <>
            {/* <Button
                label="Discard Changes"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => {
                    setMetricConfigDialog(false);
                }}
            /> */}
            <Button
                label="Save Order & Close"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveOrder();
                }}
            />
        </>
    );
    const saveOrder = () => {


        let newObj = JSON.parse(JSON.stringify(orderlist)).map((i) => { return { id: i.id, order: i.order } })
        if (tierid === 4) {
            APIServices.patch(API.DataPoint, newObj).then((res) => {

                setTier4(orderlist)
            })

        } else if (tierid === 3) {
            APIServices.patch(API.Metric, newObj).then((res) => {

                setTier3(orderlist)
            })

        } else if (tierid === 2) {
            APIServices.patch(API.Topic, newObj).then((res) => {

                setTier2(orderlist)
            })

        } else if (tierid === 1) {
            APIServices.patch(API.Categories, newObj).then((res) => {

                setTier1(orderlist)
            })

        }

        setOrderDialog(false)
    }
    const updateDataPoints = () => {

        setSubmitted(true);
        //   config.datasource !== null && !Array.isArray(config.datasource)
        // && config.suffix.trim().length !== 0 && config.suffix.trim()[0] === 'D' && config.suffix.trim()[1] === 'P'
        let locs = config

        locs.datasource = (config.datasource === null || config.datasource === undefined) ? null : config.datasource.id
        locs.datasource2 = (config.datasource2 === null || config.datasource2 === undefined) ? null : config.datasource2.id

        if (config.unit.trim().length !== 0 ) {


            APIServices.patch(API.DataPoint_Edit(selectedDataPoint.id),
                {
                    title: selectedDataPoint.title, data1: [locs], suffix: config.suffix
                },
            ).then((res) => {
                let loc = tier4;
                let index = tier4.findIndex((id) => { return id.id === selectedDataPoint.id })
                loc[index]['suffix'] = config.suffix
                if (loc.datasource !== null) {
                    loc.datasource = datasourcelist[datasourcelist.findIndex((k) => { return k.id === locs.datasource })]

                }
                if (loc.datasource2 !== null) {
                    loc.datasource2 = datasource2list[datasource2list.findIndex((k) => { return k.id === locs.datasource2 })]

                }
                loc[index]['data1'] = [config];
                setTier4(loc)
                setDataPointConfigDialog(false)
            });
        }

    }
    const updateDataPoints_ = () => {

        setSubmitted(true);
        //   config.datasource !== null && !Array.isArray(config.datasource)
        // && config.suffix.trim().length !== 0 && config.suffix.trim()[0] === 'D' && config.suffix.trim()[1] === 'P'
        if (config.unit.trim().length !== 0 && config.datasource !== undefined) {

            let locs = config

            locs.datasource = (config.datasource === null || config.datasource === undefined) ? null : config.datasource.id
            APIServices.patch(API.DataPoint_Edit(selectedDataPoint.id),
                {
                    title: selectedDataPoint.title, data1: [locs], suffix: config.suffix
                },
            ).then((res) => {
                let loc = tier4;
                let index = tier4.findIndex((id) => { return id.id === selectedDataPoint.id })
                loc[index]['suffix'] = config.suffix
                loc.datasource = datasourcelist[datasourcelist.findIndex((k) => { return k.id === locs.datasource })]

                loc[index]['data1'] = [config];
                setTier4(loc)
                setDPDCFMapDialog(false)
            });
        }

    }
    const updateMetric = () => {
        setSubmitted(true);
        if ((metricconfig.type === 1 && metricconfig.title.trim().length !== 0) || (metricconfig.type === 0 && metricconfig.unit.trim().length !== 0 && metricconfig.title.trim().length !== 0 && (metricconfig.source === 1 || (metricconfig.source === 0 && metricconfig.indicator.length !== 0)))) {

            APIServices.patch(
                API.Metric_Edit(metric.id),
                {
                    title: metric.title, data1: [metricconfig]
                },
            ).then((res) => {
                let loc = tier3;
                let index = tier3.findIndex((id) => { return id.id === metric.id })
                loc[index]['data1'] = [metricconfig];
                let metricbk = metricListBK
                let mbkind = metricListBK.findIndex((l) => { return l.id === metric.id })
                if (mbkind !== -1) {
                    metricbk[index]['data1'] = [metricconfig];

                    setMetricListBK(metricbk)
                    setMetricList(metricbk)
                }
                setTier3(loc)
                setMetricConfigDialog(false)

            });
        }

    }
    const setDataSource = () => {

    }
    const openConfig = (items) => {
        // setDataSource()
        let item = JSON.parse(items)
        setSubmitted(false)
        console.log(item)
        setSelectedDataPoint(item);
        if (item.data1 === null) {
            setConfig({ unit: '', desc: '', suffix: 'DP', tags: [], datasource: null, type: { name: 'Quantitative' } });
            setDataPointConfigDialog(true);
        } else {
            if (item.data1[0]['suffix'] === undefined) { item.data1[0]['suffix'] = 'DP' }

            if ((item.data1[0].datasource !== null && item.data1[0].datasource !== undefined) || !Array.isArray(item.data1[0].datasource)) {
                console.log(item.data1[0])


                let index1 = datasourcelist.findIndex((k) => { return k.id === item.data1[0].datasource })
                if (index1 !== -1) {
                    item.data1[0].datasource = datasourcelist[index1]
                } else {
                    item.data1[0].datasource = null
                }

                let index2 = datasource2list.findIndex((k) => { return k.id === item.data1[0].datasource2 })
                if (index2 !== -1) {
                    item.data1[0].datasource2 = datasource2list[index2]
                } else {
                    item.data1[0].datasource2 = null
                }



            }

            setConfig(item.data1[0]);
            setDataPointConfigDialog(true);
        }
    }
    const openConfig_ = async (items) => {
        // setDataSource()
        let item = JSON.parse(items)
        const { value: password } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Enter Password to Update Mapping</div>`,
            input: 'password',

            confirmButtonColor: '#005284',
            inputPlaceholder: 'Enter your password',
            showCancelButton: true,

            inputAttributes: {
                maxlength: 15,
                autocapitalize: 'off',
                autocorrect: 'off',
                autocomplete: 'off',
            },
            didOpen: () => {



            }
        })
        if (password === 'mapDcf2Dp') {
            setSubmitted(false)
            console.log(item)
            setSelectedDataPoint(item);
            if (item.data1 === null) {
                setConfig({ unit: '', desc: '', suffix: 'DP', tags: [], datasource: null, type: { name: 'Quantitative' } });
                setDPDCFMapDialog(true);
            } else {
                if (item.data1[0]['suffix'] === undefined) { item.data1[0]['suffix'] = 'DP' }

                if ((item.data1[0].datasource !== null && item.data1[0].datasource !== undefined) || !Array.isArray(item.data1[0].datasource)) {

                    item.data1[0].datasource = datasourcelist[datasourcelist.findIndex((k) => { return k.id === item.data1[0].datasource })]
                }

                setConfig(item.data1[0]);
                setDPDCFMapDialog(true);


            }
        }

    }
    const openMetricConfig = (items) => {
        let item = JSON.parse(items)
        setSubmitted(false)
        setSelectedMetric(item);

        setMetricList(metricListBK.filter((i) => { return i.id !== item.id }).filter((j) => { return Array.isArray(j.data1) && j.data1[0].type !== undefined && j.data1[0].type === 0 }))
        console.log(item, metricList)
        if (item.data1 === null) {

            setMetricConfig({ desc: '', type: 0, indicator: [], dcf: [], tags1: [], tags2: [], tags3: [], tags4: [], source: 1, rf: null, title: '', unit: '', general: false });
            setMetricConfigDialog(true);
        } else {
            if (Object.keys(item.data1[0]).length === 2) {
                setMetricConfig({ ...item.data1[0], type: 0, indicator: [], dcf: [], tags1: [], tags2: [], tags3: [], tags4: [], source: 1, rf: null, title: '', unit: '', general: false });

            } else {
                if (item.data1[0].unit === undefined) {
                    item.data1[0].unit = ''
                    item.data1[0].general = false
                }
                if (item.data1[0].title === undefined) {
                    item.data1[0].title = ''
                }
                if (item.data1[0].indicator !== undefined) {
                    item.data1[0].indicator = JSON.parse(JSON.stringify(metricListBK)).filter((i) => { return i.id !== item.id }).filter((i) => { return item.data1[0].indicator.includes(i.id) }).filter((j) => { return Array.isArray(j.data1) && j.data1[0].type !== undefined && j.data1[0].type === 0 }).map((i) => { return i.id })
                }
                setMetricConfig(item.data1[0]);

            }
            setMetricConfigDialog(true);
        }
    }
    const updateConfig = (obj, val) => {
        let loc = config;
        console.log(obj, val)
        if (obj === 'type') {
            loc.unit = ''
        }

        loc[obj] = val;


        setConfig(loc);
        forceUpdate()

    }
    const updateMetricConfig = (obj, val) => {
        let loc = metricconfig;

        console.log(val)
        loc[obj] = val;
        if (obj === 'type') {
            loc['source'] = 1
            loc['indicator'] = []
            loc['rf'] = null
        }

        setMetricConfig(loc);
        forceUpdate()

    }

    const addNewTier1 = () => {

        let index = tier1.findIndex((i) => { return i.title.trim().toLowerCase() === module.tier1.trim().toLowerCase() })

        if (index === -1 && module.tier1.trim().length !== 0) {
            APIServices.post(API.Categories,
                {
                    title: module.tier1.trim(), suffix: 'CT'
                },
            )
                .then((res) => {
                    console.log(res);
                    if (res.status === 200) {
                        let tier = tier1;
                        tier.push(res.data);
                        setTier1(tier);
                        setModule({
                            tier1: "",
                            tier2: "",
                            tier3: "",
                            tier0: "",

                            tier4: ''
                        });
                    }
                })
                .catch((e) => {
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: `Something went wrong, try again later `,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                });
        }
    };
    const addNewTier2 = () => {

        let index = tier2.findIndex((i) => { return i.title.trim().toLowerCase() === module.tier1.trim().toLowerCase() })

        if (index === -1 && module.tier2.trim().length !== 0) {
            APIServices.post(
                API.Topic_Categories(selected.tier1),
                {
                    title: module.tier2.trim(), suffix: 'TP'
                },
            )
                .then((res) => {
                    if (res.status === 200) {
                        let tier = tier2;
                        tier.push(res.data);
                        setTier2(tier);
                        setModule({
                            tier1: "",
                            tier2: "",
                            tier3: "",

                            tier4: ''
                        });
                    }
                })
                .catch((e) => {
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: `Something went wrong, try again later `,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                });
        }
    };
    const addNewTier3 = () => {

        let index = tier3.findIndex((i) => { return i.title.trim().toLowerCase() === module.tier1.trim().toLowerCase() })

        if (index === -1 && module.tier3.trim().length !== 0) {
            APIServices.post(
                API.Metric_Topic(selected.tier2),
                {
                    title: module.tier3.trim(), suffix: 'MT', data1: [{ unit: '', desc: '', type: 0, indicator: [], dcf: [], tags1: [], tags2: [], tags3: [], tags4: [], source: 1, rf: null, title: '', general: false }]
                },
            )
                .then((res) => {
                    console.log(res);
                    if (res.status === 200) {
                        let tier = tier3;
                        let metloc = metricListBK;
                        metloc.push(res.data)
                        setMetricListBK(metloc)
                        res.data.data1 = null
                        tier.push(res.data);
                        let metricbk = metricListBK
                        metricbk.push(res.data)
                        setMetricList(metricbk)
                        setMetricListBK(metricbk)
                        setTier3(tier);
                        setModule({
                            tier1: "",
                            tier2: "",
                            tier3: "",

                            tier4: ''
                        });
                    }
                })
                .catch((e) => {
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: `Something went wrong, try again later `,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                });
        }
    };
    let sampleRF = []
    const exportRF = () => {

        let xls = []
        try {
            sampleRF.forEach((i) => {
                i.data1.forEach((j) => {
                    console.log(j.label === undefined, i)
                    xls.push({
                        'RF_ID': i.id, 'RF_Title': i.title, 'RForDCF': '', 'Question': j.label === undefined ? 'NA' : j.label.replace(/^\"|\"$/g, "`").replace(/(<([^>]+)>)/gi, "")
                            .replace(/\n/g, " ")
                            .replace(/&nbsp;/g, " ").replace('&amp;', '&'), 'RF_ID_Name': j.name
                    })
                })
            })
        }
        catch (e) {
            console.log(e)
        }
        console.log(xls)
        if (xls.length !== 0) {
            const ws = XLSX.utils.json_to_sheet(xls)
            let sheet_name = 'RF'
            const wb = {
                Sheets: { [sheet_name]: ws },
                SheetNames: [sheet_name],
            };

            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

            const data = new Blob([excelBuffer], {
                type:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
            });
            FileSaver.saveAs(data, sheet_name + ".xlsx");
        } else {
            alert('Nothing to Export')
        }
        console.log(xls)
    }
    const addNewTier4 = () => {

        let index = tier4.findIndex((i) => { return i.title.trim().toLowerCase() === module.tier1.trim().toLowerCase() })

        if (index === -1 && module.tier4.trim().length !== 0) {
            APIServices.post(API.DataPoint_Metric(selected.tier3),
                {
                    title: module.tier4.trim(), suffix: 'DP'
                },
            )
                .then((res) => {
                    console.log(res);
                    if (res.status === 200) {

                        let tier = tier4;
                        res.data.data1 = null
                        tier.push(res.data);
                        setTier4(tier);
                        setModule({
                            tier1: "",
                            tier2: "",
                            tier3: "",
                            tier4: ''
                        });
                    }
                })
                .catch((e) => {
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: `Something went wrong, try again later `,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                });
        }
    };
    const updateTier1 = (e, id) => {
        console.log(e)
        let index = tier1.findIndex((i) => {
            return i.id === id;
        });
        if (index !== -1) {
            APIServices.patch(API.Categories_Edit(tier1[index].id),
                {
                    title: e.trim(),
                },
            ).then((res) => {
                let loc = tier1
                loc[index].title = e.trim();
                setTier1(loc);
                forceUpdate()
            });
        }
    };


    const getTier2Data = (id) => {
        let sel = selected;
        sel.tier1 = id;
        sel.tier2 = "";
        sel.tier3 = "";
        sel.tier4 = ''
        setSelected(sel);

        APIServices.get(API.Topic_Categories(id),
        ).then((res) => {
            setTier2(res.data);
        });
    };
    const getTier3Data = (id) => {
        let sel = selected;
        sel.tier2 = id;
        sel.tier3 = "";
        sel.tier4 = ''
        setSelected(sel);
        forceUpdate();
        APIServices.get(API.Metric_Topic(selected.tier2)).then((res) => {
            setTier3(res.data);
        });
        // APIServices.get(API.Metric_Topic(selected.tier2) + `?filter=${encodeURIComponent(JSON.stringify({ 'include': ['indicatorSectionAssignments'] }))}`
        // ).then((res) => {
        //     setTier3(res.data);
        // });
    };

    const getTier4Data = (id) => {
        let sel = selected;
        sel.tier3 = id;

        sel.tier4 = ''
        setSelected(sel);
        forceUpdate();
        APIServices.get(API.DataPoint_Metric(selected.tier3)
        ).then((res) => {
            setTier4(res.data);
        });
    };
    const getTier5Data = (id) => {
        let sel = selected;
        sel.tier4 = id;
        setSelected(sel);
        forceUpdate();
    };
    const updateTier2 = (e, id) => {
        let index = tier2.findIndex((i) => {
            return i.id === id;
        });

        APIServices.patch(API.Topic_Edit(tier2[index].id),
            {
                title: e.trim(),
            },
        ).then((res) => {
            let loc = tier2
            loc[index].title = e.trim();
            setTier2(loc);
            forceUpdate()

        });
    };
    const updateTier3 = (e, id) => {
        let index = tier3.findIndex((i) => {
            return i.id === id;
        });
        let index2 = metricListBK.findIndex((i) => {
            return i.id === id;
        });
        APIServices.patch(API.Metric_Edit(tier3[index].id),
            {
                title: e.trim(),
            },
        ).then((res) => {
            let loc = tier3
            let metloc = metricListBK
            metloc[index2].title = e.trim();
            setMetricListBK(metloc)
            loc[index].title = e.trim();
            setTier3(loc);
            forceUpdate()

        });
    };
    const showTier4CM = (e, ind) => {
        console.log(tier4ref.current)
        tier4ref.current.forEach((i, j) => {
            if (j === ind) {
                tier4ref.current[ind].show(e)
            } else {
                if (tier4ref.current[j] !== null) {
                    tier4ref.current[j].hide(e)
                }
            }
        })
        tier1ref.current.forEach((i, j) => {

            if (tier1ref.current[j] !== null) {
                tier1ref.current[j].hide(e)

            }
        })
        tier2ref.current.forEach((i, j) => {

            if (tier2ref.current[j] !== null) {
                tier2ref.current[j].hide(e)

            }
        })
        tier3ref.current.forEach((i, j) => {

            if (tier3ref.current[j] !== null) {
                tier3ref.current[j].hide(e)

            }
        })
    }
    const showTier3CM = (e, ind) => {

        tier3ref.current.forEach((i, j) => {
            if (j === ind) {
                tier3ref.current[ind].show(e)
            } else {
                if (tier3ref.current[j] !== null) {
                    tier3ref.current[j].hide(e)
                }
            }
        })
        tier1ref.current.forEach((i, j) => {

            if (tier1ref.current[j] !== null) {
                tier1ref.current[j].hide(e)

            }
        })
        tier2ref.current.forEach((i, j) => {

            if (tier2ref.current[j] !== null) {
                tier2ref.current[j].hide(e)

            }
        })
        tier4ref.current.forEach((i, j) => {

            if (tier4ref.current[j] !== null) {
                tier4ref.current[j].hide(e)

            }
        })
    }
    const showTier2CM = (e, ind) => {

        tier2ref.current.forEach((i, j) => {
            if (j === ind) {
                tier2ref.current[ind].show(e)
            } else {
                if (tier2ref.current[j] !== null) {
                    tier2ref.current[j].hide(e)
                }
            }
        })
        tier1ref.current.forEach((i, j) => {

            if (tier1ref.current[j] !== null) {
                tier1ref.current[j].hide(e)

            }
        })
        tier3ref.current.forEach((i, j) => {

            if (tier3ref.current[j] !== null) {
                tier3ref.current[j].hide(e)

            }
        })
        tier4ref.current.forEach((i, j) => {

            if (tier4ref.current[j] !== null) {
                tier4ref.current[j].hide(e)

            }
        })
    }
    const showTier1CM = (e, ind) => {

        tier1ref.current.forEach((i, j) => {
            if (j === ind) {
                tier1ref.current[ind].show(e)
            } else {
                if (tier1ref.current[j] !== null) {
                    tier1ref.current[j].hide(e)
                }
            }
        })
        tier2ref.current.forEach((i, j) => {
            console.log(tier2ref.current[j])
            if (tier2ref.current[j] !== null) {
                tier2ref.current[j].hide(e)

            }
        })
        tier3ref.current.forEach((i, j) => {

            if (tier3ref.current[j] !== null) {
                tier3ref.current[j].hide(e)
            }

        })
        tier4ref.current.forEach((i, j) => {

            if (tier4ref.current[j] !== null) {
                tier4ref.current[j].hide(e)

            }
        })

    }
    const updateTier4 = (e, id) => {
        console.log(e)
        let index = tier4.findIndex((i) => {
            return i.id === id;
        });

        APIServices.patch(API.DataPoint_Edit(tier4[index].id),
            {
                title: e.trim(),
            },
        ).then((res) => {
            let loc = tier4
            loc[index].title = e.trim();
            setTier4(loc);
            forceUpdate()

        });
        console.log(tier4)
    };
    const updateTierValue = (obj, e) => {

        let mod = module;
        mod[obj] = e.target.value;
        setModule(mod);
        forceUpdate();
    };
    const search = (event) => {

        let _items = datasourcelist.filter((k) => { return (k.name.trim().toLowerCase().includes(event.query.trim().toLowerCase()) || k.id.toString().includes(event.query.trim())) });
        setDCFItems(_items);
        forceUpdate()
    }
    const search_ = (event) => {

        let _items = datasource2list.filter((k) => { return (k.name.trim().toLowerCase().includes(event.query.trim().toLowerCase()) || k.id.toString().includes(event.query.trim())) });
        setSAPItems(_items);
        forceUpdate()
    }
    const updateChangeOrder = async () => {
        const { value: password } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Are you sure want to change order
          </div>`,
            input: 'password',
            inputLabel: 'Password',
            confirmButtonColor: 'red',
            inputPlaceholder: 'Enter your password',
            showCancelButton: true,

            inputAttributes: {
                maxlength: 15,
                autocapitalize: 'off',
                autocorrect: 'off',
                autocomplete: 'off',
            },
            didOpen: () => {
                // Perform actions when the popup opens


            }
        })

        if (password === 'orderorderorder') {
            setOrderDialog(true);
        }
    }
    const showList = (id, item) => {
        let loclist = selectedlist
        if (id === 1) {
            loclist.title = 'Selected Indicators'
            loclist.data = metricList.filter((k) => { return item.includes(k.id) })
        }
        setSelectedList(loclist)
        setPrevSListDialog(true)

    }
    const renderListPreview = () => {
        return (
            <div className="col-12 grid" >
                {selectedlist.data.map((i, j) => {
                    return (
                        <label className="col-12">{j + 1}. &nbsp; {i.title}</label>
                    )
                })

                }
            </div>
        )
    }
    const deleteItem = async () => {
        const { value: password } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Enter Password to delete</div>`,
            input: 'password',

            confirmButtonColor: 'red',
            inputPlaceholder: 'Enter your password',
            showCancelButton: true,

            inputAttributes: {
                maxlength: 15,
                autocapitalize: 'off',
                autocorrect: 'off',
                autocomplete: 'off',
            },
            didOpen: () => {



            }
        })
        return password === 'plsproseed'
    }
    let data = [{ id: 14, title: "Waste", sapId: 14 }]
    const patch = async () => {
        // let submitIds = [5605, 5606, 5607, 5608, 5609, 5610, 5611, 5612, 5613, 5614, 5615, 5616, 5617, 5618, 5619, 5620, 5621, 5622, 5623, 5624, 5625, 5626, 5627, 5628, 5629, 5630, 5631, 5632, 5633, 5634, 5635, 5636, 5637, 5638, 5639, 5640
        //     , 6742, 6743, 6744, 6745, 6746, 6747, 6748, 6749, 6750, 6751, 6752, 6753, 6754, 6755, 6756, 6757, 6758, 6759, 6760, 6761, 6762, 6763, 6764, 6765,
        //     6353, 6366, 6367, 6368, 6369, 6370, 6371, 6372, 6373, 6374, 6375, 6376, 6377, 6378, 6379, 6380, 6381, 6382, 6383, 6384, 6385, 6386, 6387, 6388, 6389, 6390, 6391, 6392, 6393, 6394, 6395, 6396, 6397, 6398, 6399, 6400,
        //     6557, 6558, 6559, 6560, 6561, 6562, 6563, 6564, 6565, 6566, 6567, 6568, 6641, 6642, 6643, 6644, 6645, 6646, 6647, 6648, 6649, 6650, 6651, 6652, 6653, 6654, 6655, 6656, 6657, 6658, 6659, 6660, 6661, 6662, 6663, 6664,
        //     5641, 5642, 5643, 5644, 5645, 5646, 5647, 5648, 5649, 5650, 5651, 5652, 5653, 5654, 5655, 5656, 5657, 5658, 5659, 5660, 5661, 5662, 5663, 5664, 5665, 5666, 5667, 5668, 5669, 5670, 5671, 5672, 5673, 5674, 5675, 5676,
        //     6766, 6767, 6768, 6769, 6770, 6771, 6772, 6773, 6774, 6775, 6776, 6777,
        //     5299, 5300, 5301, 5302, 5303, 5304, 5305, 5306, 5307, 5308, 5309, 5310, 5311, 5312, 5313, 5314, 5315, 5316, 5317, 5318, 5319, 5320, 5321, 5322, 5323, 5324, 5325, 5326, 5327, 5328, 5329, 5330, 5331, 5332, 5333, 5334,
        //     6329, 6330, 6331, 6332, 6333, 6334, 6335, 6336, 6337, 6338, 6339, 6340, 6401, 6402, 6403, 6404, 6405, 6406, 6407, 6408, 6409, 6410, 6411, 6412, 6413, 6414, 6415, 6416, 6417, 6418, 6419, 6420, 6421, 6422, 6423, 6424
        // ]
        let submitIds = [6569, 6570, 6571, 6572, 6573, 6574, 6575, 6576, 6577, 6578, 6579, 6580, 6581, 6582, 6583, 6584, 6585, 6586, 6587, 6588, 6589, 6590, 6591, 6592, 6593, 6594, 6595, 6596, 6597, 6598, 6599, 6600, 6601, 6602, 6603, 6604, 6605, 6606, 6607, 6608, 6609, 6610, 6611, 6612, 6613, 6614, 6615, 6616, 6617, 6618, 6619, 6620, 6621, 6622, 6623, 6624, 6625, 6626, 6627, 6628, 6629, 6630, 6631, 6632, 6633, 6634, 6635, 6636, 6637, 6638, 6639, 6640, 6701, 6702, 6703, 6704, 6705, 6706, 6707, 6708, 6709, 6710, 6711, 6712, 6713, 6714, 6715, 6716, 6717, 6718, 6719, 6720, 6721, 6722, 6723, 6724, 6725, 6726, 6727, 6728, 6729, 6730, 6731, 6732, 6733, 6734, 6735, 6736,
            5335, 5336, 5337, 5338, 5339, 5340, 5341, 5342, 5343, 5344, 5345, 5346, 5347, 5348, 5349, 5350, 5351, 5352, 5353, 5354, 5355, 5356, 5357, 5358, 5359, 5360, 5361, 5362, 5363, 5364, 5365, 5366, 5367, 5368, 5369, 5370, 5371, 5372, 5373, 5374, 5375, 5376, 5377, 5378, 5379, 5380, 5381, 5382, 5383, 5384, 5385, 5386, 5387, 5388, 5389, 5390, 5391, 5392, 5393, 5394, 5395, 5396, 5397, 5398, 5399, 5400, 5401, 5402, 5403, 5404, 5405, 5406, 5407, 5408, 5409, 5410, 5411, 5412, 5413, 5414, 5415, 5416, 5417, 5418, 5419, 5420, 5421, 5422, 5423, 5424, 5425, 5426, 5427, 5428, 5429, 5430, 5431, 5432, 5433, 5434, 5435, 5436, 5437, 5438, 5439, 5440, 5441, 5442, 5443, 5444, 5445, 5446, 5447, 5448, 5449, 5450, 5451, 5452, 5453, 5454, 5455, 5456, 5457, 5458, 5459, 5460, 5461, 5462, 5463, 5464, 5465, 5466,
            5677, 5678, 5679, 5680, 5681, 5682, 5683, 5684, 5685, 5686, 5687, 5688, 5689, 5690, 5691, 5692, 5693, 5694, 5695, 5696, 5697, 5698, 5699, 5700, 5701, 5702, 5703, 5704, 5705, 5706, 5707, 5708, 5709, 5710, 5711, 5712, 5713, 5714, 5715, 5716, 5717, 5718, 5719, 5720, 5721, 5722, 5723, 5724, 5725, 5726, 5727, 5728, 5729, 5730, 5731, 5732, 5733, 5734, 5735, 5736, 5737, 5738, 5739, 5740, 5741, 5742, 5743, 5744, 5745, 5746, 5747, 5748, 5749, 5750, 5751, 5752, 5753, 5754, 5755, 5756, 5757, 5758, 5759, 5760, 5761, 5762, 5763, 5764, 5765, 5766, 5767, 5768, 5769, 5770, 5771, 5772, 5773, 5774, 5775, 5776, 5777, 5778, 5779, 5780, 5781, 5782, 5783, 5784, 5785, 5786, 5787, 5788, 5789, 5790, 5791, 5792, 5793, 5794, 5795, 5796, 5797, 5798, 5799, 5800, 5801, 5802, 5803, 5804, 5805, 5806, 5807, 5808,
            6778, 6779, 6780, 6781, 6782, 6783, 6784, 6785, 6786, 6787, 6788, 6789, 6790, 6791, 6792, 6793, 6794, 6795, 6796, 6797, 6798, 6799, 6800, 6801, 6802, 6803, 6804, 6805, 6806, 6807, 6808, 6809, 6810, 6811, 6812, 6813, 6814, 6815, 6816, 6817, 6818, 6819, 6820, 6821, 6822, 6823, 6824, 6825, 6826, 6827, 6828, 6829, 6830, 6831, 6832, 6833, 6834, 6835, 6836, 6837, 6838, 6839, 6840, 6841, 6842, 6843, 6844, 6845, 6846, 6847, 6848, 6849, 6850, 6851, 6852, 6853, 6854, 6855, 6856, 6857, 6858, 6859, 6860, 6861, 6862, 6863, 6864, 6865, 6866, 6867, 6868, 6869, 6870, 6871, 6872, 6873, 6874, 6875, 6876, 6877, 6878, 6879, 6880, 6881, 6882, 6883, 6884,
            6341, 6342, 6343, 6344, 6345, 6346, 6347, 6348, 6349, 6350, 6351, 6352, 6425, 6426, 6427, 6428, 6429, 6430, 6431, 6432, 6433, 6434, 6435, 6436, 6437, 6438, 6439, 6440, 6441, 6442, 6443, 6444, 6445, 6446, 6447, 6448, 6449, 6450, 6451, 6452, 6453, 6454, 6455, 6456, 6457, 6458, 6459, 6460, 6461, 6462, 6463, 6464, 6465, 6466, 6467, 6468, 6469, 6470, 6471, 6472, 6473, 6474, 6475, 6476, 6477, 6478, 6479, 6480, 6481, 6482, 6483, 6484, 6485, 6486, 6487, 6488, 6489, 6490, 6491, 6492, 6493, 6494, 6495, 6496, 6497, 6498, 6499, 6500, 6501, 6502, 6503, 6504, 6505, 6506, 6507, 6508, 6509, 6510, 6511, 6512, 6513, 6514, 6515, 6516, 6517, 6518, 6519, 6520, 6521, 6522, 6523, 6524, 6525, 6526, 6527, 6528, 6529, 6530, 6531, 6532, 6533, 6534, 6535, 6536, 6537, 6538, 6539, 6540, 6541, 6542, 6543, 6544

        ]
        let resultArray = [], rejectedArray = []
        for (const ids of submitIds) {
            try {

                resultArray.push(ids)
            } catch (e) {
                rejectedArray.push(ids)
            }

        }
        console.log(resultArray, rejectedArray)
    }
    const downloadReport = async (type) => {
        const { value: password } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Are you sure want to download report
          </div>`,
            input: 'password',
            inputLabel: 'Password',
            confirmButtonColor: 'red',
            inputPlaceholder: 'Enter your password',
            showCancelButton: true,
            inputAttributes: {
                maxlength: 15,
                autocapitalize: 'off',
                autocorrect: 'off'
            }
        })

        if (password === 'usbport') {
            renderData(type)
        }
    }
    const items_ = [
        {
            label: 'Indicator Listing',

            command: () => {
                downloadReport(0)

            }
        },
        {
            label: 'Indicator > DP',

            command: () => {

                downloadReport(1)
            }
        },
        {
            label: 'Indicator > RF ',

            command: () => {
                downloadReport(3)
            }
        },
        {
            label: 'DP > DCF ',

            command: () => {

                downloadReport(2)
            }
        }
    ];

    const handleDragStart = (event, index) => {
        event.dataTransfer.setData('text/plain', index);
        event.dataTransfer.effectAllowed = 'move';
        setDraggedItem(index);
    };

    const handleDragOver = (event, index) => {
        event.preventDefault();

        if (index === draggedItem) {
            return; // Do nothing if dragging over the same div
        }

        const updatedItems = [...orderlist];
        const draggedItems = updatedItems[draggedItem];
        updatedItems.splice(draggedItem, 1);
        updatedItems.splice(index, 0, draggedItems);
        updatedItems.forEach((i, j) => {
            i.order = j + 1
        })
        setOrderList(updatedItems);

        setDraggedItem(index);
    };
    const itemTemplate = (item, index) => {
        return (
            <div className="card col-12" style={{ cursor: 'move' }}
                key={item.id}
                draggable
                onDragStart={(event) => handleDragStart(event, index)}
                onDragOver={(event) => handleDragOver(event, index)}
            >
                {index + 1}. &nbsp; {item.title}
            </div>
        );
    };
    const getRFStatus = (item) => {
        if (Array.isArray(item.data1) && item.data1.length !== 0) {
            if (Object.keys(item.data1[0]).length > 2) {
                if (item.data1[0].type === 1) {
                    if (item.data1[0].rf === null) {
                        return false
                    } else {
                        return true
                    }
                }
            }
        }
        return true
    }
    const renderData = (type) => {
        let dps = []
        metricList.forEach((item) => {
            if (item.newDataPoints) {
                dps.push(...item.newDataPoints)
            }
        })
        export2Excel(type, metricList, dps)
    }
    const groupArrayObject = (array) => {
        return array.reduce((group, arr) => {

            const { DCF } = arr;

            group[DCF] = group[DCF] ?? [];

            group[DCF].push(arr);

            return group;

        },

            {})
    }
    const updateDefaultSection = (val) => {
        APIServices.post(API.IndicatorAss_NewMetric(sectionobj.newMetricId), { ...sectionobj, indicatorSectionId: val }).then((res) => {
            setSectionObj((prev) => ({ ...prev, indicatorSectionId: val }))
            let metricindex = tier3.findIndex(i => i.id === sectionobj.newMetricId)
            if (metricindex !== -1) {
                if (tier3[metricindex].indicatorSectionAssignments) {
                    let assIndex = tier3[metricindex].indicatorSectionAssignments.findIndex(i => i.userProfileId === null && i.newMetricId === sectionobj.newMetricId)
                    if (assIndex !== -1) {
                        tier3[metricindex].indicatorSectionAssignments[assIndex] = res.data
                        forceUpdate()
                    } else {
                        tier3[metricindex].indicatorSectionAssignments = [...tier3[metricindex].indicatorSectionAssignments, res.data]
                        forceUpdate()
                    }
                } else {
                    tier3[metricindex].indicatorSectionAssignments = [res.data]
                    forceUpdate()
                }
            }

        })

    }
    const getTag = (item) => {
        let result = { title: 'Default', class: 'status-tag-gray' }

        if (item.tag !== undefined && item.tag !== null) {
            let tag = clienttag.findIndex(i => i.id === parseFloat(item.tag))
            if (tag === -1) {
                result = { title: 'No Tag', class: 'status-tag-red' }
            } else {
                result = { title: clienttag[tag].name, class: 'status-tag-red' }
            }


        }


        return result
    }
    const getStatus = (item) => {
        let result = { title: 'NA', color: 'red' }
        if (Array.isArray(item.data1) && item.data1[0].length !== 0) {
            if (item.data1[0].source === 0) {
                result = { title: 'Derived', color: 'hotpink' }

            } else if (item.data1[0].source === 1 && item.data1[0].type !== 1) {
                result = { title: 'Standalone', color: 'pink' }

            } else if (item.data1[0].type === 1) {

                result = { title: 'Qualitative', color: 'cornflowerblue' }
            }
        }

        return result
    }
    const export2Excel = (type, metrics, dps) => {
        let loc = [], loc2 = []
        let obj = {}

        if (type === 0) {

            metrics.forEach((i) => {
                console.log(i)

                if (Array.isArray(i.data1) && i.data1.length !== 0) {

                    if (i.data1[0].type !== undefined) {
                        let tags1 = '', tags2 = '', tags3 = ''
                        i.data1[0].tags1.forEach((j, ind) => {
                            if (ind === i.data1[0].tags1.length - 1) {
                                tags1 = tags1 + j
                            } else {
                                tags1 = tags1 + j + ','
                            }


                        })
                        i.data1[0].tags2.forEach((j, ind) => {
                            if (ind === i.data1[0].tags2.length - 1) {
                                tags2 = tags2 + j
                            } else {
                                tags2 = tags2 + j + ','
                            }


                        })
                        i.data1[0].tags3.forEach((j, ind) => {
                            if (ind === i.data1[0].tags3.length - 1) {
                                tags3 = tags3 + j
                            } else {
                                tags3 = tags3 + j + ','
                            }


                        })
                        if (i.data1[0].type === 0 && i.data1[0].source === 0) {
                            loc.push({ 'Category': i.category, 'Topic': i.topic, 'Indicator ID': 'MT' + i.id, 'Indicator - Short Title': i.title, 'Indicator - Long Title': i.data1[0].title === undefined ? 'No Title Added' : i.data1[0].title, 'Type': 'Derived Quantitative', 'Sub Indicator': i.data1[0].indicator.join(', '), 'Must Have': tags1, 'Progressive': tags2, 'Advanced': tags3 })
                        } else if (i.data1[0].type === 0 && i.data1[0].source === 1) {
                            let dcf_ids = []
                            if (i.newDataPoints !== undefined) {
                                i.newDataPoints.forEach((ndp) => {
                                    if (Array.isArray(ndp.data1) && ndp.data1[0].datasource !== undefined) {
                                        (ndp.data1[0].datasource !== null && ndp.data1[0].datasource !== 0 && !dcf_ids.includes(ndp.data1[0].datasource)) && dcf_ids.push(ndp.data1[0].datasource)
                                    }
                                })
                            }
                            console.log(dcf_ids)
                            loc.push({ 'Category': i.category, 'Topic': i.topic, 'Indicator ID': 'MT' + i.id, 'Indicator - Short Title': i.title, 'Indicator - Long Title': i.data1[0].title === undefined ? 'No Title Added' : i.data1[0].title, 'Type': 'Standalone Quantitative', 'DCF_IDs': dcf_ids.join(', '), "DP_IDs": i.newDataPoints !== undefined ? i.newDataPoints.map(i => i.suffix).join(', ') : 'Data Points Not Added', 'Must Have': tags1, 'Progressive': tags2, 'Advanced': tags3 })
                        } else if (i.data1[0].type === 1 && i.data1[0].source === 1) {
                            loc.push({ 'Category': i.category, 'Topic': i.topic, 'Indicator ID': 'MT' + i.id, 'Indicator - Short Title': i.title, 'Indicator - Long Title': i.data1[0].title === undefined ? 'No Title Added' : i.data1[0].title, 'Type': 'Qualtitative', 'RF_ID': i.data1[0].rf, 'Must Have': tags1, 'Progressive': tags2, 'Advanced': tags3 })
                        }

                    } else {
                        loc.push({ 'Category': i.category, 'Topic': i.topic, 'Indicator ID': 'MT' + i.id, 'Indicator - Short Title': i.title, 'Indicator - Long Title': 'No Title Added', 'Type': 'Not Configured', 'Must Have': 'Not Found', 'Progressive': 'Not Found', 'Advanced': 'Not Found' })

                    }
                } else {
                    loc.push({ 'Category': i.category, 'Topic': i.topic, 'Indicator ID': 'MT' + i.id, 'Indicator - Short Title': i.title, 'Indicator - Long Title': 'Not Configured', 'Type': 'Not Configured', 'Must Have': 'Not Found', 'Progressive': 'Not Found', 'Advanced': 'Not Found' })
                }

            })

        } else if (type === 1) {

            metrics.forEach((i) => {


                if (i.newDataPoints !== undefined) {
                    i.newDataPoints.forEach((j, ind) => {



                        loc.push({ Indicator: i.title, DP_ID: j.suffix === null ? 'Not Assigned' : j.suffix.substr(0, 2).includes('DP') ? j.suffix.length === 2 ? 'Invalid DP' : j.suffix : 'Not Assigned', DP: j.title })


                    })
                } else {
                    loc.push({ Indicator: i.title, DP_ID: 'Not Assigned', DP: 'Not Found' })
                }

            })

        } else if (type === 2) {
            dps.forEach((item) => {
                if (item.data1 !== null) {
                    if (typeof item.data1[0].datasource === 'number') {
                        if (datasourcelist_.filter((i) => { return i.id === item.data1[0].datasource }).length === 0) {
                            loc2.push({ DP: item.title, DCF: 'Deleted DCF ID' })
                        } else {
                            let dcf = datasourcelist_.filter((i) => { return i.id === item.data1[0].datasource })[0]
                            loc2.push({ DP_ID: item.suffix === null ? 'Not Assigned' : item.suffix.substr(0, 2).includes('DP') ? item.suffix.length === 2 ? 'Invalid DP' : item.suffix : 'Not Assigned', DP: item.title, DCF: dcf.suffix + ' : ' + dcf.title })
                        }


                    } else {
                        loc2.push({ DP_ID: item.suffix === null ? 'Not Assigned' : item.suffix.substr(0, 2).includes('DP') ? item.suffix.length === 2 ? 'Invalid DP' : item.suffix : 'Not Assigned', DP: item.title, DCF: item.data1[0].datasource === null ? 'Not Found' : 'Invalid DCF' })

                    }
                    if (typeof item.data1[0].datasource2 === 'number') {
                        if (datasource2list.filter((i) => { return i.id === item.data1[0].datasource2 }).length === 0) {
                            loc2.push({ DP: item.title, SAP: 'Deleted SAP ID' })
                        } else {
                            let dcf = datasource2list.filter((i) => { return i.id === item.data1[0].datasource2 })[0]
                            loc2.push({ DP_ID: item.suffix === null ? 'Not Assigned' : item.suffix.substr(0, 2).includes('DP') ? item.suffix.length === 2 ? 'Invalid DP' : item.suffix : 'Not Assigned', DP: item.title, SAP: dcf.name })
                        }


                    } else {
                        loc2.push({ DP_ID: item.suffix === null ? 'Not Assigned' : item.suffix.substr(0, 2).includes('DP') ? item.suffix.length === 2 ? 'Invalid DP' : item.suffix : 'Not Assigned', DP: item.title, SAP: item.data1[0].datasource2 === null ? 'Not Found' : 'Invalid SAP' })
                    }
                }
            })

            Object.values(groupArrayObject(loc2)).forEach((item) => {

                item.forEach((item2, ind) => {

                    loc.push({ DP_ID: item2.DP_ID, DP: item2.DP, DCF: item2.DCF })



                })
            })
        } else if (type === 3) {
            metrics.forEach((i) => {
                if (Array.isArray(i.data1) && i.data1.length !== 0 && i.data1[0].type === 1) {
                    if (i.data1[0].rf !== null) {
                        let index = rfList.findIndex((l) => { return l.id === i.data1[0].rf })
                        if (index !== -1) {

                            loc.push({ "Category": i.category, "Topic": i.topic, "Indicator": "MT" + i.id + "-" + i.title, "RF ID": rfList[index].id, "RF": rfList[index].title })

                        } else {
                            loc.push({ "Category": i.category, "Topic": i.topic, "Indicator": "MT" + i.id + "-" + i.title, "RF ID": rfList[index].id, "RF": 'Deleted RF' })
                        }
                    } else {
                        loc.push({ "Category": i.category, "Topic": i.topic, "Indicator": "MT" + i.id + "-" + i.title, "RF ID": 'Not Configured', "RF": 'Not Configured' })

                    }

                }

            })


        }

        if (loc.length !== 0) {
            const ws = XLSX.utils.json_to_sheet(loc)
            let sheet_name = type === 0 ? 'Indicator_Listing' : type === 1 ? 'Indicator_DP_Listing' : type === 2 ? 'DP_DCF_Listing' : 'Indicator_RF_Listing'
            const wb = {
                Sheets: { [sheet_name]: ws },
                SheetNames: [sheet_name],
            };

            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

            const data = new Blob([excelBuffer], {
                type:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
            });
            FileSaver.saveAs(data, sheet_name + ".xlsx");
        } else {
            alert('Nothing to Export')
        }
    }
    return (
        <div className="grid">

            {selector.role === "eisqradmin" ? (
                <div className="col-12">
                    <div className="card">
                        <h3> Data Configuration </h3>
                        <div>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}>
                                <SplitButton label="Export" icon="pi pi-print" model={items_} />
                            </div>
                            <div className="field">
                                <div>
                                    <div className="col-12">
                                        <div className="grid">

                                            <div className="col-3">
                                                <div className="grid">
                                                    <div className="col-10">
                                                        <InputText
                                                            type={"text"}
                                                            value={module.tier1}
                                                            onChange={(e) => {
                                                                updateTierValue("tier1", e);
                                                            }}
                                                            placeholder="Enter Category"
                                                        />
                                                    </div>
                                                    <div className="col-2" style={{ marginLeft: -10 }}>
                                                        <Button
                                                            icon="pi pi-plus"

                                                            style={{ cursor: module.tier1.trim().length === 0 ? 'not-allowed' : 'pointer', background: module.tier1.trim().length === 0 ? 'gray' : '#22C55E', border: module.tier1.trim().length === 0 ? '1px solid gray' : '1px solid #22C55E' }}
                                                            className="p-button-success mr-2"
                                                            onClick={() => {
                                                                addNewTier1();
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className="col-12"
                                                    style={{
                                                        border: "1px solid grey",
                                                        height: "calc(100vh - 320px)",
                                                        overflow: "auto",
                                                        borderRadius: 5
                                                    }}
                                                >
                                                    {tier1.length === 0 ? (
                                                        <text
                                                            style={{
                                                                padding: 5,
                                                            }}
                                                        >
                                                            {"Add Category"}
                                                        </text>
                                                    ) : (
                                                        tier1.sort((a, b) => { return a.order - b.order }).map((item, cyindex) => {
                                                            return (
                                                                <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>

                                                                    <div className="col-11"
                                                                        onClick={() => {
                                                                            getTier2Data(item.id);
                                                                        }}
                                                                        style={{
                                                                            color: selected.tier1 === item.id && "white",
                                                                            background: selected.tier1 === item.id && "grey",
                                                                            borderBottom: "0.5px ridge grey", padding: 0
                                                                        }}
                                                                    >
                                                                        <EdiText

                                                                            viewContainerClassName={selected.tier1 === item.id ? 'my-custom-view-wrapper-selected' : 'my-custom-view-wrapper'}

                                                                            type='text'
                                                                            value={item.title}
                                                                            onSave={(e) => { updateTier1(e, item.id); setEditModeOffTier1() }}
                                                                            editing={item.edit}
                                                                            onCancel={() => { setEditModeOffTier1() }}
                                                                            hideIcons={true}
                                                                            validationMessage="Title should not exist / not empty "
                                                                            validation={val => { console.log(val); return val.trim().length !== 0 && tier1.findIndex((k) => { return (k.title.trim().toLowerCase() === val.trim().toLowerCase() && item.id !== k.id) }) === -1 }}
                                                                            saveButtonContent="✔"
                                                                            cancelOnUnfocus
                                                                            cancelButtonContent={<strong>X</strong>}

                                                                            editButtonClassName="custom-edit-button"
                                                                        />
                                                                        {/* <EditText
                                                                            onSave={(e) => {
                                                                                if (e.value.trim().length !== 0) {
                                                                                    updateTier1(e);
                                                                                }
                                                                            }}
                                                                            name="textbox3"
                                                                            // value={item.country}
                                                                            defaultValue={item.title}
                                                                            editButtonProps={{
                                                                                style: {
                                                                                    marginLeft: "5px",
                                                                                    width: 16,
                                                                                },
                                                                            }}
                                                                            showEditButton
                                                                        /> */}
                                                                    </div>
                                                                    <ContextMenu model={[
                                                                        {
                                                                            label: 'Edit',
                                                                            icon: 'pi pi-fw pi-pencil',
                                                                            command: () => { setEditModeTier1(cyindex) }
                                                                        },
                                                                        // {
                                                                        //     label: 'Change Order',
                                                                        //     icon: 'pi pi-fw pi-sort-alt',
                                                                        //     command: () => { setOrderList(JSON.parse(JSON.stringify(tier1))); setTierID(1); updateChangeOrder() }
                                                                        // },
                                                                        // {
                                                                        //     label: 'Delete',
                                                                        //     icon: 'pi pi-fw pi-trash', command: () => {deleteItem().then((result)=>{if(result){deleteTier1(item) }})   }
                                                                        // }
                                                                    ]} ref={(r) => { tier1ref.current[parseInt(`1${item.id}`)] = r }}></ContextMenu>
                                                                    <div className="col-1" onContextMenu={(e) => { showTier1CM(e, parseInt(`1${item.id}`)) }} style={{ padding: 0, cursor: 'pointer' }} >

                                                                        <i className="material-icons" >settings</i>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    )}
                                                </div>
                                            </div>
                                            {selected.tier1 !== "" && (
                                                <div className="col-3">
                                                    <div className="grid">
                                                        <div className="col-10">
                                                            <InputText
                                                                type={"text"}
                                                                value={module.tier2}
                                                                onChange={(e) => {
                                                                    updateTierValue("tier2", e);
                                                                }}
                                                                placeholder="Enter Topic"
                                                            />
                                                        </div>
                                                        <div className="col-2" style={{ marginLeft: -10 }}>
                                                            <Button
                                                                icon="pi pi-plus"
                                                                className="p-button-success mr-2"
                                                                style={{ cursor: module.tier2.trim().length === 0 ? 'not-allowed' : 'pointer', background: module.tier2.trim().length === 0 ? 'gray' : '#22C55E', border: module.tier2.trim().length === 0 ? '1px solid gray' : '1px solid #22C55E' }}
                                                                onClick={() => {
                                                                    addNewTier2();
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="col-12"
                                                        style={{
                                                            border: "1px solid grey",
                                                            height: "calc(100vh - 320px)",
                                                            overflow: "auto",
                                                            borderRadius: 5
                                                        }}
                                                    >
                                                        {tier2.length === 0 ? (
                                                            <text
                                                                style={{
                                                                    padding: 5,
                                                                }}
                                                            >

                                                                {"Add Topic"}
                                                            </text>
                                                        ) : (
                                                            tier2.sort((a, b) => { return a.order - b.order }).map((item, cyindex) => {
                                                                return (
                                                                    <>
                                                                        <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                                                            <Tooltip target={".topic" + item.id} position={'top'} autoHide={true}> TP{item.id + ' ' + item.title} </Tooltip>
                                                                            <div className={"col-11 topic" + item.id}
                                                                                onClick={() => {
                                                                                    getTier3Data(item.id);
                                                                                }}
                                                                                style={{
                                                                                    color: selected.tier2 === item.id && "white",
                                                                                    background: selected.tier2 === item.id && "grey",
                                                                                    borderBottom: "0.5px ridge grey", padding: 0
                                                                                }}
                                                                            >
                                                                                <label className={"fw-5 ml-1 " + getTag(item).class} style={{ background: selected.tier2 === item.id && "white" }} >{getTag(item).title}</label>
                                                                                <EdiText

                                                                                    viewContainerClassName={selected.tier2 === item.id ? 'my-custom-view-wrapper-selected' : 'my-custom-view-wrapper'}

                                                                                    type='text'
                                                                                    value={item.title}
                                                                                    onSave={(e) => { updateTier2(e, item.id); setEditModeOffTier2() }}
                                                                                    editing={item.edit}
                                                                                    onCancel={() => { setEditModeOffTier2() }}
                                                                                    hideIcons={true}
                                                                                    validationMessage="Title should not exist / not empty "
                                                                                    validation={val => { console.log(val); return val.trim().length !== 0 && tier2.findIndex((k) => { return (k.title.trim().toLowerCase() === val.trim().toLowerCase() && item.id !== k.id && item.tag === k.tag) }) === -1 }}
                                                                                    saveButtonContent="✔"
                                                                                    cancelOnUnfocus
                                                                                    cancelButtonContent={<strong>X</strong>}

                                                                                    editButtonClassName="custom-edit-button"
                                                                                />
                                                                                {/* <EditText
                                                                                    onSave={(e) => {
                                                                                        if (e.value.trim().length !== 0) {
                                                                                            updateTier2(e);
                                                                                        }
                                                                                    }}
                                                                                    name="textbox3"
                                                                                    // value={item.country}
                                                                                    defaultValue={item.title}
                                                                                    editButtonProps={{
                                                                                        style: {
                                                                                            marginLeft: "5px",
                                                                                            width: 16,
                                                                                        },
                                                                                    }}
                                                                                    showEditButton
                                                                                /> */}
                                                                            </div>
                                                                            <ContextMenu model={[
                                                                                {
                                                                                    label: 'Edit',
                                                                                    icon: 'pi pi-fw pi-pencil',
                                                                                    command: () => { setEditModeTier2(cyindex) }
                                                                                },
                                                                                tier2.length > 1 ? {
                                                                                    label: 'Change Order',
                                                                                    icon: 'pi pi-fw pi-sort-alt',
                                                                                    command: () => { setOrderList(JSON.parse(JSON.stringify(tier2))); setTierID(2); updateChangeOrder() }
                                                                                } : null,
                                                                                {
                                                                                    label: 'Edit Tag',
                                                                                    icon: 'pi pi-fw pi-pencil',
                                                                                    command: () => { editTagTopic(item, cyindex) }
                                                                                }
                                                                                ,
                                                                                (item.extra === undefined || item.extra === null) ? {
                                                                                    label: 'Clone',
                                                                                    icon: 'pi pi-fw pi-clone',
                                                                                    command: () => { cloneTopic({ topic_id: item.id }) }
                                                                                } : null,
                                                                                (selected.tier2 === item.id && tier3.length === 0) ? {
                                                                                    label: 'Delete',
                                                                                    icon: 'pi pi-fw pi-trash', command: () => { deleteItem().then((result) => { if (result) { deleteTier2(item) } }) }
                                                                                } : null
                                                                            ].filter((i) => { return i !== null })} ref={(r) => { tier2ref.current[parseInt(`2${item.id}`)] = r }}></ContextMenu>
                                                                            <div className="col-1" onContextMenu={(e) => { showTier2CM(e, parseInt(`2${item.id}`)) }} style={{ padding: 0, cursor: 'pointer' }} >
                                                                                <i className="material-icons" >settings</i>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                );
                                                            })
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                            {selected.tier2 !== "" && (
                                                <div className="col-3">
                                                    <div className="grid">
                                                        <div className="col-10">
                                                            <InputText
                                                                type={"text"}
                                                                value={module.tier3}
                                                                onChange={(e) => {
                                                                    updateTierValue("tier3", e);
                                                                }}
                                                                placeholder="Enter Indicator"
                                                            />
                                                        </div>
                                                        <div className="col-2" style={{ marginLeft: -10 }}>
                                                            <Button
                                                                icon="pi pi-plus"
                                                                className="p-button-success mr-2"
                                                                style={{ cursor: module.tier3.trim().length === 0 ? 'not-allowed' : 'pointer', background: module.tier3.trim().length === 0 ? 'gray' : '#22C55E', border: module.tier3.trim().length === 0 ? '1px solid gray' : '1px solid #22C55E' }}
                                                                onClick={() => {
                                                                    addNewTier3();
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="col-12"
                                                        style={{
                                                            border: "1px solid grey",
                                                            height: "calc(100vh - 320px)",
                                                            overflow: "auto",
                                                            borderRadius: 5
                                                        }}
                                                    >
                                                        {tier3.length === 0 ? (
                                                            <text
                                                                style={{
                                                                    padding: 5,
                                                                }}
                                                            >

                                                                {"Add Indicator"}
                                                            </text>
                                                        ) : (
                                                            tier3.sort((a, b) => { return a.order - b.order }).map((item, cyindex) => {
                                                                return (
                                                                    <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                                                        <Tooltip target={".metric" + item.id} position={'top'} autoHide={true}> MT{item.id}   {(item.data1 === null || item.data1[0].title === undefined) ? '*****' : item.data1[0].title} </Tooltip>
                                                                        <div className={"col-11 " + " metric" + item.id}

                                                                            onClick={() => {
                                                                                let loc = selected


                                                                                if (Array.isArray(item.data1)) {
                                                                                    if (Object.keys(item.data1[0]) !== 2) {
                                                                                        if (item.data1[0].source === 1 && item.data1[0].type === 0) {
                                                                                            getTier4Data(item.id);
                                                                                        } else {
                                                                                            loc.tier3 = ''
                                                                                        }
                                                                                    } else {
                                                                                        loc.tier3 = ''
                                                                                    }
                                                                                } else {
                                                                                    loc.tier3 = ''
                                                                                }
                                                                                setSelected(loc)
                                                                                forceUpdate()
                                                                            }}
                                                                            style={{
                                                                                color: selected.tier3 === item.id && "white",
                                                                                background: selected.tier3 === item.id && "grey",
                                                                                borderBottom: "0.5px ridge grey", padding: 0,
                                                                            }}
                                                                        >
                                                                            <div  >
                                                                                <label style={{
                                                                                    padding: '5px',
                                                                                    background: getStatus(item).color,
                                                                                    borderRadius: '5px',
                                                                                    color: 'white'
                                                                                }}>{getStatus(item).title}</label>
                                                                                <label className={"fw-5 ml-1 " + getTag(item).class} style={{ background: selected.tier3 === item.id && "white" }} >{getTag(item).title}</label>
                                                                            </div>
                                                                            <EdiText

                                                                                viewContainerClassName={selected.tier3 === item.id ? 'my-custom-view-wrapper-selected' : 'my-custom-view-wrapper'}

                                                                                type='text'
                                                                                value={item.title}
                                                                                onSave={(e) => { updateTier3(e, item.id); setEditModeOffTier3() }}
                                                                                editing={item.edit}
                                                                                onCancel={() => { setEditModeOffTier3() }}
                                                                                hideIcons={true}
                                                                                validationMessage="Title should not exist / not empty "
                                                                                validation={val => { console.log(val); return val.trim().length !== 0 && tier3.findIndex((k) => { return (k.title.trim().toLowerCase() === val.trim().toLowerCase() && item.id !== k.id && item.tag === k.tag) }) === -1 }}
                                                                                saveButtonContent="✔"
                                                                                cancelOnUnfocus
                                                                                cancelButtonContent={<strong>X</strong>}

                                                                                editButtonClassName="custom-edit-button"
                                                                            />
                                                                            {/* <EditText
                                                                                onSave={(e) => {
                                                                                    if (e.value.trim().length !== 0) {
                                                                                        updateTier3(e);
                                                                                    }
                                                                                }}
                                                                                name="textbox3"
                                                                                // value={item.country}
                                                                                defaultValue={item.title}
                                                                                editButtonProps={{
                                                                                    style: {
                                                                                        marginLeft: "5px",
                                                                                        width: 16,
                                                                                    },
                                                                                }}
                                                                                showEditButton
                                                                            /> */}
                                                                        </div>
                                                                        <ContextMenu model={[
                                                                            {
                                                                                label: 'Edit Title',
                                                                                icon: 'pi pi-fw pi-pencil',
                                                                                command: () => { setEditModeTier3(cyindex) }
                                                                            },

                                                                            {
                                                                                label: 'Edit Tag',
                                                                                icon: 'pi pi-fw pi-pencil',
                                                                                command: () => { editTagMetric(item, cyindex) }
                                                                            },
                                                                            (item.extra === undefined || item.extra === null) ? {
                                                                                label: 'Clone',
                                                                                icon: 'pi pi-fw pi-clone',
                                                                                command: () => { cloneIndicator({ metric_id: item.id }) }
                                                                            } : null,
                                                                            tier3.length > 1 ? {
                                                                                label: 'Change Order',
                                                                                icon: 'pi pi-fw pi-sort-alt',
                                                                                command: () => { setOrderList(JSON.parse(JSON.stringify(tier3))); setTierID(3); updateChangeOrder() }
                                                                            } : null,
                                                                            (Array.isArray(item.data1) && item.data1[0].type !== undefined && item.data1[0].type === 1) || (Array.isArray(item.data1) && item.data1[0].type !== undefined && item.data1[0].type === 0 && item.data1[0].source === 0) || (Array.isArray(item.data1) && item.data1[0].type !== undefined && item.data1[0].type === 0 && item.data1[0].source === 1 && selected.tier3 === item.id && tier4.length === 0) ? {
                                                                                label: 'Delete',
                                                                                icon: 'pi pi-fw pi-trash', command: () => { deleteItem().then((result) => { if (result) { deleteTier3(item) } }) }
                                                                            } : null
                                                                        ].filter((i) => { return i !== null })
                                                                        } ref={(r) => { tier3ref.current[parseInt(`3${item.id}`)] = r }}></ContextMenu>
                                                                        <div className="col-1" onContextMenu={(e) => { showTier3CM(e, parseInt(`3${item.id}`)) }} style={{ padding: 0, cursor: 'pointer' }} onClick={() => { openMetricConfig(JSON.stringify(item)) }}>

                                                                            <i className="material-icons" style={{ color: !getRFStatus(item) && 'red' }} >settings</i>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                            {selected.tier3 !== "" && (
                                                <div className="col-3">
                                                    <div className="grid">
                                                        <div className="col-10">
                                                            <InputText
                                                                type={"text"}

                                                                value={module.tier4}
                                                                onChange={(e) => {
                                                                    updateTierValue("tier4", e);
                                                                }}
                                                                placeholder="Enter Data Point"
                                                            />
                                                        </div>
                                                        <div className="col-2" style={{ marginLeft: -10 }}>
                                                            <Button
                                                                icon="pi pi-plus"
                                                                className="p-button-success mr-2"
                                                                style={{ cursor: module.tier4.trim().length === 0 ? 'not-allowed' : 'pointer', background: module.tier4.trim().length === 0 ? 'gray' : '#22C55E', border: module.tier4.trim().length === 0 ? '1px solid gray' : '1px solid #22C55E' }}
                                                                onClick={() => {

                                                                    addNewTier4();
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="col-12"
                                                        style={{
                                                            border: "1px solid grey",
                                                            height: "calc(100vh - 320px)",
                                                            overflow: "auto",
                                                            borderRadius: 5
                                                        }}
                                                    >
                                                        {tier4.length === 0 ? (
                                                            <text
                                                                style={{
                                                                    padding: 5,
                                                                }}
                                                            >

                                                                {"Add Data Points"}
                                                            </text>
                                                        ) : (
                                                            tier4.sort((a, b) => { return a.order - b.order }).map((item, cyindex) => {
                                                                return (
                                                                    <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                                                        <Tooltip target={".dp" + item.id} position={'top'} autoHide={true}> {item.title} </Tooltip>

                                                                        <div className={"col-11 " + " dp" + item.id}
                                                                            onClick={() => {

                                                                                getTier5Data(item.id);
                                                                            }}
                                                                            style={{
                                                                                borderBottom: "0.5px ridge grey", padding: 0
                                                                            }}

                                                                        >
                                                                            <div  >
                                                                                <label style={{
                                                                                    padding: '5px',
                                                                                    background: 'cadetblue',
                                                                                    borderRadius: '5px',
                                                                                    color: 'white'
                                                                                }}>{item.suffix}</label>

                                                                            </div>
                                                                            <EdiText

                                                                                viewContainerClassName='my-custom-view-wrapper'
                                                                                type='text'
                                                                                value={item.title}
                                                                                onSave={(e) => { updateTier4(e, item.id); setEditModeOffTier4() }}
                                                                                editing={item.edit}
                                                                                onCancel={() => { setEditModeOffTier4() }}
                                                                                hideIcons={true}
                                                                                validationMessage="Title should not exist / not empty"
                                                                                validation={val => { return val.trim().length !== 0 && tier4.findIndex((k) => { return (k.title.trim().toLowerCase() === val.trim().toLowerCase() && item.id !== k.id) }) === -1 }}
                                                                                saveButtonContent="✔"
                                                                                cancelOnUnfocus
                                                                                cancelButtonContent={<strong>X</strong>}

                                                                                editButtonClassName="custom-edit-button"
                                                                            />
                                                                            {/* <EditText
                                                                                onSave={(e) => {
                                                                                    if (e.value.trim().length !== 0) {
                                                                                        updateTier4(e);
                                                                                    }
                                                                                }}
                                                                                name="textbox3"

                                                                                // value={item.country}
                                                                                defaultValue={item.title}
                                                                                editButtonProps={{
                                                                                    style: {
                                                                                        marginLeft: "5px",
                                                                                        width: 16,
                                                                                    },
                                                                                }}
                                                                                showEditButton

                                                                            /> */}

                                                                        </div>
                                                                        <ContextMenu model={[
                                                                            {
                                                                                label: 'Edit',
                                                                                icon: 'pi pi-fw pi-pencil',
                                                                                command: () => { setEditModeTier4(cyindex) }
                                                                            },
                                                                            {
                                                                                label: 'Map DCF',
                                                                                icon: 'pi pi-fw pi-pencil',
                                                                                command: () => { openConfig_(JSON.stringify(item)) }
                                                                            },
                                                                            tier4.length > 1 ? {
                                                                                label: 'Change Order',
                                                                                icon: 'pi pi-fw pi-sort-alt',
                                                                                command: () => { setOrderList(JSON.parse(JSON.stringify(tier4))); setTierID(4); updateChangeOrder() }
                                                                            } : null,
                                                                            {
                                                                                label: 'Delete',
                                                                                icon: 'pi pi-fw pi-trash', command: () => { deleteItem().then((result) => { if (result) { deleteTier4(item) } }) }
                                                                            }
                                                                        ].filter((i) => { return i !== null })} ref={(r) => { tier4ref.current[parseInt(`4${item.id}`)] = r }}></ContextMenu>


                                                                        <div className="col-1" onContextMenu={(e) => { showTier4CM(e, parseInt(`4${item.id}`)) }} style={{ padding: 0, cursor: 'pointer' }} onClick={() => { openConfig(JSON.stringify(item)) }}>

                                                                            <i className="material-icons" style={{ color: (item.data1 === null || ((!item.data1[0].datasource && !item.data1[0].datasource2))) ? 'red' : '#495057' }} >settings</i>
                                                                        </div>

                                                                    </div>
                                                                );
                                                            })
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="col-12 card">You have no rights to access this page </div>
            )}
            <Dialog
                visible={datapointconfigdialog}
                style={{
                    width: "60%",
                }}
                header={'#' + selectedDataPoint.suffix + ' ' + selectedDataPoint.title}
                modal
                footer={submitFooter}
                onHide={() => {
                    setDataPointConfigDialog(false);
                }}
            >
                <div>
                    <div className="col-12 lg:col-12">
                        <div
                            className="col-12 "
                            style={{
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <div
                                style={{
                                    width: "20%",
                                    display: "flex",
                                    alignSelf: "center",
                                }}
                            >
                                <span>
                                    Type

                                </span>
                            </div>
                            <div
                                style={{
                                    width: "80%",
                                }}
                                className="p-inputgroup"
                            >
                                <Dropdown
                                    optionLabel="name"
                                    value={config.type}

                                    onChange={(e) => {
                                        updateConfig("type", e.value);
                                    }}
                                    options={[{ name: 'Quantitative' }, { name: 'Qualitative' }]}
                                    placeholder="type"
                                />

                            </div>

                        </div>
                        {config.type.name === 'Quantitative' ?
                            <>
                                <div
                                    className="col-12 "
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "20%",
                                            display: "flex",
                                            alignSelf: "center",
                                        }}
                                    >
                                        <span>
                                            Unit of measure
                                            <span
                                                style={{
                                                    color: "red",
                                                }}
                                            >
                                                &nbsp;*
                                            </span>
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            width: "80%",
                                        }}
                                        className="p-inputgroup"
                                    >
                                        <InputText
                                            value={config.unit}
                                            min={1}
                                            onChange={(e) => {
                                                updateConfig("unit", e.target.value);
                                            }}
                                            type={"text"}
                                            placeholder="unit"
                                        />

                                    </div>

                                </div>
                                {submitted && config.unit.trim().length === 0 &&
                                    (
                                        <small
                                            className="p-invalid"
                                            style={{
                                                color: "red", marginLeft: '22%'
                                            }}
                                        >

                                            Unit of measure is mandatory
                                        </small>
                                    )}
                            </>
                            :
                            <>
                                <div
                                    className="col-12 "
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "20%",
                                            display: "flex",
                                            alignSelf: "center",
                                        }}
                                    >
                                        <span>
                                            Describe the question that is required
                                            <span
                                                style={{
                                                    color: "red",
                                                }}
                                            >
                                                &nbsp;*
                                            </span>
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            width: "80%",
                                        }}
                                        className="p-inputgroup"
                                    >

                                        <InputTextarea value={config.unit} onChange={(e) => {
                                            updateConfig("unit", e.target.value);
                                        }} placeholder="Enter the question for which a qualitative response is required" rows={3} cols={30} />

                                    </div>

                                </div>
                                {submitted && config.unit.trim().length === 0 &&
                                    (
                                        <small
                                            className="p-invalid"
                                            style={{
                                                color: "red", marginLeft: '22%'
                                            }}
                                        >

                                            qualitative question is required
                                        </small>
                                    )}
                            </>
                        }

                        <div
                            className="col-12 "
                            style={{
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <div
                                style={{
                                    width: "20%",
                                    display: "flex",
                                    alignSelf: "center",
                                }}
                            >
                                <span>
                                    Data Point ID
                                    <span
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        &nbsp;*
                                    </span>
                                </span>
                            </div>
                            <div
                                style={{
                                    width: "80%",
                                }}
                                className="p-inputgroup"
                            >
                                <InputText
                                    value={config.suffix}
                                    min={1}
                                    onChange={(e) => {
                                        updateConfig("suffix", e.target.value);
                                    }}
                                    type={"text"}
                                    placeholder="ID"
                                />

                            </div>

                        </div>
                        {/* {submitted && (config.suffix.trim().length === 0 || config.suffix[0] !== 'D' || config.suffix[1] !== 'P') &&
                            (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red", marginLeft: '22%'
                                    }}
                                >

                                    Data point ID is either invalid format / empty. Format : DP1,DP55,DP999
                                </small>
                            )} */}

                        <div
                            className="col-12 "
                            style={{
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <div
                                style={{
                                    width: "20%",
                                    display: "flex",
                                    alignSelf: "center",
                                }}
                            >
                                <span>
                                    Description

                                </span>
                            </div>
                            <div
                                style={{
                                    width: "80%",
                                }}
                                className="p-inputgroup"
                            >

                                <InputTextarea value={config.desc} onChange={(e) => {
                                    updateConfig("desc", e.target.value);
                                }} placeholder="description..." rows={5} cols={30} />
                            </div>
                        </div>

                        <div
                            className="col-12 "
                            style={{
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <div
                                style={{
                                    width: "20%",
                                    display: "flex",
                                    alignSelf: "center",
                                }}
                            >
                                <span>
                                    Tags

                                </span>
                            </div>
                            <div
                                style={{
                                    width: "80%",
                                }}
                                className="p-inputgroup"
                            >
                                <Chips value={config.tags} onChange={(e) => {
                                    updateConfig("tags", e.value);
                                }} separator="," />
                            </div>
                        </div>
                        <small
                            className="p-invalid"
                            style={{
                                color: "gray", marginLeft: '22%'
                            }}
                        >

                            ( press , or enter key to add tags )
                        </small>


                        <div
                            className="col-12 "
                            style={{
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <div
                                style={{
                                    width: "20%",
                                    display: "flex",
                                    alignSelf: "center",
                                }}
                            >
                                <span>
                                    Select DCF
                                    {/* <span style={{ color: 'red' }} >*</span> */}
                                </span>
                            </div>
                            <div
                                style={{
                                    width: "80%",
                                }}
                                className="p-inputgroup"
                            >
                                <AutoComplete disabled={config.datasource !== null && typeof config.datasource === 'object'} field="title" forceSelection value={config.datasource} suggestions={dcfitems} completeMethod={search} onChange={(e) => updateConfig('datasource', e.value)} dropdown />


                                {/* <span onClick={(e) => updateConfig('datasource', null)} style={{ textDecoration: 'underline', padding: 10, cursor: 'pointer' }}>reset</span> */}
                            </div>
                        </div>
                        <div
                            className="col-12 "
                            style={{
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <div
                                style={{
                                    width: "20%",
                                    display: "flex",
                                    alignSelf: "center",
                                }}
                            >
                                <span>
                                    Select SAP
                                    {/* <span style={{ color: 'red' }} >*</span> */}
                                </span>
                            </div>
                            <div
                                style={{
                                    width: "80%",
                                }}
                                className="p-inputgroup"
                            >
                                <AutoComplete disabled={config.datasource2 !== null && typeof config.datasource2 === 'object'} field="name" forceSelection value={config.datasource2} suggestions={sapitems} completeMethod={search_} onChange={(e) => updateConfig('datasource2', e.value)} dropdown />


                                {/* <span onClick={(e) => updateConfig('datasource', null)} style={{ textDecoration: 'underline', padding: 10, cursor: 'pointer' }}>reset</span> */}
                            </div>
                        </div>
                        {submitted && (!config.datasource && !config.datasource2) &&
                            (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red", marginLeft: '22%'
                                    }}
                                >

                                    Select either DCF/SAP
                                </small>
                            )}

                    </div>



                </div>
            </Dialog>
            <Dialog
                visible={metricconfigdialog}
                style={{
                    width: "60%",
                }}
                header={"#MT" + metric.id + " " + metric.title}
                modal
                footer={submitFooter_metric}
                onHide={() => {
                    setMetricConfigDialog(false);
                }}
            >
                <div>
                    <div className="col-12 lg:col-12">
                        <div
                            className="col-12 "
                            style={{
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <div
                                style={{
                                    width: "20%",
                                    display: "flex",
                                    alignSelf: "center",
                                }}
                            >
                                <span>

                                    Long Title
                                    <span style={{ color: 'red' }}> *</span>
                                </span>
                            </div>
                            <div
                                style={{
                                    width: "80%",
                                }}
                                className="p-inputgroup"
                            >

                                <InputText value={metricconfig.title} style={{ borderRadius: 10 }} onChange={(e) => {
                                    updateMetricConfig("title", e.target.value);
                                }} placeholder="title" />
                            </div>
                        </div>
                        {submitted && metricconfig.title.trim().length === 0 &&
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red", marginLeft: '22%'
                                }}
                            >
                                Title required

                            </small>
                        }

                        <div
                            className="col-12 "
                            style={{
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <div
                                style={{
                                    width: "20%",
                                    display: "flex",
                                    alignSelf: "center",
                                }}
                            >
                                <span>
                                    Select type
                                    <span style={{ color: 'red' }}> *</span>
                                </span>
                            </div>
                            <div
                                style={{
                                    width: "80%",
                                }}
                                className="p-inputgroup"
                            >
                                <Dropdown value={metricconfig.type} options={[{ name: 'Quantitative', id: 0 }, { name: 'Qualitative', id: 1 }]} optionLabel="name" optionValue="id" onChange={(e) => updateMetricConfig("type", e.value)} />
                            </div>
                        </div>

                        {submitted && metricconfig.type === null &&
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red", marginLeft: '22%'
                                }}
                            >
                                select type

                            </small>
                        }
                        {metricconfig.type === 0 ?
                            <>
                                <div
                                    className="col-12 "
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "20%",
                                            display: "flex",
                                            alignSelf: "center",
                                        }}
                                    >
                                        <span>
                                            Unit of measure
                                            <span
                                                style={{
                                                    color: "red",
                                                }}
                                            >
                                                &nbsp;*
                                            </span>
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            width: "80%",
                                        }}
                                        className="p-inputgroup"
                                    >
                                        <InputText
                                            value={metricconfig.unit}
                                            min={1}
                                            onChange={(e) => {
                                                updateMetricConfig("unit", e.target.value);
                                            }}
                                            type={"text"}
                                            placeholder="unit"
                                        />

                                    </div>

                                </div>
                                {submitted && metricconfig.unit.trim().length === 0 &&
                                    (
                                        <small
                                            className="p-invalid"
                                            style={{
                                                color: "red", marginLeft: '22%'
                                            }}
                                        >

                                            Unit of measure is mandatory
                                        </small>
                                    )}
                                <div
                                    className="col-12 "
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                >

                                    <div
                                        style={{
                                            width: "20%",
                                            display: "flex",
                                            alignSelf: "center",
                                        }}
                                    >
                                        <span>
                                            Select Source
                                            <span style={{ color: 'red' }}> *</span>
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            width: "80%",
                                        }}
                                        className="p-inputgroup"
                                    >
                                        <Dropdown value={metricconfig.source} options={[{ name: 'Derived', id: 0 }, { name: 'Standalone', id: 1 }]} optionLabel="name" onChange={(e) => updateMetricConfig("source", e.value)} optionValue="id" />
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div
                                    className="col-12 "
                                    style={{
                                        display: "flex",
                                        flexDirection: "row", alignItems: 'center'
                                    }}
                                >
                                    <Checkbox inputId="gen" value={metricconfig.general} onChange={(e) => { updateMetricConfig("general", e.checked) }} checked={metricconfig.general} />
                                    <label htmlFor="gen" className="ml-2">Only for General Information. Not applicable  for scoring. </label>

                                </div>
                                <div
                                    className="col-12 "
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "20%",
                                            display: "flex",
                                            alignSelf: "center",
                                        }}
                                    >
                                        <span>
                                            Select DF
                                            {/* <span style={{ color: 'red' }}> *</span> */}
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            width: "80%",
                                        }}
                                        className="p-inputgroup"
                                    >
                                        <Dropdown value={metricconfig.rf} filter options={rfList} panelStyle={{ width: '60%' }} filterBy={"title,id"} itemTemplate={(e) => { console.log(e); return (<> {'DF ' + e.id + ' - ' + e.title}</>) }} optionLabel="title" onChange={(e) => updateMetricConfig("rf", e.value)} optionValue="id" />
                                    </div>
                                </div>
                                {/* {submitted && metricconfig.rf === null &&
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red", marginLeft: '22%'
                                        }}
                                    >
                                        RF Form required

                                    </small>
                                } */}
                            </>

                        }
                        {submitted && metricconfig.source === null &&
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red", marginLeft: '22%'
                                }}
                            >
                                Source field required

                            </small>
                        }
                        {metricconfig.source !== null && metricconfig.source === 0 &&
                            <>
                                <div
                                    className="col-12 "
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "20%",
                                            display: "flex",
                                            alignSelf: "center",
                                        }}
                                    >
                                        <span>
                                            Source Indicator {metricconfig.indicator.length === 1 && `( ${metricconfig.indicator.length} )`}
                                            <span style={{ color: 'red' }}> *</span>
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            width: "80%",
                                        }}
                                        className="p-inputgroup"
                                    >
                                        <MultiSelect display="chip" value={metricconfig.indicator} onChange={(e) => updateMetricConfig("indicator", e.value)} options={metricList} optionLabel="title" optionValue="id"
                                            filter={true} placeholder="Select Indicator" className="w-full " panelClassName={'hidefilter'} />
                                    </div>
                                </div>
                                {metricconfig.indicator.length > 1 &&
                                    <div className="grid" >
                                        <div className="col-12" style={{ display: 'flex', justifyContent: 'flex-end' }} ><a style={{ cursor: 'pointer' }} onClick={() => { showList(1, metricconfig.indicator) }}>View selected {`(${metricconfig.indicator.length})`}</a></div>

                                    </div>

                                }
                                {submitted && metricconfig.indicator.length === 0 &&
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red", marginLeft: '22%'
                                        }}
                                    >
                                        Select Indicator

                                    </small>
                                }
                            </>

                        }

                        <fieldset style={{

                            borderRadius: '10px', margin: 10,
                            border: '1px solid #ced4da',

                        }}>
                            <legend>Tags</legend>
                            <div
                                className="col-12 "
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}
                            >
                                <div
                                    style={{
                                        width: "20%",
                                        display: "flex",
                                        alignSelf: "center",
                                    }}
                                >
                                    <span>
                                        Must Have

                                    </span>
                                </div>
                                <div
                                    style={{
                                        width: "80%",
                                    }}
                                    className="p-inputgroup"
                                >
                                    <Chips value={metricconfig.tags1} onChange={(e) => {
                                        updateMetricConfig("tags1", e.value);
                                    }} separator="," />
                                </div>
                            </div>
                            <div
                                className="col-12 "
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}
                            >
                                <div
                                    style={{
                                        width: "20%",
                                        display: "flex",
                                        alignSelf: "center",
                                    }}
                                >
                                    <span>
                                        Progressive

                                    </span>
                                </div>
                                <div
                                    style={{
                                        width: "80%",
                                    }}
                                    className="p-inputgroup"
                                >
                                    <Chips value={metricconfig.tags2} onChange={(e) => {
                                        updateMetricConfig("tags2", e.value);
                                    }} separator="," />
                                </div>
                            </div>
                            <div
                                className="col-12 "
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}
                            >
                                <div
                                    style={{
                                        width: "20%",
                                        display: "flex",
                                        alignSelf: "center",
                                    }}
                                >
                                    <span>
                                        Advanced

                                    </span>
                                </div>
                                <div
                                    style={{
                                        width: "80%",
                                    }}
                                    className="p-inputgroup"
                                >
                                    <Chips value={metricconfig.tags3} onChange={(e) => {
                                        updateMetricConfig("tags3", e.value);
                                    }} separator="," />
                                </div>
                            </div>

                            <small
                                className="p-invalid"
                                style={{
                                    color: "gray", marginLeft: '3%'
                                }}
                            >

                                ( press , or enter key to add tags )
                            </small>
                        </fieldset>


                        <div
                            className="col-12 "
                            style={{
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <div
                                style={{
                                    width: "20%",
                                    display: "flex",
                                    alignSelf: "center",
                                }}
                            >
                                <span>
                                    Description

                                </span>
                            </div>
                            <div
                                style={{
                                    width: "80%",
                                }}
                                className="p-inputgroup"
                            >

                                <InputTextarea value={metricconfig.desc} style={{ borderRadius: 10 }} onChange={(e) => {
                                    updateMetricConfig("desc", e.target.value);
                                }} placeholder="description..." rows={5} cols={30} />
                            </div>
                        </div>
                    </div>



                </div>
            </Dialog>
            <Dialog
                visible={orderdialog}
                style={{
                    width: "40%",
                }}
                header={"Order " + tierid === 1 ? 'Categories' : tierid === 2 ? 'Topics' : tierid === 3 ? 'Indicators' : 'Data Points'}
                modal
                footer={submitFooter_order}
                onHide={() => {
                    setOrderDialog(false);
                }}
            >
                <div>


                    <div className="xl:flex xl:justify-content-center grid">
                        {orderlist.map((i, j) => {
                            console.log(i)
                            return itemTemplate(i, j)
                        })

                        }
                    </div>

                </div>
            </Dialog>
            <Dialog
                visible={prevSListdialog}
                style={{
                    width: "60%",
                }}
                header={selectedlist.title}
                modal
                className="p-fluid"

                onHide={() => { setPrevSListDialog(false) }}
            >
                {renderListPreview()}
            </Dialog>
            <Dialog
                visible={dpdcfmapdialog}
                style={{
                    width: "60%",
                }}
                header={'#' + selectedDataPoint.suffix + ' ' + selectedDataPoint.title}
                modal
                footer={submitFooter_}
                onHide={() => {
                    setDPDCFMapDialog(false);
                }}
            >
                <div>
                    <div className="col-12 lg:col-12">

                        <div
                            className="col-12 "
                            style={{
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <div
                                style={{
                                    width: "20%",
                                    display: "flex",
                                    alignSelf: "center",
                                }}
                            >
                                <span>
                                    Select DCF

                                </span>
                            </div>
                            <div
                                style={{
                                    width: "80%",
                                }}
                                className="p-inputgroup"
                            >
                                <AutoComplete field="title" forceSelection value={config.datasource} suggestions={dcfitems} completeMethod={search} onChange={(e) => updateConfig('datasource', e.value)} dropdown />
                                <span onClick={(e) => updateConfig('datasource', null)} style={{ textDecoration: 'underline', padding: 10, cursor: 'pointer' }}>Reset Assignment</span>
                            </div>
                        </div>


                    </div>



                </div>
            </Dialog>
            <Dialog
                visible={sectiondialog}
                style={{
                    width: "60%",
                }}
                header={'Tag Section for MT' + (sectionobj.newMetricId)}
                modal
                footer={submitFooter_2}
                onHide={() => {
                    setSectionDialog(false);
                }}
            >
                <div>

                    <div className="field">
                        <label htmlFor="refdate">Select Default Section</label>
                        <Dropdown style={{ width: "450px" }} onChange={(e) => { updateDefaultSection(e.value) }} value={sectionobj.indicatorSectionId} options={sectionlist} optionLabel="title" optionValue={'id'} placeholder="Select" />
                    </div>


                </div>
            </Dialog>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(DataPointConfig, comparisonFn);
