import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Button } from "primereact/button";

const data = [
  { year: "FY-2023", fatalities: 0, recordable: 0, highConsequence: 0 },
  { year: "FY-2024", fatalities: 0, recordable: 0, highConsequence: 0 },
];

const InjuryUK = () => {
  return (
    <div style={{ fontFamily: "Lato", margin: "25px" }}>
      <h3 style={{ fontSize: "18px" }}>Injury Trends in UK Plant</h3>
      <p>
        Tracking injury trends of employees broken down by type: fatalities,
        recordable injuries, and High consequence injury, in UK plant.
      </p>

      {/* Responsive Container for Recharts */}
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="year"
            label={{ value: "Year", position: "insideBottom", offset: -5 }}
          />
          <YAxis
            label={{
              value: "Number of Injuries",
              angle: -90,
              position: "insideLeft",
            }}
          />
          <Tooltip />
          <Legend />

          {/* Injury Types */}
          <Bar
            dataKey="fatalities"
            fill="#e74c3c"
            name="Fatalities"
            barSize={20}
          />
          <Bar
            dataKey="recordable"
            fill="#f39c12"
            name="Recordable Injuries"
            barSize={20}
          />
          <Bar
            dataKey="highConsequence"
            fill="#16a085"
            name="High Consequence"
            barSize={20}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default InjuryUK;
