import React, { useEffect, useRef, useState } from "react";
import { Panel } from "primereact/panel";
import { InputTextarea } from "primereact/inputtextarea";
import useForceUpdate from "use-force-update";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Chips } from "primereact/chips";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { MultiSelect } from 'primereact/multiselect';
import { Editor } from "primereact/editor";
import { ContextMenu } from "primereact/contextmenu";
import Swal from "sweetalert2";
import Axios from "axios";
import { API } from "../../constants/api_url";
import { useSelector } from "react-redux";
import moment from "moment";
import { InputNumber } from "primereact/inputnumber";
import { type } from "jquery";
import APIServices from "../../service/APIService";



const ConsolidateForm = () => {
    let yOffset = 0;
    const [activecell, setActiveCell] = useState({ data: {} });
    const [activecellId, setActiveCellId] = useState(null);
    const selector = useSelector((state) => state.user.admindetail)
    const [dupids, setDupIds] = useState([])
    const params = useLocation()
    const [form, setForm] = useState({ title: '', data1: [], comments: '', suffix: 'SRF', type: 2 })
    const [clienttag, setClientTag] = useState([])
    const navigate = useHistory()
    const [cellconfigdialog, setCellConfigDialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const forceUpdate = useForceUpdate();
    const [draggedItem, setDraggedItem] = useState(null);
    const [draggedWidget, setDraggedWidget] = useState(null);

    const typelist = [{ name: 'Text', id: 1 }, { name: 'TextArea', id: 2 }, { name: 'Number', id: 3 }, { name: 'Dropdown', id: 4 }, { name: 'Date', id: 6 }]

    function findMaxNumber(stringArray, startingString) {
        // Filter strings starting with the specified startingString
        const filteredStrings = stringArray.filter(str => str.startsWith(startingString));

        if (filteredStrings.length === 0) {
            return startingString + form.id + '_' + 1
        }
        // Extract numbers after the startingString and convert them to an array of numbers
        const numbersArray = filteredStrings.map(str => parseInt(str.split('_')[1]));

        // Find the maximum value in the numbersArray
        const maxValue = Math.max(...numbersArray) + 1;
        console.log(maxValue)
        return startingString + form.id + '_' + maxValue;
    }
    // function findMaxNumber(stringArray, startingString) {

    //     const filteredStrings = stringArray.filter(str => str.startsWith(startingString));

    //     if (filteredStrings.length === 0) {
    //         return startingString + form.id + '_' + 1
    //     }

    //     const numbersArray = filteredStrings.map(str => parseInt(str.split('_')[1]));


    //     const maxValue = Math.max(...numbersArray) + 1;
    //     console.log(maxValue)
    //     return startingString + form.id + '_' + maxValue;
    // }
    function extractSubstringFromSecondPosition(inputString, character) {
        const firstIndex = inputString.indexOf(character);
        const secondIndex = inputString.indexOf(character, firstIndex + 1);

        if (firstIndex !== -1 && secondIndex !== -1) {
            const startIndex = secondIndex + 1; // Starting from the second position after the second occurrence
            return parseInt(inputString.substring(startIndex));
        }

        return 1; // Character not found or only one occurrence
    }
    function findMaxNumber_table(stringArray, startingString) {
        // Filter strings starting with the specified startingString
        console.log(stringArray, startingString)
        const filteredStrings = stringArray.filter(str => str.startsWith(startingString));

        if (filteredStrings.length === 0) {
            return 1
        }
        // Extract numbers after the startingString and convert them to an array of numbers
        const numbersArray = filteredStrings.map(str => parseInt(str.split('_')[2]));

        // Find the maximum value in the numbersArray
        const maxValue = Math.max(...numbersArray) + 1;
        console.log(maxValue)
        return maxValue;
    }
    const getID = () => {
        console.log(form)
        if (form.data1.length !== 0) {
            let ids = []
            form.data1.forEach((i) => {
                if (i.type !== 'htmleditor' && i.type !== 'paragraph') {
                    if (i.type !== 'table' && i.type !== 'tableadd') {
                        console.log(i)
                        ids.push(i.name)
                    }
                }
            })
            console.log(ids)
            return findMaxNumber(ids, 'SDP')
        } else {
            return 'SDP' + form.id + '_1'
        }
    }
    const getID_table = (id) => {

        if (form.data1.length !== 0) {
            let ids = []
            form.data1.forEach((i) => {

                if ((i.type === 'table' || i.type == 'tableadd') && i.name === id) {
                    i.data.forEach((j) => {
                        Object.values(j).forEach((k) => {
                            ids.push(k.data.name)
                        })

                    })

                }

            })
            console.log(ids, findMaxNumber_table(ids, id), 'table')
            return findMaxNumber_table(ids, id)
        } else {
            return 1
        }
    }
    const getObjectByType = (type) => {
        switch (type) {
            case "text":
                return { type: "text", label: "", name: getID(), required: false, value: null }

                break;
            case "inputtextarea":
                return { type: "textarea", label: "", name: getID(), required: false, value: null }

                break;
            case "date":
                return { type: "date", label: "", name: getID(), required: false, value: null }

                break;
            case "file":
                return { type: "file", label: "", name: getID(), required: false, value: null }

                break;
            case "paragraph":
                return { type: "paragraph", label: "" }

                break;
            case "htmleditor":
                return { type: "htmleditor", label: "", value: null }

                break;

            case "number":
                return { type: "number", label: "", name: getID(), required: false, value: null, fraction: 0, directionsign: 0 }

                break;
            case "table":
                return { type: "table", label: "", name: getID(), value: [], headerlimit: 5, required: false, data: [] }

                break;
            case "tableadd":
                return { type: "tableadd", label: "", name: getID(), value: [], maxrowlimit: 5, required: false, data: [] }

                break;
            case "radio":
                return { type: "radio-group", name: getID(), label: "", values: [{ label: 'Option-1', value: '1', selected: false }, { label: 'Option-2', value: '2', selected: false }], required: false, data: [], value: null }

                break;
            case "checkbox":
                return { type: "checkbox-group", name: getID(), label: "", values: [{ label: 'Option-1', value: '1', selected: false }, { label: 'Option-2', value: '2', selected: false }, { label: 'Option-3', value: '3', selected: false }], required: false, value: null }

                break;
            case "checkpoint":
                return { type: "checkpoint", name: getID(), label: "", values: [{ label: 'Yes', value: '1', selected: false }, { label: 'No', value: '2', selected: false }, { label: 'NA', value: '3', selected: false }], required: false, value: null }

                break;
            case "dropdown":
                return { type: "select", label: "", name: getID(), values: [{ label: 'Option-1', value: '1', selected: false }, { label: 'Option-2', value: '2', selected: false }, { label: 'Option-3', value: '3', selected: false }], required: false, value: null }

                break;
            default:
                return null;
        }
    }
    const addWidget = (type) => {
        let loc = JSON.parse(JSON.stringify(form));
        setSubmitted(false)
        switch (type) {
            case "text":
                loc.data1.push({ type: "text", label: "", name: getID(), required: false, value: null, isAttachmentMandatory: false, isLegalCompliance: false, isDedicated: false });
                setForm(loc);
                forceUpdate();
                break;
            case "inputtextarea":
                loc.data1.push({ type: "textarea", label: "", name: getID(), required: false, value: null, isAttachmentMandatory: false, isLegalCompliance: false, isDedicated: false });
                setForm(loc);
                forceUpdate();
                break;
            case "date":
                loc.data1.push({ type: "date", label: "", name: getID(), required: false, value: null, isAttachmentMandatory: false, isLegalCompliance: false, isDedicated: false });
                setForm(loc);
                forceUpdate();
                break;
            case "file":
                loc.data1.push({ type: "file", label: "", name: getID(), required: false, value: null });
                setForm(loc);
                forceUpdate();
                break;
            case "paragraph":
                loc.data1.push({ type: "paragraph", label: "" });
                setForm(loc);
                forceUpdate();
                break;
            case "htmleditor":
                loc.data1.push({ type: "htmleditor", label: "", value: null });
                setForm(loc);
                forceUpdate();
                break;

            case "number":
                loc.data1.push({ type: "number", label: "", name: getID(), required: false, fraction: 0, value: null, directionsign: 0, isAttachmentMandatory: false, isLegalCompliance: false, isDedicated: false });
                setForm(loc);
                forceUpdate();
                break;
            case "table":
                loc.data1.push({ type: "table", label: "", name: getID(), value: [], headerlimit: 5, required: false, data: [] });
                setForm(loc);
                forceUpdate();
                break;
            case "tableadd":
                loc.data1.push({ type: "tableadd", label: "", name: getID(), value: [], maxrowlimit: 5, required: false, data: [] });
                setForm(loc);
                forceUpdate();
                break;
            case "radio":
                loc.data1.push({ type: "radio-group", name: getID(), label: "", values: [{ label: 'Option-1', value: '1', selected: false }, { label: 'Option-2', value: '2', selected: false }], required: false, data: [], value: null, isAttachmentMandatory: false, isLegalCompliance: false, isDedicated: false });
                setForm(loc);
                forceUpdate();
                break;
            case "checkbox":
                loc.data1.push({ type: "checkbox-group", name: getID(), label: "", values: [{ label: 'Option-1', value: '1', selected: false }, { label: 'Option-2', value: '2', selected: false }, { label: 'Option-3', value: '3', selected: false }], required: false, value: null, isAttachmentMandatory: false, isLegalCompliance: false, isDedicated: false });
                setForm(loc);
                forceUpdate();
                break;
            case "checkpoint":
                loc.data1.push({ type: "checkpoint", name: getID(), label: "", values: [{ label: 'Yes', value: '1', selected: false }, { label: 'No', value: '2', selected: false }, { label: 'NA', value: '3', selected: false }], required: false, value: null, isAttachmentMandatory: false, isLegalCompliance: false, isDedicated: false });
                setForm(loc);
                forceUpdate();
                break;
            case "dropdown":
                loc.data1.push({ type: "select", label: "", name: getID(), values: [{ label: 'Option-1', value: '1', selected: false }, { label: 'Option-2', value: '2', selected: false }, { label: 'Option-3', value: '3', selected: false }], required: false, value: null, isAttachmentMandatory: false, isLegalCompliance: false, isDedicated: false });
                setForm(loc);
                forceUpdate();
                break;
            default:
                return null;
        }
    };
    useEffect(() => {
        APIServices.get(API.UserProfile).then((res) => {
            let locuser = [];

            res.data.forEach((item) => {
                if (item.role === "clientadmin" && item.information.enterpriseid !== undefined) {
                    locuser.push({
                        name: item.information.enterpriseid,
                        id: item.id,
                    });
                }
            });

            setClientTag(locuser);
        });
        console.log(params)
        if (params.state !== null && params.state !== undefined) {
            setForm(params.state)

        }
    }, [])
    const addRow = (val, index) => {
        let obj = {}
        let id = getID_table(form.data1[index].name)

        val.forEach((key, ind) => {
            obj[key] = { type: 1, data: { name: form.data1[index].name + '_' + id, required: false } }
            id = id + 1
        })
        console.log(obj)
        form.data1[index].data.push(obj)
        forceUpdate()

    };
    const duplicateRowAtLast = (windex, rindex) => {
        let loc = JSON.parse(JSON.stringify(form))

        let selected_row = JSON.parse(JSON.stringify(loc.data1[windex].data[rindex]))
        let obj = {}
        let id = getID_table(form.data1[windex].name)
        Object.values(selected_row).forEach((item, j) => {
            console.log((Object.keys(selected_row)[j]))
            if (item.type !== 5) {
                item.required = false
                item.data['name'] = form.data1[windex].name + '_' + id
                id += 1
            } else {
                item.label = ''
            }
            obj[Object.keys(selected_row)[j]] = item
        })


        loc.data1[windex].data.push(obj);

        setForm(loc)
        forceUpdate()

    };
    const duplicateRowBelow = (windex, rindex) => {

        let loc = JSON.parse(JSON.stringify(form))
        let selected_row = JSON.parse(JSON.stringify(loc.data1[windex].data[rindex]))
        let obj = {}
        let id = getID_table(form.data1[windex].name)

        Object.values(selected_row).forEach((item, j) => {
            console.log((Object.keys(selected_row)[j]))
            if (item.type !== 5) {
                item.required = false
                item.data['name'] = form.data1[windex].name + '_' + id
                id += 1
            } else {
                item.label = ''
            }
            obj[Object.keys(selected_row)[j]] = item
        })

        loc.data1[windex].data.splice(rindex + 1, 0, obj);

        setForm(loc)

        forceUpdate()

    };
    const deleteTRow = (windex, rindex) => {

        form.data1[windex].data.splice(rindex, 1)
        forceUpdate()
    }
    const getCellClassName = (rowData) => {
        if (rowData.type !== 5) {

            if (rowData.data.name === undefined || rowData.data.name.trim().length === 0) {
                return 'cell-p-invalid'
            } else if (dupids.includes(rowData.data.name.trim().toLowerCase())) {
                return 'cell-p-invalid-id'
            }
            else {
                console.log(dupids.includes(rowData.data.name.trim().toLowerCase()), dupids)
                return ''
            }
        } else {
            return ''
        }
    }
    const actionTemplate = (rowData, windex, rindex) => {
        return (
            <>
                <Button
                    icon="pi pi-angle-down"
                    className="mr-2 actionbtn" style={{
                        width: '20px',
                        height: '20px',
                        background: 'transparent',
                        color: 'red'
                    }}
                    onClick={() => {
                        duplicateRowBelow(windex, rindex)
                    }}
                />
                <Button
                    icon="pi pi-angle-double-down"
                    className="mr-2 actionbtn" style={{
                        width: '20px',
                        height: '20px',
                        background: 'transparent',
                        color: 'red'
                    }}
                    onClick={() => {
                        duplicateRowAtLast(windex, rindex)
                    }}
                />
                <Button
                    icon="pi pi-trash"
                    className="mr-2 actionbtn" style={{
                        width: '20px',
                        height: '20px',
                        background: 'transparent',
                        color: 'palevioletred'
                    }}
                    onClick={() => {
                        deleteTRow(windex, rindex)
                    }}
                />
            </>
        )
    }
    const actionTemplate2 = (rowData, windex, rindex) => {
        return (
            <>

                <Button
                    icon="pi pi-trash"
                    className="mr-2 actionbtn" style={{
                        width: '20px',
                        height: '20px',
                        background: 'transparent',
                        color: 'palevioletred'
                    }}
                    onClick={() => {
                        deleteTRow(windex, rindex)
                    }}
                />
            </>
        )
    }
    const removeWidget = (index) => {
        let loc = JSON.parse(JSON.stringify(form));
        loc.data1.splice(index, 1)
        setForm(loc)
    }
    const editWidget = (index) => {
        let loc = JSON.parse(JSON.stringify(form));
        loc.data1.forEach((i, j) => {
            if (j === index) {
                i.expanded = i.expanded === undefined ? true : !i.expanded
            } else {
                i.expanded = false
            }
        })
        // loc[index].expanded = !loc[index].expanded
        setForm(loc)
    }
    const onMouseOver = (e, bg, clr) => {

        e.target.style.background = bg
        e.target.style.color = clr
        e.target.style.cursor = 'pointer'
    }
    const onMouseLeave = (e) => {

        e.target.style.background = 'transparent'
        e.target.style.color = 'rgba(0, 0, 0, 0.6)'
        e.target.style.cursor = 'none'
    }
    const checkLandN = (item) => {

        if (item.name !== undefined && item.name.trim().length !== 0 && !dupids.includes(item.name.trim().toLowerCase()) && checkDPName(item.name) && item.label.trim().length !== 0) {

            return false

        } else if (item.expanded === undefined || !item.expanded) {
            return true
        } else {
            return false
        }
    }
    const checkTable = (item) => {
        let check = false
        if (item.value.length === 0 || item.label.trim().length === 0) {
            check = true
        } else if (item.data.length !== 0) {
            Object.values(item.data).forEach((i) => {
                Object.values(i).forEach((j) => {
                    if (j.type !== 5) {
                        if (j.data.name !== undefined) {
                            if (dupids.includes(j.data.name.trim().toLowerCase())) {
                                check = true
                            }
                        } else {
                            check = true
                        }
                    }

                })
            })

        } else {
            check = true
        }

        return check
    }
    const checkLNO = (item) => {
        console.log(validateArray(item.values))
        if (item.name !== undefined && item.name.trim().length !== 0 && !dupids.includes(item.name.trim().toLowerCase()) && checkDPName(item.name) && item.label.trim().length !== 0 && validateArray(item.values).result) {

            return false

        } else if (item.expanded === undefined || !item.expanded) {
            return true
        } else {
            return false
        }
    }
    const checkCP = (item) => {
        console.log(item, item.values.filter((i) => { return i.label.toString().trim().length === 0 }))
        if (item.name !== undefined && item.name.trim().length !== 0 && !dupids.includes(item.name.trim().toLowerCase()) && checkDPName(item.name) && item.label.trim().length !== 0 && item.values.filter((i) => { return i.label.toString().trim().length === 0 }).length === 0) {
            return false
        } else if (item.expanded === undefined || !item.expanded) {
            return true
        } else {
            return false
        }
    }
    function isValueUniqueInArray(value, arr, key) {
        let count = 0;

        for (const item of arr) {
            if (item[key].toString().trim() === value.toString().trim()) {
                count++;
                if (count > 1) {
                    return false;
                }
            }
        }

        return count === 1;
    }
    const renderComponent = (widget, index) => {
        console.log(widget)
        switch (widget.type) {
            case "text":
                return (
                    <div onDrop={(e) => { itemDrop(e, index) }} onDragEnter={(e) => { console.log('enter', e) }} onDragLeave={(e) => { console.log('leave', e) }} onDragStart={(e) => { itemDragStart(e, widget) }} onDragOver={(e) => { itemDragOver(e) }} onDragEnd={(e) => { itemDragEnd(e, index) }} className="flex flex-wrap gap-3 p-card " draggable={widget.expanded !== true} style={{ borderRadius: 10, margin: 5, padding: 5, position: 'relative', position: 'relative' }}>
                        <div className="col-12 " style={{ borderRadius: 10, background: '#00000010', color: '#00000099', display: 'flex', border: (submitted & checkLandN(widget)) ? '1px solid red' : '' }}>
                            <div className="col-10" style={{ alignItems: 'center', display: 'flex' }}>
                                {(widget.label.trim().length !== 0) ? widget.label : 'please provide label '}
                            </div>
                            <div className="col-2 justify-content-end flex">
                                <i className="material-icons" style={{

                                    borderRadius: '5px',
                                    margin: 5,
                                    fontSize: '18px',
                                    padding: '2px'
                                }} onMouseOver={(e) => { onMouseOver(e, 'green', 'white') }} onMouseLeave={(e) => { onMouseLeave(e) }} onClick={() => { editWidget(index) }}>edit</i>
                                <i className="material-icons" style={{

                                    borderRadius: '5px',
                                    margin: 5,
                                    fontSize: '18px',
                                    padding: '2px'
                                }} onMouseOver={(e) => { onMouseOver(e, 'red', 'white') }} onMouseLeave={(e) => { onMouseLeave(e) }} onClick={() => { removeWidget(index) }}>close</i>
                            </div>
                        </div>
                        {widget.expanded && <>
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <Checkbox
                                    inputId={"text" + index}
                                    value={widget.required}
                                    onChange={(e) => {
                                        form.data1[index].required = !e.value;
                                        forceUpdate();
                                    }}
                                    checked={widget.required}
                                />
                                <label htmlFor={"cb" + index} className="ml-2">
                                    is Required
                                </label>
                            </div>
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <Checkbox
                                    inputId={"attman" + index}
                                    value={widget.isAttachmentMandatory}
                                    onChange={(e) => {
                                        form.data1[index].isAttachmentMandatory = !e.value;
                                        forceUpdate();
                                    }}
                                    checked={widget.isAttachmentMandatory}
                                />
                                <label htmlFor={"attman" + index} className="ml-2">
                                    is Attachment Required
                                </label>
                            </div>
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <Checkbox
                                    inputId={"dedi" + index}
                                    value={widget.isDedicated}
                                    onChange={(e) => {
                                        form.data1[index].isDedicated = !e.value;
                                        forceUpdate();
                                    }}
                                    checked={widget.isDedicated}
                                />
                                <label htmlFor={"dedi" + index} className="ml-2">
                                    is this Dedicated Question
                                </label>
                            </div>
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <Checkbox
                                    inputId={"legal" + index}
                                    value={widget.isLegalCompliance}
                                    onChange={(e) => {
                                        form.data1[index].isLegalCompliance = !e.value;
                                        forceUpdate();
                                    }}
                                    checked={widget.isLegalCompliance}
                                />
                                <label htmlFor={"legal" + index} className="ml-2">
                                    is this Legal Compliance
                                </label>
                            </div>
                            <div className="col-12">
                                <label  >
                                    Label
                                </label>
                                <InputTextarea
                                    type={"text"}
                                    style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                    value={widget.label}
                                    className={widget.label.trim().length === 0 && submitted && widget.expanded && 'p-invalid'}
                                    onChange={(e) => {
                                        form.data1[index].label = e.target.value;
                                        forceUpdate();
                                    }}
                                />
                            </div>
                            <div className="col-12">
                                <label  >
                                    Help Text
                                </label>
                                <InputText
                                    type={"text"}
                                    style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                    value={widget.description}
                                    onChange={(e) => {
                                        form.data1[index].description = e.target.value;
                                        forceUpdate();
                                    }}
                                />
                            </div>
                            <div className="col-12">
                                <label  >
                                    ID
                                </label>
                                <InputText
                                    type={"text"}

                                    style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                    value={widget.name}
                                    className={((widget.name === undefined || widget.name.trim().length === 0) || (dupids.includes(widget.name.trim().toLowerCase())) || !checkDPName(widget.name.trim())) && submitted && widget.expanded && 'p-invalid'}
                                    onChange={(e) => {
                                        form.data1[index].name = e.target.value.trim();
                                        forceUpdate();
                                    }}
                                />
                            </div>
                            {widget.name !== undefined && (dupids.includes(widget.name.trim().toLowerCase())) && submitted &&
                                <small style={{
                                    margin: '-10px 5px 0px 10px',
                                    color: 'red'
                                }}>Duplicate ID Found</small>
                            }
                            <div className="col-12">
                                <label  >
                                    Placeholder
                                </label>
                                <InputText
                                    type={"text"}
                                    style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                    value={widget.placeholder}
                                    onChange={(e) => {
                                        form.data1[index].placeholder = e.target.value;
                                        forceUpdate();
                                    }}
                                />
                            </div>
                        </>}
                        <div style={{ position: 'absolute', top: 0, left: 0 }}>
                            <label style={{
                                fontWeight: 'bold',
                                textShadow: '1px 1px darkgrey',
                                width: 100,
                                background: 'white',
                                display: 'flex',
                                borderRadius: '10px',
                                padding: '2px'
                            }}>Text</label>
                        </div>
                    </div>
                );
            case "number":
                return (
                    <div onDrop={(e) => { itemDrop(e, index) }} onDragStart={(e) => { itemDragStart(e, widget) }} onDragOver={(e) => { e.preventDefault() }} onDragEnd={(e) => { itemDragEnd(e, index) }} className="flex flex-wrap gap-3 p-card " draggable={widget.expanded !== true} style={{ borderRadius: 10, margin: 5, padding: 5, position: 'relative' }}>
                        <div className="col-12 " style={{ borderRadius: 10, background: '#00000010', color: '#00000099', display: 'flex', border: (submitted & checkLandN(widget)) ? '1px solid red' : '' }}>
                            <div className="col-10" style={{ alignItems: 'center', display: 'flex' }}>
                                {(widget.label.trim().length !== 0) ? widget.label : 'please provide label'}
                            </div>
                            <div className="col-2 justify-content-end flex">
                                <i className="material-icons" style={{

                                    borderRadius: '5px',
                                    margin: 5,
                                    fontSize: '18px',
                                    padding: '2px'
                                }} onMouseOver={(e) => { onMouseOver(e, 'green', 'white') }} onMouseLeave={(e) => { onMouseLeave(e) }} onClick={() => { editWidget(index) }}>edit</i>
                                <i className="material-icons" style={{

                                    borderRadius: '5px',
                                    margin: 5,
                                    fontSize: '18px',
                                    padding: '2px'
                                }} onMouseOver={(e) => { onMouseOver(e, 'red', 'white') }} onMouseLeave={(e) => { onMouseLeave(e) }} onClick={() => { removeWidget(index) }}>close</i>
                            </div>
                        </div>
                        {widget.expanded && <>
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <Checkbox
                                    inputId={"number" + index}
                                    value={widget.required}
                                    onChange={(e) => {
                                        form.data1[index].required = !e.value;
                                        forceUpdate();
                                    }}
                                    checked={widget.required}
                                />
                                <label htmlFor={"cb" + index} className="ml-2">
                                    is Required
                                </label>
                            </div>
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <Checkbox
                                    inputId={"attman" + index}
                                    value={widget.isAttachmentMandatory}
                                    onChange={(e) => {
                                        form.data1[index].isAttachmentMandatory = !e.value;
                                        forceUpdate();
                                    }}
                                    checked={widget.isAttachmentMandatory}
                                />
                                <label htmlFor={"attman" + index} className="ml-2">
                                    is Attachment Required
                                </label>
                            </div>
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <Checkbox
                                    inputId={"dedi" + index}
                                    value={widget.isDedicated}
                                    onChange={(e) => {
                                        form.data1[index].isDedicated = !e.value;
                                        forceUpdate();
                                    }}
                                    checked={widget.isDedicated}
                                />
                                <label htmlFor={"dedi" + index} className="ml-2">
                                    is this Dedicated Question
                                </label>
                            </div>
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <Checkbox
                                    inputId={"legal" + index}
                                    value={widget.isLegalCompliance}
                                    onChange={(e) => {
                                        form.data1[index].isLegalCompliance = !e.value;
                                        forceUpdate();
                                    }}
                                    checked={widget.isLegalCompliance}
                                />
                                <label htmlFor={"legal" + index} className="ml-2">
                                    is this Legal Compliance
                                </label>
                            </div>
                            <div className="col-12">
                                <label  >
                                    Label
                                </label>
                                <InputTextarea
                                    type={"text"}
                                    style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                    value={widget.label}
                                    className={widget.label.trim().length === 0 && submitted && widget.expanded && 'p-invalid'}
                                    onChange={(e) => {
                                        form.data1[index].label = e.target.value;
                                        forceUpdate();
                                    }}
                                />
                            </div>
                            <div className="col-12">
                                <label  >
                                    Fraction
                                </label>
                                <InputNumber
                                    min={0}

                                    style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                    value={widget.fraction}
                                    className={(widget.fraction < 0 || widget.fraction === null || widget.fraction === undefined) && submitted && widget.expanded && 'p-invalid'}
                                    onChange={(e) => {
                                        form.data1[index].fraction = e.value;
                                        forceUpdate();
                                    }}
                                />
                            </div>
                            <div className="col-12">
                                <label  >
                                    Does a higher value represent improvement, or does a lower value represent improvement? (0-Higher, 1-Lower)
                                </label>
                                <InputNumber
                                    min={0}
                                    max={1}
                                    style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                    value={widget.directionsign}
                                    className={(widget.directionsign < 0 || widget.directionsign === null || widget.directionsign === undefined) && submitted && widget.expanded && 'p-invalid'}
                                    onChange={(e) => {
                                        form.data1[index].directionsign = e.value;
                                        forceUpdate();
                                    }}
                                />
                            </div>
                            <div className="col-12">
                                <label  >
                                    Help Text
                                </label>
                                <InputText
                                    type={"text"}
                                    style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                    value={widget.description}
                                    onChange={(e) => {
                                        form.data1[index].description = e.target.value;
                                        forceUpdate();
                                    }}
                                />
                            </div>
                            <div className="col-12">
                                <label  >
                                    ID
                                </label>
                                <InputText
                                    type={"text"}

                                    style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                    value={widget.name}
                                    className={((widget.name === undefined || widget.name.trim().length === 0) || (dupids.includes(widget.name.trim().toLowerCase())) || !checkDPName(widget.name.trim())) && submitted && widget.expanded && 'p-invalid'}
                                    onChange={(e) => {
                                        form.data1[index].name = e.target.value.trim();
                                        forceUpdate();
                                    }}
                                />
                            </div>
                            {widget.name !== undefined && (dupids.includes(widget.name.trim().toLowerCase())) && submitted &&
                                <small style={{
                                    margin: '-10px 5px 0px 10px',
                                    color: 'red'
                                }}>Duplicate ID Found</small>
                            }
                            <div className="col-12">
                                <label  >
                                    Placeholder
                                </label>
                                <InputText
                                    type={"text"}
                                    style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                    value={widget.placeholder}
                                    onChange={(e) => {
                                        form.data1[index].placeholder = e.target.value;
                                        forceUpdate();
                                    }}
                                />
                            </div>
                        </>}
                        <div style={{ position: 'absolute', top: 0, left: 0 }}>
                            <label style={{
                                fontWeight: 'bold',
                                textShadow: '1px 1px darkgrey',
                                width: 100,
                                background: 'white',
                                display: 'flex',
                                borderRadius: '10px',
                                padding: '2px'
                            }}>Number</label>
                        </div>
                    </div>
                );
            case "date":
                return (
                    <div onDrop={(e) => { itemDrop(e, index) }} onDragStart={(e) => { itemDragStart(e, widget) }} onDragOver={(e) => { e.preventDefault() }} onDragEnd={(e) => { itemDragEnd(e, index) }} className="flex flex-wrap gap-3 p-card " draggable={widget.expanded !== true} style={{ borderRadius: 10, margin: 5, padding: 5, position: 'relative' }}>
                        <div className="col-12 " style={{ borderRadius: 10, background: '#00000010', color: '#00000099', display: 'flex', border: (submitted & checkLandN(widget)) ? '1px solid red' : '' }}>
                            <div className="col-10" style={{ alignItems: 'center', display: 'flex' }}>
                                {(widget.label.trim().length !== 0) ? widget.label : 'please provide label'}
                            </div>
                            <div className="col-2 justify-content-end flex">
                                <i className="material-icons" style={{

                                    borderRadius: '5px',
                                    margin: 5,
                                    fontSize: '18px',
                                    padding: '2px'
                                }} onMouseOver={(e) => { onMouseOver(e, 'green', 'white') }} onMouseLeave={(e) => { onMouseLeave(e) }} onClick={() => { editWidget(index) }}>edit</i>
                                <i className="material-icons" style={{

                                    borderRadius: '5px',
                                    margin: 5,
                                    fontSize: '18px',
                                    padding: '2px'
                                }} onMouseOver={(e) => { onMouseOver(e, 'red', 'white') }} onMouseLeave={(e) => { onMouseLeave(e) }} onClick={() => { removeWidget(index) }}>close</i>
                            </div>
                        </div>
                        {widget.expanded && <>
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <Checkbox
                                    inputId={"number" + index}
                                    value={widget.required}
                                    onChange={(e) => {
                                        form.data1[index].required = !e.value;
                                        forceUpdate();
                                    }}
                                    checked={widget.required}
                                />
                                <label htmlFor={"cb" + index} className="ml-2">
                                    is Required
                                </label>
                            </div>
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <Checkbox
                                    inputId={"attman" + index}
                                    value={widget.isAttachmentMandatory}
                                    onChange={(e) => {
                                        form.data1[index].isAttachmentMandatory = !e.value;
                                        forceUpdate();
                                    }}
                                    checked={widget.isAttachmentMandatory}
                                />
                                <label htmlFor={"attman" + index} className="ml-2">
                                    is Attachment Required
                                </label>
                            </div>
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <Checkbox
                                    inputId={"dedi" + index}
                                    value={widget.isDedicated}
                                    onChange={(e) => {
                                        form.data1[index].isDedicated = !e.value;
                                        forceUpdate();
                                    }}
                                    checked={widget.isDedicated}
                                />
                                <label htmlFor={"dedi" + index} className="ml-2">
                                    is this Dedicated Question
                                </label>
                            </div>
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <Checkbox
                                    inputId={"legal" + index}
                                    value={widget.isLegalCompliance}
                                    onChange={(e) => {
                                        form.data1[index].isLegalCompliance = !e.value;
                                        forceUpdate();
                                    }}
                                    checked={widget.isLegalCompliance}
                                />
                                <label htmlFor={"legal" + index} className="ml-2">
                                    is this Legal Compliance
                                </label>
                            </div>
                            <div className="col-12">
                                <label  >
                                    Label
                                </label>
                                <InputTextarea
                                    type={"text"}
                                    style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                    value={widget.label}
                                    className={widget.label.trim().length === 0 && submitted && widget.expanded && 'p-invalid'}
                                    onChange={(e) => {
                                        form.data1[index].label = e.target.value;
                                        forceUpdate();
                                    }}
                                />
                            </div>
                            <div className="col-12">
                                <label  >
                                    Help Text
                                </label>
                                <InputText
                                    type={"text"}
                                    style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                    value={widget.description}
                                    onChange={(e) => {
                                        form.data1[index].description = e.target.value;
                                        forceUpdate();
                                    }}
                                />
                            </div>
                            <div className="col-12">
                                <label  >
                                    ID
                                </label>
                                <InputText
                                    type={"text"}

                                    style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                    value={widget.name}
                                    className={((widget.name === undefined || widget.name.trim().length === 0) || (dupids.includes(widget.name.trim().toLowerCase())) || !checkDPName(widget.name.trim())) && submitted && widget.expanded && 'p-invalid'}
                                    onChange={(e) => {
                                        form.data1[index].name = e.target.value.trim();
                                        forceUpdate();
                                    }}
                                />
                            </div>
                            {widget.name !== undefined && (dupids.includes(widget.name.trim().toLowerCase())) && submitted &&
                                <small style={{
                                    margin: '-10px 5px 0px 10px',
                                    color: 'red'
                                }}>Duplicate ID Found</small>
                            }
                        </>}
                        <div style={{ position: 'absolute', top: 0, left: 0 }}>
                            <label style={{
                                fontWeight: 'bold',
                                textShadow: '1px 1px darkgrey',
                                width: 100,
                                background: 'white',
                                display: 'flex',
                                borderRadius: '10px',
                                padding: '2px'
                            }}>Date</label>
                        </div>
                    </div>
                );
            case "file":
                return (
                    <div onDrop={(e) => { itemDrop(e, index) }} onDragStart={(e) => { itemDragStart(e, widget) }} onDragOver={(e) => { e.preventDefault() }} onDragEnd={(e) => { itemDragEnd(e, index) }} className="flex flex-wrap gap-3 p-card " draggable={widget.expanded !== true} style={{ borderRadius: 10, margin: 5, padding: 5, position: 'relative' }}>
                        <div className="col-12 " style={{ borderRadius: 10, background: '#00000010', color: '#00000099', display: 'flex', border: (submitted & checkLandN(widget)) ? '1px solid red' : '' }}>
                            <div className="col-10" style={{ alignItems: 'center', display: 'flex' }}>
                                {(widget.label.trim().length !== 0) ? widget.label : 'please provide label'}
                            </div>
                            <div className="col-2 justify-content-end flex">
                                <i className="material-icons" style={{

                                    borderRadius: '5px',
                                    margin: 5,
                                    fontSize: '18px',
                                    padding: '2px'
                                }} onMouseOver={(e) => { onMouseOver(e, 'green', 'white') }} onMouseLeave={(e) => { onMouseLeave(e) }} onClick={() => { editWidget(index) }}>edit</i>
                                <i className="material-icons" style={{

                                    borderRadius: '5px',
                                    margin: 5,
                                    fontSize: '18px',
                                    padding: '2px'
                                }} onMouseOver={(e) => { onMouseOver(e, 'red', 'white') }} onMouseLeave={(e) => { onMouseLeave(e) }} onClick={() => { removeWidget(index) }}>close</i>
                            </div>
                        </div>
                        {widget.expanded && <>
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <Checkbox
                                    inputId={"number" + index}
                                    value={widget.required}
                                    onChange={(e) => {
                                        form.data1[index].required = !e.value;
                                        forceUpdate();
                                    }}
                                    checked={widget.required}
                                />
                                <label htmlFor={"cb" + index} className="ml-2">
                                    is Required
                                </label>
                            </div>
                            <div className="col-12">
                                <label  >
                                    Label
                                </label>
                                <InputTextarea
                                    type={"text"}
                                    style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                    value={widget.label}
                                    className={widget.label.trim().length === 0 && submitted && widget.expanded && 'p-invalid'}
                                    onChange={(e) => {
                                        form.data1[index].label = e.target.value;
                                        forceUpdate();
                                    }}
                                />
                            </div>
                            <div className="col-12">
                                <label  >
                                    Help Text
                                </label>
                                <InputText
                                    type={"text"}
                                    style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                    value={widget.description}
                                    onChange={(e) => {
                                        form.data1[index].description = e.target.value;
                                        forceUpdate();
                                    }}
                                />
                            </div>
                            <div className="col-12">
                                <label  >
                                    ID
                                </label>
                                <InputText
                                    type={"text"}

                                    style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                    value={widget.name}

                                    className={((widget.name === undefined || widget.name.trim().length === 0) || (dupids.includes(widget.name.trim().toLowerCase())) || !checkDPName(widget.name.trim())) && submitted && widget.expanded && 'p-invalid'}
                                    onChange={(e) => {
                                        form.data1[index].name = e.target.value.trim();
                                        forceUpdate();
                                    }}
                                />
                            </div>
                            {widget.name !== undefined && (dupids.includes(widget.name.trim().toLowerCase())) && submitted &&
                                <small style={{
                                    margin: '-10px 5px 0px 10px',
                                    color: 'red'
                                }}>Duplicate ID Found</small>
                            }
                        </>}
                        <div style={{ position: 'absolute', top: 0, left: 0 }}>
                            <label style={{
                                fontWeight: 'bold',
                                textShadow: '1px 1px darkgrey',
                                width: 100,
                                background: 'white',
                                display: 'flex',
                                borderRadius: '10px',
                                padding: '2px'
                            }}>File</label>
                        </div>
                    </div>
                );

            case "paragraph":
                return (
                    <div onDrop={(e) => { itemDrop(e, index) }} onDragStart={(e) => { itemDragStart(e, widget) }} onDragOver={(e) => { e.preventDefault() }} onDragEnd={(e) => { itemDragEnd(e, index) }} className="flex flex-wrap gap-3 p-card " draggable={widget.expanded !== true} style={{ borderRadius: 10, margin: 5, padding: 5, position: 'relative' }}>
                        <div className="col-12 " style={{ borderRadius: 10, background: '#00000010', color: '#00000099', display: 'flex', border: (submitted && widget.label.trim().length === 0 && widget.expanded !== true) ? '1px solid red' : '' }}>
                            <div className="col-10" style={{ alignItems: 'center', display: 'flex' }}>
                                {(widget.label.trim().length !== 0) ? widget.label : 'please provide label'}
                            </div>
                            <div className="col-2 justify-content-end flex">
                                <i className="material-icons" style={{

                                    borderRadius: '5px',
                                    margin: 5,
                                    fontSize: '18px',
                                    padding: '2px'
                                }} onMouseOver={(e) => { onMouseOver(e, 'green', 'white') }} onMouseLeave={(e) => { onMouseLeave(e) }} onClick={() => { editWidget(index) }}>edit</i>
                                <i className="material-icons" style={{

                                    borderRadius: '5px',
                                    margin: 5,
                                    fontSize: '18px',
                                    padding: '2px'
                                }} onMouseOver={(e) => { onMouseOver(e, 'red', 'white') }} onMouseLeave={(e) => { onMouseLeave(e) }} onClick={() => { removeWidget(index) }}>close</i>
                            </div>
                        </div>
                        {widget.expanded && <>

                            <div className="col-12">
                                <label  >
                                    Label
                                </label>
                                <InputTextarea
                                    type={"text"}
                                    style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                    value={widget.label}
                                    className={widget.label.trim().length === 0 && submitted && widget.expanded && 'p-invalid'}
                                    onChange={(e) => {
                                        form.data1[index].label = e.target.value;
                                        forceUpdate();
                                    }}
                                />
                            </div>

                        </>}
                        <div style={{ position: 'absolute', top: 0, left: 0 }}>
                            <label style={{
                                fontWeight: 'bold',
                                textShadow: '1px 1px darkgrey',
                                width: 100,
                                background: 'white',
                                display: 'flex',
                                borderRadius: '10px',
                                padding: '2px'
                            }}>Paragraph</label>
                        </div>
                    </div>
                );

            case "textarea":
                return (
                    <div onDrop={(e) => { itemDrop(e, index) }} onDragStart={(e) => { itemDragStart(e, widget) }} onDragOver={(e) => { e.preventDefault() }} onDragEnd={(e) => { itemDragEnd(e, index) }} className="flex flex-wrap gap-3 p-card " draggable={widget.expanded !== true} style={{ borderRadius: 10, margin: 5, padding: 5, position: 'relative' }}>
                        <div className="col-12 " style={{ borderRadius: 10, background: '#00000010', color: '#00000099', display: 'flex', border: (submitted & checkLandN(widget)) ? '1px solid red' : '' }}>
                            <div className="col-10" style={{ alignItems: 'center', display: 'flex' }}>
                                {(widget.label.trim().length !== 0) ? widget.label : 'please provide label'}
                            </div>
                            <div className="col-2 justify-content-end flex">
                                <i className="material-icons" style={{

                                    borderRadius: '5px',
                                    margin: 5,
                                    fontSize: '18px',
                                    padding: '2px'
                                }} onMouseOver={(e) => { onMouseOver(e, 'green', 'white') }} onMouseLeave={(e) => { onMouseLeave(e) }} onClick={() => { editWidget(index) }}>edit</i>
                                <i className="material-icons" style={{

                                    borderRadius: '5px',
                                    margin: 5,
                                    fontSize: '18px',
                                    padding: '2px'
                                }} onMouseOver={(e) => { onMouseOver(e, 'red', 'white') }} onMouseLeave={(e) => { onMouseLeave(e) }} onClick={() => { removeWidget(index) }}>close</i>
                            </div>
                        </div>
                        {widget.expanded && <>
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <Checkbox
                                    inputId={"textarea" + index}
                                    value={widget.required}
                                    onChange={(e) => {
                                        form.data1[index].required = !e.value;
                                        forceUpdate();
                                    }}
                                    checked={widget.required}
                                />
                                <label htmlFor={"cb" + index} className="ml-2">
                                    is Required
                                </label>
                            </div>
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <Checkbox
                                    inputId={"attman" + index}
                                    value={widget.isAttachmentMandatory}
                                    onChange={(e) => {
                                        form.data1[index].isAttachmentMandatory = !e.value;
                                        forceUpdate();
                                    }}
                                    checked={widget.isAttachmentMandatory}
                                />
                                <label htmlFor={"attman" + index} className="ml-2">
                                    is Attachment Required
                                </label>
                            </div>
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <Checkbox
                                    inputId={"dedi" + index}
                                    value={widget.isDedicated}
                                    onChange={(e) => {
                                        form.data1[index].isDedicated = !e.value;
                                        forceUpdate();
                                    }}
                                    checked={widget.isDedicated}
                                />
                                <label htmlFor={"dedi" + index} className="ml-2">
                                    is this Dedicated Question
                                </label>
                            </div>
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <Checkbox
                                    inputId={"legal" + index}
                                    value={widget.isLegalCompliance}
                                    onChange={(e) => {
                                        form.data1[index].isLegalCompliance = !e.value;
                                        forceUpdate();
                                    }}
                                    checked={widget.isLegalCompliance}
                                />
                                <label htmlFor={"legal" + index} className="ml-2">
                                    is this Legal Compliance
                                </label>
                            </div>
                            <div className="col-12">
                                <label  >
                                    Label
                                </label>
                                <InputTextarea
                                    type={"text"}
                                    style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                    value={widget.label}
                                    className={widget.label.trim().length === 0 && submitted && widget.expanded && 'p-invalid'}
                                    onChange={(e) => {
                                        form.data1[index].label = e.target.value;
                                        forceUpdate();
                                    }}
                                />
                            </div>
                            <div className="col-12">
                                <label  >
                                    Help Text
                                </label>
                                <InputText
                                    type={"text"}
                                    style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                    value={widget.description}
                                    onChange={(e) => {
                                        form.data1[index].description = e.target.value;
                                        forceUpdate();
                                    }}
                                />
                            </div>
                            <div className="col-12">
                                <label  >
                                    ID
                                </label>
                                <InputText
                                    type={"text"}

                                    style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                    value={widget.name}
                                    className={((widget.name === undefined || widget.name.trim().length === 0) || (dupids.includes(widget.name.trim().toLowerCase())) || !checkDPName(widget.name.trim())) && submitted && widget.expanded && 'p-invalid'}
                                    onChange={(e) => {
                                        form.data1[index].name = e.target.value.trim();
                                        forceUpdate();
                                    }}
                                />
                            </div>
                            {widget.name !== undefined && (dupids.includes(widget.name.trim().toLowerCase())) && submitted &&
                                <small style={{
                                    margin: '-10px 5px 0px 10px',
                                    color: 'red'
                                }}>Duplicate ID Found</small>
                            }
                            <div className="col-12">
                                <label  >
                                    Placeholder
                                </label>
                                <InputText
                                    type={"text"}
                                    style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                    value={widget.placeholder}
                                    onChange={(e) => {
                                        form.data1[index].placeholder = e.target.value;
                                        forceUpdate();
                                    }}
                                />
                            </div>
                        </>}
                        <div style={{ position: 'absolute', top: 0, left: 0 }}>
                            <label style={{
                                fontWeight: 'bold',
                                textShadow: '1px 1px darkgrey',
                                width: 100,
                                background: 'white',
                                display: 'flex',
                                borderRadius: '10px',
                                padding: '2px'
                            }}>TextArea</label>
                        </div>
                    </div>
                );
            case "table":
                return (
                    <div>
                        <div onDrop={(e) => { itemDrop(e, index) }} onDragStart={(e) => { itemDragStart(e, widget) }} onDragOver={(e) => { e.preventDefault() }} onDragEnd={(e) => { itemDragEnd(e, index) }} className="flex flex-wrap gap-3 p-card " draggable={widget.expanded !== true} style={{ borderRadius: 10, margin: 5, padding: 5, position: 'relative' }}>
                            <div className="col-12 " style={{ borderRadius: 10, background: '#00000010', color: '#00000099', display: 'flex', border: submitted && checkTable(widget) && widget.expanded !== true && '1px solid red' }}>
                                <div className="col-10" style={{ alignItems: 'center', display: 'flex' }}>
                                    {(widget.label.trim().length !== 0) ? widget.label : 'please provide label'}
                                </div>
                                <div className="col-2 justify-content-end flex">
                                    <i className="material-icons" style={{

                                        borderRadius: '5px',
                                        margin: 5,
                                        fontSize: '18px',
                                        padding: '2px'
                                    }} onMouseOver={(e) => { onMouseOver(e, 'green', 'white') }} onMouseLeave={(e) => { onMouseLeave(e) }} onClick={() => { editWidget(index) }}>edit</i>
                                    <i className="material-icons" style={{

                                        borderRadius: '5px',
                                        margin: 5,
                                        fontSize: '18px',
                                        padding: '2px'
                                    }} onMouseOver={(e) => { onMouseOver(e, 'red', 'white') }} onMouseLeave={(e) => { onMouseLeave(e) }} onClick={() => { removeWidget(index) }}>close</i>
                                </div>
                            </div>
                            {widget.expanded && <>

                                <div className="col-12">
                                    <label  >
                                        Label
                                    </label>
                                    <InputTextarea
                                        type={"text"}
                                        style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                        value={widget.label}
                                        className={widget.label.trim().length === 0 && submitted && widget.expanded && 'p-invalid'}
                                        onChange={(e) => {
                                            form.data1[index].label = e.target.value;
                                            forceUpdate();
                                        }}
                                    />
                                </div>
                                <div className="col-12">
                                    <label  >
                                        Help Text
                                    </label>
                                    <InputText
                                        type={"text"}
                                        style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                        value={widget.description}
                                        onChange={(e) => {
                                            form.data1[index].description = e.target.value;
                                            forceUpdate();
                                        }}
                                    />
                                </div>
                                <div className="col-12">
                                    <label  >
                                        Headers ( comma separated and limit is not more than 5  )
                                    </label>
                                    <Chips

                                        onRemove={(e) => { console.log(e); widget.data.forEach((i) => { delete i[e.value[0]] }); widget.data = widget.data.filter((i) => { return Object.keys(i).length !== 0 }); }}
                                        placeholder={widget.expanded === true && submitted && widget.value.length > 6 && "add header"}
                                        value={widget.value}
                                        className={'col-12 chip-fw ' + (widget.expanded === true && submitted && widget.value.length === 0 && 'p-invalid')}
                                        onChange={(e) => {
                                            form.data1[index].value = e.value;

                                            forceUpdate();
                                        }}
                                        onAdd={(e, i) => {
                                            let id = getID_table(widget.name)
                                            console.log(id)
                                            if (widget.value.length !== 0) {
                                                widget.data.forEach((i, j) => {
                                                    i[e.value] = { type: 1, data: { name: widget.name + '_' + id, required: false } }
                                                    id += 1
                                                })

                                            } else {
                                                widget.data.push({ [e.value]: { type: 1, data: { name: widget.name + '_' + id, required: false } } })
                                            }

                                        }}
                                        max={5}
                                        separator=","
                                    />
                                </div>

                                {widget.value.length !== 0 && (
                                    <div className="col-12">
                                        <div className="flex justify-content-end" style={{ margin: 10 }}>
                                            <Button onClick={() => { addRow(widget.value, index) }} icon='pi pi-plus'></Button>
                                        </div>

                                        <DataTable showGridlines reorderableRows onRowReorder={(e) => { form.data1[index].data = e.value; forceUpdate() }} value={widget.data} className="fullheight" style={{ width: '100%', margin: 10 }}>
                                            <Column rowReorder style={{ width: '3rem' }} />
                                            {widget.value.map((h, j) => {
                                                return <Column header={h} body={(rowData, props) => { console.log(rowData[h], props); return <div className={getCellClassName(rowData[h])} onClick={() => { setActiveCellId({ widgetind: index, dataind: props.rowIndex, colind: j }); setActiveCell(JSON.parse(JSON.stringify(rowData[h]))); setCellConfigDialog(true) }}>{rowData[h].type === 5 ? rowData[h].data.label : typelist.find((i) => { return i.id === rowData[h].type }).name}</div> }} />;
                                            })}
                                            <Column style={{ width: 112 }} header='Action' body={(rowData, props) => { return actionTemplate(rowData, index, props.rowIndex) }} />
                                        </DataTable>
                                    </div>
                                )}
                            </>}
                            <div style={{ position: 'absolute', top: 0, left: 0 }}>
                                <label style={{
                                    fontWeight: 'bold',
                                    textShadow: '1px 1px darkgrey',
                                    width: 100,
                                    background: 'white',
                                    display: 'flex',
                                    borderRadius: '10px',
                                    padding: '2px'
                                }}>Static Table</label>
                            </div>
                        </div>

                    </div>
                );
            case "tableadd":
                return (
                    <div>
                        <div onDrop={(e) => { itemDrop(e, index) }} onDragStart={(e) => { itemDragStart(e, widget) }} onDragOver={(e) => { e.preventDefault() }} onDragEnd={(e) => { itemDragEnd(e, index) }} className="flex flex-wrap gap-3 p-card " draggable={widget.expanded !== true} style={{ borderRadius: 10, margin: 5, padding: 5, position: 'relative' }}>
                            <div className="col-12 " style={{ borderRadius: 10, background: '#00000010', color: '#00000099', display: 'flex', border: submitted && checkTable(widget) && widget.expanded !== true && '1px solid red' }}>
                                <div className="col-10" style={{ alignItems: 'center', display: 'flex' }}>
                                    {(widget.label.trim().length !== 0) ? widget.label : 'please provide label'}
                                </div>
                                <div className="col-2 justify-content-end flex">
                                    <i className="material-icons" style={{

                                        borderRadius: '5px',
                                        margin: 5,
                                        fontSize: '18px',
                                        padding: '2px'
                                    }} onMouseOver={(e) => { onMouseOver(e, 'green', 'white') }} onMouseLeave={(e) => { onMouseLeave(e) }} onClick={() => { editWidget(index) }}>edit</i>
                                    <i className="material-icons" style={{

                                        borderRadius: '5px',
                                        margin: 5,
                                        fontSize: '18px',
                                        padding: '2px'
                                    }} onMouseOver={(e) => { onMouseOver(e, 'red', 'white') }} onMouseLeave={(e) => { onMouseLeave(e) }} onClick={() => { removeWidget(index) }}>close</i>
                                </div>
                            </div>
                            {widget.expanded && <>
                                <div className="flex align-items-center col-5" style={{ margin: 10 }}>
                                    <Checkbox
                                        inputId={"cb" + index}
                                        value={widget.required}
                                        onChange={(e) => {
                                            form.data1[index].required = !e.value;
                                            forceUpdate();
                                        }}
                                        checked={widget.required}
                                    />
                                    <label htmlFor={"cb" + index} className="ml-2">
                                        is Required
                                    </label>
                                </div>
                                <div className="col-12">
                                    <label  >
                                        Label
                                    </label>
                                    <InputTextarea
                                        type={"text"}
                                        style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                        value={widget.label}
                                        className={widget.label.trim().length === 0 && submitted && widget.expanded && 'p-invalid'}
                                        onChange={(e) => {
                                            form.data1[index].label = e.target.value;
                                            forceUpdate();
                                        }}
                                    />
                                </div>
                                <div className="col-12">
                                    <label  >
                                        Maximum rows allowed (1-100)
                                    </label>
                                    <InputText
                                        type={'number'}
                                        style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                        value={widget.maxrowlimit}

                                        onChange={(e) => {
                                            console.log(e.target.value)
                                            form.data1[index].maxrowlimit = (parseInt(e.target.value) === 0 || e.target.value === '') ? 1 : parseInt(e.target.value) > 100 ? 100 : parseInt(e.target.value);
                                            forceUpdate();
                                        }}
                                    />
                                </div>
                                <div className="col-12">
                                    <label  >
                                        Help Text
                                    </label>
                                    <InputText
                                        type={"text"}
                                        style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                        value={widget.description}
                                        onChange={(e) => {
                                            form.data1[index].description = e.target.value;
                                            forceUpdate();
                                        }}
                                    />
                                </div>
                                <div className="col-12">
                                    <label  >
                                        Headers ( comma separated and limit is not more than 5  )
                                    </label>
                                    <Chips

                                        onRemove={(e) => { console.log(e); widget.data.forEach((i) => { delete i[e.value[0]] }); widget.data = widget.data.filter((i) => { return Object.keys(i).length !== 0 }); }}
                                        placeholder={widget.expanded === true && submitted && widget.value.length > 6 && "add header"}
                                        value={widget.value}
                                        className={'col-12 chip-fw ' + (widget.expanded === true && submitted && widget.value.length === 0 && 'p-invalid')}
                                        onChange={(e) => {
                                            form.data1[index].value = e.value;

                                            forceUpdate();
                                        }}
                                        onAdd={(e) => {
                                            let id = getID_table(widget.name)
                                            if (widget.value.length !== 0) {
                                                widget.data.forEach((i) => {
                                                    i[e.value] = { type: 1, data: { name: widget.name + '_' + id } }
                                                    id += 1
                                                })

                                            } else {
                                                widget.data.push({ [e.value]: { type: 1, data: { name: widget.name + '_' + id } } })
                                            }

                                        }}
                                        max={5}
                                        separator=","
                                    />
                                </div>

                                {widget.value.length !== 0 && (
                                    <div className="col-12">
                                        <div className="flex justify-content-end" style={{ margin: 10 }}>

                                            {widget.data.length === 0 && <Button onClick={() => { addRow(widget.value, index) }} icon='pi pi-plus'></Button>}
                                        </div>

                                        <DataTable showGridlines value={widget.data} className="fullheight" style={{ width: '100%', margin: 10 }}>

                                            {widget.value.map((h, j) => {
                                                return <Column header={h} body={(rowData, props) => { console.log(rowData[h], props); return <div className={getCellClassName(rowData[h])} onClick={() => { setActiveCellId({ widgetind: index, dataind: props.rowIndex, colind: j }); setActiveCell(JSON.parse(JSON.stringify(rowData[h]))); setCellConfigDialog(true) }}>{rowData[h].type === 5 ? rowData[h].data.label : typelist.find((i) => { return i.id === rowData[h].type }).name}</div> }} />;
                                            })}
                                            <Column style={{ width: 35 }} header='Action' body={(rowData, props) => { return actionTemplate2(rowData, index, props.rowIndex) }} />
                                        </DataTable>
                                    </div>
                                )}
                            </>}
                            <div style={{ position: 'absolute', top: 0, left: 0 }}>
                                <label style={{
                                    fontWeight: 'bold',
                                    textShadow: '1px 1px darkgrey',
                                    width: 105,
                                    background: 'white',
                                    display: 'flex',
                                    borderRadius: '10px',
                                    padding: '2px'
                                }}>Dynamic Table</label>
                            </div>
                        </div>
                    </div>
                );


            case "select":
                return (
                    <div onDrop={(e) => { itemDrop(e, index) }} onDragStart={(e) => { itemDragStart(e, widget) }} onDragOver={(e) => { e.preventDefault() }} onDragEnd={(e) => { itemDragEnd(e, index) }} className="flex flex-wrap gap-3 p-card " draggable={widget.expanded !== true} style={{ borderRadius: 10, margin: 5, padding: 5, position: 'relative' }}>
                        <div className="col-12 " style={{ borderRadius: 10, background: '#00000010', color: '#00000099', display: 'flex', border: (submitted && checkLNO(widget)) ? '1px solid red' : '' }}>
                            <div className="col-10" style={{ alignItems: 'center', display: 'flex' }}>
                                {(widget.label.trim().length !== 0) ? widget.label : 'please provide label'}
                            </div>
                            <div className="col-2 justify-content-end flex">
                                <i className="material-icons" style={{

                                    borderRadius: '5px',
                                    margin: 5,
                                    fontSize: '18px',
                                    padding: '2px'
                                }} onMouseOver={(e) => { onMouseOver(e, 'green', 'white') }} onMouseLeave={(e) => { onMouseLeave(e) }} onClick={() => { editWidget(index) }}>edit</i>
                                <i className="material-icons" style={{

                                    borderRadius: '5px',
                                    margin: 5,
                                    fontSize: '18px',
                                    padding: '2px'
                                }} onMouseOver={(e) => { onMouseOver(e, 'red', 'white') }} onMouseLeave={(e) => { onMouseLeave(e) }} onClick={() => { removeWidget(index) }}>close</i>
                            </div>
                        </div>
                        {widget.expanded && <>
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <Checkbox
                                    inputId={"text" + index}
                                    value={widget.required}
                                    onChange={(e) => {
                                        form.data1[index].required = !e.value;
                                        forceUpdate();
                                    }}
                                    checked={widget.required}
                                />
                                <label htmlFor={"cb" + index} className="ml-2">
                                    is Required
                                </label>
                            </div>
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <Checkbox
                                    inputId={"attman" + index}
                                    value={widget.isAttachmentMandatory}
                                    onChange={(e) => {
                                        form.data1[index].isAttachmentMandatory = !e.value;
                                        forceUpdate();
                                    }}
                                    checked={widget.isAttachmentMandatory}
                                />
                                <label htmlFor={"attman" + index} className="ml-2">
                                    is Attachment Required
                                </label>
                            </div>
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <Checkbox
                                    inputId={"dedi" + index}
                                    value={widget.isDedicated}
                                    onChange={(e) => {
                                        form.data1[index].isDedicated = !e.value;
                                        forceUpdate();
                                    }}
                                    checked={widget.isDedicated}
                                />
                                <label htmlFor={"dedi" + index} className="ml-2">
                                    is this Dedicated Question
                                </label>
                            </div>
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <Checkbox
                                    inputId={"legal" + index}
                                    value={widget.isLegalCompliance}
                                    onChange={(e) => {
                                        form.data1[index].isLegalCompliance = !e.value;
                                        forceUpdate();
                                    }}
                                    checked={widget.isLegalCompliance}
                                />
                                <label htmlFor={"legal" + index} className="ml-2">
                                    is this Legal Compliance
                                </label>
                            </div>
                            <div className="col-12">
                                <label  >
                                    Label
                                </label>
                                <InputTextarea
                                    type={"text"}
                                    style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                    value={widget.label}
                                    className={widget.label.trim().length === 0 && submitted && widget.expanded && 'p-invalid'}
                                    onChange={(e) => {
                                        form.data1[index].label = e.target.value;
                                        forceUpdate();
                                    }}
                                />
                            </div>
                            <div className="col-12">
                                <label  >
                                    Help Text
                                </label>
                                <InputText
                                    type={"text"}
                                    style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                    value={widget.description}
                                    onChange={(e) => {
                                        form.data1[index].description = e.target.value;
                                        forceUpdate();
                                    }}
                                />
                            </div>
                            <div className="col-12">
                                <label  >
                                    ID
                                </label>
                                <InputText
                                    type={"text"}

                                    style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                    value={widget.name}
                                    className={((widget.name === undefined || widget.name.trim().length === 0) || (dupids.includes(widget.name.trim().toLowerCase())) || !checkDPName(widget.name.trim())) && submitted && widget.expanded && 'p-invalid'}
                                    onChange={(e) => {
                                        form.data1[index].name = e.target.value.trim();
                                        forceUpdate();
                                    }}
                                />
                            </div>
                            {widget.name !== undefined && (dupids.includes(widget.name.trim().toLowerCase())) && submitted &&
                                <small style={{
                                    margin: '-10px 5px 0px 10px',
                                    color: 'red'
                                }}>Duplicate ID Found</small>
                            }
                            <div className="m-3 col-12">
                                <label htmlFor="ph" className="font-bold block mb-2">Options</label>
                                <div  >
                                    {widget.values.map((i, ind) => {

                                        return (
                                            <div className="col-12" style={{ padding: 5 }}>
                                                <div className="grid" style={{ alignItems: 'center' }}>
                                                    <div className="col-5" >

                                                        <InputText type="text" className={i.label.trim().length === 0 && 'p-invalid'} value={i.label} onChange={(e) => { form.data1[index].values[ind].label = e.target.value; forceUpdate(); }} placeholder="label" />
                                                    </div>

                                                    <div className="col-5">

                                                        <InputText type="text" className={(i.value.toString().trim().length === 0 || !isValueUniqueInArray(i.value, form.data1[index].values, 'value')) && 'p-invalid'} value={i.value} onChange={(e) => { form.data1[index].values[ind].value = e.target.value; forceUpdate(); }} placeholder="value" />


                                                    </div>

                                                    <div className="col-2">
                                                        {((form.data1[index].values.length - 1 === ind) || (ind === 1 && form.data1[index].values.length === 2)) ?
                                                            <div>
                                                                <i className="material-icons" onClick={(e) => { e.stopPropagation(); addNewRow(form.data1[index].values) }} style={{ color: 'green' }}>add_box</i>
                                                                {ind > 1 && <i className="material-icons" onClick={() => { deleteDDRow(index, ind) }}>delete</i>}

                                                            </div> :
                                                            ind > 1 &&
                                                            <div>
                                                                <i className="material-icons" onClick={() => { deleteDDRow(index, ind) }}>delete</i>

                                                            </div>

                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>

                            </div>
                        </>}
                        <div style={{ position: 'absolute', top: 0, left: 0 }}>
                            <label style={{
                                fontWeight: 'bold',
                                textShadow: '1px 1px darkgrey',
                                width: 100,
                                background: 'white',
                                display: 'flex',
                                borderRadius: '10px',
                                padding: '2px'
                            }}>Dropdown</label>
                        </div>
                    </div>
                );

            case "checkbox-group":
                return (
                    <div onDrop={(e) => { itemDrop(e, index) }} onDragStart={(e) => { itemDragStart(e, widget) }} onDragOver={(e) => { e.preventDefault() }} onDragEnd={(e) => { itemDragEnd(e, index) }} className="flex flex-wrap gap-3 p-card " draggable={widget.expanded !== true} style={{ borderRadius: 10, margin: 5, padding: 5, position: 'relative' }}>
                        <div className="col-12 " style={{ borderRadius: 10, background: '#00000010', color: '#00000099', display: 'flex', border: (submitted && checkLNO(widget)) ? '1px solid red' : '' }}>
                            <div className="col-10" style={{ alignItems: 'center', display: 'flex' }}>
                                {(widget.label.trim().length !== 0) ? widget.label : 'please provide label'}
                            </div>
                            <div className="col-2 justify-content-end flex">
                                <i className="material-icons" style={{

                                    borderRadius: '5px',
                                    margin: 5,
                                    fontSize: '18px',
                                    padding: '2px'
                                }} onMouseOver={(e) => { onMouseOver(e, 'green', 'white') }} onMouseLeave={(e) => { onMouseLeave(e) }} onClick={() => { editWidget(index) }}>edit</i>
                                <i className="material-icons" style={{

                                    borderRadius: '5px',
                                    margin: 5,
                                    fontSize: '18px',
                                    padding: '2px'
                                }} onMouseOver={(e) => { onMouseOver(e, 'red', 'white') }} onMouseLeave={(e) => { onMouseLeave(e) }} onClick={() => { removeWidget(index) }}>close</i>
                            </div>
                        </div>
                        {widget.expanded && <>
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <Checkbox
                                    inputId={"text" + index}
                                    value={widget.required}
                                    onChange={(e) => {
                                        form.data1[index].required = !e.value;
                                        forceUpdate();
                                    }}
                                    checked={widget.required}
                                />
                                <label htmlFor={"cb" + index} className="ml-2">
                                    is Required
                                </label>
                            </div>
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <Checkbox
                                    inputId={"attman" + index}
                                    value={widget.isAttachmentMandatory}
                                    onChange={(e) => {
                                        form.data1[index].isAttachmentMandatory = !e.value;
                                        forceUpdate();
                                    }}
                                    checked={widget.isAttachmentMandatory}
                                />
                                <label htmlFor={"attman" + index} className="ml-2">
                                    is Attachment Required
                                </label>
                            </div>
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <Checkbox
                                    inputId={"dedi" + index}
                                    value={widget.isDedicated}
                                    onChange={(e) => {
                                        form.data1[index].isDedicated = !e.value;
                                        forceUpdate();
                                    }}
                                    checked={widget.isDedicated}
                                />
                                <label htmlFor={"dedi" + index} className="ml-2">
                                    is this Dedicated Question
                                </label>
                            </div>
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <Checkbox
                                    inputId={"legal" + index}
                                    value={widget.isLegalCompliance}
                                    onChange={(e) => {
                                        form.data1[index].isLegalCompliance = !e.value;
                                        forceUpdate();
                                    }}
                                    checked={widget.isLegalCompliance}
                                />
                                <label htmlFor={"legal" + index} className="ml-2">
                                    is this Legal Compliance
                                </label>
                            </div>
                            <div className="col-12">
                                <label  >
                                    Label
                                </label>
                                <InputTextarea
                                    type={"text"}
                                    style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                    value={widget.label}
                                    className={widget.label.trim().length === 0 && submitted && widget.expanded && 'p-invalid'}
                                    onChange={(e) => {
                                        form.data1[index].label = e.target.value;
                                        forceUpdate();
                                    }}
                                />
                            </div>
                            <div className="col-12">
                                <label  >
                                    Help Text
                                </label>
                                <InputText
                                    type={"text"}
                                    style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                    value={widget.description}
                                    onChange={(e) => {
                                        form.data1[index].description = e.target.value;
                                        forceUpdate();
                                    }}
                                />
                            </div>
                            <div className="col-12">
                                <label  >
                                    ID
                                </label>
                                <InputText
                                    type={"text"}

                                    style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                    value={widget.name}
                                    className={((widget.name === undefined || widget.name.trim().length === 0) || (dupids.includes(widget.name.trim().toLowerCase())) || !checkDPName(widget.name.trim())) && submitted && widget.expanded && 'p-invalid'}
                                    onChange={(e) => {
                                        form.data1[index].name = e.target.value.trim();
                                        forceUpdate();
                                    }}
                                />
                            </div>
                            {widget.name !== undefined && (dupids.includes(widget.name.trim().toLowerCase())) && submitted &&
                                <small style={{
                                    margin: '-10px 5px 0px 10px',
                                    color: 'red'
                                }}>Duplicate ID Found</small>
                            }
                            <div className="m-3 col-12">
                                <label htmlFor="ph" className="font-bold block mb-2">Options</label>
                                <div  >
                                    {widget.values.map((i, ind) => {

                                        return (
                                            <div className="col-12" style={{ padding: 5 }}>
                                                <div className="grid" style={{ alignItems: 'center' }}>
                                                    <div className="col-5" >

                                                        <InputText type="text" value={i.label} className={i.label.trim().length === 0 && 'p-invalid'} onChange={(e) => { form.data1[index].values[ind].label = e.target.value; forceUpdate(); }} placeholder="label" />
                                                    </div>

                                                    <div className="col-5">

                                                        <InputText type="text" value={i.value} className={(i.value.toString().trim().length === 0 || !isValueUniqueInArray(i.value, form.data1[index].values, 'value')) && 'p-invalid'} onChange={(e) => { form.data1[index].values[ind].value = e.target.value; forceUpdate(); }} placeholder="value" />


                                                    </div>

                                                    <div className="col-2">
                                                        {((form.data1[index].values.length - 1 === ind) || (ind === 1 && form.data1[index].values.length === 2)) ?
                                                            <div>
                                                                <i className="material-icons" onClick={(e) => { e.stopPropagation(); addNewRow(form.data1[index].values) }} style={{ color: 'green' }}>add_box</i>
                                                                {ind > 1 && <i className="material-icons" onClick={() => { deleteDDRow(index, ind) }}>delete</i>}

                                                            </div> :
                                                            ind > 1 &&
                                                            <div>
                                                                <i className="material-icons" onClick={() => { deleteDDRow(index, ind) }}>delete</i>

                                                            </div>

                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>

                            </div>
                        </>}
                        <div style={{ position: 'absolute', top: 0, left: 0 }}>
                            <label style={{
                                fontWeight: 'bold',
                                textShadow: '1px 1px darkgrey',
                                width: 100,
                                background: 'white',
                                display: 'flex',
                                borderRadius: '10px',
                                padding: '2px'
                            }}>Checkbox</label>
                        </div>
                    </div>
                );

            case "radio-group":
                return (
                    <div onDrop={(e) => { itemDrop(e, index) }} onDragStart={(e) => { itemDragStart(e, widget) }} onDragOver={(e) => { e.preventDefault() }} onDragEnd={(e) => { itemDragEnd(e, index) }} className="flex flex-wrap gap-3 p-card " draggable={widget.expanded !== true} style={{ borderRadius: 10, margin: 5, padding: 5, position: 'relative' }}>
                        <div className="col-12 " style={{ borderRadius: 10, background: '#00000010', color: '#00000099', display: 'flex', border: (submitted && checkLNO(widget)) ? '1px solid red' : '' }}>
                            <div className="col-10" style={{ alignItems: 'center', display: 'flex' }}>
                                {(widget.label.trim().length !== 0) ? widget.label : 'please provide label'}
                            </div>
                            <div className="col-2 justify-content-end flex">
                                <i className="material-icons" style={{

                                    borderRadius: '5px',
                                    margin: 5,
                                    fontSize: '18px',
                                    padding: '2px'
                                }} onMouseOver={(e) => { onMouseOver(e, 'green', 'white') }} onMouseLeave={(e) => { onMouseLeave(e) }} onClick={() => { editWidget(index) }}>edit</i>
                                <i className="material-icons" style={{

                                    borderRadius: '5px',
                                    margin: 5,
                                    fontSize: '18px',
                                    padding: '2px'
                                }} onMouseOver={(e) => { onMouseOver(e, 'red', 'white') }} onMouseLeave={(e) => { onMouseLeave(e) }} onClick={() => { removeWidget(index) }}>close</i>
                            </div>
                        </div>
                        {widget.expanded && <>
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <Checkbox
                                    inputId={"text" + index}
                                    value={widget.required}
                                    onChange={(e) => {
                                        form.data1[index].required = !e.value;
                                        forceUpdate();
                                    }}
                                    checked={widget.required}
                                />
                                <label htmlFor={"cb" + index} className="ml-2">
                                    is Required
                                </label>
                            </div>
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <Checkbox
                                    inputId={"attman" + index}
                                    value={widget.isAttachmentMandatory}
                                    onChange={(e) => {
                                        form.data1[index].isAttachmentMandatory = !e.value;
                                        forceUpdate();
                                    }}
                                    checked={widget.isAttachmentMandatory}
                                />
                                <label htmlFor={"attman" + index} className="ml-2">
                                    is Attachment Required
                                </label>
                            </div>
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <Checkbox
                                    inputId={"dedi" + index}
                                    value={widget.isDedicated}
                                    onChange={(e) => {
                                        form.data1[index].isDedicated = !e.value;
                                        forceUpdate();
                                    }}
                                    checked={widget.isDedicated}
                                />
                                <label htmlFor={"dedi" + index} className="ml-2">
                                    is this Dedicated Question
                                </label>
                            </div>
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <Checkbox
                                    inputId={"legal" + index}
                                    value={widget.isLegalCompliance}
                                    onChange={(e) => {
                                        form.data1[index].isLegalCompliance = !e.value;
                                        forceUpdate();
                                    }}
                                    checked={widget.isLegalCompliance}
                                />
                                <label htmlFor={"legal" + index} className="ml-2">
                                    is this Legal Compliance
                                </label>
                            </div>
                            <div className="col-12">
                                <label  >
                                    Label
                                </label>
                                <InputTextarea
                                    type={"text"}
                                    style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                    value={widget.label}
                                    className={widget.label.trim().length === 0 && submitted && widget.expanded && 'p-invalid'}
                                    onChange={(e) => {
                                        form.data1[index].label = e.target.value;
                                        forceUpdate();
                                    }}
                                />
                            </div>
                            <div className="col-12">
                                <label  >
                                    Help Text
                                </label>
                                <InputText
                                    type={"text"}
                                    style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                    value={widget.description}
                                    onChange={(e) => {
                                        form.data1[index].description = e.target.value;
                                        forceUpdate();
                                    }}
                                />
                            </div>
                            <div className="col-12">
                                <label  >
                                    ID
                                </label>
                                <InputText
                                    type={"text"}

                                    style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                    value={widget.name}
                                    className={((widget.name === undefined || widget.name.trim().length === 0) || (dupids.includes(widget.name.trim().toLowerCase())) || !checkDPName(widget.name.trim())) && submitted && widget.expanded && 'p-invalid'}
                                    onChange={(e) => {
                                        form.data1[index].name = e.target.value.trim();
                                        forceUpdate();
                                    }}
                                />
                            </div>
                            {widget.name !== undefined && (dupids.includes(widget.name.trim().toLowerCase())) && submitted &&
                                <small style={{
                                    margin: '-10px 5px 0px 10px',
                                    color: 'red'
                                }}>Duplicate ID Found</small>
                            }
                            <div className="m-3 col-12">
                                <label htmlFor="ph" className="font-bold block mb-2">Options</label>
                                <div  >
                                    {widget.values.map((i, ind) => {

                                        return (
                                            <div className="col-12" style={{ padding: 5 }}>
                                                <div className="grid" style={{ alignItems: 'center' }}>
                                                    <div className="col-5" >

                                                        <InputText type="text" value={i.label} className={i.label.trim().length === 0 && 'p-invalid'} onChange={(e) => { form.data1[index].values[ind].label = e.target.value; forceUpdate(); }} placeholder="label" />
                                                    </div>

                                                    <div className="col-5">

                                                        <InputText type="text" className={(i.value.toString().trim().length === 0 || !isValueUniqueInArray(i.value, form.data1[index].values, 'value')) && 'p-invalid'} value={i.value} onChange={(e) => { form.data1[index].values[ind].value = e.target.value; forceUpdate(); }} placeholder="value" />


                                                    </div>

                                                    <div className="col-2">
                                                        {((form.data1[index].values.length - 1 === ind) || (ind === 1 && form.data1[index].values.length === 2)) ?
                                                            <div>
                                                                <i className="material-icons" onClick={(e) => { e.stopPropagation(); addNewRow(form.data1[index].values) }} style={{ color: 'green' }}>add_box</i>
                                                                {ind > 1 && <i className="material-icons" onClick={() => { deleteDDRow(index, ind) }}>delete</i>}

                                                            </div> :
                                                            ind > 1 &&
                                                            <div>
                                                                <i className="material-icons" onClick={() => { deleteDDRow(index, ind) }}>delete</i>

                                                            </div>

                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>

                            </div>
                        </>}
                        <div style={{ position: 'absolute', top: 0, left: 0 }}>
                            <label style={{
                                fontWeight: 'bold',
                                textShadow: '1px 1px darkgrey',
                                width: 100,
                                background: 'white',
                                display: 'flex',
                                borderRadius: '10px',
                                padding: '2px'
                            }}>Radio</label>
                        </div>
                    </div>
                );

            case "checkpoint":
                return (
                    <div onDrop={(e) => { itemDrop(e, index) }} onDragStart={(e) => { itemDragStart(e, widget) }} onDragOver={(e) => { e.preventDefault() }} onDragEnd={(e) => { itemDragEnd(e, index) }} className="flex flex-wrap gap-3 p-card " draggable={widget.expanded !== true} style={{ borderRadius: 10, margin: 5, padding: 5, position: 'relative' }}>
                        <div className="col-12 " style={{ borderRadius: 10, background: '#00000010', color: '#00000099', display: 'flex', border: (submitted && checkCP(widget)) ? '1px solid red' : '' }}>
                            <div className="col-10" style={{ alignItems: 'center', display: 'flex' }}>
                                {(widget.label.trim().length !== 0) ? widget.label : 'please provide label'}
                            </div>
                            <div className="col-2 justify-content-end flex">
                                <i className="material-icons" style={{

                                    borderRadius: '5px',
                                    margin: 5,
                                    fontSize: '18px',
                                    padding: '2px'
                                }} onMouseOver={(e) => { onMouseOver(e, 'green', 'white') }} onMouseLeave={(e) => { onMouseLeave(e) }} onClick={() => { editWidget(index) }}>edit</i>
                                <i className="material-icons" style={{

                                    borderRadius: '5px',
                                    margin: 5,
                                    fontSize: '18px',
                                    padding: '2px'
                                }} onMouseOver={(e) => { onMouseOver(e, 'red', 'white') }} onMouseLeave={(e) => { onMouseLeave(e) }} onClick={() => { removeWidget(index) }}>close</i>
                            </div>
                        </div>
                        {widget.expanded && <>
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <Checkbox
                                    inputId={"text" + index}
                                    value={widget.required}
                                    onChange={(e) => {
                                        form.data1[index].required = !e.value;
                                        forceUpdate();
                                    }}
                                    checked={widget.required}
                                />
                                <label htmlFor={"cb" + index} className="ml-2">
                                    is Required
                                </label>
                            </div>
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <Checkbox
                                    inputId={"attman" + index}
                                    value={widget.isAttachmentMandatory}
                                    onChange={(e) => {
                                        form.data1[index].isAttachmentMandatory = !e.value;
                                        forceUpdate();
                                    }}
                                    checked={widget.isAttachmentMandatory}
                                />
                                <label htmlFor={"attman" + index} className="ml-2">
                                    is Attachment Required
                                </label>
                            </div>
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <Checkbox
                                    inputId={"dedi" + index}
                                    value={widget.isDedicated}
                                    onChange={(e) => {
                                        form.data1[index].isDedicated = !e.value;
                                        forceUpdate();
                                    }}
                                    checked={widget.isDedicated}
                                />
                                <label htmlFor={"dedi" + index} className="ml-2">
                                    is this Dedicated Question
                                </label>
                            </div>
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <Checkbox
                                    inputId={"legal" + index}
                                    value={widget.isLegalCompliance}
                                    onChange={(e) => {
                                        form.data1[index].isLegalCompliance = !e.value;
                                        forceUpdate();
                                    }}
                                    checked={widget.isLegalCompliance}
                                />
                                <label htmlFor={"legal" + index} className="ml-2">
                                    is this Legal Compliance
                                </label>
                            </div>
                            <div className="col-12">
                                <label  >
                                    Label
                                </label>
                                <InputTextarea
                                    type={"text"}
                                    style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                    value={widget.label}
                                    className={widget.label.trim().length === 0 && submitted && widget.expanded && 'p-invalid'}
                                    onChange={(e) => {
                                        form.data1[index].label = e.target.value;
                                        forceUpdate();
                                    }}
                                />
                            </div>
                            <div className="col-12">
                                <label  >
                                    Help Text
                                </label>
                                <InputText
                                    type={"text"}
                                    style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                    value={widget.description}
                                    onChange={(e) => {
                                        form.data1[index].description = e.target.value;
                                        forceUpdate();
                                    }}
                                />
                            </div>
                            <div className="col-12">
                                <label  >
                                    ID
                                </label>
                                <InputText
                                    type={"text"}

                                    style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                    value={widget.name}
                                    className={((widget.name === undefined || widget.name.trim().length === 0) || (dupids.includes(widget.name.trim().toLowerCase())) || !checkDPName(widget.name.trim())) && submitted && widget.expanded && 'p-invalid'}
                                    onChange={(e) => {
                                        form.data1[index].name = e.target.value.trim();
                                        forceUpdate();
                                    }}
                                />
                            </div>
                            {widget.name !== undefined && (dupids.includes(widget.name.trim().toLowerCase())) && submitted &&
                                <small style={{
                                    margin: '-10px 5px 0px 10px',
                                    color: 'red'
                                }}>Duplicate ID Found</small>
                            }
                            <div className="m-3 col-12">
                                <label htmlFor="ph" className="font-bold block mb-2">Options</label>
                                <div  >
                                    {widget.values.map((i, ind) => {

                                        return (
                                            <div className="col-12" style={{ padding: 5 }}>
                                                <div className="grid" style={{ alignItems: 'center' }}>
                                                    <div className="col-5" >

                                                        <InputText type="text" className={i.label.trim().length === 0 && 'p-invalid'} value={i.label} onChange={(e) => { form.data1[index].values[ind].label = e.target.value; forceUpdate(); }} placeholder="label" />
                                                    </div>




                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>

                            </div>
                        </>}
                        <div style={{ position: 'absolute', top: 0, left: 0 }}>
                            <label style={{
                                fontWeight: 'bold',
                                textShadow: '1px 1px darkgrey',
                                width: 100,
                                background: 'white',
                                display: 'flex',
                                borderRadius: '10px',
                                padding: '2px'
                            }}>Checkpoint</label>
                        </div>
                    </div>
                );
            case "htmleditor":
                return (
                    <div onDrop={(e) => { itemDrop(e, index) }} onDragStart={(e) => { itemDragStart(e, widget) }} onDragOver={(e) => { e.preventDefault() }} onDragEnd={(e) => { itemDragEnd(e, index) }} className="flex flex-wrap gap-3 p-card " draggable={widget.expanded !== true} style={{ borderRadius: 10, margin: 5, padding: 5, position: 'relative' }}>
                        <div className="col-12 " style={{ borderRadius: 10, background: '#00000010', color: '#00000099', display: 'flex', border: (submitted && widget.expanded !== true && widget.label.trim().length === 0) ? '1px solid red' : '' }}>
                            <div className="col-10" style={{ alignItems: 'center', display: 'flex' }}>
                                {(widget.label.trim().length !== 0) ? 'Click edit to open' : 'please provide label'}
                            </div>
                            <div className="col-2 justify-content-end flex">
                                <i className="material-icons" style={{

                                    borderRadius: '5px',
                                    margin: 5,
                                    fontSize: '18px',
                                    padding: '2px'
                                }} onMouseOver={(e) => { onMouseOver(e, 'green', 'white') }} onMouseLeave={(e) => { onMouseLeave(e) }} onClick={() => { editWidget(index) }}>edit</i>
                                <i className="material-icons" style={{

                                    borderRadius: '5px',
                                    margin: 5,
                                    fontSize: '18px',
                                    padding: '2px'
                                }} onMouseOver={(e) => { onMouseOver(e, 'red', 'white') }} onMouseLeave={(e) => { onMouseLeave(e) }} onClick={() => { removeWidget(index) }}>close</i>
                            </div>
                        </div>
                        {widget.expanded && <>

                            <div className="col-12">
                                <label  >
                                    HTML Editor
                                </label>
                                <Editor value={widget.label} onTextChange={(e) => {
                                    form.data1[index].label = e.htmlValue === null ? '' : e.htmlValue;
                                    forceUpdate();
                                }} style={{ width: '100%', padding: 10, maxHeight: 350, height: 'auto', overflow: 'scroll', border: (widget.label.trim().length === 0 && submitted && widget.expanded) ? '1px solid red' : '' }} onChange={(e) => console.log(e)} />


                            </div>
                            <div className="col-12">
                                <label  >
                                    Help Text
                                </label>
                                <InputText
                                    type={"text"}
                                    style={{ width: "100%", margin: '10px 0px 10px 0px' }}
                                    value={widget.description}
                                    onChange={(e) => {
                                        form.data1[index].description = e.target.value;
                                        forceUpdate();
                                    }}
                                />
                            </div>
                        </>}
                        <div style={{ position: 'absolute', top: 0, left: 0 }}>
                            <label style={{
                                fontWeight: 'bold',
                                textShadow: '1px 1px darkgrey',
                                width: 100,
                                background: 'white',
                                display: 'flex',
                                borderRadius: '10px',
                                padding: '2px'
                            }}>HTML Editor</label>
                        </div>
                    </div>
                );

            default:
                return null;
        }
    };
    const addNewRow = (options) => {
        options.push({ label: '', value: (options.length + 1).toString() })
        forceUpdate()
        console.log(options)
    }
    const deleteRow = (index) => {
        activecell.data.values.splice(index, 1)
        forceUpdate()
    }
    const deleteDDRow = (index1, index2) => {
        form.data1[index1].values.splice(index2, 1)
        forceUpdate()
    }
    const itemDragStart = (e, item) => {

        setDraggedItem(item);
        e.dataTransfer.setData('text/plain', item);
        forceUpdate()
    };
    const widgetDragStart = (e, item) => {

        setDraggedWidget(item);

        e.dataTransfer.setData('text/plain', item);
        forceUpdate()
    };
    const getObjectAtIndex = (data, index) => {
        const keys = Object.keys(data);
        const key = keys[index];
        return data[key];
    };
    function validateArray(values) {
        let arr = JSON.parse(JSON.stringify(values))
        const uniqueValues = new Set();
        const invalidIndices = [];

        for (let i = 0; i < arr.length; i++) {
            const item = arr[i];
            item.value = item.value.toString()
            item.label = item.label.toString()

            if (
                typeof item.value === 'string' && item.value.trim() !== '' && // Value is not empty
                typeof item.label === 'string' && item.label.trim() !== '' && // Label is not empty
                !uniqueValues.has(item.value.trim()) // Value is unique
            ) {
                uniqueValues.add(item.value.trim());
            } else {
                invalidIndices.push(i);
            }
        }

        const isValid = invalidIndices.length === 0;

        return { result: isValid, index: invalidIndices };
    }
    const checkEmptyField = (obj) => {
        let flag = false

        if (obj.type === 5) {
            if (obj.data.label !== undefined && obj.data.label.trim().length !== 0) {
                flag = true
            }
        } else {
            if (obj.data.name !== undefined && obj.data.name.trim().length !== 0 && checkDPName(obj.data.name)) {
                if (obj.type === 3) {
                    console.log(obj.data.fraction)
                    if (obj.data.fraction !== undefined && obj.data.fraction !== null && obj.data.fraction >= 0) {
                        flag = true
                    } else {
                        flag = false
                    }
                } else {
                    flag = true
                }

            }
        }

        return flag
    }
    const saveCellConfig = () => {
        let loc = JSON.parse(JSON.stringify(form));

        let item = activecell
        // getObjectAtIndex(loc[activecellId.widgetind].data[activecellId.dataind], activecellId.colind)
        if (item.type === 4) {
            let validate = validateArray(item.data.values)
            console.log(validate, activecell.data.name)
            if (validate.result && activecell.data.name !== undefined && activecell.data.name.trim().length !== 0) {
                loc.data1[activecellId.widgetind].data[activecellId.dataind][loc.data1[activecellId.widgetind].value[activecellId.colind]] = activecell
                setForm(loc)
                setCellConfigDialog(false)
            }
        } else {
            if (checkEmptyField(activecell)) {

                loc.data1[activecellId.widgetind].data[activecellId.dataind][loc.data1[activecellId.widgetind].value[activecellId.colind]] = activecell
                setForm(loc)
                setCellConfigDialog(false)
            }
        }

        console.log(item)
    }
    const checkDPName = (str) => {
        let possibleIDs = ['SDP']
        return possibleIDs.some(prefix => str.trim().startsWith(prefix));
    }
    const cellEditFooter = () => {
        return (<>
            <Button
                label="Close"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => {
                    setCellConfigDialog(false);
                }}
            />
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveCellConfig();
                }}
            />
        </>)
    }
    const itemDragEnd = () => {
        setDraggedItem(null);
    };
    const widgetDragEnd = () => {
        setDraggedWidget(null);
    };
    const widgetDrop = (e) => {

        if (draggedWidget !== null) {
            form.data1.push(draggedWidget)
            setDraggedWidget(null)
        }

        e.preventDefault();



    };
    const widgetDragOver = (ev) => {



        // Prevent the default dragover behavior
        ev.preventDefault();
    };
    const itemDragOver = (ev) => {



        // Prevent the default dragover behavior
        ev.preventDefault();
    };
    const itemDrop = (e, targetIndex) => {
        console.log(e, targetIndex)
        e.preventDefault();
        e.stopPropagation()
        const fromIndex = form.data1.indexOf(draggedItem);
        let loc = JSON.parse(JSON.stringify(form))
        if (fromIndex !== -1 && draggedItem !== null) {
            const updatedItems = [...loc.data1];
            updatedItems.splice(fromIndex, 1);
            updatedItems.splice(targetIndex, 0, draggedItem);
            loc.data1 = updatedItems
            setForm(loc);
            setDraggedItem(null);
        }
        if (draggedWidget !== null) {

            form.data1.splice(targetIndex + 1, 0, draggedWidget)
            forceUpdate()
            setDraggedWidget(null)
        }
    };
    const validateID = () => {
        let loc = JSON.parse(JSON.stringify(form))
        let dps = []
        loc.data1.forEach((item) => {
            console.log(item)
            if (item.type !== 'paragraph' && item.type !== 'htmleditor') {
                if (item.type !== 'table' && item.type !== 'tableadd') {
                    if (item.name) {
                        dps.push(item.name.trim().toLowerCase())
                    } else {
                        dps.push(null)
                    }

                } else {
                    Object.values(item.data).forEach((i) => {
                        Object.values(i).forEach((j) => {
                            if (j.type !== 5) {
                                dps.push(j.data.name.trim().toLowerCase())
                            }
                        })
                    })
                }
            }
        })

        const { uniqueIds, duplicateIds } = checkNUDArray(dps);
        setDupIds(duplicateIds)
        console.log(duplicateIds, uniqueIds, dps)
        return uniqueIds.length === dps.length
    }
    function checkNUDArray(array) {
        const uniqueIds = [];
        const duplicateIds = [];
        const seenItems = new Set();

        array.forEach((item) => {
            if (item !== null && item !== undefined) {
                if (typeof item === 'string') {
                    const trimmedItem = item.trim().toLowerCase();
                    if (trimmedItem !== '') {
                        if (!seenItems.has(trimmedItem)) {
                            uniqueIds.push(trimmedItem);
                            seenItems.add(trimmedItem);
                        } else if (!duplicateIds.includes(trimmedItem)) {
                            duplicateIds.push(trimmedItem);
                        }
                    }
                } else if (!seenItems.has(item.trim().toLowerCase())) {
                    uniqueIds.push(item.trim().toLowerCase());
                    seenItems.add(item.trim().toLowerCase());
                } else if (!duplicateIds.includes(item.trim().toLowerCase())) {
                    duplicateIds.push(item.trim().toLowerCase());
                }
            }
        });


        return { uniqueIds, duplicateIds };
    }
    const validateForm = () => {

        let loc = JSON.parse(JSON.stringify(form))
        let check = 0
        setSubmitted(true)
        loc.data1.forEach((item) => {
            if (item.type === 'text' || item.type === 'textarea' || item.type === 'number' || item.type === 'date' || item.type === 'file') {
                if (item.name === undefined || item.name.trim().length === 0 || !checkDPName(item.name) || item.label === undefined || item.label.trim().length === 0) {

                    check -= 1
                } else {
                    if (item.type === 'number') {
                        if (item.fraction !== null && item.fraction !== undefined && item.fraction >= 0) {
                            check += 1
                        } else {
                            check -= 1
                        }
                    } else {
                        check += 1
                    }

                }


            } else if (item.type === 'select' || item.type === 'radio-group' || item.type === 'checkbox-group') {
                if (item.name === undefined || item.name.trim().length === 0 || !checkDPName(item.name) || item.label === undefined || item.label.trim().length === 0) {
                    check -= 1

                } else if (item.values.length !== 0) {
                    validateArray(item.values).result === false ? check -= 1 : check += 1
                }
            } else if (item.type === 'checkpoint') {


                if (item.name === undefined || item.name.trim().length === 0 || !checkDPName(item.name) || item.label === undefined || item.label.trim().length === 0) {
                    check -= 1

                } else if (item.values !== undefined && item.values.length !== 0) {
                    item.values.filter((i) => { return i.label.toString().trim().length === 0 }).length === 0 ? check += 1 : check -= 1

                } else if (item.values === undefined || item.values.length === 0) {
                    check -= 1
                }

            } else if (item.type === 'paragraph' || item.type === 'htmleditor') {
                if (item.label === undefined || item.label.trim().length === 0) {

                    check -= 1
                } else {
                    check += 1
                }
            } else if (item.type === 'table') {


                if (item.label.trim().length !== 0 && item.value.length !== 0) {
                    if (item.data.length !== 0) {
                        let status = true
                        item.data.forEach((i) => {
                            item.value.forEach((j) => {
                                let cell = i[j]
                                if (cell.type === 4) {
                                    let validate = validateArray(cell.data.values)

                                    if (validate.result && cell.data.name !== undefined && cell.data.name.trim().length !== 0 && checkDPName(cell.data.name)) {


                                    } else {
                                        status = false
                                    }
                                } else {
                                    if (!checkEmptyField(cell)) {
                                        status = false

                                    }
                                }

                            })
                        })
                        console.log(status)
                        if (status) {
                            check += 1
                        } else {

                            check -= 1
                        }
                    } else {

                        check -= 1
                    }
                } else {

                    check -= 1
                }
            } else if (item.type === 'tableadd') {


                if (item.label.trim().length !== 0 && item.value.length !== 0 && item.maxrowlimit !== 0 && item.maxrowlimit <= 100) {
                    if (item.data.length === 1) {
                        let status = true
                        item.data.forEach((i) => {
                            item.value.forEach((j) => {
                                let cell = i[j]
                                if (cell.type === 4) {
                                    let validate = validateArray(cell.data.values)

                                    if (validate.result && cell.data.name !== undefined && cell.data.name.trim().length !== 0 && checkDPName(cell.data.name)) {


                                    } else {
                                        status = false
                                    }
                                } else {
                                    if (!checkEmptyField(cell)) {
                                        status = false

                                    }
                                }

                            })
                        })
                        console.log(status)
                        if (status) {
                            item.newrow = item.data
                            check += 1
                        } else {

                            check -= 1
                        }
                    } else {

                        check -= 1
                    }
                } else {

                    check -= 1
                }
            }
        })
        return check === loc.data1.length && loc.data1.length !== 0

    }
    const previewForm = () => {
        if (validateForm() && validateID()) {
            // const url = `/cf_preview_view/state=${encodeURIComponent(JSON.stringify(form))}`;
            // window.open(url, '_blank');

            navigate.push({ pathname: '/cf_preview_view', state: form })
        } else {
            Swal.fire({
                position: "center",
                icon: "warning",
                title: "Fill the mandatory fields",
                showConfirmButton: false,
                timer: 1500,
            });
        }

    }
    const saveData = () => {
        console.log(validateForm(), validateID())
        if (validateForm() && validateID() && form.title.trim().length !== 0 && form.data1.length !== 0) {
            let data = form;
            data.data1.forEach((item) => {
                if (item.type === 'tableadd') {
                    item['newrow'] = item.data
                }
            })
            delete data.data2
            delete data.type
            if (data.id === undefined) {
                data['curator_id'] = selector.id
                data['created'] = moment.utc()

                APIServices.post(API.SRF, data).then((res) => {
                    if (res.status === 200) {
                        setDupIds([])
                        setSubmitted(false)
                        setForm({ title: '', data1: [], comments: '', suffix: 'SRF' });
                        Swal.fire({
                            position: "center",
                            icon: "success",
                            title: "Consolidated Form Added Successfully",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                        navigate.push({ pathname: '/form/cf_list' })
                        forceUpdate()
                    }
                })
            } else {

                delete data.created
                delete data.curator_id
                delete data.purpose
                delete data.suffix
                if (data.comments === null || data.comments === undefined) {
                    delete data.comments
                }
                data['modifier_id'] = selector.id
                data['updated'] = moment.utc()
                APIServices.patch(API.SRF_Edit(form.id), { ...data, data1: JSON.stringify(data.data1) }).then((res) => {
                    if (res.status === 204) {
                        setDupIds([])
                        setSubmitted(false)
                        setForm({ title: '', data1: [], comments: '', type: 2, suffix: 'SRF' });
                        Swal.fire({
                            position: "center",
                            icon: "success",
                            title: "Consolidated Form Updated Successfully",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                        navigate.push({ pathname: '/form/cf_list' })
                        forceUpdate()
                    }
                })

            }


        } else {
            Swal.fire({
                position: "center",
                icon: "warning",
                title: "Fill the mandatory fields",
                showConfirmButton: false,
                timer: 1500,
            });
        }
    }
    const updateForm = (obj, value) => {
        let loc = JSON.parse(JSON.stringify(form))
        loc[obj] = value
        setForm(loc)
    }
    return (
        <div className=" card col-12" style={{ margin: 5, height: "calc(100vh - 9rem)", overflowY: 'scroll' }}>
            <div
                className="col-12"
                style={{
                    textDecoration: "underline",
                    textAlign: "center",
                    textDecorationColor: "green",
                }}
            >
                <h5> SR Form</h5>
            </div>
            <div
                className="col-12 "
                style={{
                    display: "flex",
                    flexDirection: "row",
                }}
            >
                <div
                    style={{
                        width: "20%",
                        display: "flex",
                        alignSelf: "center",
                    }}
                >
                    <span>
                        SRF Title
                        <span
                            style={{
                                color: "red",
                            }}
                        >
                            &nbsp;*
                        </span>
                    </span>
                </div>
                <div
                    style={{
                        width: "80%",
                    }}
                    className="p-inputgroup"
                >
                    <InputText
                        type={"text"}
                        className={submitted && form.title.trim().length === 0 && 'p-invalid'}
                        value={form.title}
                        onChange={(e) => {
                            updateForm('title', e.target.value)
                        }}
                        placeholder="SRF Title"
                    />
                </div>
            </div>
            <div
                className="col-12 "
                style={{
                    display: "flex",
                    flexDirection: "row"
                }}
            >
                <div
                    style={{
                        width: "20%",
                        display: "flex",
                        alignSelf: "center",
                    }}
                >
                    <span>
                        Tags
                        <span
                            style={{
                                color: "red",
                            }}
                        >
                            &nbsp;*
                        </span>
                    </span>
                </div>
                <div
                    style={{
                        width: "80%",
                    }}
                    className="p-inputgroup"
                >
                    <MultiSelect value={!form.tags ? [] : form.tags.filter(i => clienttag.map(j => j.id).includes(i))} onChange={(e) => { setForm((prev) => ({ ...prev, tags: e.value })) }} style={{ width: '100%' }} options={clienttag} optionLabel="name" display='chip' optionValue="id"
                        placeholder="Select Tags" className="w-full" />

                </div>

            </div>
            <div
                className="col-12 "
                style={{
                    display: "flex",
                    flexDirection: "row",
                }}
            >
                <div
                    style={{
                        width: "20%",
                        display: "flex",
                        alignSelf: "center",
                    }}
                >
                    <span>
                        Description
                    </span>
                </div>
                <div
                    style={{
                        width: "80%",
                    }}
                    className="p-inputgroup"
                >
                    <InputTextarea
                        type={"text"}
                        value={form.comments}
                        onChange={(e) => {
                            updateForm('comments', e.target.value)
                        }}
                        placeholder="description"
                    />
                </div>
            </div>
            <div className='grid m-0 '>
                <div className="col-2">

                    <div draggable onDragStart={(e) => { widgetDragStart(e, getObjectByType('text')) }}
                        className="draggable-widget"
                        // draggable="true"
                        onClick={(e) => {
                            addWidget("text");
                        }}
                    >
                        <i className="pi pi-align-justify"></i> InputText {form ? form.data1 ? (`(${form.data1.filter(i => i.type === 'text').length})`) : null : null} </div>
                    <div draggable onDragStart={(e) => { widgetDragStart(e, getObjectByType('number')) }}
                        className="draggable-widget"
                        onClick={(e) => {
                            addWidget("number");
                        }}
                    >
                        <i style={{ fontSize: '1.1rem' }} className="material-icons">pin</i> Number {form ? form.data1 ? (`(${form.data1.filter(i => i.type === 'number').length})`) : null : null}
                    </div>
                    <div draggable onDragStart={(e) => { widgetDragStart(e, getObjectByType('inputtextarea')) }}
                        className="draggable-widget"
                        // draggable="true"
                        onClick={(e) => {
                            addWidget("inputtextarea");
                        }}
                    >
                        <i style={{
                            fontSize: '12px',
                            paddingRight: '5px'
                        }}
                            className="pi 
pi-pencil"
                        ></i> InputTextArea {form ? form.data1 ? (`(${form.data1.filter(i => i.type === 'textarea').length})`) : null : null}
                    </div>
                    <div draggable onDragStart={(e) => { widgetDragStart(e, getObjectByType('paragraph')) }}
                        className="draggable-widget"
                        // draggable="true"
                        onClick={(e) => {
                            addWidget("paragraph");
                        }}
                    >
                        <i
                            className="pi pi-align-center"
                        ></i> Paragraph {form ? form.data1 ? (`(${form.data1.filter(i => i.type === 'paragraph').length})`) : null : null}

                    </div>
                    <div draggable onDragStart={(e) => { widgetDragStart(e, getObjectByType('select')) }}
                        className="draggable-widget"
                        onClick={(e) => {
                            addWidget("dropdown");
                        }}
                    >
                        <i className="pi pi-list"></i> Dropdown {form ? form.data1 ? (`(${form.data1.filter(i => i.type === 'select').length})`) : null : null}
                    </div>
                    <div draggable onDragStart={(e) => { widgetDragStart(e, getObjectByType('table')) }}
                        className="draggable-widget"
                        onClick={(e) => {
                            addWidget("table");
                        }}
                    >
                        <i className="pi pi-table"></i> Static Table {form ? form.data1 ? (`(${form.data1.filter(i => i.type === 'table').length})`) : null : null}
                    </div>
                    <div draggable onDragStart={(e) => { widgetDragStart(e, getObjectByType('tableadd')) }}
                        className="draggable-widget"
                        onClick={(e) => {
                            addWidget("tableadd");
                        }}
                    >
                        <i className="pi pi-table"></i> Dynamic Table {form ? form.data1 ? (`(${form.data1.filter(i => i.type === 'tableadd').length})`) : null : null}
                    </div>
                    <div draggable onDragStart={(e) => { widgetDragStart(e, getObjectByType('checkpoint')) }}
                        className="draggable-widget"
                        onClick={(e) => {
                            addWidget("checkpoint");
                        }}
                    >
                        <i className="pi pi-server"></i> Checkpoint {form ? form.data1 ? (`(${form.data1.filter(i => i.type === 'checkpoint').length})`) : null : null}
                    </div>
                    <div draggable onDragStart={(e) => { widgetDragStart(e, getObjectByType('checkbox-group')) }}
                        className="draggable-widget"
                        onClick={(e) => {
                            addWidget("checkbox");
                        }}
                    >
                        <i className="pi pi-stop"></i> Checkbox {form ? form.data1 ? (`(${form.data1.filter(i => i.type === 'checkbox').length})`) : null : null}
                    </div>
                    <div draggable onDragStart={(e) => { widgetDragStart(e, getObjectByType('radio-group')) }}
                        className="draggable-widget"
                        onClick={(e) => {
                            addWidget("radio");
                        }}
                    >
                        <i className="pi pi-stop-circle"></i> Radio {form ? form.data1 ? (`(${form.data1.filter(i => i.type === 'radio').length})`) : null : null}
                    </div>
                    <div draggable onDragStart={(e) => { widgetDragStart(e, getObjectByType('date')) }}
                        className="draggable-widget"
                        onClick={(e) => {
                            addWidget("date");
                        }}
                    >
                        <i className="pi pi-calendar"></i> Date {form ? form.data1 ? (`(${form.data1.filter(i => i.type === 'date').length})`) : null : null}
                    </div>
                    <div draggable onDragStart={(e) => { widgetDragStart(e, getObjectByType('file')) }}
                        className="draggable-widget"
                        onClick={(e) => {
                            addWidget("file");
                        }}
                    >
                        <i className="pi pi-file"></i> File Upload {form ? form.data1 ? (`(${form.data1.filter(i => i.type === 'file').length})`) : null : null}
                    </div>
                    <div draggable onDragStart={(e) => { widgetDragStart(e, getObjectByType('htmleditor')) }}
                        className="draggable-widget"
                        onClick={(e) => {
                            addWidget("htmleditor");
                        }}
                    >
                        <i style={{ fontSize: '1.1rem' }} className="material-icons">integration_instructions</i> HTML Editor {form ? form.data1 ? (`(${form.data1.filter(i => i.type === 'htmleditor').length})`) : null : null}
                    </div>
                </div>
                <div className="col-10" onDragOver={(e) => { e.preventDefault() }} onDrop={(e) => { widgetDrop(e) }} style={{ height: "55vh", overflowY: "scroll", marginTop: 10 }}   >

                    {form.data1.map((widget, index) => (
                        <div   >
                            {renderComponent(widget, index)}
                        </div>
                    ))}
                </div>
            </div>
            <div className="col-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button style={{ marginRight: 10 }} rounded text raised aria-label="Filter" onClick={() => { let loc = JSON.parse(JSON.stringify(form)); loc.data1 = []; setForm(loc) }} >Clear </Button>

                {params.state !== null && params.state !== undefined && <Button style={{ marginRight: 10 }} rounded text raised aria-label="Filter" onClick={() => { navigate.goBack() }}>Back</Button>}
                <Button style={{ marginRight: 10 }} rounded text raised aria-label="Filter" onClick={() => { saveData() }}>Save </Button>

                {/* <Button icon='pi pi-cloud-download' rounded text raised aria-label="Filter" onClick={() => { exportTable2Excel(0) }}> </Button> */}
            </div>

            <Dialog
                visible={cellconfigdialog}
                style={{
                    width: "65%"
                }}
                header="Edit Cell Configuration"
                modal
                className="p-fluid"
                footer={cellEditFooter}
                onHide={() => { setCellConfigDialog(false) }}
            >
                <div>
                    <Dropdown value={activecell.type} onChange={(e) => { let loc = activecell; loc.type = e.value; if (e.value === 4) { loc.data.values = [{ label: '', value: '1' }, { label: '', value: '2' }] }; setActiveCell(() => loc); forceUpdate(); }} optionLabel="name" optionValue="id" options={[{ name: 'Text', id: 1 }, { name: 'TextArea', id: 2 }, { name: 'Number', id: 3 }, { name: 'Dropdown', id: 4 }, { name: 'Date', id: 6 }, { name: 'Label', id: 5 }]} />

                    {activecell.type !== 5 && activecell.data !== undefined &&
                        <div className="flex align-items-center" style={{ margin: 10 }}>
                            <Checkbox
                                inputId={"ac"}
                                value={activecell.data.required === undefined ? false : activecell.data.required}
                                onChange={(e) => {
                                    activecell.data['required'] = !e.value;
                                    forceUpdate();
                                }}
                                checked={activecell.data.required === undefined ? false : activecell.data.required}
                            />
                            <label htmlFor={"ac"} className="ml-2">
                                is Required
                            </label>
                        </div>

                    }
                    {activecell.type !== 5 &&
                        <>
                            <div className="m-2">
                                <label htmlFor="id" className="font-bold block mb-2">ID</label>
                                <InputText type="text" className={((activecell.data.name === undefined || activecell.data.name.trim().length === 0) || dupids.includes(activecell.data.name.trim().toLowerCase()) || !checkDPName(activecell.data.name)) && 'p-invalid'} value={activecell.data.name === undefined ? '' : activecell.data.name} onChange={(e) => { activecell.data.name = e.target.value.trim(); forceUpdate(); }} placeholder="ID" />
                            </div>
                            {activecell.data.name !== undefined && (dupids.includes(activecell.data.name.trim().toLowerCase())) && submitted &&
                                <small style={{
                                    margin: '-10px 5px 0px 10px',
                                    color: 'red'
                                }}>Duplicate ID Found</small>
                            }
                        </>
                    }
                    {activecell.type === 3 &&
                        <>
                            <div className="m-2">
                                <label htmlFor="id" className="font-bold block mb-2">Fraction</label>
                                <InputNumber min={0} className={((activecell.data.fraction === undefined || activecell.data.fraction < 0)) && 'p-invalid'} value={activecell.data.fraction === undefined ? null : activecell.data.fraction} onChange={(e) => { activecell.data.fraction = e.value; forceUpdate(); }} placeholder="Fraction" />
                            </div>
                            {activecell.data.fraction !== undefined && submitted &&
                                <small style={{
                                    margin: '-10px 5px 0px 10px',
                                    color: 'red'
                                }}>Fraction Value should be atleast 0</small>
                            }
                            <div className="m-2">
                                <label htmlFor="id" className="font-bold block mb-2">Does a higher value represent improvement, or does a lower value represent improvement? (0-Higher,1-Lower)</label>
                                <InputNumber min={0} max={1} value={activecell.data.directionsign === undefined ? null : activecell.data.directionsign} onChange={(e) => { activecell.data.directionsign = e.value; forceUpdate(); }} placeholder="0/1" />
                            </div>
                        </>
                    }
                    {activecell.type === 5 &&
                        <div className="m-2">
                            <label htmlFor="phs" className="font-bold block mb-2">Label</label>
                            <InputText type="text" className={(activecell.data.label === undefined || activecell.data.label.trim().length === 0) && 'p-invalid'} value={activecell.data.label === undefined ? '' : activecell.data.label} onChange={(e) => { activecell.data.label = e.target.value; forceUpdate(); }} placeholder="label Value" />
                        </div>
                    }
                    {activecell.type < 5 &&
                        <div className="m-3">
                            <label htmlFor="pha" className="font-bold block mb-2">Placeholder</label>
                            <InputText type="text" value={activecell.data.placeholder === undefined ? '' : activecell.data.placeholder} onChange={(e) => { activecell.data.placeholder = e.target.value; forceUpdate(); }} placeholder="Placeholder" />
                        </div>
                    }
                    {activecell.type === 4 &&
                        <div className="m-3">
                            <label htmlFor="ph" className="font-bold block mb-2">Options</label>
                            <div style={{ maxHeight: 200, overflowY: 'scroll' }} >
                                {activecell.data.values.map((i, index) => {
                                    console.log(index, activecell.data.values.length)
                                    return (
                                        <div className="col-12" style={{ padding: 5 }}>
                                            <div className="grid" style={{ alignItems: 'center' }}>
                                                <div className="col-4" >

                                                    <InputText type="text" value={i.label} className={(i.label.toString().trim().length === 0) && 'p-invalid'} onChange={(e) => { activecell.data.values[index].label = e.target.value; forceUpdate(); }} placeholder="label" />
                                                </div>

                                                <div className="col-4">

                                                    <InputText type="text" className={(i.value.toString().trim().length === 0 || !isValueUniqueInArray(i.value, activecell.data.values, 'value')) && 'p-invalid'} value={i.value} onChange={(e) => { activecell.data.values[index].value = e.target.value; forceUpdate(); }} placeholder="value" />


                                                </div>

                                                <div className="col-1">
                                                    {((activecell.data.values.length - 1 === index) || (index === 1 && activecell.data.values.length === 2)) ?
                                                        <div>
                                                            <i className="material-icons" onClick={(e) => { e.stopPropagation(); addNewRow(activecell.data.values) }} style={{ color: 'green' }}>add_box</i>
                                                            {index > 1 && <i className="material-icons" onClick={() => { deleteRow(index) }}>delete</i>}

                                                        </div> :
                                                        index > 1 &&
                                                        <div>
                                                            <i className="material-icons" onClick={() => { deleteRow(index) }}>delete</i>

                                                        </div>

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                        </div>
                    }
                </div>
            </Dialog>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ConsolidateForm, comparisonFn);
