import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";

const data = [
  {
    year: "FY-2023",
    consumption: 796309,
  },
  {
    year: "FY-2024",
    consumption: 0,
  },
];

const DemoWaterConsumption = () => {
  return (
    <div style={{ height: 500 }}>
      <h3 style={{ fontSize: "18px", margin: "25px" }}>Water Consumption</h3>
      <ResponsiveContainer>
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="year" />

          <YAxis>
            <Label
              value="Kilo Liters(KL)"
              angle={-90}
              position="insideLeft"
              style={{ textAnchor: "middle", fill: "#333", fontSize: "14px" }}
            />
          </YAxis>
          <Tooltip />
          <Legend verticalAlign="bottom" />

          <Bar
            dataKey="consumption"
            name="Water Consumption"
            fill="#8FC3E8"
            barSize={60}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DemoWaterConsumption;
