import React, { useState, useEffect } from "react";
import {
  Tab,
  Tabs,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import APIServices from "../../service/APIService";
import { API } from "../../constants/api_url";
import { useSelector } from "react-redux";
import { tvssection } from "../../assets/tvs/js/tvssection";
import { filterDataByTierAndLocationByLevel, filterDataByTierAndLocationByTierId, getFiscalYearsFromStartDate, getRPTextFormat, sectionsList } from "../../components/BGHF/helper";
import { convertReportingMonthsToLetters } from "../../components/BGHF/helper";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { DateTime } from "luxon";
import { Tag } from "primereact/tag";
import { Badge } from "primereact/badge";
export const OverallDataDump = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [label1, label2, label3] = useSelector((state) => state.user.tierLabel);

  const [rawData, setRawData] = useState([]);
  const [efassignment, setEfAssignment] = useState([]);
  const [sapData, setSapData] = useState([]);
  const [metricsData, setMetricsData] = useState([]);
  const [assignedDcf, setAssignedDcf] = useState([]);
  const [rawResponse, setRawResponse] = useState([]);
  const [metricResponse, setMetricResponse] = useState([])
  const [filteredRawData, setFilteredRawData] = useState(rawData);
  const [filteredSapData, setFilteredSapData] = useState(sapData);
  const [selectedDcf, setSelectedDcf] = useState();
  const [selectedSap, setSelectedSap] = useState();
  const [reportingPeriod, setReportingPeriod] = useState(null);
  const [category, setCategory] = useState();
  const [dataSource, setDataSource] = useState("dcf");
  const [indicatorType, setIndicatorType] = useState(0);
  const [metricKpi, setMetricKpi] = useState("");
  const [sapDataResponse, setSapDataResponse] = useState([]);
  const [sapDropdown, setSapDropdown] = useState([]);
  const [load, setLoad] = useState(false);
  const [filters, setFilters] = useState({});
  const [yearOption, setYearOption] = useState([]);
  const [customMetricResponseBk, setCustomMetricResponseBk] = useState([]);
  const [customMetricResponse, setCustomMetricResponse] = useState([]);

  const admin_data = useSelector((state) => state.user.admindetail);
  const userList = useSelector((state) => state.userlist.userList);
  const [rawsitelist, setRawsitelist] = useState([]);
  const tvsSubAdminRoles = useSelector((state) => state.user.tvsSubAdminRoles);

  function findKeyById(tvssection, id) {
    for (const key in tvssection) {
      if (tvssection[key].includes(id)) {
        return parseFloat(key);
      }
    }
    return 0;
  }

  const convertDateFormat = (dateString) => {
    if (dateString)
      return DateTime.fromFormat(dateString, "yyyyMMdd").toFormat("MM-yyyy");
    return null;
  };

  useEffect(() => {
    setFilteredSapData(sapData);
  }, [sapData]);

  function filterDerivedAndStandaloneWithIds(data, overall) {
    const childIds = new Set();
    const standaloneChildren = {};

    function collectStandaloneIds(itemId) {
      const item = overall.find((d) => d.id === itemId);
      if (!item || !item.data1[0]) return [];

      let standaloneIds = item.data1[0].indicator.filter((id) => {
        const child = overall.find((d) => d.id === id);
        return child && child.data1[0]?.source === 1;
      });

      item.data1[0].indicator.forEach((id) => {
        const child = overall.find((d) => d.id === id);
        if (child && child.data1[0]?.source === 0) {
          const nestedStandaloneIds = collectStandaloneIds(child.id);
          standaloneIds = standaloneIds.concat(nestedStandaloneIds);
        }
      });

      return standaloneIds;
    }

    // Collect standalone children for derived parents
    data.forEach((item) => {
      if (item.data1[0]?.type === 0 && item.data1[0]?.source === 0) {
        const standaloneIds = collectStandaloneIds(item.id);
        if (standaloneIds.length > 0) {
          standaloneChildren[item.id] = standaloneIds;
        }

        // Add all child IDs (standalone or not) to the set of child IDs
        item.data1[0].indicator.forEach((id) => childIds.add(id));
      }
    });

    // Filter out derived children and attach standalone_ids to derived parents
    const filteredData = data
      .map((item) => {
        // If item is a derived parent and has standalone children, add standalone_ids
        if (standaloneChildren[item.id]) {
          return { ...item, standalone_ids: standaloneChildren[item.id] };
        }

        // Check for standalone items
        if (item.data1[0]?.type === 0 && item.data1[0]?.source === 1) {
          return { ...item, standalone_ids: [item.id] };
        }

        // Retain only if it's not a derived child (id not in childIds)
        if (!childIds.has(item.id)) {
          return item;
        }

        return null; // Exclude derived children
      })
      .filter((item) => item !== null);

    return filteredData;
  }

  useEffect(() => {
    const fetchSapData = async () => {
      try {
        setLoad(true);
        const response = await APIServices.post(
          API.SapResponseCustom_UP(admin_data.id)
        );
        if (response.data.result) {
          setSapDataResponse(response.data.data);
        } else {
          setSapDataResponse([]);
        }
      } catch (error) {
        console.error("Error fetching sap data:", error);
      } finally {
        setLoad(false);
      }
    };

    fetchSapData();
  }, []);

  useEffect(() => {
    const fetchSapDropdown = async () => {
      try {
        setLoad(true);
        const response = await APIServices.get(
          API.SapCollection_UP(admin_data.id)
        );

        if (response.data) {
          setSapDropdown(response.data);
        } else {
          setSapDropdown([]);
        }
      } catch (error) {
        console.error("Error fetching sap data:", error);
      } finally {
        setLoad(false);
      }
    };
    fetchSapDropdown();
  }, [sapDataResponse]);
  useEffect(async () => {
    if (rawData.length && efassignment.length && customMetricResponse.length) {
      let filtered = JSON.parse(JSON.stringify(rawData))


      // for (const indi of customMetricResponse) {
      //   let index = metricArray.findIndex(y => y.indicatorId === indi.id)
      //   for (const i of filtered.filter(x => indi.dcfIds.includes(x?.dcfId))) {
      //     if (i.formType === 2 && i.dataType === 1) {
      //       let lastDate = DateTime.fromFormat(i.reporting_period.split(' to ')[0], 'LLL-yyyy', { zone: 'utc' });
      //       let filteredAssignment1 = efassignment.filter(x => x.hierarchyId === i.uniqueId && x.startMonth && x.startMonth !== 'NA')

      //       const dateIndex = filteredAssignment1.findIndex((dateRange) => {
      //         const startDate = DateTime.fromFormat(dateRange.startMonth, 'LLL-yyyy');
      //         const endDate = (dateRange.endMonth && dateRange.endMonth !== "Present") ? DateTime.fromFormat(dateRange.endMonth, 'LLL-yyyy') : DateTime.local();
      //         console.log(startDate, endDate, lastDate)
      //         // Step 8: Check if reporting_date + 1 is greater than the start and end of the item in newEfDates
      //         return lastDate >= startDate && lastDate <= endDate;
      //       });
      //       console.log(dateIndex, filteredAssignment1)
      //       if (dateIndex !== -1) {
      //         let filteredLocation = filterDataByTierAndLocationByLevel([{ locationId: i.locationId, level: i.level }], rawsitelist, filteredAssignment1[dateIndex].tier1_id, filteredAssignment1[dateIndex].tier2_id, filteredAssignment1[dateIndex].tier3_id)
      //         if (filteredLocation.length > 0) {
      //           const obj = filteredAssignment1[dateIndex]
      //           i.emissionFactorName = obj.standard
      //           i.emissionFactorValue = obj.co2e
      //           i.efkey = obj.uniqueEfId
      //           i.formula = obj.methodology
      //           i.computedValue = ((i.emissionFactorValue / 1000) * i.value).toFixed(3)
      //         }
      //       } else {
      //         i.emissionFactorName = '-'
      //         i.emissionFactorValue = '-'
      //         i.efkey = "-"
      //         i.formula = "-"
      //         i.computedValue = "-"
      //       }

      //     }
      //     if (index === -1) {
      //       metricArray.push({ indicatorId: indi.id, title: indi.title, contributingEntities: [{ title: i.entity, locationId: i.locationId, level: i.level, contributingReportingPeriod: [{ title: i.reporting_period, status: i.status, contributingDataPoints: [i] }] }] })
      //     } else {
      //       let index2 = metricArray[index].contributingEntities.findIndex(y => y.locationId === i.locationId && y.level === i.level)
      //       if (index2 === -1) {
      //         metricArray[index]['contributingEntities'].push({ title: i.entity, locationId: i.locationId, level: i.level, contributingReportingPeriod: [{ title: i.reporting_period, status: i.status, contributingDataPoints: [i] }] })
      //       } else {
      //         let index3 = metricArray[index].contributingEntities[index2].contributingReportingPeriod.findIndex(y => y.title === i.reporting_period)
      //         if (index3 === -1) {
      //           metricArray[index]['contributingEntities'][index2]['contributingReportingPeriod'].push({ title: i.reporting_period, status: i.status, contributingDataPoints: [i] })
      //         } else {
      //           metricArray[index]['contributingEntities'][index2]['contributingReportingPeriod'][index3].contributingDataPoints.push(i)
      //         }

      //       }

      //     }
      //   }

      // }
      const result = await processCustomMetrics(customMetricResponse, filtered, efassignment, rawsitelist)
      console.log(result)
      setMetricsData(result)
    }
  }, [rawData, efassignment, customMetricResponse])
  async function processCustomMetrics(customMetricResponse, filtered, efassignment, rawsitelist) {
    let metricArray = []
    for (const indi of customMetricResponse) {

      // Process filtered data for each metric response
      await Promise.all(
        filtered
          .filter(x => indi.dcfIds.includes(x?.dcfId))
          .map(async (i) => {
            let index = metricArray.findIndex(y => y.indicatorId === indi.id);
            // i.methodology = indi.type === 1 ? '-' : indi.standalone_ids.map(i => "MT" + i).join(' + ')

            if (i.formType === 2 && i.dataType === 1) {
              let lastDate = DateTime.fromFormat(i.reporting_period.split(' to ')[0], 'LLL-yyyy', { zone: 'utc' });
              let filteredAssignment1 = efassignment.filter(
                x => x.hierarchyId === i.uniqueId && x.startMonth && x.startMonth !== 'NA'
              );

              const dateIndex = filteredAssignment1.findIndex((dateRange) => {
                const startDate = DateTime.fromFormat(dateRange.startMonth, 'LLL-yyyy');
                const endDate = (dateRange.endMonth && dateRange.endMonth !== "Present")
                  ? DateTime.fromFormat(dateRange.endMonth, 'LLL-yyyy')
                  : DateTime.local();

                // Check if reporting_date falls within the range
                return lastDate >= startDate && lastDate <= endDate;
              });

              if (dateIndex !== -1) {
                let filteredLocation = filterDataByTierAndLocationByLevel(
                  [{ locationId: i.locationId, level: i.level }],
                  rawsitelist,
                  filteredAssignment1[dateIndex].tier1_id,
                  filteredAssignment1[dateIndex].tier2_id,
                  filteredAssignment1[dateIndex].tier3_id
                );

                if (filteredLocation.length > 0) {
                  const obj = filteredAssignment1[dateIndex];
                  Object.assign(i, {
                    emissionFactorName: obj.standard,
                    emissionFactorValue: obj.co2e,
                    efkey: obj.uniqueEfId,
                    methodology: obj.methodology,
                    computedValue: ((obj.co2e / 1000) * i.value).toFixed(3),
                  });
                }
              } else {
                // Set default values if no valid date range is found
                Object.assign(i, {
                  emissionFactorName: '-',
                  emissionFactorValue: '-',
                  efkey: "-",
                  formula: "-",
                  computedValue: "-",
                });
              }
            } else {
              i.computedValue = i.value
            }
            console.log(index)
            // Update metricArray
            if (index === -1) {
              // Add new indicator
              metricArray.push({
                indicatorId: indi.id,
                title: indi.title, type: indi?.type || null, standalone_ids: indi?.standalone_ids, unit: indi?.data1?.[0]?.unit || '-',
                contributingEntities: [{
                  title: i.entity,
                  locationId: i.locationId,
                  level: i.level,
                  contributingReportingPeriod: [{
                    title: i.reporting_period,
                    status: i.status, periodFrom: i.periodFrom, periodTo: i.periodTo,
                    contributingDataPoints: [i],
                  }]
                }]
              });
            } else {
              // Update existing indicator
              let entityIndex = metricArray[index].contributingEntities.findIndex(
                y => y.locationId === i.locationId && y.level === i.level
              );
              console.log(entityIndex)
              if (entityIndex === -1) {
                // Add new contributing entity
                metricArray[index].contributingEntities.push({
                  title: i.entity,
                  locationId: i.locationId,
                  level: i.level,
                  contributingReportingPeriod: [{
                    title: i.reporting_period,
                    status: i.status, periodFrom: i.periodFrom, periodTo: i.periodTo,
                    contributingDataPoints: [i],
                  }]
                });
              } else {

                // Update existing contributing entity
                let periodIndex = metricArray[index].contributingEntities[entityIndex].contributingReportingPeriod.findIndex(
                  y => y.title === i.reporting_period
                );

                if (periodIndex === -1) {
                  // Add new reporting period
                  metricArray[index].contributingEntities[entityIndex].contributingReportingPeriod.push({
                    title: i.reporting_period,
                    status: i.status, periodFrom: i.periodFrom, periodTo: i.periodTo,
                    contributingDataPoints: [i],
                  });
                } else {
                  // Add data point to existing reporting period
                  metricArray[index].contributingEntities[entityIndex].contributingReportingPeriod[periodIndex].contributingDataPoints.push(i);
                }
              }
            }
          })
      );
    }
    return metricArray
  }

  const formatIsoDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB");
  };

  useEffect(() => {
    const shapedSapData = [
      ...sapDataResponse.map((item) => ({
        id: item.sapId,
        dataPoint: item.sapId === 'SAP1' ? item.FuelType : (item.sapId === 'SAP2' || item.sapId === 'SAP3') ? item.WasteDescription : item.sapId === 'SAP5' ? item.MaterialDescription : item.sapId === 'SAP6' ? item.BTOrigin + ' to ' + item.BTDestination : item.sapId === 'SAP7' ? item.OriginLocation + ' - ' + item.DestinationLocation
          : item.sapId === 'SAP8' ? item.EmpId : 'No', //NeedToConfirm
        value: item?.Quantity || '-',
        unitOfMeasure: item.UoM || 'NA',
        entity: item.Location || 'NA',
        reportingPeriodFrom: convertDateFormat(item.Date),
        reportingPeriodTo: convertDateFormat(item.Date),
        syncDate: formatIsoDate(item.fetched_on),
      })),
    ];
    const groupedSapData = Object.values(
      shapedSapData.reduce((acc, item) => {
        const {
          reportingPeriodFrom,
          dataPoint,
          value,
          unitOfMeasure,
          entity,
          reportingPeriodTo,
          syncDate,
        } = item;

        const key = `${reportingPeriodFrom}-${dataPoint}-${unitOfMeasure}-${entity}`;

        if (!acc[key]) {
          acc[key] = {
            reportingPeriodTo,
            reportingPeriodFrom,
            dataPoint,
            unitOfMeasure,
            entity,
            value: 0,
            syncDate,
          };
        }
        const parsedValue = parseFloat(value);
        if (!isNaN(parsedValue)) {
          acc[key].value += parsedValue;
        }

        return acc;
      }, {})
    );
    setSapData(groupedSapData);
    setFilteredSapData(groupedSapData);
  }, [sapDataResponse]);

  useEffect(() => {
    APIServices.get(API.Client_EF_Mapping_UP(admin_data.id))
      .then((res) => {
        setEfAssignment(processSingleObject(res.data, rawsitelist));
      })
      .catch((e) => {
        setEfAssignment([]);
      });
  }, [rawsitelist]);
  function processSingleObject(locations, locationData) {
    return locations
      .map(
        ({
          tier1_id,
          tier2_id,
          tier3_id,
          efGhgCat,
          efStandard,
          efCategory,
          efGhgSubCat,
          hierarchicalData,
          efGhgCatId,
          efStandardId,
          efCategoryId,
          efGhgSubCatId,
        }) => {
          let tier1 = "Global";
          let tier2 = "All";
          let tier3 = "All";

          if (tier1_id) {
            const tier1Data = locationData.find((loc) => loc.id === tier1_id);
            if (tier1Data) tier1 = tier1Data.name;

            if (tier2_id) {
              const tier2Data = tier1Data.locationTwos.find(
                (loc) => loc.id === tier2_id
              );
              if (tier2Data) {
                tier2 = tier2Data.name;

                if (tier3_id) {
                  const tier3Data = tier2Data.locationThrees.find(
                    (loc) => loc.id === tier3_id
                  );
                  if (tier3Data) tier3 = tier3Data.name;
                }
              }
            }
          }

          return hierarchicalData.map((x) => ({
            ...x,
            ...{
              uniqueEfId:
                `S${efStandardId}-${x?.dateId ? "T" + x.dateId : "NA"
                }-G${efGhgCatId}-GS${efGhgSubCatId}-I${efCategoryId}-` +
                x.hierarchyId, uniqueId: x.hierarchyId,
              startMonth: x?.startDate || "NA",
              endMonth: x?.endDate || "NA",
              methodology: x?.methodology || "Not Found",
              tier1_id,
              tier2_id,
              tier3_id,
              tier1,
              tier2,
              tier3,
              standard: efStandard?.title || "Not Found",
              ghgcategory: efGhgCat?.title || "Not Found",
              ghgsubcategory: efGhgSubCat?.title || "Not Found",
              item: efCategory?.title || "Not Found",
              co2e: x?.co2e || "-",
              co2: x?.co2 || "-",
              ch4: x?.ch4 || "-",
              n2o: x?.n2o || "-",
            },
          }));
        }
      )
      .reduce((a, b) => [...a, ...b], []);
  }

  const convertSectionIdToName = (id) => {
    return sectionsList?.find((i) => i.id === id).title;
  };
  const convertSectionNameToId = (name) => {
    return sectionsList?.find((i) => i.title === name).id;
  };

  const getCoverageText = (rowData, rawsitelist) => {
    let text = "";
    console.log(rowData, rawsitelist)
    if (rowData.level === 0) {
      text = "Corporate";
    } else if (rowData.level === 1) {
      let country_index = rawsitelist.findIndex(
        (i) => i.id === rowData.locationId
      );
      if (country_index !== -1) {
        text = rawsitelist[country_index].name;
      }
    } else if (rowData.level === 2) {
      let city_index = rawsitelist
        ?.flatMap((i) =>
          i.locationTwos?.flatMap((j) =>
            j.locationThrees?.map((k) => {
              return {
                site_id: k.id,
                site_name: k.name,
                city_id: j.id,
                city_name: j.name,
                country_id: i.id,
                country_name: i.name,
              };
            })
          )
        )
        .findIndex((i) => {
          return i.city_id === rowData.locationId;
        });
      if (city_index !== -1) {
        text = rawsitelist.flatMap((i) =>
          i.locationTwos.flatMap((j) =>
            j.locationThrees.map((k) => {
              return {
                site_id: k.id,
                site_name: k.name,
                city_id: j.id,
                city_name: j.name,
                country_id: i.id,
                country_name: i.name,
              };
            })
          )
        )[city_index].city_name;
      }
    } else if (rowData.level === 3) {
      let site_index = rawsitelist
        .flatMap((i) =>
          i.locationTwos.flatMap((j) =>
            j.locationThrees.map((k) => {
              return {
                site_id: k.id,
                site_name: k.name,
                city_id: j.id,
                city_name: j.name,
                country_id: i.id,
                country_name: i.name,
              };
            })
          )
        )
        .findIndex((i) => {
          return i.site_id === rowData.locationId;
        });
      if (site_index !== -1) {
        text = rawsitelist.flatMap((i) =>
          i.locationTwos.flatMap((j) =>
            j.locationThrees.map((k) => {
              return {
                site_id: k.id,
                site_name: k.name,
                city_id: j.id,
                city_name: j.name,
                country_id: i.id,
                country_name: i.name,
              };
            })
          )
        )[site_index].site_name;
      }
    }
    return text;
  };

  useEffect(() => {
    const nextReportingPeriod = `${parseInt(reportingPeriod)}`;
    const previousReportingPeriod = `${parseInt(reportingPeriod) - 1}`;

    const isInReportingPeriod = (item) => {
      const [periodFromMonth, periodFromYear] = item.periodFrom
        .split("-")
        .map(Number);
      const [periodToMonth, periodToYear] = item.periodTo
        .split("-")
        .map(Number);

      return (
        (periodFromYear === parseInt(previousReportingPeriod) &&
          periodFromMonth >= 4) ||
        (periodToYear === parseInt(nextReportingPeriod) && periodToMonth <= 3)
      );
    };

    const isSapReportingPeriod = (item) => {
      const [
        reportingPeriodFromDate,
        reportingPeriodFromMonth,
        reportingPeriodFromYear,
      ] = item.reportingPeriodFrom?.split("/").map(Number);

      const [
        reportingPeriodToDate,
        reportingPeriodToMonth,
        reportingPeriodToYear,
      ] = item.reportingPeriodTo.split("/").map(Number);

      const startDate = new Date(`${previousReportingPeriod}-04-01`);
      const endDate = new Date(`${nextReportingPeriod}-03-31`);

      const itemStartDate = new Date(
        reportingPeriodFromYear,
        reportingPeriodFromMonth - 1,
        reportingPeriodFromDate
      );
      const itemEndDate = new Date(
        reportingPeriodToYear,
        reportingPeriodToMonth - 1,
        reportingPeriodToDate
      );

      return (
        (itemStartDate >= startDate && itemStartDate <= endDate) ||
        (itemEndDate >= startDate && itemEndDate <= endDate) ||
        (itemStartDate <= startDate && itemEndDate >= endDate)
      );
    };

    let tempAssignedDcf =
      category &&
      assignedDcf?.filter(
        (i) => i?.section === convertSectionNameToId(category)
      );

    if (dataSource === "dcf") {
      if (reportingPeriod && !category && !selectedDcf) {
        const updatedData = rawData.filter(isInReportingPeriod);
        // .map((item, index) => ({ ...item, sno: index + 1 }));
        setFilteredRawData(updatedData);
      } else if (reportingPeriod && category && !selectedDcf) {
        const updatedData = rawData.filter(
          (item) =>
            isInReportingPeriod(item) && item?.dcfId === tempAssignedDcf[0]?.id
        );
        // .map((item, index) => ({ ...item, sno: index + 1 }));
        setFilteredRawData(updatedData);
      } else if (reportingPeriod && !category && selectedDcf) {
        const updatedData = rawData.filter(
          (item) => isInReportingPeriod(item) && item?.dcfId === selectedDcf
        );
        // .map((item, index) => ({ ...item, sno: index + 1 }));
        setFilteredRawData(updatedData);
      } else if (!reportingPeriod && category && !selectedDcf) {
        const updatedData = rawData.filter(
          (item) => item?.dcfId === tempAssignedDcf[0]?.id
        );
        // .map((item, index) => ({ ...item, sno: index + 1 }));
        setFilteredRawData(updatedData);
      } else if (!reportingPeriod && category && selectedDcf) {
        const updatedData = rawData.filter(
          (item) =>
            item?.dcfId === tempAssignedDcf[0]?.id &&
            item?.dcfId === selectedDcf
        );
        // .map((item, index) => ({ ...item, sno: index + 1 }));
        setFilteredRawData(updatedData);
      } else if (!reportingPeriod && !category && selectedDcf) {
        const updatedData = rawData.filter(
          (item) => item?.dcfId === selectedDcf
        );
        // .map((item, index) => ({ ...item, sno: index + 1 }));
        setFilteredRawData(updatedData);
      } else if (reportingPeriod && category && selectedDcf) {
        const updatedData = rawData.filter(
          (item) =>
            isInReportingPeriod(item) &&
            item?.dcfId === tempAssignedDcf[0]?.id &&
            item?.dcfId === selectedDcf
        );
        // .map((item, index) => ({ ...item, sno: index + 1 }));
        setFilteredRawData(updatedData);
      } else {
        const updatedData = rawData.map((item, index) => ({
          ...item,
          // sno: index + 1,
        }));
        setFilteredRawData(updatedData);
      }
    } else if (dataSource === "sap") {
      if (reportingPeriod && !selectedSap) {
        const updatedData = sapData.filter((item) =>
          isSapReportingPeriod(item)
        );
        // .map((item, index) => ({ ...item, sno: index + 1 }));
        setFilteredSapData(updatedData);
      } else if (reportingPeriod && selectedSap) {
        const updatedData = sapData.filter(
          (item) => isSapReportingPeriod(item) && item?.id === selectedSap
        );
        // .map((item, index) => ({ ...item, sno: index + 1 }));
        setFilteredSapData(updatedData);
      } else if (!reportingPeriod && selectedSap) {
        const updatedData = sapData.filter((item) => item?.id === selectedSap);
        // .map((item, index) => ({ ...item, sno: index + 1 }));
        setFilteredSapData(updatedData);
      } else {
        const updatedData = sapData.map((item, index) => ({
          ...item,
          // sno: index +  1,
        }));
        setFilteredSapData(updatedData);
      }
    }
  }, [reportingPeriod, category, selectedDcf, selectedSap]);

  useEffect(() => {
    setReportingPeriod(null);
    setCategory(null);
    setSelectedDcf(null);
    setSelectedSap(null);
  }, [dataSource]);

  // useEffect(() => {
  //   let tempAssignedDcf =
  //     category &&
  //     assignedDcf?.filter(
  //       (i) => i?.section === convertSectionNameToId(category)
  //     );
  //   category
  //     ? setFilteredRawData(
  //         rawData.filter((i) => i?.dcfId === tempAssignedDcf[0]?.id)
  //       )
  //     : setFilteredRawData(rawData);
  // }, [category]);
  // useEffect(() => {
  //   selectedDcf
  //     ? setFilteredRawData(rawData.filter((i) => i?.dcfId === selectedDcf))
  //     : setFilteredRawData(rawData);
  // }, [selectedDcf]);
  // useEffect(() => {
  //   selectedSap
  //     ? setFilteredSapData(sapData.filter((i) => i?.tag === selectedSap))
  //     : setFilteredSapData(sapData);
  // }, [selectedSap]);

  const getUser = (id) => {
    if (id === admin_data.id) {
      return "Enterprise Admin";
    }
    return userLookup[id] ? userLookup[id].information.empname : "";
  };
  const userLookup = userList.reduce((acc, user) => {
    acc[user.id] = user;
    return acc;
  }, {});

  useEffect(() => {
    const fetchData = async () => {
      try {
        let uriString = {
          include: [
            {
              relation: "newTopics",
              scope: {
                include: [
                  {
                    relation: "newMetrics",
                    scope: { include: [{ relation: "newDataPoints" }] },
                  },
                ],
              },
            },
          ],
        };
        let assigned_dcf = [];
        const curatedIndicators = Array.from(
          new Set(
            Object.entries(tvssection).flatMap((i) =>
              [8, 9, 10, 11, 12, 13, 14, 15, 16].includes(parseFloat(i[0]))
                ? i[1]
                : []
            )
          )
        );
        const promise0 = await APIServices.get(
          API.AssignDCFClient_UP(admin_data.id)
        );

        const promise1 = await APIServices.get(
          API.Categories +
          `?filter=${encodeURIComponent(JSON.stringify(uriString))}`
        );
        const promise2 = await APIServices.get(API.DCF);
        let dcf_list = promise2.data;
        let tempArray = [];

        const shapedCategory = promise1?.data
          ?.map((item) => {
            if (item.newTopics) {
              item.newTopics = item.newTopics.filter(
                (topics) => topics.newMetrics && topics.newMetrics?.length > 0
              );
            }
            return item;
          })
          .filter((item) => item.newTopics && item.newTopics?.length > 0);
        let overallmetric = shapedCategory.flatMap(
          (i) => i.newTopics && i.newTopics.flatMap((x) => x.newMetrics)
        );
        if (promise0?.data?.length !== 0) {
          // let topic_ids = promise0?.data[0]?.topic_ids;

          shapedCategory
            ?.flatMap((i) => i?.newTopics)
            ?.forEach((top) => {
              if (
                promise0.data[0]?.topic_ids.includes(top.id) &&
                (top.tag === null || parseFloat(top.tag) === admin_data.id)
              ) {
                top.newMetrics.forEach((met) => {
                  if (
                    Array.isArray(met.data1) && promise0.data[0].metric_ids.includes(met.id) &&
                    (met.tag === null || parseFloat(met.tag) === admin_data.id)
                  ) {
                    tempArray.push({ ...met, type: (met.data1[0].type === 0 && met.data1[0].source === 0) ? 2 : 1 });
                  }
                  if (
                    curatedIndicators.includes(met.id) &&
                    promise0.data[0].metric_ids.includes(met.id) &&
                    (met.tag === null ||
                      parseFloat(met.tag) === admin_data.id) &&
                    met.newDataPoints !== undefined
                  ) {
                    met.newDataPoints.forEach((ndp) => {
                      if (
                        Array.isArray(ndp.data1) &&
                        ndp.data1[0].datasource &&
                        typeof ndp.data1[0].datasource === "number" &&
                        dcf_list
                          .map((i) => i.id)
                          .includes(ndp.data1[0].datasource)
                      ) {
                        let dcf_index = dcf_list.findIndex(
                          (i) => i.id === ndp.data1[0].datasource
                        );
                        if (
                          !assignedDcf
                            .map((i) => i.id)
                            .includes(ndp.data1[0].datasource) &&
                          dcf_index !== -1 &&
                          (dcf_list[dcf_index].tags === null ||
                            dcf_list[dcf_index].tags.includes(admin_data.id))
                        ) {
                          let dcf = {
                            ...dcf_list[dcf_index],
                            metricId: met.id,
                          };
                          dcf.section = findKeyById(tvssection, met.id);
                          assigned_dcf.push(dcf);
                        }
                      }
                    });
                  }
                });
              }
            });
          // console.log(
          //   filterDerivedAndStandaloneWithIds(tempArray, overallmetric),
          //   "overallMetric"
          // );
        }
        let attachedArray = [];
        for (let item of filterDerivedAndStandaloneWithIds(tempArray, overallmetric)) {
          attachedArray.push({ ...item, type: item?.standalone_ids?.length === 1 && item.standalone_ids.includes(item.id) ? 1 : ((item?.standalone_ids?.length === 1 && !item.standalone_ids.includes(item.id)) || (item?.standalone_ids?.length > 1)) ? 2 : 0, ...extractDcfSapIds(item.standalone_ids, overallmetric) })
        }
        console.log(attachedArray, "tempArray");
        setCustomMetricResponseBk(attachedArray);
        setCustomMetricResponse(attachedArray);

        let yrOptions = getFiscalYearsFromStartDate(
          admin_data.information.startdate
        );
        setAssignedDcf(assigned_dcf);
        setYearOption(yrOptions);
        let uriStringLoc = {
          include: [
            {
              relation: "locationTwos",
              scope: { include: [{ relation: "locationThrees" }] },
            },
          ],
        };
        const locationResponse = await APIServices.get(
          API.LocationOne_UP(admin_data.id) +
          `?filter=${encodeURIComponent(JSON.stringify(uriStringLoc))}`
        );
        //console.log(locationResponse, "LOC");
        const shapedSite = locationResponse?.data
          .map((item) => {
            if (item.locationTwos) {
              item.locationTwos = item.locationTwos.filter(
                (locationTwo) =>
                  locationTwo.locationThrees &&
                  locationTwo.locationThrees?.length > 0
              );
            }
            return item;
          })
          .filter((item) => item.locationTwos && item.locationTwos?.length > 0);

        setRawsitelist(shapedSite);
        try {
          const response = await APIServices.get(
            API.QN_Submit_UP(admin_data.id)
          );
          let resStr = {
            include: ['submitDcf']
          }
          const response2 = await APIServices.get(API.Structured_UP(admin_data.id) + `?filter=${encodeURIComponent(JSON.stringify(resStr))}`);
          const result2 = response2.data.filter(i => i.submitDcf).map(i => ({
            title: i.label.replace(/(<([^>]+)>)/gi, "")
              ?.replace(/\n/g, " ")
              ?.replace(/&nbsp;/g, " ")
              ?.replace("&amp;", "&") || '-', approver: getUser(i.submitDcf.approved_by), approverComments: "No Comments", dateOfApproval: i.submitDcf?.approved_on ? new Date(i.submitDcf?.approved_on).toLocaleString().split(",")[0] : '-', dcfId: i.dcfId, entity: getCoverageText(i.submitDcf, shapedSite), periodFrom: i.reporting_period?.[0] || "N/A",
            periodTo:
              i?.reporting_period[i?.reporting_period?.length - 1] ||
              "N/A",
            unitOfMeasure: i?.uom || "Nos",
            dataType: i?.dataType || null,
            formType: i?.formType || null,
            uniqueId: i?.uniqueId || null,
            locationId: i.submitDcf?.locationId,
            level: i.submitDcf?.level,
            reporter: `${getUser(i.submitDcf?.reporter_modified_by)}`,
            reportedDate: new Date(i.submitDcf?.reporter_modified_on)
              .toLocaleString()
              .split(",")[0],
            reporting_period: getRPTextFormat(i.reporting_period),

            reporterComments: i.submitDcf?.logs?.[0]?.remarks || "No Comments",
            reviewer: i.submitDcf?.self ? 'Self' : ` ${getUser(i.submitDcf?.reviewed_by)}`,
            value: i.value,
            reviewerComments:
              i.submitDcf?.return_remarks?.[0]?.remarks || "No Comments",
            approver: i.submitDcf?.approved_by
              ? `${getUser(i.submitDcf?.approved_by)}`
              : "N/A",
            status:
              i.submitDcf?.type === 3
                ? "Approved"
                : i.submitDcf?.type === 2
                  ? "Pending Approval"
                  : i.submitDcf?.type === 1
                    ? "Pending Review"
                    : i.submitDcf?.type === 0 && i.submitDcf?.reject === 1
                      ? "Resubmission Required"
                      : null,
          }))
          console.log(result2)


          const data = response?.data;
          setLoad(true);

          setRawResponse(data);
          const transformedRawData = data.flatMap((item, index) =>
            item.response
              .filter((i) => i.type !== "paragraph")
              .map((res, resIndex) => ({
                //sno: `${++serialNumber}`,
                title:
                  res?.label
                    ?.replace(/(<([^>]+)>)/gi, "")
                    ?.replace(/\n/g, " ")
                    ?.replace(/&nbsp;/g, " ")
                    ?.replace("&amp;", "&") || "No Title",
                value: res?.type === "number" ? res?.value || 0 : "N/A",
                periodFrom: item?.reporting_period?.[0] || "N/A",
                periodTo:
                  item?.reporting_period[item?.reporting_period?.length - 1] ||
                  "N/A",
                entity: `${getCoverageText(item, shapedSite)}`,

                status:
                  item?.type === 3
                    ? "Approved"
                    : item?.type === 2
                      ? "Pending Approval"
                      : item?.type === 1
                        ? "Pending Review"
                        : item?.type === 0 && item?.reject === 1
                          ? "Resubmission Required"
                          : null,
                dcfId: item.dcfId,
                unitOfMeasure: item?.unitOfMeasure || "Nos",
                reporter: `${getUser(item?.reporter_modified_by)}`,
                reportedDate: new Date(item?.reporter_modified_on)
                  .toLocaleString()
                  .split(",")[0],
                reporterComments: item?.logs?.[0]?.remarks || "No Comments",
                reviewer: ` ${getUser(item?.reviewed_by)}`,
                reviewedDate:
                  item.reviewed_on !== null
                    ? new Date(item?.reviewed_on).toLocaleString().split(",")[0]
                    : "N/A",
                reviewerComments:
                  item?.return_remarks?.[0]?.remarks || "No Comments",
                approver: item?.approved_by
                  ? `${getUser(item?.approved_by)}`
                  : "N/A",
                dateOfApproval:
                  item.approver_modified_on !== null
                    ? new Date(item?.approved_on).toLocaleString().split(",")[0]
                    : "N/A",
                approverComments: item?.approver_modified_on
                  ? "Modified by Approver"
                  : "No Comments",
              }))
          );

          setRawData(result2);
          setFilteredRawData(result2);
          setLoad(false);
        } catch (error) {
          console.log(error);
        } finally {
          setLoad(false);
        }
        const transformedMetricsData = metricsData.flatMap((item, index) =>
          item.response
            .filter((i) => i.type !== "paragraph")
            .map((res, resIndex) => ({
              reportingFrequencies: "Annual",
              contributingEntities: `${getCoverageText(item, shapedSite)}`,
              contributingDataPoints:
                res?.label
                  ?.replace(/(<([^>]+)>)/gi, "")
                  ?.replace(/\n/g, " ")
                  ?.replace(/&nbsp;/g, " ")
                  ?.replace("&amp;", "&") || "No Title",
              totalQuantity: res?.type === "number" ? res?.value || 0 : "N/A",
              unitOfMeasure: "Nos", // Replace with actual unit if available
              emissionFactorName: "N/A", // Replace with actual data if available
              emissionFactorValue: "N/A", // Replace with actual data if available
              formula: "Sum of Values", // Replace with actual formula
              computedValue: "N/A", // Replace with actual computed value if available
              entitySummary: `Entity ${item.locationId} reported ${item.response?.length} data points.`,
            }))
        );

        setMetricsData(transformedMetricsData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [admin_data.id]);


  function RenderTable(data) {
    console.log(data)
    return (
      <div className="p-datatable p-component p-datatable-gridlines p-datatable-responsive-scroll custom-datatable p-datatable-striped" data-scrollselectors=".p-datatable-wrapper" data-pc-name="datatable" data-pc-section="root" pr_id_5="">
        <div className="p-datatable-wrapper" data-pc-section="wrapper">
          <table className="p-datatable-table p-datatable-scrollable-table" role="table" data-pc-section="table" style={{ border: 2, borderCollapse: "collapse", width: "100%" }}>
            <thead className="p-datatable-thead" data-pc-section="thead">
              <tr>
                <th >Indicator</th>
                <th>Type</th>
                <th>Contributing Entities</th>
                <th>Approval Status</th>
                <th>From</th>
                <th>To</th>
                <th>Contributing Data Points</th>
                <th>Total Quantity</th>
                <th>Unit of Measure</th>
                <th>Emission Factor (if applicable)</th>
                <th>Emission Factor ID</th>
                <th>Emission Factor Value</th>
                <th>Formula</th>
                <th>Computed Value</th>
                <th>Unit Of Measure</th>
                <th>Entity Summary</th>
                <th>Enterprise Summary</th>
              </tr>
            </thead>
            <tbody className="p-datatable-tbody" data-pc-section="tbody">
              {data.map((indicator, indicatorIndex) => {
                let indicatorRowSpan = indicator?.contributingEntities?.reduce(
                  (sum, entity) =>
                    sum +
                    entity.contributingReportingPeriod.reduce(
                      (innerSum, period) =>
                        innerSum + period.contributingDataPoints.length,
                      0
                    ),
                  0
                );
                let entityCount = 0;
                let periodCount = 0;
                let contributingPoint = 0;


                return indicator?.contributingEntities?.map((entity, entityIndex) => {
                  const entityRowSpan = entity.contributingReportingPeriod.reduce(
                    (sum, period) => sum + period.contributingDataPoints.length,
                    0
                  );
                  entityCount += entityRowSpan;
                 
                
                
                  console.log(entityCount);
                  return entity?.contributingReportingPeriod?.map(
                    (period, periodIndex) => {
                      const periodRowSpan = period.contributingDataPoints.length;
                      periodCount += periodRowSpan;
                  
                    
                      return period?.contributingDataPoints?.map(
                        (dataPoint, dataPointIndex) => {
                          contributingPoint += 1; 

                          return (<tr key={`${indicatorIndex}-${entityIndex}-${periodIndex}-${dataPointIndex}`}>
                            {/* Merge rows for Indicator and Type */}
                            {entityIndex === 0 &&
                              periodIndex === 0 &&
                              dataPointIndex === 0 && (
                                <>
                                  <td className={'datatable-td-bottom'} rowSpan={indicatorRowSpan}>
                                    {'MT' + indicator.indicatorId + ' :' + indicator.title}
                                  </td>
                                  <td className={'datatable-td-bottom'} rowSpan={indicatorRowSpan}>
                                    {indicator.type === 1 ? 'Standalone' : 'Derived'}
                                  </td>
                                </>
                              )}

                            {/* Merge rows for Contributing Entities */}
                            {periodIndex === 0 && dataPointIndex === 0 && (
                              <td className={entityCount === indicatorRowSpan ? 'datatable-td-bottom' : ''} rowSpan={entityRowSpan}>{entity.title}</td>
                            )}

                            {/* Merge rows for Contributing Reporting Period */}
                            {dataPointIndex === 0 && (
                              <>
                                <td rowSpan={periodRowSpan} className={periodCount === indicatorRowSpan ? 'datatable-td-bottom' : ''}>
                                  {period.status || ""}
                                </td>
                                <td rowSpan={periodRowSpan} className={periodCount === indicatorRowSpan ? 'datatable-td-bottom' : ''}>
                                  {period.periodFrom || ""}
                                </td>
                                <td rowSpan={periodRowSpan} className={periodCount === indicatorRowSpan ? 'datatable-td-bottom' : ''}>
                                  {period.periodTo || ""}
                                </td>
                              </>
                            )}

                            {/* Data points (no row merging) */}
                            <td className={contributingPoint === indicatorRowSpan ? 'datatable-td-bottom' : ''}>{dataPoint.title || ""}</td>
                            <td className={contributingPoint === indicatorRowSpan ? 'datatable-td-bottom' : ''}>{dataPoint.value || "-"}</td>
                            <td className={contributingPoint === indicatorRowSpan ? 'datatable-td-bottom' : ''}>{dataPoint.unitOfMeasure || "-"}</td>
                            <td className={contributingPoint === indicatorRowSpan ? 'datatable-td-bottom' : ''}>{dataPoint.emissionFactorName || "-"}</td>
                            <td className={contributingPoint === indicatorRowSpan ? 'datatable-td-bottom' : ''}>{dataPoint.efkey || "-"}</td>
                            <td className={contributingPoint === indicatorRowSpan ? 'datatable-td-bottom' : ''}>{dataPoint.emissionFactorValue || "-"}</td>
                            <td className={contributingPoint === indicatorRowSpan ? 'datatable-td-bottom' : ''}>{indicator.type === 2 ? indicator?.standalone_ids?.map(i => "MT" + i).join(' + ') : indicator.type === 1 ? dataPoint.methodology : "-"}</td>
                            <td className={contributingPoint === indicatorRowSpan ? 'datatable-td-bottom' : ''}>{dataPoint.computedValue || "-"}</td>
                            {entityIndex === 0 &&
                              periodIndex === 0 &&
                              dataPointIndex === 0 && (
                                <td className={'datatable-td-bottom' } rowSpan={indicatorRowSpan}>{indicator.unit}</td>

                              )}
                            {
                              periodIndex === 0 &&
                              dataPointIndex === 0 &&
                              (<>

                                <td className={entityCount === indicatorRowSpan ? 'datatable-td-bottom' : ''} rowSpan={entityRowSpan}>{indicator?.contributingEntities[entityIndex]?.contributingReportingPeriod.flatMap((x) => x?.contributingDataPoints).reduce((a, b) => { return (a + parseFloat(b?.computedValue)) }, 0)}</td>
                              </>
                              )}

                            {entityIndex === 0 &&
                              periodIndex === 0 &&
                              dataPointIndex === 0 && (
                                <td className={'datatable-td-bottom'} rowSpan={indicatorRowSpan}>{indicator?.contributingEntities?.flatMap((x) => x?.contributingReportingPeriod && x?.contributingReportingPeriod?.flatMap(y => y?.contributingDataPoints)).reduce((a, b) => { return (a + parseFloat(b?.computedValue)) }, 0)}</td>

                              )}
                          </tr>)
                        }
                      );
                    }
                  );
                });
              })}
            </tbody>
          </table>
        </div>  </div>
    );
  }



  function extractDcfSapIds(standaloneIds, data) {
    let dcfIds = new Set();  // Using Set to ensure uniqueness
    let sapIds = new Set();

    standaloneIds.forEach(id => {
      // Find matching data for the given id in the second input (input2)
      const metric = data.find(item => item.id === id);
      if (metric && metric.newDataPoints) {
        // Iterate over newDataPoints for the current metric
        metric.newDataPoints.forEach(dataPoint => {
          // Add sapIds (assuming `dataPoint.dcf` is the source for these)
          dataPoint.data1 && Array.isArray(dataPoint.data1) && dataPoint.data1.forEach(point => {
            // Add dcfIds from `datasource`
            if (point.datasource) {
              dcfIds.add(point.datasource) // Assuming `datasource` holds the dcfId
            }

            // Add sapIds from `datasource2`
            if (point.datasource2) {
              sapIds.add(point.datasource2) // Assuming `datasource2` holds the sapId
            }
          });
        });
      }
    });

    return { sapIds: Array.from(sapIds), dcfIds: Array.from(dcfIds) };
  }

  const transformedSapDropdown = sapDropdown?.map((item) => ({
    label: `${item.sapId}-${item.title}`,
    value: item.sapId,
  }));

  const DataCollectionIdOptions =
    dataSource === "sap"
      ? transformedSapDropdown
      : Array.from(
        new Map(
          assignedDcf.filter(Boolean).map((item) => [item.id, item])
        ).values()
      ).map((item) => ({
        label: `DCF${item.id}-${item.title}`,
        value: item.id,
      }));

  const filterTemplate = (fieldName) => {
    const uniqueOptions =
      dataSource === "dcf"
        ? Array.from(
          new Set(filteredRawData?.map((item) => item[fieldName]))
        ).filter((item) => item)
        : Array.from(
          new Set(filteredSapData?.map((item) => item[fieldName]))
        ).filter((item) => item);

    const allOptions = uniqueOptions.map((option, index) => ({
      name: option,
      id: option,
    }));

    return (options) => (
      <MultiSelect
        panelClassName="hidefilter"
        value={options.value}
        options={allOptions}
        optionLabel="name"
        optionValue="id"
        filter
        placeholder="Any"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
        onChange={(e) => {
          handleFilterChange(fieldName, options.filterCallback)(e.value);
        }}
      />
    );
  };

  const handleFilterChange =
    (fieldName, filterCallback) => (selectedValues) => {
      const safeValues = Array.isArray(selectedValues) ? selectedValues : [];
      filterCallback(safeValues);
    };

  const exportReport = (data, columns, fileName = "report.csv") => {
    if (!data || data.length === 0) {
      alert("No data available to export!");
      return;
    }

    // Extract headers from columns
    const headers = columns.map((col) => col.header);

    // Generate CSV rows
    const csvRows = [];
    csvRows.push(headers.join(",")); // Add header row

    // Add data rows
    data.forEach((row) => {
      const rowData = columns.map((col) => {
        const value = row[col.field] || ""; // Safely access field values
        return `"${value.toString().replace(/"/g, '""')}"`; // Escape double quotes
      });
      csvRows.push(rowData.join(","));
    });

    // Create CSV string
    const csvString = csvRows.join("\n");

    // Create a Blob and download the file
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const rawDcfDataColumns = [
    // { field: "sno", header: "SNo" },
    { field: "title", header: "Quantitative Data Point" },
    { field: "value", header: "Quantitative Value" },
    { field: "unitOfMeasure", header: "Unit of Measure" },
    { field: "periodFrom", header: "Reporting Period (From)" },
    { field: "periodTo", header: "Reporting Period (To)" },
    { field: "entity", header: "Reporting Entity" },
    { field: "status", header: "Current Status" },
    { field: "reporter", header: "Reporter" },
    { field: "reportedDate", header: "Reported Date" },
    { field: "reporterComments", header: "Reporter Comments" },
    { field: "reviewer", header: "Reviewer" },
    { field: "reviewedDate", header: "Reviewed Date" },
    { field: "reviewerComments", header: "Reviewer Comments" },
    { field: "approver", header: "Approver" },
    { field: "dateOfApproval", header: "Date of Approval" },
    { field: "approverComments", header: "Approver Comments" },
  ];

  const rawSapDataColumns = [
    // { field: "sno", header: "SNo" },
    { field: "dataPoint", header: "Quantitative Data Point" },
    { field: "value", header: "Quantitative Value" },
    { field: "unitOfMeasure", header: "Unit of Measure" },
    { field: "reportingPeriodFrom", header: "Reporting Date (From)" },
    { field: "reportingPeriodTo", header: "Reporting Date (To)" },
    { field: "entity", header: "Reporting Entity" },
    { field: "syncDate", header: "Sync Date" },
  ];

  const metricsColumns = [
    { field: "reporting_period", header: "Reporting Frequencies" },
    { field: "entity", header: "Contributing Entities" },
    { field: "title", header: "Contributing Data Points" },
    { field: "value", header: "Total Quantity" },
    { field: "unitOfMeasure", header: "Unit of Measure" },
    { field: "emissionFactorName", header: "Emission Factor Name" },
    { field: "emissionFactorValue", header: "Emission Factor Value" },
    { field: "formula", header: "Formula" },
    { field: "computedValue", header: "Computed Value" },
    { field: "efkey", header: "EF Id" },
    { field: "entitySummary", header: "Entity Summary for Time Interval" },
  ];

  const emissionsColumns = [
    { header: label1, field: "tier1" },
    { header: label2, field: "tier2" },
    { header: label3, field: "tier3" },
    { header: "EF_ID", field: "uniqueEfId" },
    { header: "Standard", field: "standard" },
    { header: "Start Month", field: "startMonth" },
    { header: "End Month", field: "endMonth" },
    { header: "GHG Category", field: "ghgcategory" },
    { header: "GHG SubCategory", field: "ghgsubcategory" },
    { header: "Item", field: "item" },
    { header: "Item Category1", field: "subCategory1" },
    { header: "Item Category2", field: "subCategory2" },
    { header: "Item Category3", field: "subCategory3" },
    { header: "Item Category4", field: "subCategory4" },
    { header: "Co2e in kg", field: "co2e" },
  ];

  const bodyTemplate = (rowData) => {
    if (
      rowData.status === "Approved" ||
      rowData.status === "Pending Approval" ||
      rowData?.status === "Pending Review" ||
      rowData?.status === "Resubmission Required"
    ) {
      return (
        <Badge
          style={{ width: 100 }}
          value={rowData.status}
          severity={
            rowData.status === "Approved"
              ? "success"
              : rowData.status === "Pending Approval"
                ? "info"
                : rowData?.status === "Pending Review"
                  ? "info"
                  : rowData?.status === "Resubmission Required"
                    ? "warning"
                    : null
          }
        />
      );
    }
    return null;
  };

  const renderDataTable = (data, columns) => (
    <>
      <DataTable
        value={data}
        paginator
        rows={10}
        className="custom-datatable"
        removableSort
        loading={load}
        filters={{
          title: { value: null, matchMode: "in" },
          dataPoint: { value: null, matchMode: "in" },
          entity: { value: null, matchMode: "in" },
          periodFrom: { value: null, matchMode: "in" },
          reportingPeriodFrom: { value: null, matchMode: "in" },
          status: { value: null, matchMode: "in" },
          reporter: { value: null, matchMode: "in" },
          reviewer: { value: null, matchMode: "in" },
          approver: { value: null, matchMode: "in" },
          unitOfMeasure: { value: null, matchMode: "in" },
        }}
      >
        {columns.map((col, index) => {
          if (
            col.field === "title" ||
            col.field === "label" ||
            col.field === "dataPoint" ||
            col.field === "entity" ||
            col.field === "periodFrom" ||
            col.field === "reportingPeriodFrom" ||
            col.field === "status" ||
            col.field === "reporter" ||
            col.field === "reviewer" ||
            col.field === "approver" ||
            col.field === "unitOfMeasure" ||
            col.field === "efkey"
          ) {
            return (
              <Column
              key={index}
              field={col.field}
              header={col.header}
              filter
              filterElement={filterTemplate(col.field)}
              showFilterMatchModes={false}
              showFilterMenuOptions={false}
              body={col.field === "status" && bodyTemplate}
              sortable={
                col.field === "periodFrom" ||
                col.field === "reportingPeriodFrom"
              }
              // showApplyButton={false}
              // showAddButton={false}
              // showClearButton={false}
            />
            );
          }
          return (
            <Column
              key={index}
              field={col.field}
              header={col.header}
              sortable={col.field === "value"}
            />
          );
        })}
      </DataTable>
    </>
  );
  const updateMetricData = async (val) => {
    let filtered = JSON.parse(JSON.stringify(rawData))
    const result = await processCustomMetrics(customMetricResponse.filter(i => i.type === val || val === 0), filtered, efassignment, rawsitelist)
    console.log(result, val, rawData)
    setIndicatorType(val)
    setMetricsData(result)
  }
  const RowFilterTemplate = (options, obj) => {
    return (
      <MultiSelect
        value={options.value}
        options={Array.from(new Set(efassignment.map((i) => i[obj])))}
        onChange={(e) => options.filterCallback(e.value)}
        placeholder="Any"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
      />
    );
  };
  return (
    <Box>
      <div className="col-12">
        <div
          className="col-12 flex align-items-center"
          style={{ padding: "0px 20px" }}
        >
          <p className="text-big-one">

            Welcome {admin_data.information.contactperson} !
          </p>
          <Tag className="ml-3 p-tag-blue" style={{ width: "10rem" }}>

            Enterprise Administrator
          </Tag>
        </div>

        <div className="col-12" style={{ padding: "0px 20px" }}>
          <label className="text-big-one text-navy flex fs-16">

            Data Assurance
          </label>
          <label className="text-small-one text-navy flex fs-16">

            Data integrity and transparency though a comprehensive audit trail
            for internal assurance and external validation.
          </label>
        </div>
      </div>
      <Tabs
        value={activeTab}
        onChange={(e, newValue) => setActiveTab(newValue)}
      >
        <Tab label="Raw Data" />
        <Tab label="Indicator" />
        <Tab label="Emission Factors" />
      </Tabs>
      <Box sx={{ padding: 2 }}>
        {activeTab === 0 && dataSource === "dcf" && (
          <>
            <p>
              This screen displays the raw data submitted for the selected
              reporting year. Use the filters to choose the year, category, and
              data source to view associated data points, time periods, and
              reporting entities. Within each data point, you can see the
              reported values along with details of who reported, reviewed, and
              approved the data. Clicking on the data point opens its specific
              submission screen, showing the full workflow, attached evidences,
              and additional details. The table will display data only after it
              has been submitted by the Reporter or pulled from a data source.
              Any required data that has not yet been submitted or integrated
              into the platform will not appear here. You can also download this
              report in a CSV format.
            </p>
            <div className="col-12 align-items-end flex justify-content-end">
              <Button
                onClick={() => {
                  exportReport(
                    filteredRawData,
                    rawDcfDataColumns,
                    "raw_data.csv"
                  );
                }}
                label="Export Report"
                icon="pi pi-download"
                className="p-button-primary mr-3"
              />
            </div>

            <Box display="flex" gap={2} padding={2} alignItems="center">
              <FormControl sx={{ minWidth: 200 }}>
                <label htmlFor="reporting-period-dropdown">
                  Reporting Year
                </label>
                <Dropdown
                  id="reporting-period-dropdown"
                  value={reportingPeriod}
                  options={[{ label: "All", name: null }, ...yearOption]}
                  optionValue="name"
                  optionLabel="label"
                  onChange={(e) => setReportingPeriod(e.value)}
                  placeholder="Select Reporting Year"
                />
              </FormControl>

              <FormControl sx={{ minWidth: 200 }}>
                <label htmlFor="category-dropdown">Topic/Section</label>
                <Dropdown
                  id="category-dropdown"
                  value={category}
                  filter
                  options={[
                    { label: "All", value: "" },
                    ...Array.from(
                      new Set(
                        assignedDcf?.map((d) =>
                          convertSectionIdToName(d.section)
                        )
                      )
                    ).map((cat) => ({
                      label: cat,
                      value: cat,
                    })),
                  ]}
                  onChange={(e) => setCategory(e.value)}
                  placeholder="Select Topic/Section"
                />
              </FormControl>

              <FormControl sx={{ minWidth: 200 }}>
                <label htmlFor="datasource-dropdown">Data Source</label>
                <Dropdown
                  id="data-source"
                  value={dataSource}
                  filter
                  options={[
                    { label: "DCF", value: "dcf" },
                    { label: "SAP", value: "sap" },
                    { label: "SRF", value: "srf" },
                  ]}
                  onChange={(e) => setDataSource(e.value)}
                  placeholder="Select Data Source"
                />
              </FormControl>
              <FormControl sx={{ minWidth: 550 }}>
                <label htmlFor="dcf-dropdown">Select ID</label>
                <Dropdown
                  id="dcf-dropdown"
                  value={dataSource === "dcf" ? selectedDcf : selectedSap}
                  filter
                  options={[
                    { label: "All", value: "" },
                    ...DataCollectionIdOptions,
                  ]}
                  onChange={(e) => {
                    dataSource === "dcf"
                      ? setSelectedDcf(e.value)
                      : setSelectedSap(e.value);
                  }}
                  placeholder="Select Id"
                />
              </FormControl>
            </Box>
            {renderDataTable(filteredRawData, rawDcfDataColumns)}
          </>
        )}
        {activeTab === 0 && dataSource === "sap" && (
          <>
            <p>
              This screen displays the raw data submitted for the selected
              reporting year. Use the filters to choose the year, category, and
              data source to view associated data points, time periods, and
              reporting entities. Within each data point, you can see the
              reported values along with details of who reported, reviewed, and
              approved the data. Clicking on the data point opens its specific
              submission screen, showing the full workflow, attached evidences,
              and additional details. Do note that the data will appear in this
              table only after it has gone through the stipulated internal
              approval process. You can also download this report in a CSV
              format.
            </p>
            <div className="col-12 align-items-end flex justify-content-end">
              <Button
                onClick={() => {
                  exportReport(
                    filteredSapData,
                    rawSapDataColumns,
                    "sap_data.csv"
                  );
                }}
                label="Export Report"
                icon="pi pi-download"
                className="p-button-primary mr-3"
              />
            </div>
            <Box display="flex" gap={2} padding={2} alignItems="center">
              <FormControl sx={{ minWidth: 200 }}>
                <label htmlFor="reporting-period-dropdown">
                  Reporting Year
                </label>
                <Dropdown
                  id="reporting-period-dropdown"
                  value={reportingPeriod}
                  options={[{ label: "All", name: null }, ...yearOption]}
                  optionValue="name"
                  optionLabel="label"
                  onChange={(e) => setReportingPeriod(e.value)}
                  placeholder="Select Reporting Year"
                />
              </FormControl>

              {/* <FormControl sx={{ minWidth: 200 }}>
                <label htmlFor="category-dropdown">Topic/Section</label>
                <Dropdown
                  id="category-dropdown"
                  value={category}
                  filters
                  options={[
                    { label: "All", value: "" },
                    ...Array.from(
                      new Set(
                        assignedDcf?.map((d) =>
                          convertSectionIdToName(d.section)
                        )
                      )
                    ).map((cat) => ({
                      label: cat,
                      value: cat,
                    })),
                  ]}
                  onChange={(e) => setCategory(e.value)}
                  placeholder="Select Topic/Section"
                />
              </FormControl> */}
              <FormControl sx={{ minWidth: 200 }}>
                <label htmlFor="datasource-dropdown">Data Source</label>
                <Dropdown
                  id="data-source"
                  value={dataSource}
                  filter
                  options={[
                    { label: "DCF", value: "dcf" },
                    { label: "SAP", value: "sap" },
                    { label: "SRF", value: "dcf" },
                  ]}
                  onChange={(e) => setDataSource(e.value)}
                  placeholder="Select Data Source"
                />
              </FormControl>
              <FormControl sx={{ minWidth: 550 }}>
                <label htmlFor="dcf-dropdown">Select ID</label>
                <Dropdown
                  id="dcf-dropdown"
                  value={dataSource === "dcf" ? selectedDcf : selectedSap}
                  filter
                  options={[
                    { label: "All", value: "" },
                    ...DataCollectionIdOptions,
                  ]}
                  onChange={(e) => {
                    dataSource === "dcf"
                      ? setSelectedDcf(e.value)
                      : setSelectedSap(e.value);
                  }}
                  placeholder="Select Id"
                />
              </FormControl>
            </Box>
            {renderDataTable(filteredSapData, rawSapDataColumns)}
          </>
        )}

        {activeTab === 1 && (
          <>
            <p>
              This screen displays indicators for the selected reporting year,
              where "Indicator" refers to computed value
              included in reports or dashboards. Use the filters to select the
              year, category, and indicator to view. Only indicators that have
              completed the approval process are listed here; unapproved data
              points are not visible. For the selected indicator(s), the table
              shows all contributing source data, reporting entities, and, where
              applicable, the associated emission factors. Clicking on a data
              point will open its specific submission screen, providing full
              workflow details, attached evidences, and additional information.
            </p>
            <div className="col-12 align-items-end flex justify-content-end">
              <Button
                onClick={() => {
                  exportReport(metricsData, metricsColumns, "metrics_data.csv");
                }}
                label="Export Report"
                icon="pi pi-download"
                className="p-button-primary mr-3"
              />
            </div>
            <Box display="flex" gap={2} padding={2} alignItems="center">
              <FormControl sx={{ minWidth: 200 }}>
                <label htmlFor="reporting-period-dropdown">
                  Reporting Year
                </label>
                <Dropdown
                  id="reporting-period-dropdown"
                  value={reportingPeriod}
                  options={[{ label: "All", name: null }, ...yearOption]}
                  optionValue="name"
                  optionLabel="label"
                  onChange={(e) => setReportingPeriod(e.value)}
                  placeholder="Select Reporting Year"
                />
              </FormControl>
              {/* 
              <FormControl sx={{ minWidth: 200 }}>
                <label htmlFor="category-dropdown">Topic/Section</label>
                <Dropdown
                  id="category-dropdown"
                  value={category}
                  filter
                  options={[
                    { label: "All", value: "" },
                    ...Array.from(
                      new Set(
                        assignedDcf?.map((d) =>
                          convertSectionIdToName(d.section)
                        )
                      )
                    ).map((cat) => ({
                      label: cat,
                      value: cat,
                    })),
                  ]}
                  onChange={(e) => setCategory(e.value)}
                  placeholder="Select Topic/Section"
                />
              </FormControl> */}

              <FormControl sx={{ minWidth: 200 }}>
                <label htmlFor="datasource-dropdown">Type of Indicator</label>
                <Dropdown
                  id="indicator-types"
                  value={indicatorType}
                  filter
                  optionLabel="label"
                  optionValue="value"
                  options={[
                    { label: "All", value: 0 },
                    { label: "Standalone", value: 1 },
                    { label: "Derived", value: 2 },
                  ]}
                  onChange={(e) => { updateMetricData(e.value) }}
                  placeholder="Select Type of Indicator"
                />
              </FormControl>
              {/* <FormControl sx={{ minWidth: 550 }}>
                <label htmlFor="dcf-dropdown">
                  Indicator / Metric / Quantitative Disclosure
                </label>
                <Dropdown
                  id="metric"
                  value={metricKpi}
                  filter

                  optionLabel="title"
                  itemTemplate={(op) => { return op.id + ': ' + op.title }}
                  options={customMetricResponse}
                  onChange={(e) => {
                    updateMetricData('indicator', e.value)

                  }}
                  placeholder="Select Indicator / Metric / Quantitative Disclosure"
                />
              </FormControl> */}
            </Box>
            {RenderTable(metricsData)}
            {/* {renderDataTable(metricsData, metricsColumns)} */}
          </>
        )}
        {activeTab === 2 && (
          <div>
            <p>
              This screen provides an overview of the emission factors used to
              calculate CO2e values for various reports and disclosures. Using
              the filters, you can select a specific reporting entity within the
              enterprise to view the corresponding emission factor source,
              category, and sub-categories. Emission values are presented for
              each item within the selected category, based on the unit of
              measurement used to collect data on the Navigos platform. Each
              emission factor in this list has a unique ID that is used in
              computations and can be traced back to its source.
            </p>
            <div className="col-12 align-items-end flex justify-content-end">
              <Button
                onClick={() => {
                  exportReport(
                    efassignment,
                    emissionsColumns,
                    "emissions_data.csv"
                  );
                }}
                label="Export Report"
                icon="pi pi-download"
                className="p-button-primary mr-3"
              />
            </div>
            <DataTable
              showGridlines
              className="h-500"
              loading={load}
              filters={{
                startMonth: { value: null, matchMode: "in" },
                endMonth: { value: null, matchMode: "in" },
                tier1: { value: null, matchMode: "in" },
                tier2: { value: null, matchMode: "in" },
                tier3: { value: null, matchMode: "in" },
                standard: { value: null, matchMode: "in" },
                ghgcategory: { value: null, matchMode: "in" },
                ghgsubcategory: { value: null, matchMode: "in" },
                item: { value: null, matchMode: "in" },
                subCategory1: { value: null, matchMode: "in" },
                subCategory2: { value: null, matchMode: "in" },
                subCategory3: { value: null, matchMode: "in" },
                subCategory4: { value: null, matchMode: "in" },
              }}
              value={efassignment}
              scrollable
            >
              <Column
                header={label1}
                field={"tier1"}
                showFilterMatchModes={false}
                filter
                body={(rowData) => rowData.tier1}
                filterElement={(options) => RowFilterTemplate(options, "tier1")}
              />

              <Column
                header={label3}
                field={"tier2"}
                showFilterMatchModes={false}
                filter
                body={(rowData) => rowData.tier2}
                filterElement={(options) => RowFilterTemplate(options, "tier2")}
              />
              <Column
                header={label3}
                field={"tier3"}
                showFilterMatchModes={false}
                filter
                body={(rowData) => rowData.tier3}
                filterElement={(options) => RowFilterTemplate(options, "tier3")}
              />

              <Column
                header={"EF_ID"}
                bodyStyle={{ width: "150px", minWidth: "150px" }}
                field={"uniqueEfId"}
              />

              <Column
                header={"Standard"}
                field={"standard"}
                showFilterMatchModes={false}
                filter
                filterElement={(options) =>
                  RowFilterTemplate(options, "standard")
                }
              />
              <Column
                header={"Start Month"}
                field={"startMonth"}
                showFilterMatchModes={false}
                filter
                body={(rowData) => rowData.startMonth}
                filterElement={(options) =>
                  RowFilterTemplate(options, "startMonth")
                }
              />
              <Column
                header={"End Month"}
                field={"endMonth"}
                showFilterMatchModes={false}
                filter
                body={(rowData) => rowData.endMonth}
                filterElement={(options) =>
                  RowFilterTemplate(options, "endMonth")
                }
              />
              <Column
                header={"GHG Category"}
                field={"ghgcategory"}
                showFilterMatchModes={false}
                filter
                filterElement={(options) =>
                  RowFilterTemplate(options, "ghgcategory")
                }
              />
              <Column
                header={"GHG SubCategory"}
                field={"ghgsubcategory"}
                showFilterMatchModes={false}
                filter
                filterElement={(options) =>
                  RowFilterTemplate(options, "ghgsubcategory")
                }
              />
              <Column
                header={"Item"}
                field={"item"}
                showFilterMatchModes={false}
                filter
                filterElement={(options) => RowFilterTemplate(options, "item")}
              />
              <Column
                header={"Item Category1"}
                field={"subCategory1"}
                showFilterMatchModes={false}
                filter
                filterElement={(options) =>
                  RowFilterTemplate(options, "subCategory1")
                }
              />
              <Column
                header={"Item Category2"}
                field={"subCategory2"}
                showFilterMatchModes={false}
                filter
                filterElement={(options) =>
                  RowFilterTemplate(options, "subCategory2")
                }
              />
              <Column
                header={"Item Category3"}
                field={"subCategory3"}
                showFilterMatchModes={false}
                filter
                filterElement={(options) =>
                  RowFilterTemplate(options, "subCategory3")
                }
              />
              <Column
                header={"Item Category4"}
                field={"subCategory4"}
                showFilterMatchModes={false}
                filter
                filterElement={(options) =>
                  RowFilterTemplate(options, "subCategory4")
                }
              />
              <Column header={"Co2e in kg"} field={"co2e"} />
            </DataTable>
          </div>
        )}
      </Box>
    </Box>
  );
};

