
export const supplierAssessmentMail = (supplierName, url, date, contactMailId) => {
    return (`
        <p style="font-family: Arial, sans-serif; font-size: 14px; color: #333; line-height: 1.6;">
            Dear ${supplierName},
        </p>

        <p style="font-family: Arial, sans-serif; font-size: 14px; color: #333; line-height: 1.6;">
            At <strong>TVS Motor Company</strong>, sustainability is a cornerstone of our operations and partnerships.
            As part of our "<strong>My Sustainability Index</strong>" program, which aims to enhance sustainable practices
            across our supply chain, we kindly request you to complete the "<strong>Self-Assessment</strong>" form.
        </p>

        <p style="font-family: Arial, sans-serif; font-size: 14px; color: #333; line-height: 1.6;">
            This self-assessment will enable us to:
        </p>
        <ul style="font-family: Arial, sans-serif; font-size: 14px; color: #333; line-height: 1.6;">
            <li>Evaluate and understand your current sustainability practices.</li>
            <li>Identify opportunities for collaboration and improvement.</li>
        </ul>

        <p style="font-family: Arial, sans-serif; font-size: 14px; color: #333; line-height: 1.6;">
            <strong>What You Need to Do:</strong>
        </p>
        <ol style="font-family: Arial, sans-serif; font-size: 14px; color: #333; line-height: 1.6;">
            <li>Access the form by login through : <a href={"${url}"} style="color: #007bff;">${url}</a>.</li>
            <li>Complete all sections of the self-assessment with accurate and updated information.</li>
            <li>Submit the form by on/before <strong>${date}</strong>.</li>
        </ol>

        <p style="font-family: Arial, sans-serif; font-size: 14px; color: #333; line-height: 1.6;">
            <strong>Key Details:</strong>
        </p>
        <ul style="font-family: Arial, sans-serif; font-size: 14px; color: #333; line-height: 1.6;">
            <li>The form includes questions on your sustainability policies, initiatives, etc.</li>
            <li>Please upload supporting evidence (e.g., certifications, reports) within the form.</li>
        </ul>

        <p style="font-family: Arial, sans-serif; font-size: 14px; color: #333; line-height: 1.6;">
            Should you require any assistance or have questions, our team is here to support you.
            Please feel free to reach out to us at <a href="mailto:${contactMailId}" style="color: #007bff;">${ contactMailId }</a>
        
        </p>

        <p style="font-family: Arial, sans-serif; font-size: 14px; color: #333; line-height: 1.6;">
            Your participation is vital to the success of the "<strong>My Sustainability Index</strong>" program, and
            we appreciate your cooperation in helping us build a sustainable future together.
        </p>

        <p style="font-family: Arial, sans-serif; font-size: 14px; color: #333; line-height: 1.6;">
            Thank you for your continued partnership.
        </p>

        <p style="font-family: Arial, sans-serif; font-size: 14px; color: #333; line-height: 1.6;">
            Best regards,<br />
            <strong>TVS Motor Company</strong>
        </p>
    `)
}