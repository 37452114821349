import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';
import supplyData from '../supply.json';

// Function to fetch and process the data
const fetchData = async () => {
    // Adjust path if necessary
    const rawData = supplyData;

    // Calculate MSI score range per category
    const categoryStats = rawData.reduce((acc, item) => {
        if (!acc[item.category]) {
            acc[item.category] = { 
                category: item.category, 
                min: item.msi_score, 
                max: item.msi_score 
            };
        } else {
            acc[item.category].min = Math.min(acc[item.category].min, item.msi_score);
            acc[item.category].max = Math.max(acc[item.category].max, item.msi_score);
        }
        return acc;
    }, {});

    // Prepare data with range calculation
    return Object.values(categoryStats).map(item => ({
        category: item.category,
        min: item.min,
        range: item.max - item.min
    }));
};

const SupplierMSIDistributionChart = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        fetchData().then(setData);
    }, []);

    return (
        <div className="container mt-4">
            <h5 className="text-center text-danger mb-4">
                Distribution of MSI Scores Based on Supplier Category
            </h5>
            <ResponsiveContainer width="100%" height={400}>
                <BarChart layout="vertical" data={data} margin={{ top: 20, right: 30, left: 50, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" />
                    <YAxis dataKey="category" type="category" width={200} />
                    <Tooltip />
                    <Bar dataKey="min" stackId="a" fill="transparent" /> 
                    <Bar dataKey="range" stackId="a" fill="#8884d8" name="MSI Score Range" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default SupplierMSIDistributionChart;
