import React, { useEffect, useState } from 'react';

import EnvironmentGoals from './EnvironmentGoals';

import EnvironmentMetrics from './EnvironmentMetrics';
import './TabsComponent.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Dropdown } from 'primereact/dropdown';
import { useSelector } from 'react-redux';
import APIServices from '../../../service/APIService';
import { API } from '../../../constants/api_url';
import { DateTime } from 'luxon';
import useForceUpdate from 'use-force-update';
import { TabMenu } from 'primereact/tabmenu';
import { tvssection } from '../../../assets/tvs/js/tvssection';
function TVSPerformanceDashboard() {
    const login_data = useSelector((state) => state.user.userdetail);
    const admin_data = useSelector((state) => state.user.admindetail);
    const allRoles = useSelector((state) => state.user.allRoles)
    const [activeIndex, setActiveIndex] = useState(0);
    const [goal,setGoal]= useState([])

    const { fymonth } = useSelector((state) => state.user.fyStartMonth);
    const [quantitativesubmission, setQuantitativeSubmission] = useState([])
    const [goals, setGoals] = useState([])
    const [allgoals, setAllGoals] = useState([])


    const [activeMainTab, setActiveMainTab] = useState('Goals and Targets');
    const [filter, setFilter] = useState({ year: null, category: 0, country: 0, city: null, location: null, section: allRoles.includes(8) ? 8 : allRoles.includes(9) ? 9 : allRoles.includes(10) ? 10 : allRoles.includes(11) ? 11 : allRoles.includes(12) ? 12 : allRoles.includes(13) ? 13 : allRoles.includes(14) ? 14 : allRoles.includes(15) ? 15 : 16 })
    const [locList, setLocList] = useState({ country: [], city: [], location: [] })
    const [dcflist, setDcfList] = useState([])
    const [apief, setApiEF] = useState([])
    const [rawsitelist, setRawSiteList] = useState([])
    const forceUpdate = useForceUpdate()
    const [yearoptions, setYearOptions] = useState([])
    const [subcat, setSubCat] = useState({ one: [], two: [], three: [], four: [] })
    const [goalprops, setGoalProps] = useState({ scope1_2: 0, waterData: [], locationData: [], scope3: 0, scope2Data: [], totalScope1: [], totalScope1Breakup: [] })
    const [section, setSection] = useState([{ name: 'Sustainability', id: 8 }, { name: 'Health', id: 9 }, { name: 'Safety Central', id: 10 }, { name: 'Safety Operational', id: 11}, { name: 'Supply Chain', id: 12 }, { name: 'Dealership Sustainability', id: 13 }, { name: 'Environmental', id: 14 }, { name: 'Social', id: 15 }, { name: 'Governance', id: 16 }])

    // Determine which component to render based on the active tab and sub-tab
    const renderContent = () => {
        if (activeMainTab === 'Goals and Targets') {
            return <EnvironmentGoals goalslist={goals} emissions={goalprops} year={filter.year} fymonth={fymonth} />;
        } else {
            return <EnvironmentMetrics year={filter.year} fymonth={fymonth} headers={[]} />;
        }
    };
    useEffect(() => {
        let selectedYear = null
        let yrOptions = getFiscalYearsFromStartDate(admin_data.information.startdate)
        console.log(yrOptions)
        if (yrOptions.length !== 0) {
            selectedYear = yrOptions[yrOptions.length - 1].name
            setFilter((prev) => ({ ...prev, year: selectedYear }))
        }
        console.log(yrOptions)
        setYearOptions(yrOptions)
        let uriString = {
            include: [
                {
                    relation: "locationTwos",
                    scope: { include: [{ relation: "locationThrees" }] },
                },
            ],
        };
        let goalString = {
            include: [
            'newIndicatorTwos','newTargetsTwos'
            ],
        };
        let ef_complete = API.EF_Std + `?filter=%7B%20%22include%22%3A%20%5B%7B%20%22relation%22%3A%20%22newEfDates%22,%20%22scope%22%3A%20%7B%20%22include%22%3A%20%5B%7B%20%22relation%22%3A%20%22newEfs%22,%20%22scope%22%3A%20%7B%20%22include%22%3A%20%5B%7B%20%22relation%22%3A%20%22newEfItems%22%20%7D%5D%20%7D%20%7D%5D%20%7D%20%7D%5D%7D`

        const promise1 = APIServices.get(API.QN_Submit_UP(login_data.clientId))

        const promise2 = APIServices.get(
            API.LocationOne_UP(login_data.information.cid) +
            `?filter=${encodeURIComponent(JSON.stringify(uriString))}`)
        const promise3 = APIServices.get(ef_complete)
        const promise4 = APIServices.get(API.EF_SC1)
        const promise5 = APIServices.get(API.EF_SC2)
        const promise6 = APIServices.get(API.EF_SC3)
        const promise7 = APIServices.get(API.EF_SC4)
        const promise8 = APIServices.get(API.DCF_Title_Only)
        const promise9 = APIServices.get(
            API.Goal_UP(admin_data.id) +
            `?filter=${encodeURIComponent(JSON.stringify(goalString))}`)
        Promise.all([promise1, promise2, promise3, promise4, promise5, promise6, promise7, promise8,promise9]).then((values) => {
            const curatedIndicators = Array.from(new Set(Object.entries(tvssection).flatMap(i =>  i[1] || [] )))
            setAllGoals(values[8].data.filter(i => i.newIndicatorTwos && i.newIndicatorTwos.some(x => curatedIndicators.includes(x.indicator)) ) )
            let qnsubmissions = values[0].data
            setApiEF(values[2].data)
            setDcfList(values[7].data)
            setQuantitativeSubmission(qnsubmissions.filter(i => (i.type === 2 || i.type === 3)))
            const shapedSite = values[1].data
                .map((item) => {
                    if (item.locationTwos) {
                        item.locationTwos = item.locationTwos.filter(
                            (locationTwo) =>
                                locationTwo.locationThrees &&
                                locationTwo.locationThrees.length > 0
                        );
                    }
                    return item;
                })
                .filter((item) => item.locationTwos && item.locationTwos.length > 0);
            setGoalProps((prev) => ({ ...prev, locationData: shapedSite }))
            setRawSiteList(shapedSite)
            setLocList((prev) => ({ ...prev, country: [{ name: 'All Countries', id: 0 }, ...shapedSite.map(i => ({ name: i.name, id: i.id }))] }))

            let loc_subcat = subcat
            loc_subcat.one = values[3].data
            loc_subcat.two = values[4].data
            loc_subcat.three = values[5].data
            loc_subcat.four = values[6].data
            setSubCat((prev) => ({ ...prev, ...loc_subcat }))
            if (selectedYear) {
                setFilter((prev) => ({ ...prev, year: selectedYear }))
            }


        })
    }, [])



    useEffect(() => {
        if (subcat.one.length !== 0) {
            console.log(quantitativesubmission.filter(i => i.dcfId === 246 || i.dcfId === 247))
            renderData(quantitativesubmission, filter)
        }
    }, [subcat])





    const generateMTDArray = (year, fymonth) => {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let result = [];

        // Determine the fiscal year start and end months
        const fiscalStartMonth = fymonth - 1; // 0-based month index
        const fiscalEndMonth = (fymonth + 11) % 12; // Fiscal year ends 11 months after fyMonth

        // Calculate fiscal start and end year
        let fiscalStartYear = year;
        let fiscalEndYear = year;

        if (fiscalStartMonth > 0) { // If the fiscal year does not start in January
            fiscalStartYear = year - 1;
        }
        fiscalEndYear = fiscalStartYear + 1;

        // Generate month-year strings
        for (let i = 0; i < 12; i++) {
            let monthIndex = (fiscalStartMonth + i) % 12;
            let currentYear = fiscalStartYear + Math.floor((fiscalStartMonth + i) / 12);
            result.push(`${months[monthIndex]}-${currentYear}`);
            if (currentYear > fiscalEndYear) break;
        }

        return result;
    };


    function getFiscalYearsFromStartDate(start_date) {
        console.log(start_date);
        const startDate = DateTime.fromISO(start_date, { zone: 'utc' }).plus({ months: 1 });
        const currentDate = DateTime.now();

        let startFiscalYear, currentFiscalYear;
        const fiscalYears = [];

        if (fymonth === 1) {
            // When fiscal month is January, it's a single year
            startFiscalYear = startDate.year;
            currentFiscalYear = currentDate.year;

            for (let year = startFiscalYear; year <= currentFiscalYear; year++) {
                fiscalYears.push({ name: year, label: `${year}` });
            }
        } else {
            // Normal fiscal year spanning two calendar years
            startFiscalYear = startDate.month >= fymonth ? startDate.year : startDate.year - 1;
            currentFiscalYear = currentDate.month >= fymonth ? currentDate.year : currentDate.year - 1;

            for (let year = startFiscalYear; year <= currentFiscalYear; year++) {
                const label = `${year}-${(year + 1).toString().slice(-2)}`;
                fiscalYears.push({ name: year + 1, label });
            }

            // Include the current fiscal year only if the current month is before the fiscal year start month
            if (currentDate.month < fymonth) {
                fiscalYears.pop();
            }
        }

        return fiscalYears;
    }
    const updateLocationFilter = (obj, val) => {
        let item = { ...filter, [obj]: val }
        let selected_item = { country: 0, city: 0, location: 0 }
        let country_list = [{ name: 'All Countries', id: 0 }]
        let city_list = [{ name: 'All Regions', id: 0 }]
        let location_list = [{ name: 'All Business Unit', id: 0 }]
        rawsitelist.forEach((country) => {
            country_list.push({ name: country.name, id: country.id })
            if (country.id === item.country || item.country === 0) {
                if (country.locationTwos) {
                    country.locationTwos.forEach((city) => {
                        city_list.push({ name: city.name, id: city.id })
                        if (city.id === item.city || item.city === 0) {
                            if (city.locationThrees) {
                                city.locationThrees.forEach((site) => {
                                    location_list.push({ name: site.name, id: site.id })

                                })
                            }
                        }
                    })

                }

            }

        })
        if (obj === 'country') {
            item.city = val === 0 ? null : 0
            item.location = null
        }
        else if (obj === 'city') {

            item.location = val === 0 ? null : 0
        }else if (obj=== 'section'){
            const curatedIndicators = Array.from(new Set(Object.entries(tvssection).flatMap(i => ([8, 9, 10, 11, 12, 13, 14, 15].includes(val)) ? i[1] : [])))
console.log(curatedIndicators)
            setGoals(allgoals.filter(i => i.newIndicatorTwos && i.newIndicatorTwos.some(x => curatedIndicators.includes(x.indicator)) ) )
           item[obj]=val
        }

        setFilter(item)

        setLocList((prev) => ({ ...prev, 'country': country_list, 'city': city_list, 'location': location_list }))
        renderData(quantitativesubmission, item)
    }
    const getEmissionFactorCalculation = (item, year) => {

        let betweenMonths = getBetweenMonths(yearoptions.find(i => i.name === year).label, fymonth)
        let month_data = []
        let scope = 0
        if (item.dcfId === 11) {

            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {

                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                console.log('Matched_SC', standard_index)
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {
                        if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {
                            console.log('Dates_SC', dates)

                            let date_index = findIndexByDate(apief[standard_index].newEfDates, item.reporting_period[0])

                            if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {
                                let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === 1 })
                                console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                    let sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPA0130 && i.subcategory2 === sc.DPA0131 && i.subcategory3 === sc.DPA0132 })
                                    console.log(sc_index, sc)
                                    if (sc_index !== -1) {
                                        let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                        let ghg = parseFloat((((parseFloat(sc.DPA0336) * ef.co2e)) / 1000).toFixed(3))
                                        console.log(sc.DPA0336, ef)
                                        let fuel_type_index = subcat.two.findIndex((i) => { return i.id === sc.DPA0131 })
                                        let unit_index = subcat.three.findIndex((i) => { return i.id === sc.DPA0132 })
                                        let fuel_cat_index = subcat.one.findIndex((i) => { return i.id === sc.DPA0130 })
                                        let unit_type = 'NA', cat_type = 'NA'
                                        let fuel_type = 'Other', usage = 0, r = 0, nr = 0
                                        if (fuel_type_index !== -1) {
                                            fuel_type = subcat.two[fuel_type_index].title
                                        }
                                        if (unit_index !== -1) {
                                            unit_type = subcat.three[fuel_type_index].title
                                        }
                                        if (fuel_cat_index !== -1) {
                                            cat_type = subcat.one[fuel_cat_index].title
                                        }
                                        if (unit_index !== -1) {


                                            if (unit_type.includes('ton')) {
                                                if (fuel_cat_index !== -1) {
                                                    cat_type = subcat.one[fuel_cat_index].title

                                                    if (cat_type.trim().toLowerCase().includes('bio')) {
                                                        r = parseFloat((((parseFloat(sc.DPA0336) * 0.82) * 10800 * 4.187) / 1000000000).toFixed(3))
                                                    } else {

                                                        nr = parseFloat((((parseFloat(sc.DPA0336) * 0.82) * 10800 * 4.187) / 1000000000).toFixed(3))

                                                    }
                                                }
                                                usage = parseFloat((sc.DPA0336 / 0.81).toFixed(3))

                                            } else {
                                                if (fuel_cat_index !== -1) {


                                                    if (cat_type.trim().toLowerCase().includes('bio')) {
                                                        r = parseFloat((((parseFloat(sc.DPA0336) * 0.82) * 10.8 * 4.187) / 1000000000).toFixed(3))
                                                    } else {

                                                        nr = parseFloat((((parseFloat(sc.DPA0336) * 0.82) * 10.8 * 4.187) / 1000000000).toFixed(3))
                                                    }
                                                }
                                                usage = parseFloat(sc.DPA0336)
                                            }
                                        }
                                        scope = 1
                                        sc.efdata = { year: item.reporting_period[0].split('-')[1], ef, scope: 1, ghg, cat_type, unit_type, fuel_type, usage, r, nr }
                                        let month_index = month_data.findIndex((i) => { return i.scope === 1 && i.fuel_type === fuel_type && i.unit_type === unit_type && i.cat_type === cat_type })
                                        // if (month_index === -1) {
                                        month_data.push({ year: item.reporting_period[0].split('-')[1], ef, scope: 1, ghg, cat_type, unit_type, fuel_type, usage, r, nr })
                                        // } else {
                                        //     month_data[month_index].ghg += ghg
                                        //     month_data[month_index].usage += usage
                                        //     month_data[month_index].r += r
                                        //     month_data[month_index].nr += nr
                                        // }

                                    }

                                }
                            }

                        }
                    })
                }
            }

        } else if (item.dcfId === 10) {

            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {
                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {
                        if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {

                            let date_index = findIndexByDate(apief[standard_index].newEfDates, item.reporting_period[0])
                            console.log(date_index, apief[standard_index].newEfDates[date_index].newEfs)
                            if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {
                                let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === 5 })
                                console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                    let sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPA0136 })
                                    console.log(sc_index, sc.DPA0136, apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems)
                                    if (sc_index !== -1) {
                                        console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index], sc)
                                        let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                        let ghg = parseFloat((((parseFloat(sc.DPA0138) * ef.co2e)) / 1000).toFixed(3))
                                        console.log(sc.DPA0138, ef)
                                        let gas_type_index = subcat.one.findIndex((i) => { return i.id === sc.DPA0136 })
                                        let gas_type = 'Other'
                                        if (gas_type_index !== -1) {
                                            gas_type = subcat.one[gas_type_index].title
                                        }
                                        let month_index = month_data.findIndex((i) => { return i.scope === 1 && i.gas_type === gas_type })
                                        // if (month_index === -1) {
                                        scope = 1
                                        sc.efdata = { year: item.reporting_period[0].split('-')[1], ef, scope: 1, ghg, gas_type, usage: parseFloat(sc.DPA0138) }
                                        month_data.push({ year: item.reporting_period[0].split('-')[1], ef, scope: 1, ghg, gas_type, usage: parseFloat(sc.DPA0138) })
                                        // } else {
                                        //     month_data[month_index].ghg += ghg
                                        //     month_data[month_index].usage += parseFloat(sc.DPA0138)
                                        // }

                                    }

                                }
                            }


                        }
                    })
                }
            }

        } else if (item.dcfId === 16) {

            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {
                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {
                        if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {

                            let date_index = findIndexByDate(apief[standard_index].newEfDates, item.reporting_period[0])

                            if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {
                                let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === 11 })
                                console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                    let sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPA0287 })

                                    if (sc_index !== -1) {
                                        let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                        let ghg = parseFloat((((parseFloat(sc.DPA0288) * parseFloat(sc.DPA0289) * ef.co2e)) / 1000).toFixed(3))

                                        let item_class_index = subcat.one.findIndex((i) => { return i.id === sc.DPA0287 })
                                        let item_class = 'Other'
                                        if (item_class_index !== -1) {
                                            item_class = subcat.one[item_class_index].title
                                        }
                                        let month_index = month_data.findIndex((i) => { return i.scope === 1 && i.item_class === item_class })
                                        // if (month_index === -1) {
                                        scope = 1
                                        sc.efdata = { year: item.reporting_period[0].split('-')[1], ef, scope: 1, ghg, item_class }
                                        month_data.push({ year: item.reporting_period[0].split('-')[1], ef, scope: 1, ghg, item_class })
                                        // } else {
                                        //     month_data[month_index].ghg += ghg
                                        // }

                                    }

                                }
                            }


                        }
                    })
                }
            }

        } else if (item.dcfId === 15) {

            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {
                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {
                        if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {

                            let date_index = findIndexByDate(apief[standard_index].newEfDates, item.reporting_period[0])

                            if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {
                                if (!sc.DP_MODE) {
                                    let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === 1 })
                                    console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                    if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                        let sc_index = -1
                                        if (sc.DPA0141 === 2) {
                                            sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === 1 && i.subcategory2 === sc.DPA0141 && i.subcategory3 === 7 })
                                        } else if (sc.DPA0141 === 13) {
                                            sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === 4 && i.subcategory2 === sc.DPA0141 && i.subcategory3 === 51 })
                                        } else if (sc.DPA0141 === 19) {
                                            sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === 4 && i.subcategory2 === sc.DPA0141 && i.subcategory3 === 75 })

                                        }
                                        if (sc_index !== -1) {
                                            let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                            let ghg = parseFloat((((parseFloat(sc.DPA0143) * ef.co2e)) / 1000).toFixed(3))

                                            let fuel_type_index = subcat.two.findIndex((i) => { return i.id === sc.DPA0141 })
                                            let fuel_type = 'Other'
                                            if (fuel_type_index !== -1) {
                                                fuel_type = subcat.two[fuel_type_index].title
                                            }
                                            let month_index = month_data.findIndex((i) => { return i.scope === 1 && i.type === 'byFuel' && i.fuel_type === fuel_type })
                                            // if (month_index === -1) {
                                            scope = 1
                                            sc.efdata = { year: item.reporting_period[0].split('-')[1], ef, scope: 1, ghg, fuel_type, type: 'byFuel' }
                                            month_data.push({ year: item.reporting_period[0].split('-')[1], ef, scope: 1, ghg, fuel_type, type: 'byFuel' })
                                            // } else {
                                            //     month_data[month_index].ghg += ghg
                                            // }

                                        }

                                    }
                                } else {
                                    let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === sc.DPGMode })
                                    console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                    if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                        let sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPA0140 && i.subcategory2 === sc.DPA0139 && i.subcategory3 === sc.DPA0141 && i.subcategory4 === sc.DPA0339 })

                                        if (sc_index !== -1) {
                                            let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                            let ghg = parseFloat((((parseFloat(sc.DPA0144) * ef.co2e)) / 1000).toFixed(3))

                                            let fuel_type_index = subcat.two.findIndex((i) => { return i.id === sc.DPA0139 })
                                            let fuel_type = 'Other'
                                            if (fuel_type_index !== -1) {
                                                fuel_type = subcat.two[fuel_type_index].title
                                            }
                                            let month_index = month_data.findIndex((i) => { return i.scope === 1 && i.fuel_type === fuel_type && i.type === 'byDistance' })
                                            // if (month_index === -1) {
                                            scope = 1
                                            sc.efdata = { year: item.reporting_period[0].split('-')[1], ef, scope: 1, ghg, fuel_type, type: 'byDistance' }
                                            month_data.push({ year: item.reporting_period[0].split('-')[1], ef, scope: 1, ghg, fuel_type, type: 'byDistance' })
                                            // } else {
                                            //     month_data[month_index].ghg += ghg
                                            // }

                                        }

                                    }
                                }

                            }


                        }
                    })
                }
            }

        } else if (item.dcfId === 257) {

            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {
                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {

                        if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {

                            let date_index = findIndexByDate(apief[standard_index].newEfDates, item.reporting_period[0])
                            console.log(sc, item.id)
                            if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {
                                let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i, j) => { return j === 0 })
                                console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                    let sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPAN095 && i.subcategory2 === sc.DPAN096 && i.subcategory3 === sc.DPAN099 })
                                    console.log(sc_index)
                                    if (sc_index !== -1) {
                                        let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                        let ghg = 0

                                        console.log(sc.DPA0138, ef)
                                        let source_index = subcat.two.findIndex((i) => { return i.id === sc.DPAN096 })
                                        let type_index = subcat.one.findIndex((i) => { return i.id === sc.DPAN095 })
                                        let type = 'Other', source_type = 'Other', r = 0, nr = 0
                                        if (type_index !== -1) {
                                            type = subcat.one[type_index].title
                                        }
                                        if (source_index !== -1) {

                                            source_type = subcat.two[source_index].title
                                            console.log(source_type, sc.DPAN096)
                                            if (source_type.trim().toLowerCase().includes('non')) {
                                                ghg = parseFloat((((parseFloat(sc.DPAN098) * ef.co2e)) / 1000).toFixed(3))
                                                nr = parseFloat(sc.DPAN098 / 1000)
                                            } else {
                                                r = parseFloat(sc.DPAN098 / 1000)
                                            }
                                        }
                                        let month_index = month_data.findIndex((i) => { return i.scope === 2 && i.source_type === source_type && i.type === type })
                                        // if (month_index === -1) {
                                        scope = 2
                                        sc.efdata = { year: item.reporting_period[0].split('-')[1], ef, scope: 2, ghg, type, source_type, r, nr }
                                        month_data.push({ year: item.reporting_period[0].split('-')[1], ef, scope: 2, ghg, type, source_type, r, nr })
                                        // } else {
                                        //     month_data[month_index].ghg += ghg
                                        //     month_data[month_index].r += r
                                        //     month_data[month_index].nr += nr
                                        // }

                                    } else {

                                        if (subcat.one.map(i => i.id).includes(sc.DPAN095) && subcat.two.map(i => i.id).includes(sc.DPAN096) && subcat.three.map(i => i.id).includes(sc.DPAN099)) {
                                            let source_type = '', r = 0, nr = 0, type = ''
                                            let source_index = subcat.two.findIndex((i) => { return i.id === sc.DPAN096 })
                                            let type_index = subcat.one.findIndex((i) => { return i.id === sc.DPAN095 })
                                            type = subcat.one[type_index].title
                                            source_type = subcat.two[source_index].title
                                            if (!source_type.trim().toLowerCase().includes('non')) {
                                                r = parseFloat(sc.DPAN098 / 1000)
                                            }
                                            let month_index = month_data.findIndex((i) => { return i.scope === 2 && i.source_type === source_type && i.type === type })
                                            // if (month_index === -1) {
                                            scope = 2
                                            sc.efdata = { year: item.reporting_period[0].split('-')[1], ef: {}, scope: 2, ghg: 0, type, source_type, r, nr }
                                            month_data.push({ year: item.reporting_period[0].split('-')[1], ef: {}, scope: 2, ghg: 0, type, source_type, r, nr })
                                            // } else {
                                            //     month_data[month_index].ghg += 0
                                            //     month_data[month_index].r += r
                                            //     month_data[month_index].nr += nr
                                            // }
                                        }
                                    }

                                }
                            }


                        }
                    })
                }
            }

        } else if (item.dcfId === 15) {
            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {
                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {
                        if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {

                            let date_index = findIndexByDate(apief[standard_index].newEfDates, item.reporting_period[0])
                            console.log(date_index, apief[standard_index].newEfDates[date_index].newEfs)
                            if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {

                                if (sc.DP_MODE === true) {
                                    let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === sc.DPGMode })
                                    console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                    if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                        let sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPA0140 && i.subcategory2 === sc.DPA0139 && i.subcategory3 === sc.DPA0141 && i.subcategory4 === sc.DPA0339 })
                                        console.log(sc_index)
                                        if (sc_index !== -1) {
                                            console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index], sc)
                                            let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                            let ghg = parseFloat((((parseFloat(sc.DPA0144) * ef.co2e)) / 1000).toFixed(3))

                                            scope = 1
                                            sc.efdata = { year: item.reporting_period[0].split('-')[1], ef, scope: 1, ghg, usage: parseFloat(sc.DPA0144) }
                                            month_data.push({ year: item.reporting_period[0].split('-')[1], ef, scope: 1, ghg, usage: parseFloat(sc.DPA0144) })

                                        }

                                    }
                                } else {
                                    let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === 1 })

                                    if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                        let sc_index = -1
                                        if (sc.DPA0141 === 2) {
                                            sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === 1 && i.subcategory2 === 2 && i.subcategory3 === 7 })
                                        } else if (sc.DPA0141 === 13) {
                                            sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === 4 && i.subcategory2 === sc.DPA0141 && i.subcategory3 === 51 })
                                        } else {
                                            sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === 4 && i.subcategory2 === sc.DPA0141 && i.subcategory3 === 75 })
                                        }
                                        if (sc_index !== -1) {

                                            let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                            let ghg = parseFloat((((parseFloat(sc.DPA0143) * ef.co2e)) / 1000).toFixed(3))
                                            scope = 1
                                            sc.efdata = { year: item.reporting_period[0].split('-')[1], ef, scope: 1, ghg, usage: parseFloat(sc.DPA0143) }
                                            month_data.push({ year: item.reporting_period[0].split('-')[1], ef, scope: 1, ghg, usage: parseFloat(sc.DPA0143) })

                                        }


                                    }

                                }
                            }


                        }
                    })
                }
            }
        } else if (item.dcfId === 36) {
            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {
                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {
                        if (sc.DPA0291 === 'Road') {
                            if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {

                                let date_index = findIndexByDate(apief[standard_index].newEfDates, item.reporting_period[0])
                                console.log(date_index, apief[standard_index].newEfDates[date_index].newEfs)
                                if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {
                                    let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === 6 })
                                    console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                    if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                        let sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPA0295 && i.subcategory2 === sc.DPA0337 && i.subcategory3 === sc.DPA0338 && i.subcategory4 === sc.DPA0338G })
                                        console.log(sc_index, apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems)
                                        if (sc_index !== -1) {
                                            console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index], sc)
                                            let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                            let ghg = parseFloat((((parseFloat(sc.DPA0292) * ef.co2e)) / 1000).toFixed(3))


                                            scope = 3
                                            sc.efdata = { year: item.reporting_period[0].split('-')[1], ef, scope: 3, ghg, usage: parseFloat(sc.DPA0292) }
                                            month_data.push({ year: item.reporting_period[0].split('-')[1], ef, scope: 3, ghg, usage: parseFloat(sc.DPA0292) })


                                        }

                                    }
                                }


                            }
                        } else if (sc.DPA0291 === 'Air') {
                            scope = 3
                            let ghg = sc.DP_co2e_mt
                            month_data.push({ year: item.reporting_period[0].split('-')[1], scope: 3, ghg })

                        } else if (sc.DPA0291 === 'Rail') {
                            scope = 3
                            let ghg = parseFloat(sc.DPA0292) * sc.DP_KM * 0.00116
                            month_data.push({ year: item.reporting_period[0].split('-')[1], scope: 3, ghg })

                        }

                    })
                }
            }
        } else if (item.dcfId === 292) {
            console.log(item)
            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {
                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {
                        if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {
                            dates.forEach((rpm) => {
                                let date_index = findIndexByDate(apief[standard_index].newEfDates, rpm)

                                if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {

                                    let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === 42 })
                                    console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                    if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                        let sc_index = -1

                                        if (sc.DPAN1150 === 3) {
                                            sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPAN1151 && i.subcategory2 === sc.DPAN1152 && i.subcategory3 === sc.DPAN1155 })
                                        } else {
                                            if (sc.DPAN1151 === 316 || sc.DPAN1151 === 317) {
                                                sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPAN1151 && i.subcategory2 === sc.DPAN1152 && i.subcategory3 === sc.DPAN1154 && i.subcategory4 === sc.DPAN1155 })
                                            } else {
                                                sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPAN1151 && i.subcategory2 === sc.DPAN1152 && i.subcategory3 === sc.DPAN1153 && i.subcategory4 === sc.DPAN1155 })
                                            }
                                        }

                                        const mode_options = [{ name: 'Road', id: 1 }, { name: 'Air', id: 2 }, { name: 'Rail', id: 3 }, { name: 'Sea', id: 4 }]
                                        if (sc_index !== -1) {
                                            scope = 3
                                            let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                            let ghg = parseFloat((((parseFloat(sc.DPAN1156) * parseFloat(sc.DPAN1157) * ef.co2e) / dates.length) / 1000).toFixed(3))

                                            sc.efdata = { year: item.reporting_period[0].split('-')[1], ef, scope: 3, ghg, usage: 0 }
                                            month_data.push({ year: item.reporting_period[0].split('-')[1], ef, scope: 3, ghg, usage: 0 })

                                        }

                                    }


                                }

                            })
                        }
                    })
                }
            }

        } else if (item.dcfId === 293) {
            console.log(item)
            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {
                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {
                        if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {
                            dates.forEach((rpm) => {
                                let date_index = findIndexByDate(apief[standard_index].newEfDates, rpm)

                                if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {

                                    let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === 42 })
                                    console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                    if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                        let sc_index = -1

                                        if (sc.DPAN1208 === 3) {
                                            sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPAN1209 && i.subcategory2 === sc.DPAN1210 && i.subcategory3 === sc.DPAN1213 })
                                        } else {
                                            if (sc.DPAN1209 === 316 || sc.DPAN1209 === 317) {
                                                sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPAN1209 && i.subcategory2 === sc.DPAN1210 && i.subcategory3 === sc.DPAN1212 && i.subcategory4 === sc.DPAN1213 })
                                            } else {
                                                sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPAN1209 && i.subcategory2 === sc.DPAN1210 && i.subcategory3 === sc.DPAN1211 && i.subcategory4 === sc.DPAN1213 })
                                            }
                                        }

                                        const mode_options = [{ name: 'Road', id: 1 }, { name: 'Air', id: 2 }, { name: 'Rail', id: 3 }, { name: 'Sea', id: 4 }]
                                        if (sc_index !== -1) {
                                            scope = 3
                                            let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                            let ghg = parseFloat((((parseFloat(sc.DPAN1214) * parseFloat(sc.DPAN1215) * ef.co2e) / dates.length) / 1000).toFixed(3))

                                            sc.efdata = { year: item.reporting_period[0].split('-')[1], ef, scope: 3, ghg, usage: 0 }
                                            month_data.push({ year: item.reporting_period[0].split('-')[1], ef, scope: 3, ghg, usage: 0 })


                                        }

                                    }


                                }

                            })
                        }
                    })
                }
            }

        }

        item.ghg = month_data.map(i => i.ghg).reduce((a, b) => a + b, 0)
        item.scope = scope

        // DPA0292

        return month_data
    }
    const getFiscalYearRange = (year, fymonth) => {
        let startDate, endDate;
        console.log(fymonth)

        if (fymonth === 1) {
            startDate = DateTime.fromObject({ year, month: 1, day: 1 }).startOf('day');
            endDate = DateTime.fromObject({ year, month: 12, day: 31 }).endOf('day');
        } else {
            startDate = DateTime.fromObject({ year: year - 1, month: fymonth, day: 1 }).startOf('day');
            endDate = DateTime.fromObject({ year, month: fymonth - 1, day: 1 }).endOf('month');
        }

        return { startDate, endDate };
    };
    const isDateWithinPeriod = (rp, period) => {
        // Convert date strings to Luxon DateTime objects
        const rpDates = rp.map(dateString => DateTime.fromFormat(dateString, 'MM-yyyy'));
        const periodDates = period.map(dateString => DateTime.fromFormat(dateString, 'MM-yyyy'));

        // Find the matching dates in rp within the period
        const matchingDates = rpDates.filter(rpDate =>
            periodDates.some(periodDate => rpDate.toMillis() === periodDate.toMillis())
        );

        return matchingDates.length > 0 ? { match: true, dates: matchingDates.map(date => date.toFormat('MM-yyyy')) } : { match: false, dates: null };
    };
    const getBetweenMonths = (year, fymonth) => {
        const months = [];

        // Handle year input in the format "YYYY" or "YYYY-YY"
        let startYear;
        let endYear;
        if (year.includes('-')) {
            [startYear, endYear] = year.split('-').map(part => parseInt(part));
            startYear = parseInt(startYear);
            endYear = endYear.length === 2 ? parseInt(`20${endYear}`) : parseInt(endYear); // Handle two-digit year
        } else {
            startYear = parseInt(year);
            endYear = startYear;
        }

        // Case 1: FY starts in January
        if (fymonth === 1) {
            for (let i = 1; i <= 12; i++) {
                const month = DateTime.fromObject({ year: endYear, month: i });
                const formattedMonth = month.toFormat('MM-yyyy');
                months.push(formattedMonth);
            }
        } else {
            // Case 2: FY starts in a month other than January
            for (let i = fymonth; i <= 12; i++) {
                const month = DateTime.fromObject({ year: startYear, month: i });
                const formattedMonth = month.toFormat('MM-yyyy');
                months.push(formattedMonth);
            }
            for (let i = 1; i < fymonth; i++) {
                const month = DateTime.fromObject({ year: endYear, month: i });
                const formattedMonth = month.toFormat('MM-yyyy');
                months.push(formattedMonth);
            }
        }

        return months;
    }
    const findIndexByDate = (array, dateToFind) => {
        const targetDate = DateTime.fromFormat(dateToFind, 'MM-yyyy');

        if (array.length === 1) {
            return 0; // If array length is 1, return index 0
        }

        let foundIndex = -1;

        // Check for an object with both start and end not null and matching the passed date
        const matchedObject = array.find(obj => {
            const startDate = DateTime.fromISO(obj.start);
            const endDate = DateTime.fromISO(obj.end);

            return startDate <= targetDate && targetDate <= endDate;
        });

        if (matchedObject) {
            return array.indexOf(matchedObject);
        }

        // Check for an object with end as null and start date lesser than passed date
        const endIsNullObject = array.find(obj => {
            const startDate = DateTime.fromISO(obj.start);
            return obj.end === null && startDate <= targetDate;
        });

        if (endIsNullObject) {
            return array.indexOf(endIsNullObject);
        }

        // If none of the above conditions match, find the object with the lesser start date
        let lesserStartDateObject = array.reduce((prev, curr) => {
            const prevStartDate = DateTime.fromISO(prev.start);
            const currStartDate = DateTime.fromISO(curr.start);
            return prevStartDate < currStartDate ? prev : curr;
        });

        return array.indexOf(lesserStartDateObject);
    };
    function isDateInFiscalYear(year, dateString) {
        const { start, end } = parseDateString(dateString);
        let curYear = year
        if (fymonth !== 1) {
            curYear = year - 1

        }
        const startDate = DateTime.fromFormat(start.trim(), 'MMM-yyyy');
        const endDate = DateTime.fromFormat(end.trim(), 'MMM-yyyy');
        const fiscalYearStart = DateTime.fromObject({ year: curYear, month: fymonth, day: 1 }); // April 1 of the previous year
        const fiscalYearEnd = DateTime.fromObject({ year: year, month: fymonth - 1 || 12, day: DateTime.fromObject({ year: year, month: fymonth - 1 || 12 }).daysInMonth }); // March 31 of the given year
        console.log((startDate >= fiscalYearStart && startDate <= fiscalYearEnd) ||
            (endDate >= fiscalYearStart && endDate <= fiscalYearEnd) ||
            (startDate <= fiscalYearStart && endDate >= fiscalYearEnd), dateString, curYear, year)
        return (
            (startDate >= fiscalYearStart && startDate <= fiscalYearEnd) ||
            (endDate >= fiscalYearStart && endDate <= fiscalYearEnd) ||
            (startDate <= fiscalYearStart && endDate >= fiscalYearEnd)
        );
    }
    function parseDateString(dateString) {
        if (dateString.includes('to')) {
            const [start, end] = dateString.split('to');
            return { start, end };
        } else {
            return { start: dateString, end: dateString };
        }
    }
    const renderData = (submission, filter) => {
        let filteredSubmissions = filterDataByTierAndLocationByLevel(submission.filter(i => isDateInFiscalYear(filter.year, getRPTextFormat(i.reporting_period))), rawsitelist, filter.country, filter.city, filter.location)

        let scope1_2 = 0, scope3 = 0
        let totalScope1 = [{ name: 'Stationary Combustion', value: 0 }, { name: 'Fugitive', value: 0 }, { name: 'Mobile Combustion', value: 0 }]
        let months = generateMTDArray(filter.year, fymonth).map(i => ({ month: i, renewable: 0, nonrenewable: 0 }))
        let months_ = generateMTDArray(filter.year, fymonth).map(i => ({ month: i, stationary: 0, fugitive: 0, mobile: 0 }))
        let months__ = generateMTDArray(filter.year, fymonth).map(i => ({ month: i, data: filteredSubmissions.filter(x => { return getRPTextFormat(x.reporting_period) === i && (x.dcfId === 246 || x.dcfId === 247) }) }))


        for (const sub of filteredSubmissions) {
            if (sub.dcfId === 10 || sub.dcfId === 11 || sub.dcfId === 15) {
                let calcValue = Number(getEmissionFactorCalculation(sub, filter.year).map(i => i.ghg).reduce((a, b) => a + b, 0).toFixed(2))
                let monthindex = months_.findIndex(i => i.month === getRPTextFormat(sub.reporting_period))

                if (sub.dcfId === 11) {
                    totalScope1[0].value += calcValue
                    if (monthindex !== -1) {
                        months_[monthindex].stationary += calcValue
                    }
                } else if (sub.dcfId === 10) {
                    totalScope1[1].value += calcValue
                    if (monthindex !== -1) {
                        months_[monthindex].fugitive += calcValue
                    }
                } else if (sub.dcfId === 15) {
                    totalScope1[2].value += calcValue
                    if (monthindex !== -1) {
                        months_[monthindex].mobile += calcValue
                    }
                }
                scope1_2 += calcValue
            } else if (sub.dcfId === 36 || sub.dcfId === 16) {
                scope3 += getEmissionFactorCalculation(sub, filter.year).map(i => i.ghg).reduce((a, b) => a + b, 0)
            } else if (sub.dcfId === 257) {
                let calcdata = getEmissionFactorCalculation(sub, filter.year)

                let monthindex = months.findIndex(i => i.month === getRPTextFormat(sub.reporting_period))
                if (monthindex !== -1) {
                    months[monthindex].renewable += calcdata.map(i => i.r).reduce((a, b) => a + b, 0)
                    months[monthindex].nonrenewable += calcdata.map(i => i.nr).reduce((a, b) => a + b, 0)
                } else {
                    console.log(months.map(i => getRPTextFormat([i.month])), getRPTextFormat(sub.reporting_period))
                }

            }

        }
        console.log(months_)

        setGoalProps((prev) => ({ ...prev, waterData: months__, totalScope1, monthArray: months, totalScope1Breakup: months_, scope2Data: months, scope1_2: scope1_2.toFixed(2), scope3: scope3.toFixed(2) }))
        forceUpdate()
    }
    const getRPTextFormat = (item) => {
        if (item.length !== 0) {
            if (item.length >= 2) {
                const startDate = DateTime.fromFormat(item[0], "MM-yyyy").toFormat(
                    "LLL-yyyy"
                );
                const endDate = DateTime.fromFormat(
                    item[item.length - 1],
                    "MM-yyyy"
                ).toFormat("LLL-yyyy");
                return `${startDate} to ${endDate}`;
            } else {
                return DateTime.fromFormat(item[0], "MM-yyyy").toFormat("LLL-yyyy");
            }
        }
    };

    const getValidTierIds = (locationData, tier1_id, tier2_id, tier3_id) => {
        const countries = new Set();
        const regions = new Set();
        const businessUnits = new Set();

        locationData.forEach(country => {
            if (tier1_id === 0 || tier1_id === country.id) {
                countries.add(country.id);

                country.locationTwos.forEach(region => {
                    if (tier2_id === 0 || tier2_id === region.id) {
                        regions.add(region.id);

                        region.locationThrees.forEach(businessUnit => {
                            if (tier3_id === 0 || (tier2_id === 0 && tier3_id === null) || tier3_id === businessUnit.id) {
                                businessUnits.add(businessUnit.id);
                            }
                        });
                    }
                });
            }
        });

        return { countries: Array.from(countries), regions: Array.from(regions), businessUnits: Array.from(businessUnits) };
    };
    const filterDataByTierAndLocationByLevel = (data, locationData, tier1_id, tier2_id, tier3_id) => {
        if (tier1_id === 0 && tier2_id === null && tier3_id === null) {
            return data; // If tier is 0, null, null return the given data
        }

        const { countries, regions, businessUnits } = getValidTierIds(locationData, tier1_id, tier2_id, tier3_id);

        return data.filter(item => {
            if (tier1_id !== 0 && tier2_id === 0 && tier3_id === null) {
                // Case when we want all regions and sites under a country
                return (item.level === 1 && countries.includes(item.locationId)) ||
                    (item.level === 2 && regions.includes(item.locationId)) ||
                    (item.level === 3 && businessUnits.includes(item.locationId));
            } else if (tier1_id !== 0 && tier2_id !== 0 && tier3_id === 0) {
                // Case when we want a specific region and all its sites
                return (item.level === 2 && regions.includes(item.locationId)) ||
                    (item.level === 3 && businessUnits.includes(item.locationId));
            } else if (tier1_id !== 0 && tier2_id !== 0 && tier3_id !== 0) {
                // Case when we want a specific site
                return item.level === 3 && businessUnits.includes(item.locationId);
            } else {
                // Case when we want only the specific country
                return item.level === 1 && countries.includes(item.locationId);
            }
        });
    };
    return (
        <>
            <div className="col-12 md:col-12" style={{

                textAlign: 'center',
                textDecorationColor: 'green'
            }}>
                <h5> {`Performance Dashboard`} </h5>
            </div>
            <div className="tabs-container">
                <div className='flex justify-content-between'>

                    <div className='col-3 grid m-0 align-items-center' >
                        <div className='col-3'>Year :</div>
                        <div className='grid m-0 col-9' >
                            <div className='col-12' >

                                <Dropdown value={filter.year} options={yearoptions} onChange={(e) => { updateLocationFilter('year', e.value) }} optionLabel='label' optionValue='name' placeholder="Select Year" style={{ width: '100%' }} />
                            </div>

                        </div>
                    </div>
                    <div className='col-3 grid m-0 align-items-center' >
                        <div className='col-3'>Section :</div>
                        <div className='grid m-0 col-9' >
                            <div className='col-12' >
                                <Dropdown value={filter.section} options={section} onChange={(e) => { updateLocationFilter('section',e.value) }} optionLabel='name' optionValue='id' placeholder="Select Section" style={{ width: '100%' }} />
                            </div>

                        </div>
                    </div>
                    <div className='col-6 grid m-0 align-items-center' >
                        <div className='col-2'>Location :</div>
                        <div className='grid m-0 col-10 justify-content-start' >
                            <div className='col-4' >
                                <Dropdown value={filter.country} options={locList.country} onChange={(e) => { updateLocationFilter('country', e.value) }} optionLabel='name' optionValue='id' placeholder="Select Country" style={{ width: '100%' }} />
                            </div>
                            {(filter.country !== 0 && (filter.city !== null || filter.city === 0)) && <div className='col-4' >
                                <Dropdown value={filter.city} options={locList.city} onChange={(e) => { updateLocationFilter('city', e.value) }} optionLabel='name' optionValue='id' placeholder="Select Region" disabled={!locList.city.length} style={{ width: '100%' }} />
                            </div>}
                            {(filter.city !== 0 && filter.location !== null) && <div className='col-4' >
                                <Dropdown value={filter.location} options={locList.location} onChange={(e) => { updateLocationFilter('location', e.value) }} optionLabel='name' optionValue='id' placeholder="Select Business Unit" disabled={!locList.location.length} style={{ width: '100%' }} />
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
            <TabMenu activeIndex={activeIndex} onTabChange={(e) => { setActiveIndex(e.index) }} model={[{ label: 'My Goals', icon: 'pi pi-home' },
            { label: 'My KPI', icon: 'pi pi-chart-line' },]} />
            {activeIndex === 0 &&
                <EnvironmentGoals goalslist={goals} emissions={goalprops} year={filter.year} fymonth={fymonth} />
            }
            {activeIndex === 1 &&
                <EnvironmentMetrics year={filter.year} fymonth={fymonth} headers={[]} />
            }

        </>
    );

}

export default TVSPerformanceDashboard;
