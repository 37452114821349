import React, { useEffect, useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { EditText } from "react-edit-text";
import EdiText from 'react-editext'
import { AutoComplete } from "primereact/autocomplete";
import Axios from "axios";
import { SplitButton } from "primereact/splitbutton";
import { MultiSelect } from 'primereact/multiselect';
import Swal from "sweetalert2";
import { Chips } from 'primereact/chips';
import useForceUpdate from "use-force-update";
import { useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from 'primereact/inputtextarea';
import { Tooltip } from 'primereact/tooltip';
import { ContextMenu } from 'primereact/contextmenu';
import { Dropdown } from "primereact/dropdown";
import { API } from "../../constants/api_url";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import { Checkbox } from "primereact/checkbox";
import APIServices from "../../service/APIService";
const { DateTime } = require('luxon')
const ClientSectionConfiguration = () => {

    const [clientlist, setClientList] = useState([])
    const [indicatorlist, setIndicatorList] = useState([])
    const [option, setOption] = useState({ userProfileId: null, sectionId: null })
    const [sectionobj, setSectionObj] = useState({ userProfileId: null, metric_ids: [], id: null, title: '' })
    const [submitted, setSubmitted] = useState(false)
    const [sectionlist, setSectionList] = useState([])
    const [sectiondialog, setSectionDialog] = useState(false)
    const [newsectiondialog, setNewSectionDialog] = useState(false)
    const [section, setSection] = useState('')
    const admin_data = useSelector((state) => state.user.admindetail);
    const login_data = useSelector((state) => state.user.userdetail);

    useEffect(() => {
        const promise0 = APIServices.get(API.UserProfile)


        Promise.all([promise0]).then((values) => {
            setClientList(values[0].data.filter(x => x.role === 'clientadmin'))
        })

    }, [])

    const updateSection = (val) => {


        APIServices.get(API.IndicatorSection_UP(val)).then((res) => {
            setSectionList(res.data)
            setOption(prev => ({ ...prev, sectionId: null, userProfileId: val }))

        }).catch((e) => {
            setSectionList([])
            setOption(prev => ({ ...prev, sectionId: null, userProfileId: val }))

        })

    }
    const saveSection = () => {
        let dt = DateTime.utc()
        setSubmitted(true)
        if (sectionobj.userProfileId) {
            if (sectionobj.id && sectionobj.title) {
                APIServices.patch(API.IndicatorSection_Edit(sectionobj.id), { title: sectionobj.title, modified_on: dt, modified_by: login_data.id }).then((res) => {
                    let loc = JSON.parse(JSON.stringify(sectionlist))
                    let index = loc.findIndex(i => i.id === sectionobj.id)
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: `Section Title Updated successfully`,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    if (index !== -1) {
                        loc[index] = { ...loc[index], title: sectionobj.title, modified_on: dt, modified_by: login_data.id }
                        setSectionList(loc)
                        setSectionDialog(false)

                    }

                })
            } else if (sectionobj.title) {
                APIServices.post(API.IndicatorSection_UP(option.userProfileId), { title: sectionobj.title, created_on: dt, created_by: login_data.id, metric_ids: [] }).then((res) => {
                    let loc = JSON.parse(JSON.stringify(sectionlist))
                    loc.push(res.data)
                    setSectionList(loc)
                    setSectionDialog(false)



                })
            }
        }
    }
    const addNewSection = () => {
        let dt = DateTime.utc()

        setSubmitted(true)
        if (section) {
            APIServices.post(API.IndicatorSection_UP(option.userProfileId), { title: section, created_on: dt, created_by: login_data.id, metric_ids: [] }).then((res) => {
                let loc = JSON.parse(JSON.stringify(sectionlist))
                loc.push(res.data)
                setSectionList(loc)
                setNewSectionDialog(false)
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: `New Section Added successfully`,
                    showConfirmButton: false,
                    timer: 1500,
                });



            })
        }
    }
    const mapIndicators = () => {
        let dt = DateTime.utc()

        if (option.userProfileId && option.sectionId && sectionobj.id) {
            APIServices.patch(API.IndicatorSection_Edit(sectionobj.id), { metric_ids: sectionobj.metric_ids, modified_on: dt, modified_by: login_data.id }).then((res) => {
                let loc = JSON.parse(JSON.stringify(sectionlist))
                let index = loc.findIndex(i => i.id === sectionobj.id)
                if (index !== -1) {
                    loc[index] = { ...loc[index], metric_ids: sectionobj.metric_ids, modified_on: dt, modified_by: login_data.id }

                    setSectionObj({ ...loc[index], metric_ids: sectionobj.metric_ids, modified_on: dt, modified_by: login_data.id })


                }
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: `Indicator Mapped to Selected Section Successfully`,
                    showConfirmButton: false,
                    timer: 1500,
                });

            })
        }
    }
    const updateIndicatorList = (val) => {

        setOption((prev) => ({ ...prev, sectionId: val }))
        if (option.userProfileId) {
            APIServices.post(API.IndicatorBySectionId(option.userProfileId), { sectionId: val }).then((res) => {
                if (res.data.status) {
                    setSectionObj(res.data.assignment)
                    setIndicatorList(() => res.data.available)
                } else {
                    setIndicatorList([])
                }
            })
        }
    }
    const topicOptionTemplate = (option) => {

        return (
            <div className="col-12 grid">
                <div className="col-10">{option.title}</div>
                <i className="material-icons col-1" style={{ zIndex: 9 }} onClick={(e) => { e.stopPropagation(); setSectionObj(option); setSectionDialog(true) }}>edit</i>
            </div>
        );
    };
    return (
        <div className="col-12">
            {login_data.role === 'eisqradmin' ?
                <div className="card" style={{ height: 'calc(100vh - 100px)', overflow: 'auto' }}>
                    <h3> Section Configuration for Client </h3>
                    <div className="grid m-0 p-0">
                        <div className="col-7">
                            <div>
                                <div className="col-12 grid align-items-center justify-content-between">
                                    <div className="col-12 grid align-items-center">
                                        <label className="col-2 ">
                                            Select Client <span style={{ color: 'red' }}>*</span>
                                        </label>
                                        <div className="col-8">
                                            <Dropdown disabled={clientlist.length === 0} options={clientlist} style={{ width: '100%' }} filter optionLabel="information.companyname" optionValue="id" value={option.userProfileId} onChange={(e) => { updateSection(e.value) }} />

                                        </div>

                                    </div>

                                </div>
                                {option.userProfileId !== null &&
                                    <div className="col-12 grid align-items-center justify-content-between">
                                        <div className="col-12 grid align-items-center">
                                            <label className="col-2 ">
                                                Select Section <span style={{ color: 'red' }}>*</span>
                                            </label>
                                            <div className="col-8">
                                                <Dropdown style={{ width: '100%' }} itemTemplate={topicOptionTemplate} options={sectionlist} value={option.sectionId} optionLabel={'title'} optionValue="id" onChange={(e) => { updateIndicatorList(e.value) }} />


                                            </div>
                                            <div className="col-2" style={{ marginLeft: -10 }}>
                                                <Button
                                                    icon="pi pi-plus"

                                                    className="p-button-success mr-2"
                                                    onClick={() => {
                                                        setSubmitted(false)
                                                        setSection('')
                                                        setNewSectionDialog(true)
                                                    }}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                }
                                {option.userProfileId && option.sectionId &&
                                    <div className="col-12 grid align-items-center justify-content-between">
                                        <div className="col-12 grid align-items-center">
                                            <label className="col-2 ">
                                                Select Indicator(s) to map with <b>{sectionobj?.title || ' selected section'}</b>
                                            </label>
                                            <div className="col-8">
                                                <MultiSelect className={"w-full "} panelClassName={'hidefilter'} display='comma' value={sectionobj.metric_ids} style={{ width: '100%' }} optionValue="id" optionLabel="title" filter options={indicatorlist} onChange={(e) => { setSectionObj((prev) => ({ ...prev, metric_ids: e.value })) }} />

                                            </div>

                                        </div>

                                    </div>


                                }


                            </div>
                        </div>
                        <div className="col-5">
                            <div style={{ height: 'calc(100vh - 230px)', overflow: 'auto' }}>

                                {option.userProfileId && option.sectionId && sectionobj && sectionobj.metric_ids && indicatorlist &&
                                    <div>
                                        <label className="m-1 clr-navy fs-16 fw-6">Mapped indicators against {sectionobj?.title || 'selected section'}  {`(${sectionobj.metric_ids.length})`}</label>
                                        {indicatorlist.filter(i => sectionobj.metric_ids.includes(i.id)).map((item, index) => {

                                            return (
                                                <div className={"m-1 text-three-dot cur-pointer tags" + index}><b>{item.id} - </b> {item.title}
                                                    <Tooltip className="tag-tooltip" target={".tags" + index} position={'left'} autoHide={true}> {Array.isArray(item.data1) && item.data1.length && [item.data1[0].tags1, item.data1[0].tags2, item.data1[0].tags3, item.data1[0].tags4].map((i, j) => {
                                                        if (i.length !== 0) {
                                                            return (
                                                                <>
                                                                    <label style={{ color: 'black', display: 'flex' }}> {
                                                                        j === 0 ? 'Must Have' : j === 1 ? 'Progressive' : 'Advanced'

                                                                    }
                                                                    </label>
                                                                    {
                                                                        i.map((tag, k) => {

                                                                            return (
                                                                                <label style={{ color: 'green' }}>{tag}{k !== i.length - 1 && ','}</label>
                                                                            )

                                                                        })
                                                                    }
                                                                    <div style={{ marginBottom: 10 }} />
                                                                </>
                                                            )
                                                        }
                                                    })} </Tooltip>
                                                </div>
                                            )
                                        })}
                                    </div>

                                }
                            </div>
                        </div>
                    </div>
                    {option.userProfileId && option.sectionId &&
                        <div className="col-12 flex justify-content-end">
                            <Button style={{ width: 120 }} label="Save & Exit" onClick={() => { mapIndicators() }} />

                        </div>
                    }
                </div>
                :
                <div className="card col-12">You have no rights to access this page</div>
            }
            <Dialog
                visible={sectiondialog}
                style={{
                    width: "35%"
                }}
                header={sectionobj.id ? "Edit Section" : "Add Section"}
                modal
                className="p-fluid"

                onHide={() => { setSectionDialog(false) }}
            >
                <div style={{ margin: 10 }}>
                    <text>Section Title</text>

                    <InputText value={sectionobj.title} style={{ padding: 10, margin: 10 }} onChange={(e) => { setSectionObj((prev) => ({ ...prev, title: e.target.value })) }} placeholder="Section title" />

                    {submitted && sectionobj.title.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Section title required
                        </small>
                    )}
                </div>



                <div className="col-12 flex justify-content-end">
                    <Button style={{ width: 120 }} label="Save & Exit" onClick={() => { saveSection() }} />

                </div>
            </Dialog>
            <Dialog
                visible={newsectiondialog}
                style={{
                    width: "50%"
                }}
                header={"Add Section"}
                modal
                className="p-fluid"

                onHide={() => { setNewSectionDialog(false) }}
            >
                <div style={{ margin: 10 }}>
                    <text>Section Title</text>

                    <InputText value={section} style={{ padding: 10, margin: 10 }} onChange={(e) => { setSection(e.target.value) }} placeholder="Section title" />

                    {submitted && section.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Section title required
                        </small>
                    )}
                </div>


                <div className="col-12 flex justify-content-end">
                    <Button style={{ width: 120 }} label="Save & Exit" onClick={() => { addNewSection() }} />

                </div>

            </Dialog>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ClientSectionConfiguration, comparisonFn);
