import React, { useState, useEffect } from "react";
import { API } from "../../constants/api_url";
import APIServices from "../../service/APIService";
import { Tree } from 'primereact/tree';
import { Dropdown } from "primereact/dropdown";
import CheckboxTree from 'react-checkbox-tree';
import { Button } from "primereact/button";
import '../../Styles/react-checkbox-tree.css';
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import useForceUpdate from "use-force-update";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import Swal from "sweetalert2";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Splitter, SplitterPanel } from "primereact/splitter";
import { MultiSelect } from "primereact/multiselect";
const ClientEfCategoryMapping = () => {

    const [efddclientobj, setEfDdClientObj] = useState({ userProfileId: null, tier1_id: null, tier2_id: null, tier3_id: null, efStandardId: null, efCategoryId: null, efGhgCatId: null, efGhgSubCatId: null, selected_ids: [] });
    const [selectedclient, setSelectedClient] = useState(null)
    const [selectedlabel, setSelectedLabel] = useState(['Country', 'City', 'Site']);
    const [rawsitelist, setRawSiteList] = useState([])
    const [selectedlist, setSelectedList] = useState({ country: [], city: [], site: [] })
    const [assignment, setAssignment] = useState([])
    const [stdlist, setStdList] = useState([])
    const [adminlist, setAdminList] = useState([])
    const [catlist, setCatList] = useState([])
    const [ghgcatlist, setGhgCatList] = useState([])
    const [ghgsubcatlist, setGhgSubCatList] = useState([])

    const [subcatlist, setSubCatList] = useState([])

    const [efddlist, setEfDdList] = useState([])
    const [assignlist, setAssignList] = useState([])
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [expanded, setExpanded] = useState([]);

    const selector = useSelector((state) => state.user.admindetail)
    const forceUpdate = useForceUpdate();
    useEffect(() => {

        const promise0 = APIServices.get(API.EF_Std)
        const promise1 = APIServices.get(API.UserProfile)
        Promise.all([promise0, promise1]).then((values) => {
            setStdList(values[0].data)
            setAdminList(values[1].data.filter(i => i.role === 'clientadmin'))

        })
    }, [])
    useEffect(() => {
        console.log(assignment)
    }, [assignment])
    const getTierData = (id) => {
        let sel = efddclientobj;
        sel.id = undefined
        sel.efStandardId = id
        sel.efCategoryId = null
        setSelectedKeys(null)
        setEfDdClientObj(sel);

        APIServices.get(API.EF_Category_Std(id),
        ).then((res) => {
            setCatList(res.data);
        });
    };
    const getTier1Data = (id) => {
        let sel = efddclientobj;
        sel.efCategoryId = id;
        let arr = []
        setEfDdClientObj(sel);
        console.log(sel)
        let efstd = {

            include: [
                {
                    relation: "newEfSubcategory1s",
                    scope: {
                        include: [
                            {
                                relation: "newEfSubcategory2s",
                                scope: {
                                    include: [
                                        {
                                            relation: "newEfSubcategory3s",
                                            scope: {
                                                include: [{ relation: "newEfSubcategory4s" }],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],

        };
        let filter = { where: { efCategoryId: id, efStandardId: sel.standard } }
        forceUpdate()

        APIServices.get(API.EF_Category_edit(id) + `?filter=${encodeURIComponent(JSON.stringify(efstd))}`,
        ).then((res) => {
            if (res.data.newEfSubcategory1s) {
                console.log(convertDataForTree(res.data.newEfSubcategory1s))
                setEfDdList(convertDataForTree(res.data.newEfSubcategory1s))
            }
        });
    };
    function convertDataForTree(data, disabled_ids) {
        return data.map(category => {
            let key1 = "1-" + category.id.toString();
            const categoryNode = {
                value: "1-" + category.id.toString(),
                label: category.title, // Use category.title for top-level nodes

                disabled: disabled_ids.filter(x => x.split('-')[0] === "1").map(x => parseInt(x.split('-')[1])).find(x => x === category.id) ? true : false
            };

            if (category.newEfSubcategory2s && category.newEfSubcategory2s.length > 0) {
                categoryNode.children = category.newEfSubcategory2s.map(subcategory2 => {
                    let key2 = key1 + ",2-" + subcategory2.id.toString();
                    const subcategory2Node = {
                        value: "2-" + subcategory2.id.toString(),
                        label: subcategory2.title, // Use subcategory2.title for level 2 nodes
                        disabled: disabled_ids.filter(x => x.split('-')[0] === "2").map(x => parseInt(x.split('-')[1])).find(x => x === subcategory2.id) ? true : false
                    };

                    if (subcategory2.newEfSubcategory3s && subcategory2.newEfSubcategory3s.length > 0) {
                        subcategory2Node.children = subcategory2.newEfSubcategory3s.map(subcategory3 => {
                            let key3 = key2 + ",3-" + subcategory3.id.toString();

                            const subcategory3Node = {
                                value: "3-" + subcategory3.id.toString(),
                                label: subcategory3.title, // Use subcategory3.title for level 3 nodes
                                disabled: disabled_ids.filter(x => x.split('-')[0] === "3").map(x => parseInt(x.split('-')[1])).find(x => x === subcategory3.id) ? true : false
                            };

                            if (subcategory3.newEfSubcategory4s && subcategory3.newEfSubcategory4s.length > 0) {

                                subcategory3Node.children = subcategory3.newEfSubcategory4s.map(subcategory4 => {
                                    let key4 = key3 + ",4-" + subcategory4.id.toString();
                                    return {
                                        value: "4-" + subcategory4.id.toString(),
                                        label: subcategory4.title, // Use subcategory4.title for level 4 nodes
                                        disabled: disabled_ids.filter(x => x.split('-')[0] === "4").map(x => parseInt(x.split('-')[1])).find(x => x === subcategory4.id) ? true : false
                                    }
                                });
                            }

                            return subcategory3Node;
                        });
                    }

                    return subcategory2Node;
                });
            }

            return categoryNode;
        });
    }

    function processCheckboxData(data) {
        const selected_ids = Object.keys(data);
        const checked_ids = [];
        const partial_ids = [];

        selected_ids.forEach(key => {
            if (data[key].checked) {
                checked_ids.push(key);
            } else if (data[key].partialChecked) {
                partial_ids.push(key);
            }
        });

        return { selected_ids, checked_ids, partial_ids };
    }
    const saveClientEfCategory = async () => {
        const { value: accept } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Alert</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Are you sure want to save assignment
          </div>`,

            confirmButtonColor: 'green',
            showCancelButton: true,
            confirmButtonText:
                'Yes',
        })
        if (accept) {
            let newObj = { userProfileId: efddclientobj.userProfileId, locations: { tier1_id: efddclientobj.tier1_id, tier2_id: efddclientobj.tier2_id, tier3_id: efddclientobj.tier3_id }, efCategoryId: efddclientobj.efCategoryId, efStandardId: efddclientobj.efStandardId, efGhgCatId: efddclientobj.efGhgCatId, efGhgSubCatId: efddclientobj.efGhgSubCatId, selected_ids: selectedKeys }

            APIServices.post(API.EF_Category_Mapping_Submission, newObj).then((res) => {
                APIServices.get(API.Client_EF_Mapping_UP(efddclientobj.userProfileId)).then((res2) => {
                    updateAssignment(res2.data, rawsitelist)
                })

            })
        }
    }
    function processSingleObject(locations, locationData) {
        return locations.map(({ tier1_id, tier2_id, tier3_id, efGhgCat, efStandard, efCategory, efGhgSubCat, hierarchicalData, efGhgCatId, efStandardId, efCategoryId, efGhgSubCatId }) => {
            let tier1 = "Global";
            let tier2 = "All";
            let tier3 = "All";

            if (tier1_id) {
                const tier1Data = locationData.find((loc) => loc.id === tier1_id);
                if (tier1Data) tier1 = tier1Data.name;

                if (tier2_id) {
                    const tier2Data = tier1Data.locationTwos.find((loc) => loc.id === tier2_id);
                    if (tier2Data) {
                        tier2 = tier2Data.name;

                        if (tier3_id) {
                            const tier3Data = tier2Data.locationThrees.find((loc) => loc.id === tier3_id);
                            if (tier3Data) tier3 = tier3Data.name;
                        }
                    }
                }
            }

            return hierarchicalData.map((x) => ({
                ...x, ...{
                    uniqueEfId: `S${efStandardId}-${x?.dateId ? 'T' + x.dateId : 'NA'}-G${efGhgCatId}-GS${efGhgSubCatId}-I${efCategoryId}-` + x.hierarchyId,
                    startMonth: x?.startDate || 'NA', endMonth: x?.endDate || 'NA',
                    tier1,
                    tier2,
                    tier3,
                    standard: efStandard?.title || 'Not Found',
                    ghgcategory: efGhgCat?.title || 'Not Found',
                    ghgsubcategory: efGhgSubCat?.title || 'Not Found',
                    item: efCategory?.title || 'Not Found',
                    co2e: x?.co2e != null ? x.co2e : x?.co2e === 0 ? 0 : "-",
                    co2: x?.co2 != null ? x.co2 : x?.co2 === 0 ? 0 : "-",
                    ch4: x?.ch4 != null ? x.ch4 : x?.ch4 === 0 ? 0 : "-",
                    n2o: x?.n2o != null ? x.n2o : x?.n2o === 0 ? 0 : "-"
                }
            })
            )
        }).reduce((a, b) => [...a, ...b], [])
    }

    const updateAssignment = (data, shapedSite) => {
        let result = processSingleObject(data, shapedSite)
        console.log(result)
        setAssignment(result)
        forceUpdate()


    }
    const updateClientInfo = (e) => {
        if (e?.id) {
            setSelectedClient(e)
            let uriString2 = {
                "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

            }


            APIServices.get(API.LocationOne_UP(e.id) + `?filter=${encodeURIComponent(JSON.stringify(uriString2))}`).then((res) => {
                const shapedSite = res.data.map(item => {
                    if (item.locationTwos) {
                        item.locationTwos = item.locationTwos.filter(locationTwo =>
                            locationTwo.locationThrees && locationTwo.locationThrees.length > 0
                        );
                    }
                    return item;
                }).filter(item => item.locationTwos && item.locationTwos.length > 0)
                APIServices.get(API.Client_EF_Mapping_UP(e.id)).then((res) => {
                    updateAssignment(res.data, shapedSite)
                })
                let country = [{ name: 'All Countries', id: 0 }], city = [{ name: 'All Regions', id: 0 }], site = [{ name: 'All Sites', id: 0 }]
                console.log(shapedSite)
                shapedSite.forEach((i) => {
                    country.push({ name: i.name, id: i.id })
                    i.locationTwos.forEach((j) => {
                        city.push({ name: j.name, id: j.id })
                        j.locationThrees.forEach((k) => {
                            site.push({ name: k.name, id: k.id })
                        })
                    })
                })
                setRawSiteList(shapedSite)
                setEfDdClientObj({ id: undefined, tier1_id: 0, tier2_id: null, tier3_id: null, userProfileId: e.id, efCategoryId: null, efStandardId: null, efGhgCatId: null, efGhgSubCatId: null, selected_ids: [] })

                setSelectedList({ country, city, site })
            })
        } else {
            setEfDdClientObj({ id: undefined, tier1_id: null, tier2_id: null, tier3_id: null, userProfileId: null, efCategoryId: null, efStandardId: null, efGhgCatId: null, efGhgSubCatId: null, selected_ids: [] })
        }
    }
    const handleSelection = (obj, val) => {

        let loc = JSON.parse(JSON.stringify(efddclientobj))
        if (obj === 'country') {
            let country = [{ name: 'All ' + selectedlabel[0], id: 0 }], city = [{ name: 'All ' + selectedlabel[1], id: 0 }], site = [{ name: 'All ' + selectedlabel[2], id: 0 }]
            rawsitelist.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === val || val === 0) {
                    i.locationTwos.forEach((j) => {
                        city.push({ name: j.name, id: j.id })
                        j.locationThrees.forEach((k) => {

                            site.push({ name: k.name, id: k.id })
                        })
                    })
                }
            })
            loc.tier1_id = val
            loc.tier2_id = 0
            loc.tier3_id = null

            setSelectedList({ country, city, site })


            console.log(country, city, site, loc)
        } else if (obj === 'city') {
            let country = [{ name: 'All ' + selectedlabel[0], id: 0 }], city = [{ name: 'All ' + selectedlabel[1], id: 0 }], site = [{ name: 'All ' + selectedlabel[2], id: 0 }]

            rawsitelist.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === loc.tier1_id || loc.tier1_id === 0) {
                    i.locationTwos.forEach((j) => {
                        city.push({ name: j.name, id: j.id })
                        if (j.id === val || val === 0) {
                            j.locationThrees.forEach((k) => {

                                site.push({ name: k.name, id: k.id })
                            })
                        }
                    })
                }
            })
            loc.tier2_id = val
            loc.tier3_id = val === 0 ? null : 0
            console.log(country, city, site, loc)

            setSelectedList({ country, city, site })
        } else if (obj === 'site') {
            let country = [{ name: 'All ' + selectedlabel[0], id: 0 }], city = [{ name: 'All ' + selectedlabel[1], id: 0 }], site = [{ name: 'All ' + selectedlabel[2], id: 0 }]

            rawsitelist.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === loc.tier1_id || loc.tier1_id === 0) {
                    i.locationTwos.forEach((j) => {
                        city.push({ name: j.name, id: j.id })
                        if (j.id === loc.tier2_id || loc.tier2_id === 0) {
                            j.locationThrees.forEach((k) => {


                                site.push({ name: k.name, id: k.id })


                            })
                        }
                    })
                }
            })
            loc.tier3_id = val
            console.log(country, city, site, loc)

            setSelectedList({ country, city, site })
        } else if (obj === 'efStandardId') {
            APIServices.get(API.GHGCategory_EFStd(val),
            ).then((res) => {
                setGhgCatList(res.data);
            });

            loc.efStandardId = val;
            loc.efGhgCatId = null
            loc.efGhgSubCatId = null
            loc.efCategoryId = null;
            setSelectedKeys([])
        } else if (obj === 'efGhgCatId') {
            APIServices.get(API.GHGSubGategory_GHGCat(val),
            ).then((res) => {
                setGhgSubCatList(res.data);
            });
            loc.efGhgCatId = val;
            loc.efGhgSubCatId = null
            loc.efCategoryId = null;
            setSelectedKeys([])
        } else if (obj === 'efGhgSubCatId') {
            APIServices.get(API.EF_Category_GHGSubCat(val),
            ).then((res) => {
                setCatList(res.data);
            });



            loc.efGhgSubCatId = val;
            loc.efCategoryId = null;
            setSelectedKeys([])
        } else if (obj === 'efCategoryId') {

            loc.efCategoryId = val;


        }
        let newObj = { userProfileId: loc.userProfileId, locations: { tier1_id: loc.tier1_id, tier2_id: loc.tier2_id, tier3_id: loc.tier3_id }, efCategoryId: loc.efCategoryId, efStandardId: loc.efStandardId, efGhgCatId: loc.efGhgCatId, efGhgSubCatId: loc.efGhgSubCatId }
        if (newObj.userProfileId && newObj.efCategoryId && newObj.efStandardId && newObj.efGhgCatId && newObj.efGhgSubCatId) {
            APIServices.post(API.EF_Category_Mapping, newObj).then((res) => {
                console.log(convertDataForTree(res.data?.subCategory, res.data?.disabled_ids || []))

                // let checked = {}

                // res.data.checked_ids.forEach((key) => {
                //     checked[key] = { checked: true, partialChecked: false }
                // })
                // res.data.partial_ids.forEach((key) => {
                //     checked[key] = { checked: false, partialChecked: true }
                // })
                setSelectedKeys(() => res.data?.selected_ids || [])
                console.log(convertDataForTree(res.data?.subCategory || [], res.data?.disabled_ids || []))
                setEfDdList(convertDataForTree(res.data?.subCategory || [], res.data?.disabled_ids || []))

            })
        }


        setEfDdClientObj((prev) => (loc))
    }
    const nodeTemplate = (node) => {
        return (
            <span
                style={{
                    color: node.disabled ? "gray" : "inherit",
                    cursor: node.disabled ? "not-allowed" : "pointer",
                }}
            >
                {node.label}
            </span>
        );
    };
    const getFullyCheckedKeys = (nodes, checked) => {
        const fullyChecked = new Set();

        // Recursive function to traverse nodes
        const traverse = (node) => {
            let areChildrenFullyChecked = true;

            // If the node has children, process them first
            if (node.children && node.children.length) {
                node.children.forEach(traverse);

                // Check if all children are fully checked
                areChildrenFullyChecked = node.children.every((child) =>
                    fullyChecked.has(child.value)
                );
            }

            // Include the current node if it's fully checked
            if (
                (areChildrenFullyChecked && checked.includes(node.value)) ||
                (!node.children && checked.includes(node.value))
            ) {
                fullyChecked.add(node.value);
            }
        };

        // Start traversal from the root nodes
        nodes.forEach(traverse);

        return Array.from(fullyChecked);
    };
    const customIcons = {


        check: <div class="p-checkbox p-component" data-pc-section="checkboxcontainer"><div class="p-checkbox-box p-highlight flex justify-content-center" role="checkbox" aria-checked="true" data-pc-section="checkbox"><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="p-icon p-checkbox-icon p-c" aria-hidden="true" data-pc-section="checkboxicon"><path d="M4.86199 11.5948C4.78717 11.5923 4.71366 11.5745 4.64596 11.5426C4.57826 11.5107 4.51779 11.4652 4.46827 11.4091L0.753985 7.69483C0.683167 7.64891 0.623706 7.58751 0.580092 7.51525C0.536478 7.44299 0.509851 7.36177 0.502221 7.27771C0.49459 7.19366 0.506156 7.10897 0.536046 7.03004C0.565935 6.95111 0.613367 6.88 0.674759 6.82208C0.736151 6.76416 0.8099 6.72095 0.890436 6.69571C0.970973 6.67046 1.05619 6.66385 1.13966 6.67635C1.22313 6.68886 1.30266 6.72017 1.37226 6.76792C1.44186 6.81567 1.4997 6.8786 1.54141 6.95197L4.86199 10.2503L12.6397 2.49483C12.7444 2.42694 12.8689 2.39617 12.9932 2.40745C13.1174 2.41873 13.2343 2.47141 13.3251 2.55705C13.4159 2.64268 13.4753 2.75632 13.4938 2.87973C13.5123 3.00315 13.4888 3.1292 13.4271 3.23768L5.2557 11.4091C5.20618 11.4652 5.14571 11.5107 5.07801 11.5426C5.01031 11.5745 4.9368 11.5923 4.86199 11.5948Z" fill="currentColor"></path></svg></div></div>,
        uncheck: <div class="p-checkbox p-component" data-pc-section="checkboxcontainer"><div class="p-checkbox-box" role="checkbox" data-pc-section="checkbox"></div></div>,
        halfCheck: <div class="p-checkbox p-component" data-pc-section="checkboxcontainer"><div class="p-checkbox-box p-indeterminate flex justify-content-center" role="checkbox" aria-checked="false" data-pc-section="checkbox"><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="p-icon p-checkbox-icon p-c" style={{ color: "#315975" }} aria-hidden="true" data-pc-section="checkboxicon"><path d="M13.2222 7.77778H0.777778C0.571498 7.77778 0.373667 7.69584 0.227806 7.54998C0.0819442 7.40412 0 7.20629 0 7.00001C0 6.79373 0.0819442 6.5959 0.227806 6.45003C0.373667 6.30417 0.571498 6.22223 0.777778 6.22223H13.2222C13.4285 6.22223 13.6263 6.30417 13.7722 6.45003C13.9181 6.5959 14 6.79373 14 7.00001C14 7.20629 13.9181 7.40412 13.7722 7.54998C13.6263 7.69584 13.4285 7.77778 13.2222 7.77778Z" fill="currentColor"></path></svg></div></div>,
        expandClose: <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="p-icon [object Object]" aria-hidden="true" data-pc-section="togglericon"><path d="M4.38708 13C4.28408 13.0005 4.18203 12.9804 4.08691 12.9409C3.99178 12.9014 3.9055 12.8433 3.83313 12.7701C3.68634 12.6231 3.60388 12.4238 3.60388 12.2161C3.60388 12.0084 3.68634 11.8091 3.83313 11.6622L8.50507 6.99022L3.83313 2.31827C3.69467 2.16968 3.61928 1.97313 3.62287 1.77005C3.62645 1.56698 3.70872 1.37322 3.85234 1.22959C3.99596 1.08597 4.18972 1.00371 4.3928 1.00012C4.59588 0.996539 4.79242 1.07192 4.94102 1.21039L10.1669 6.43628C10.3137 6.58325 10.3962 6.78249 10.3962 6.99022C10.3962 7.19795 10.3137 7.39718 10.1669 7.54416L4.94102 12.7701C4.86865 12.8433 4.78237 12.9014 4.68724 12.9409C4.59212 12.9804 4.49007 13.0005 4.38708 13Z" fill="currentColor"></path></svg>,
        expandOpen: <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="p-icon [object Object]" aria-hidden="true" data-pc-section="togglericon"><path d="M7.01744 10.398C6.91269 10.3985 6.8089 10.378 6.71215 10.3379C6.61541 10.2977 6.52766 10.2386 6.45405 10.1641L1.13907 4.84913C1.03306 4.69404 0.985221 4.5065 1.00399 4.31958C1.02276 4.13266 1.10693 3.95838 1.24166 3.82747C1.37639 3.69655 1.55301 3.61742 1.74039 3.60402C1.92777 3.59062 2.11386 3.64382 2.26584 3.75424L7.01744 8.47394L11.769 3.75424C11.9189 3.65709 12.097 3.61306 12.2748 3.62921C12.4527 3.64535 12.6199 3.72073 12.7498 3.84328C12.8797 3.96582 12.9647 4.12842 12.9912 4.30502C13.0177 4.48162 12.9841 4.662 12.8958 4.81724L7.58083 10.1322C7.50996 10.2125 7.42344 10.2775 7.32656 10.3232C7.22968 10.3689 7.12449 10.3944 7.01744 10.398Z" fill="currentColor"></path></svg>,
        // expandAll: <span className="custom-icon">➕</span>,
        // collapseAll: <span className="custom-icon">➖</span>,
        // parentClose: <span className="custom-icon">📁</span>,
        // parentOpen: <span className="custom-icon">📂</span>,
        // leaf: <span className="custom-icon">📄</span>,
    };
    const itemTemplate = (option) => {
        console.log(option);
        return (
            <div className="flex align-items-center ">
                <div>{option.name}</div>
            </div>
        );
    };
    const RowFilterTemplate = (options, obj) => {
        return (
            <MultiSelect
                value={options.value}
                options={Array.from(new Set(assignment.map(i => i[obj])))}

                onChange={(e) => options.filterCallback(e.value)}

                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: "14rem" }}
            />
        );
    };
    const tier2RowFilterTemplate = (options) => {
        return (
            <MultiSelect
                value={options.value}
                options={Array.from(new Set(assignment.map(i => i.tier2)))}

                onChange={(e) => options.filterCallback(e.value)}

                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: "14rem" }}
            />
        );
    };
    const tier3RowFilterTemplate = (options) => {
        return (
            <MultiSelect
                value={options.value}
                options={Array.from(new Set(assignment.map(i => i.tier3)))}

                onChange={(e) => options.filterCallback(e.value)}

                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: "14rem" }}
            />
        );
    };
    const headerGroup = (
        <ColumnGroup>
            {/* First Row */}
            <Row>
                <Column header="Reporting Entity" colSpan={3} />
                <Column header="EF Details" colSpan={1} />
                <Column header="Category Breakdown" colSpan={4} />
                <Column header="Item Breakdown" colSpan={4} />
            </Row>
            {/* Second Row */}
            <Row>
                <Column header={selectedlabel[0]} />
                <Column header={selectedlabel[1]} />
                <Column header={selectedlabel[2]} />
                <Column header="EF_KEY" />
                <Column header="Standard" />
                <Column header="GHG Category" />
                <Column header="GHG SubCategory" />
                <Column header="Item" />
                <Column header="SubCategory1" />
                <Column header="SubCategory2" />
                <Column header="SubCategory3" />
                <Column header="SubCategory4" />
            </Row>
        </ColumnGroup>
    );
    return (
        <div className="col-12">
            {selector.role === 'eisqradmin' ?
                <div className="card" style={{ height: 'calc(100vh - 100px)', overflow: 'auto' }}>
                    <h3> EF Category Configuration for Client </h3>
                    <div className="col-12 grid align-items-center justify-content-between">
                        <div className="col-10 grid align-items-center">
                            <label className="col-2 ">
                                Select Client <span style={{ color: 'red' }}>*</span>
                            </label>
                            <div className="col-5">
                                <Dropdown options={adminlist} style={{ width: '100%' }} filter optionLabel="information.companyname" value={selectedclient} onChange={(e) => { updateClientInfo(e.value) }} />

                            </div>

                        </div>

                    </div>
                    {selectedclient && <Splitter style={{ height: 'auto' }} >
                        <SplitterPanel className="flex  justify-content-center">
                            <div className="col-12 grid align-items-center justify-content-between">

                                {efddclientobj.userProfileId &&
                                    <div className='col-12'>
                                        <div class="grid p-4">
                                            <div class="col-4">
                                                <Dropdown
                                                    id="country"
                                                    optionLabel="name"
                                                    optionValue="id"
                                                    value={efddclientobj.tier1_id}
                                                    options={selectedlist.country}
                                                    onChange={(e) =>
                                                        handleSelection('country', e.value)
                                                    }
                                                    placeholder={'Choose ' + selectedlabel[0]}
                                                    className="w-full"
                                                />
                                            </div>
                                            {(efddclientobj.tier1_id !== null && efddclientobj.tier1_id !== 0) && <div class="col-4">
                                                <Dropdown
                                                    id="city"
                                                    optionLabel="name"
                                                    optionValue="id"
                                                    value={efddclientobj.tier2_id}
                                                    options={selectedlist.city}
                                                    onChange={(e) =>
                                                        handleSelection('city', e.value)
                                                    }
                                                    placeholder={'Choose' + selectedlabel[1]}
                                                    className="w-full"
                                                />
                                            </div>}
                                            {efddclientobj.tier1_id !== null && efddclientobj.tier1_id !== 0 && efddclientobj.tier2_id !== null && efddclientobj.tier2_id !== 0 && <div class="col-4">
                                                <Dropdown
                                                    id="unit"
                                                    optionLabel="name"
                                                    optionValue="id"
                                                    value={efddclientobj.tier3_id}
                                                    options={selectedlist.site}
                                                    onChange={(e) =>
                                                        handleSelection('site', e.value)
                                                    }
                                                    placeholder={'Choose ' + selectedlabel[2]}
                                                    className="w-full"
                                                />
                                            </div>}
                                        </div>



                                    </div>
                                }
                                <div className="col-12 grid m-0 p-0">
                                    {efddclientobj.userProfileId !== null && selectedlist.country.length !== null && <div className="col-6 grid align-items-center justify-content-between">
                                        <div className="col-12 grid align-items-center">
                                            <label className="col-4">
                                                Standard <span style={{ color: 'red' }}>*</span>
                                            </label>
                                            <div className="col-8">
                                                <Dropdown options={stdlist} style={{ width: '100%' }} filter optionLabel="title" optionValue="id" value={efddclientobj.efStandardId} onChange={(e) => { handleSelection('efStandardId', e.value) }} />

                                            </div>

                                        </div>

                                    </div>}

                                    {efddclientobj.efStandardId !== null && <div className="col-6 grid align-items-center">
                                        <div className="col-12 grid align-items-center">
                                            <label className="col-4">
                                                GHG Category <span style={{ color: 'red' }}>*</span>
                                            </label>
                                            <div className="col-8">
                                                <Dropdown options={ghgcatlist} style={{ width: '100%' }} filter optionLabel="title" optionValue="id" value={efddclientobj.efGhgCatId} onChange={(e) => { handleSelection('efGhgCatId', e.value) }} />

                                            </div>
                                        </div>
                                    </div>}
                                </div>
                                <div className="col-12 grid m-0 p-0">
                                    {efddclientobj.efGhgCatId && <div className="col-6 grid align-items-center justify-content-between">
                                        <div className="col-12 grid align-items-center">
                                            <label className="col-4">
                                                GHG Sub Category <span style={{ color: 'red' }}>*</span>
                                            </label>
                                            <div className="col-8">
                                                <Dropdown options={ghgsubcatlist} style={{ width: '100%' }} filter optionLabel="title" optionValue="id" value={efddclientobj.efGhgSubCatId} onChange={(e) => { handleSelection('efGhgSubCatId', e.value) }} />

                                            </div>

                                        </div>

                                    </div>}

                                    {efddclientobj.efGhgSubCatId !== null && <div className="col-6 grid align-items-center">
                                        <div className="col-12 grid align-items-center">
                                            <label className="col-4">
                                                Item <span style={{ color: 'red' }}>*</span>
                                            </label>
                                            <div className="col-8">
                                                <Dropdown options={catlist} style={{ width: '100%' }} filter optionLabel="title" optionValue="id" value={efddclientobj.efCategoryId} onChange={(e) => { handleSelection('efCategoryId', e.value) }} />

                                            </div>
                                        </div>
                                    </div>}
                                </div>
                                {efddclientobj.efCategoryId !== null && efddclientobj.efStandardId !== null && efddclientobj.tier1_id !== null && efddclientobj.efGhgCatId !== null && efddclientobj.efGhgSubCatId &&
                                    <>
                                        {<div className="col-12 flex justify-content-end">
                                            <Button label={'Save Assignment'} onClick={saveClientEfCategory} ></Button>
                                        </div>}
                                        <CheckboxTree
                                            nodes={efddlist}
                                            checked={selectedKeys}
                                            icons={customIcons}
                                            expanded={expanded}
                                            onCheck={(checked) => {
                                                console.log(checked)
                                                setSelectedKeys(checked);



                                            }}
                                            onExpand={(expanded) => { setExpanded(expanded) }}
                                        />

                                        {/* <Tree nodeTemplate={nodeTemplate} value={efddlist} selectionMode='checkbox' selectionKeys={selectedKeys} onSelectionChange={(e) => { console.log(e.value); setSelectedKeys(e.value) }} className="w-full" /> */}

                                    </>

                                }
                            </div>
                        </SplitterPanel>
                        <SplitterPanel className="flex  justify-content-center">

                            {efddclientobj.userProfileId &&
                                <DataTable showGridlines style={{ display: "inline-grid" }}
                                    filters={{
                                        startMonth: { value: null, matchMode: "in" }, endMonth: { value: null, matchMode: "in" },
                                        tier1: { value: null, matchMode: "in" }, tier2: { value: null, matchMode: "in" }, tier3: { value: null, matchMode: "in" }
                                        , standard: { value: null, matchMode: "in" }, ghgcategory: { value: null, matchMode: "in" }, ghgsubcategory: { value: null, matchMode: "in" }, item: { value: null, matchMode: "in" }
                                        , subCategory1: { value: null, matchMode: "in" }, subCategory2: { value: null, matchMode: "in" }, subCategory3: { value: null, matchMode: "in" }, subCategory4: { value: null, matchMode: "in" }
                                    }} value={assignment} scrollable  >

                                    <Column header={selectedlabel[0]} field={'tier1'} showFilterMatchModes={false}
                                        filter
                                        body={(rowData) => rowData.tier1}
                                        filterElement={(options) => RowFilterTemplate(options, 'tier1')} />

                                    <Column header={selectedlabel[1]} field={'tier2'} showFilterMatchModes={false}
                                        filter
                                        body={(rowData) => rowData.tier2}
                                        filterElement={(options) => RowFilterTemplate(options, 'tier2')} />
                                    <Column header={selectedlabel[2]} field={'tier3'} showFilterMatchModes={false}
                                        filter
                                        body={(rowData) => rowData.tier3}
                                        filterElement={(options) => RowFilterTemplate(options, 'tier3')} />

                                    <Column header={'EF_ID'} bodyStyle={{ width: '150px', minWidth: '150px' }} field={'uniqueEfId'} />

                                    <Column header={'Standard'} field={'standard'} showFilterMatchModes={false}
                                        filter

                                        filterElement={(options) => RowFilterTemplate(options, 'standard')} />
                                    <Column header={'Start Month'} field={'startMonth'} showFilterMatchModes={false}
                                        filter
                                        body={(rowData) => rowData.startMonth}
                                        filterElement={(options) => RowFilterTemplate(options, 'startMonth')} />
                                    <Column header={'End Month'} field={'endMonth'} showFilterMatchModes={false}
                                        filter
                                        body={(rowData) => rowData.endMonth}
                                        filterElement={(options) => RowFilterTemplate(options, 'endMonth')} />
                                    <Column header={'GHG Category'} field={'ghgcategory'} showFilterMatchModes={false}
                                        filter

                                        filterElement={(options) => RowFilterTemplate(options, 'ghgcategory')} />
                                    <Column header={'GHG SubCategory'} field={'ghgsubcategory'} showFilterMatchModes={false}
                                        filter

                                        filterElement={(options) => RowFilterTemplate(options, 'ghgsubcategory')} />
                                    <Column header={'Item'} field={'item'} showFilterMatchModes={false}
                                        filter

                                        filterElement={(options) => RowFilterTemplate(options, 'item')} />
                                    <Column header={'Item Category1'} field={'subCategory1'} showFilterMatchModes={false}
                                        filter

                                        filterElement={(options) => RowFilterTemplate(options, 'subCategory1')} />
                                    <Column header={'Item Category2'} field={'subCategory2'} showFilterMatchModes={false}
                                        filter

                                        filterElement={(options) => RowFilterTemplate(options, 'subCategory2')} />
                                    <Column header={'Item Category3'} field={'subCategory3'} showFilterMatchModes={false}
                                        filter

                                        filterElement={(options) => RowFilterTemplate(options, 'subCategory3')} />
                                    <Column header={'Item Category4'} field={'subCategory4'} showFilterMatchModes={false}
                                        filter

                                        filterElement={(options) => RowFilterTemplate(options, 'subCategory4')} />
                                    <Column header={'Co2e in kg'} field={'co2e'}
                                    />
                                    <Column header={"co2 in kg"} field={"co2"} />
                                    <Column header={"ch4 in kg"} field={"ch4"} />
                                    <Column header={"n2o in kg"} field={"n2o"} />

                                </DataTable>
                            }
                        </SplitterPanel>
                    </Splitter>}


                </div>
                :
                <div className="card col-12">You have no rights to access this page</div>
            }
        </div>
    )
}
export default ClientEfCategoryMapping