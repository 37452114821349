import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";

const data = {
  "Senior Management": [
    {
      year: "FY-2024",
      male: 96.2,
      female: 3.8,
    },
    {
      year: "FY-2025",
      male: 0,
      female: 0,
    },
  ],
  "Middle Management": [
    {
      year: "FY-2024",
      male: 90.0,
      female: 10.0,
    },
    {
      year: "FY-2025",
      male: 0,
      female: 0,
    },
  ],
  "Non Management": [
    {
      year: "FY-2024",
      male: 83.4,
      female: 16.6,
    },
    {
      year: "FY-2025",
      male: 0,
      female: 0,
    },
  ],
};

const GenderManagementGlobalDemo = () => {
  const [location, setLocation] = useState("Senior Management");

  return (
    <div
      style={{
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "19.2px",
        textAlign: "left",
        margin: "18px 10px",
      }}
    >
      <h3 style={{ fontSize: "18px", margin: "25px" }}>
        Gender Diversity by Management Level Globally
      </h3>
      <div style={{ fontWeight: 200, fontSize: "14px", marginBottom: "10px" }}>
        Select a Management level to view the global gender diversity data.
      </div>
      {/* Location Selection Buttons */}
      <div style={{ marginBottom: "20px" }}>
        {Object.keys(data).map((locationName) => (
          <button
            key={locationName}
            onClick={() => setLocation(locationName)}
            style={{
              padding: "8px 16px",
              backgroundColor: location === locationName ? "#8888" : "#F0F0F0",
              color: location === locationName ? "white" : "gray",
              border: "none",
              borderRadius: "8px",
              height: "2.5rem",
              marginRight: "2rem",
              cursor: "pointer",
            }}
          >
            {locationName}
          </button>
        ))}
      </div>
      {/* Responsive Container for Recharts */}
      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          data={data[location]}
          margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="year"></XAxis>
          <YAxis>
            <Label
              value="Percentage"
              angle={-90}
              position="insideLeft"
              style={{
                textAnchor: "middle",
                fill: "#333",
                fontSize: "14px",
              }}
            />
          </YAxis>
          <Tooltip />
          <Legend verticalAlign="bottom" />
          <Bar dataKey="male" name="Male" fill="#47CC" barSize={60} />
          <Bar dataKey="female" name="Female" fill="#88CC" barSize={60} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default GenderManagementGlobalDemo;
