import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { Button } from "primereact/button";
import { useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Category } from "@mui/icons-material";
import { Global } from "@emotion/react";

const data = {
  India: [
    {
      year: "FY-2023",
      Category_1: 374331.58,
      Category_2: 176.82,
      Category_3: 2638.71,
      Category_4: 13190.96,
      Category_5: 363.34,
      Category_6: 3499.78,
      Category_7: 5548.08,
      Category_9: 208060.7,
      Category_11: 21166063.12,
      Category_12: 12140.54,
      Category_15: 15469.15,
      total: 384681.07,
    },
    {
      year: "FY-2024",
      Category_1: 0,
      Category_2: 0,
      Category_3: 0,
      Category_4: 0,
      Category_5: 0,
      Category_6: 0,
      Category_7: 0,
      Category_9: 0,
      Category_11: 0,
      Category_12: 0,
      Category_15: 0,
      total: 0,
    },
  ],
  Indonesia: [
    {
      year: "FY-2023",
      Category_1: 4394.06,
      Category_2: 104.33,
      Category_3: 120.32,
      Category_4: 4026.94,
      Category_5: 12.96,
      Category_6: 5.16,
      Category_7: 1074.6,
      Category_9: 1209.07,
      Category_11: 373393.26,
      Category_12: 340.36,
      Category_15: 0,
    },
    {
      year: "FY-2024",
      Category_1: 0,
      Category_2: 0,
      Category_3: 0,
      Category_4: 0,
      Category_5: 0,
      Category_6: 0,
      Category_7: 0,
      Category_9: 0,
      Category_11: 0,
      Category_12: 0,
      Category_15: 0,
    },
  ],
  UnitedKingdom: [
    {
      year: "FY-2023",
      Category_1: 662.84,
      Category_2: 0,
      Category_3: 16.28,
      Category_4: 17.44,
      Category_5: 3.11,
      Category_6: 561.32,
      Category_7: 480.48,
      Category_9: 0,
      Category_11: 5302.93,
      Category_12: 0.945,
      Category_15: 0,
    },
    {
      year: "FY-2024",
      Category_1: 0,
      Category_2: 0,
      Category_3: 0,
      Category_4: 0,
      Category_5: 0,
      Category_6: 0,
      Category_7: 0,
      Category_9: 0,
      Category_11: 0,
      Category_12: 0,
      Category_15: 0,
    },
  ],
  Global: [
    {
      year: "FY-2023",
      Category_1: 379387.89,
      Category_2: 281.15,
      Category_3: 2775.31,
      Category_4: 17235.34,
      Category_5: 379.41,
      Category_6: 4066.26,
      Category_7: 7103.16,
      Category_9: 209269.76,
      Category_11: 21544759.32,
      Category_12: 12481.85,
      Category_15: 15469.15,
    },
    {
      year: "FY-2024",
      Category_1: 0,
      Category_2: 0,
      Category_3: 0,
      Category_4: 0,
      Category_5: 0,
      Category_6: 0,
      Category_7: 0,
      Category_9: 0,
      Category_11: 0,
      Category_12: 0,
      Category_15: 0,
    },
  ],
};

const CustomLegend = (props) => {
  const { payload } = props;
  return (
    <ul
      style={{
        display: "flex",
        listStyleType: "none",
        justifyContent: "center",
        padding: 0,
        marginTop: "2rem",
      }}
    >
      {payload.map((entry, index) => (
        <li
          key={`item-${index}`}
          style={{ color: entry.color, marginRight: 20 }}
        >
          <span
            style={{
              color: entry.color,
              marginRight: 4,
              fontSize: "20px",
            }}
          >
            ■
          </span>
          <span style={{ color: "#555", fontSize: "12px" }}>
            {entry.value === "Category_1"
              ? "Category 1 - Purchased goods and services"
              : entry.value === "Category_2"
              ? "Category 2 - Capital goods"
              : entry.value === "Category_3"
              ? "Category 3 - Fuel and energy related activities"
              : entry.value === "Category_4"
              ? "Category 4 - Upstream transportation and distribution"
              : entry.value === "Category_5"
              ? "Category 5 - Waste generation in operation"
              : entry.value === "Category_6"
              ? "Category 6 - Business Travel"
              : entry.value === "Category_7"
              ? "Category 7 - Employee commuting"
              : entry.value === "Category_9"
              ? "Category 8 - Downstream transportation and distribution"
              : entry.value === "Category_11"
              ? "Category 9 - Use of sold products (avg. 15 yrs)"
              : entry.value === "Category_12"
              ? "Category 10 - End-of-life treatment of sold products"
              : entry.value === "Category_15"
              ? "Category 11 - Investments (Equity investments in subsidiaries more 50%)"
              : ""}
          </span>
        </li>
      ))}
    </ul>
  );
};

const DemoScope3Breakdown = () => {
  const [activeMode, setActiveMode] = useState(true);
  const [region, setRegion] = useState("India");

  const dt = useRef(null);

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "19.2px",
            textAlign: "left",
            margin: "18px 10px 18px 10px",
          }}
        >
          Scope 3 Emissions by Location
          <div
            style={{
              fontFamily: "Lato",
              fontSize: "12px",
              fontWeight: 300,
              lineHeight: "12.2px",
              textAlign: "left",
              margin: "18px 10px 18px 0px",
            }}
          >
            Emissions across various categories
          </div>
        </div>
        <div
          style={{
            margin: "18px 10px 18px 10px",
            display: "flex",
          }}
        >
          {/* <input
            type="month"
            style={{
              padding: "3px",
              borderRadius: "8px",
              width: "15rem",
              border: "1px solid grey",
              height: "30px",
              fontFamily: "lato",
            }}
          /> */}
          <div
            className="buttons"
            style={{
              background: "#F0F2F4",
              borderRadius: "3px",
              width: "4.5rem",
              marginLeft: "10px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                background: `${!activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
                marginRight: "4px",
              }}
              onClick={() => {
                setActiveMode(false);
              }}
            >
              <i className="pi pi-table fs-19 " />
            </Button>
            <Button
              style={{
                background: `${activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
              }}
              onClick={() => {
                setActiveMode(true);
              }}
            >
              <i className="pi pi-chart-bar fs-19" />
            </Button>
          </div>
          <Button
            style={{
              padding: "6px",
              color: "white",
              height: "30px",
              marginLeft: "2px",
            }}
            onClick={() => {
              setActiveMode(true);
            }}
          >
            <i className="pi pi-download fs-19" />
          </Button>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        {Object.keys(data).map((regionName) => (
          <button
            key={regionName}
            onClick={() => setRegion(regionName)}
            style={{
              padding: "8px 16px",
              backgroundColor: region === regionName ? "#41AA95" : "#F0F0F0",
              color: region === regionName ? "white" : "gray",
              border: "none",
              borderRadius: "8px",
              height: "2.5rem",
              marginRight: "2rem",
              cursor: "pointer",
            }}
          >
            {regionName}
          </button>
        ))}
      </div>
      {activeMode && (
        <div style={{ width: "100%", height: 400 }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={data[region]}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis dataKey="year" />
              <YAxis
                label={{
                  value: "Million tCO2e",
                  angle: -90,
                  position: "insideLeft",
                  offset: 0,
                  style: { textAnchor: "middle", fill: "#555" },
                }}
              />{" "}
              <Tooltip />
              <Legend content={CustomLegend} />
              <Bar dataKey="Category_1" fill="#F28C82" barSize={40} />
              <Bar dataKey="Category_2" fill="#A7C7E7" barSize={40} />
              <Bar dataKey="Category_3" fill="#D4C2F3" barSize={40} />
              <Bar dataKey="Category_4" fill="#B28C82" barSize={40} />
              <Bar dataKey="Category_5" fill="#B28C82" barSize={40} />
              <Bar dataKey="Category_6" fill="#B28C82" barSize={40} />
              <Bar dataKey="Category_7" fill="#B28C82" barSize={40} />
              <Bar dataKey="Category_9" fill="#B28C82" barSize={40} />
              <Bar dataKey="Category_11" fill="#B28C82" barSize={40} />
              <Bar dataKey="Category_12" fill="#B28C82" barSize={40} />
              <Bar dataKey="Category_15" fill="#B28C82" barSize={40} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </>
  );
};

export default DemoScope3Breakdown;
