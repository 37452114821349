import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, LabelList } from 'recharts';
import supplyData from '../supply.json';

// Function to fetch and process the data
const fetchData = async () => {
     // Adjust path if necessary
    const rawData = supplyData;

    // Define spend brackets based on the analyzed data
    const spendBrackets = {
        'Upto 100 Cr. INR': { min: 0, max: 100, count: 0, totalScore: 0 },
        '100-200 Cr. INR': { min: 100, max: 200, count: 0, totalScore: 0 },
        '200-300 Cr. INR': { min: 200, max: 300, count: 0, totalScore: 0 },
        '300-400 Cr. INR': { min: 300, max: 400, count: 0, totalScore: 0 },
        'Above 400 Cr. INR': { min: 400, max: Infinity, count: 0, totalScore: 0 },
    };

    // Categorize suppliers based on their spend and calculate average MSI scores
    rawData.forEach((supplier) => {
        const spent = supplier.spent;  // Assuming 'spent' is already in Cr. INR
        for (let key in spendBrackets) {
            if (spent > spendBrackets[key].min && spent <= spendBrackets[key].max) {
                spendBrackets[key].count += 1;
                spendBrackets[key].totalScore += supplier.msi_score;
            }
        }
    });

    // Format data for Recharts
    const chartData = Object.keys(spendBrackets).map((key) => ({
        bracket: key,
        averageMSI: spendBrackets[key].count > 0 
            ? (spendBrackets[key].totalScore / spendBrackets[key].count).toFixed(2) 
            : 0
    }));

    return chartData;
};

const SpendMSIRatingChart = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        fetchData().then(setData);
    }, []);

    return (
        <div className="container mt-4">
            <h5 className="text-center mb-4">Average MSI Ratings Based on Annual Spend Bracket</h5>
            <ResponsiveContainer width="100%" height={400}>
                <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="bracket" />
                    <YAxis domain={[0, 100]} />
                    <Tooltip />
                    <Bar dataKey="averageMSI" fill="#003f5c" barSize={40}>
                        <LabelList dataKey="averageMSI" position="top" fill="#000" fontSize={14} />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default SpendMSIRatingChart;
